export const logRocketAppId = () =>
  process.env.NEXT_PUBLIC_TOOL_LOG_ROCKER_APP_ID

export const gtmId = () => process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_TOOL_ID

export const sanityProjectId = () => process.env.NEXT_PUBLIC_SANITY_PROJECT_ID
export const sanityDataset = () => process.env.NEXT_PUBLIC_SANITY_DATASET
export const sanityToken = () => process.env.NEXT_PUBLIC_SANITY_TOKEN

export const backendGraphqlEndpoint = () =>
  process.env.NEXT_PUBLIC_BACKEND_JOBS_GRAPHQL_ENDPOINT
