import { ApolloClient, InMemoryCache, ApolloLink } from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { readAuthToken } from "../features/user/services/authService"
import { createUploadLink } from "apollo-upload-client"
import { backendGraphqlEndpoint } from "../root/envs"

const uploadLink: ApolloLink = createUploadLink({
  uri: backendGraphqlEndpoint(),
}) as any
const authLink = setContext((x, { headers }) => {
  const token = typeof window !== "undefined" ? readAuthToken() : undefined
  return {
    headers: {
      ...headers,
      authorization: token ? `bearer ${token}` : "",
    },
  }
})

const client = new ApolloClient({
  link: ApolloLink.from([authLink, uploadLink]),
  cache: new InMemoryCache(),
  connectToDevTools: process.env.NODE_ENV === "development",
})

export default client
