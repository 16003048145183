import { useRouter } from "next/router"
import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../state/store"
import { refreshUser } from "../../actions"

interface Props {
  children: any
  loader: React.ReactNode
  loginUrl: string
}

const AuthWrapper = ({ children, loader, loginUrl }: Props) => {
  const router = useRouter()
  const dispatch = useAppDispatch()
  const { initialized, isAuthenticated } = useAppSelector((state) => state.user)

  React.useEffect(() => {
    dispatch(refreshUser() as any)
  }, [isAuthenticated])

  React.useEffect(() => {
    if (initialized && !isAuthenticated && router.pathname !== loginUrl) {
      router.push(loginUrl)
    }
  }, [isAuthenticated, initialized])

  if (!initialized || (!isAuthenticated && router.pathname !== loginUrl)) {
    return <>{loader}</>
  }

  return <>{children}</>
}

export default AuthWrapper
