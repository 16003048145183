import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { UserAccount, UserState } from "./userTypes"

const createInitialState = () =>
  ({
    initialized: false,
  } as UserState)

const user = createSlice({
  name: "user",
  initialState: createInitialState(),
  reducers: {
    setAuthorizedUser(state, action: PayloadAction<UserAccount>) {
      return {
        initialized: true,
        isAuthenticated: true,
        isAnonymous: false,
        account: action.payload,
      }
    },
    setAnonymousUser(state) {
      return {
        initialized: true,
        isAuthenticated: false,
        isAnonymous: true,
        account: undefined,
      }
    },
  },
})

export const { setAuthorizedUser, setAnonymousUser } = user.actions

export default user.reducer
