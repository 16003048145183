import { AppThunk } from "../../../state/store"

import { setAnonymousUser, setAuthorizedUser } from "../state/userSlice"
import { readAuthToken, removeAuthToken } from "../services/authService"
import { userQuery } from "../../../api/user/api"

export const logoutUser = (): AppThunk => async (dispatch) => {
  removeAuthToken()
  dispatch(setAnonymousUser())
}

export const refreshUser = (): AppThunk => async (dispatch) => {
  if (!readAuthToken()) {
    dispatch(setAnonymousUser())
    return
  }

  try {
    const result = await userQuery()
    if (!result.data) {
      throw Error("Missing result data")
    }

    if (
      !result.data.getUser.roles?.length ||
      (result.errors?.length ?? 0) > 0
    ) {
      // removeAuthToken()
      dispatch(setAnonymousUser())
    }

    dispatch(
      setAuthorizedUser({
        ...result.data.getUser,
        roles: result.data.getUser.roles ?? [],
      })
    )
  } catch (e) {
    console.error("Error initializing authentication", e)
    dispatch(setAnonymousUser())
  }
}
