import client from "../backend-api-client"

import {
  LoginMutationVariables,
  LoginMutation,
  LoginDocument,
  LoginInput,
  GetUserQuery,
  GetUserDocument,
} from "../backend-api"

export const userLoginMutation = (input: LoginInput) =>
  client.mutate<LoginMutation, LoginMutationVariables>({
    mutation: LoginDocument,
    variables: {
      input,
    },
  })

export const userQuery = () =>
  client.mutate<GetUserQuery>({
    mutation: GetUserDocument,
    fetchPolicy: "no-cache",
  })
