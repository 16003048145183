import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type ActivateTimedVoucherInput = {
  promoCode: Scalars['String'];
  email?: Maybe<Scalars['String']>;
};

export type AddWishlistItemInput = {
  wishlistId?: Maybe<Scalars['String']>;
  variantId: Scalars['String'];
};

export type AddressCreateResult = {
  __typename?: 'AddressCreateResult';
  success: Scalars['Boolean'];
  address: CustomerAddress;
};

export type AddressDeleteResult = {
  __typename?: 'AddressDeleteResult';
  success: Scalars['Boolean'];
};

export type AddressUpdateResult = {
  __typename?: 'AddressUpdateResult';
  success: Scalars['Boolean'];
  address: CustomerAddress;
};

export type AdminGetUserInput = {
  email: Scalars['String'];
};

export type AdminOrderItem = {
  __typename?: 'AdminOrderItem';
  details: OrderDetails;
  payment?: Maybe<OrderPaymentDetails>;
  rawBasket?: Maybe<Scalars['JSON']>;
  rawOrder?: Maybe<Scalars['JSON']>;
};

export type AdminPaymentGetInput = {
  gatewayId: Scalars['String'];
  paymentId: Scalars['String'];
};

export type AdminPaymentGetResult = {
  __typename?: 'AdminPaymentGetResult';
  completed: Scalars['Boolean'];
  data?: Maybe<Scalars['JSON']>;
};

export type AdminPaymentInitInput = {
  gatewayId: Scalars['String'];
  data: AdminPaymentInitInputData;
};

export type AdminPaymentInitInputData = {
  orderTotalAmount: Scalars['Float'];
  customerEmail: Scalars['String'];
};

export type AdminPaymentInitResult = {
  __typename?: 'AdminPaymentInitResult';
  data?: Maybe<Scalars['JSON']>;
};

export type AdminPaymentRefundInput = {
  gatewayId: Scalars['String'];
  paymentId: Scalars['String'];
  orderId: Scalars['String'];
  customAmount?: Maybe<Scalars['Float']>;
};

export type AdminPaymentRefundResult = {
  __typename?: 'AdminPaymentRefundResult';
  data?: Maybe<Scalars['JSON']>;
  refoundSucceeded: Scalars['Boolean'];
  paymentId: Scalars['String'];
  refoundId: Scalars['String'];
};

export type AdminPaymentVerifyInput = {
  gatewayId: Scalars['String'];
  paymentId: Scalars['String'];
};

export type AdminPaymentVerifyResult = {
  __typename?: 'AdminPaymentVerifyResult';
  completed: Scalars['Boolean'];
  data?: Maybe<Scalars['JSON']>;
};

export type ArchiveProductInputType = {
  productIds: Array<Scalars['String']>;
  deleteFromSanity?: Maybe<Scalars['Boolean']>;
  archiveOnShopify?: Maybe<Scalars['Boolean']>;
  deleteFromCatalog?: Maybe<Scalars['Boolean']>;
};

export type AvailabilitySearchResultBrand = {
  __typename?: 'AvailabilitySearchResultBrand';
  name: Scalars['String'];
  code: Scalars['String'];
  occurrences: Scalars['Int'];
  active: Scalars['Boolean'];
};

export type AvailabilitySearchResultCategory = {
  __typename?: 'AvailabilitySearchResultCategory';
  code: Scalars['String'];
  name: Scalars['String'];
  slug: Scalars['String'];
  occurrences: Scalars['Int'];
  active: Scalars['Boolean'];
  children: Array<AvailabilitySearchResultCategory>;
  externalCategory: Scalars['Boolean'];
};

export type AvailabilitySearchResultDiscountPercent = {
  __typename?: 'AvailabilitySearchResultDiscountPercent';
  min?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
  values: Array<AvailabilitySearchResultDiscountPercentItem>;
};

export type AvailabilitySearchResultDiscountPercentItem = {
  __typename?: 'AvailabilitySearchResultDiscountPercentItem';
  value: Scalars['Float'];
  occurrences: Scalars['Int'];
};

export type AvailabilitySearchResultPaging = {
  __typename?: 'AvailabilitySearchResultPaging';
  totResults: Scalars['Int'];
  size: Scalars['Int'];
  cursor?: Maybe<Scalars['String']>;
  isLastPage: Scalars['Boolean'];
};

export type AvailabilitySearchResultTag = {
  __typename?: 'AvailabilitySearchResultTag';
  key: Scalars['String'];
  code: Scalars['String'];
  value: Scalars['String'];
  name: Scalars['String'];
  occurrences: Scalars['Int'];
  active: Scalars['Boolean'];
};

export type AvailabilitySearchResultTagCategory = {
  __typename?: 'AvailabilitySearchResultTagCategory';
  key: Scalars['String'];
  categoryName: Scalars['String'];
  weight: Scalars['Float'];
  tags: Array<AvailabilitySearchResultTag>;
  active: Scalars['Boolean'];
};

export type AvailabilitySearchResultsAggregations = {
  __typename?: 'AvailabilitySearchResultsAggregations';
  productTags: Array<AvailabilitySearchResultTagCategory>;
  productCategories: Array<AvailabilitySearchResultCategory>;
  prices: AvailabilitySearchResultsPrices;
  discountPercent: AvailabilitySearchResultDiscountPercent;
  brands: Array<AvailabilitySearchResultBrand>;
};

export type AvailabilitySearchResultsPrices = {
  __typename?: 'AvailabilitySearchResultsPrices';
  min?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
};

export type BackofficeUserRoleSetInput = {
  email: Scalars['String'];
  roleName: Scalars['String'];
};

export type BackofficeUserRolesRemoveInput = {
  email: Scalars['String'];
};

export type BasketItemPromoCodeTriggerParamsInput = {
  maxProductsPerBasket?: Maybe<Scalars['Float']>;
};

export type BasketItemPromoCodeTriggerParamsType = {
  __typename?: 'BasketItemPromoCodeTriggerParamsType';
  maxProductsPerBasket?: Maybe<Scalars['Float']>;
};

export type BrandItem = {
  __typename?: 'BrandItem';
  code: Scalars['String'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type BrandProductItemContentsType = {
  __typename?: 'BrandProductItemContentsType';
  languageId: Scalars['String'];
  name: Scalars['String'];
  subtitle?: Maybe<Scalars['String']>;
  line?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  longDescription?: Maybe<Scalars['String']>;
  highlights?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  ingredients?: Maybe<Scalars['String']>;
  fragrance?: Maybe<Scalars['String']>;
  fragranceFamily?: Maybe<Scalars['String']>;
  testResults?: Maybe<Scalars['String']>;
  composition?: Maybe<Scalars['String']>;
  technology?: Maybe<Scalars['String']>;
  images: Array<Scalars['String']>;
  videos?: Maybe<Array<Scalars['String']>>;
};

export type BrandProductOptions = {
  __typename?: 'BrandProductOptions';
  hidden: Scalars['Boolean'];
  isGift: Scalars['Boolean'];
};

export type BrandProductOptionsInput = {
  hidden: Scalars['Boolean'];
  isGift: Scalars['Boolean'];
};

export type BrandProductVariantType = {
  __typename?: 'BrandProductVariantType';
  productId: Scalars['String'];
  line?: Maybe<Scalars['String']>;
  productLine?: Maybe<Scalars['String']>;
  productLineId?: Maybe<Scalars['String']>;
  validFrom?: Maybe<Scalars['String']>;
  validTo?: Maybe<Scalars['String']>;
  eanCode: Scalars['String'];
  brandName: Scalars['String'];
  brandCode: Scalars['String'];
  contents: Array<BrandProductItemContentsType>;
  tags?: Maybe<Array<Scalars['String']>>;
  quantity?: Maybe<ToolQuantityType>;
  color?: Maybe<ToolColorType>;
  category?: Maybe<Scalars['String']>;
  secondaryCategories?: Maybe<Array<Scalars['String']>>;
  relatedEanCodes?: Maybe<Array<Scalars['String']>>;
  complementaryEanCodes?: Maybe<Array<Scalars['String']>>;
  keywords?: Maybe<Array<Scalars['String']>>;
  price?: Maybe<Scalars['Float']>;
};

export type BxgyPromoPayloadInput = {
  x: Scalars['Int'];
  y: Scalars['Int'];
};

export type BxgyPromoPayloadType = {
  __typename?: 'BxgyPromoPayloadType';
  x: Scalars['Int'];
  y: Scalars['Int'];
};

export type CalendarDate = {
  __typename?: 'CalendarDate';
  year: Scalars['Int'];
  month: Scalars['Int'];
  day: Scalars['Int'];
};

export type CalendarDateInput = {
  year: Scalars['Int'];
  month: Scalars['Int'];
  day: Scalars['Int'];
};

export type CalendarDaySchedule = {
  __typename?: 'CalendarDaySchedule';
  slots: Array<CalendarDaySlot>;
};

export type CalendarDaySlot = {
  __typename?: 'CalendarDaySlot';
  from: CalendarTime;
  to: CalendarTime;
  maxQuantity?: Maybe<Scalars['Int']>;
};

export type CalendarDefinition = {
  __typename?: 'CalendarDefinition';
  id: Scalars['String'];
  alias: Scalars['String'];
  name: Scalars['String'];
  enabled: Scalars['Boolean'];
  validFrom?: Maybe<Scalars['String']>;
  validTo?: Maybe<Scalars['String']>;
  schedule: Scalars['JSON'];
  storeId?: Maybe<Scalars['String']>;
  notificationEmail: Scalars['String'];
  locationName?: Maybe<Scalars['String']>;
  eventName: Scalars['String'];
  eventDescription: Scalars['String'];
  maxVisibilityDays: Scalars['Int'];
  defaultEventDurationMin: Scalars['Int'];
  offsetBeforeEvent: Scalars['Int'];
  offsetAfterEvent: Scalars['Int'];
  eventStartTimeIncrements: Scalars['Int'];
  minReservationDaysOffset: Scalars['Int'];
  maxReservationsPerDay: Scalars['Int'];
  extraQuestions?: Maybe<Scalars['JSON']>;
  options?: Maybe<CalendarDefinitionOptions>;
  blockPublicEmails: Scalars['Boolean'];
  phoneRequired: Scalars['Boolean'];
  internalNotificationAlias?: Maybe<Scalars['String']>;
  customerNotificationAlias?: Maybe<Scalars['String']>;
  internalNotificationTemplate: Scalars['String'];
  internalNotificationSubject: Scalars['String'];
  customerNotificationTemplate: Scalars['String'];
  customerNotificationSubject: Scalars['String'];
  internalCancellationNotificationTemplate: Scalars['String'];
  customerCancellationNotificationTemplate: Scalars['String'];
  createdOn?: Maybe<Scalars['DateTime']>;
  updatedOn?: Maybe<Scalars['DateTime']>;
};

export type CalendarDefinitionInput = {
  id: Scalars['String'];
  alias: Scalars['String'];
  name: Scalars['String'];
  enabled: Scalars['Boolean'];
  validFrom?: Maybe<Scalars['String']>;
  validTo?: Maybe<Scalars['String']>;
  schedule: Scalars['JSON'];
  storeId?: Maybe<Scalars['String']>;
  notificationEmail: Scalars['String'];
  locationName?: Maybe<Scalars['String']>;
  eventName: Scalars['String'];
  eventDescription: Scalars['String'];
  maxVisibilityDays: Scalars['Int'];
  defaultEventDurationMin: Scalars['Int'];
  offsetBeforeEvent: Scalars['Int'];
  offsetAfterEvent: Scalars['Int'];
  eventStartTimeIncrements: Scalars['Int'];
  minReservationDaysOffset: Scalars['Int'];
  maxReservationsPerDay: Scalars['Int'];
  extraQuestions?: Maybe<Scalars['JSON']>;
  options?: Maybe<CalendarDefinitionOptionsInput>;
  blockPublicEmails: Scalars['Boolean'];
  phoneRequired: Scalars['Boolean'];
  internalNotificationAlias?: Maybe<Scalars['String']>;
  customerNotificationAlias?: Maybe<Scalars['String']>;
  internalNotificationTemplate: Scalars['String'];
  internalNotificationSubject: Scalars['String'];
  customerNotificationTemplate: Scalars['String'];
  customerNotificationSubject: Scalars['String'];
  internalCancellationNotificationTemplate: Scalars['String'];
  customerCancellationNotificationTemplate: Scalars['String'];
  createdOn?: Maybe<Scalars['DateTime']>;
  updatedOn?: Maybe<Scalars['DateTime']>;
};

export type CalendarDefinitionOptions = {
  __typename?: 'CalendarDefinitionOptions';
  preSelection?: Maybe<CalendarPreSelection>;
};

export type CalendarDefinitionOptionsInput = {
  preSelection?: Maybe<CalendarPreSelectionInput>;
};

export type CalendarEvent = {
  __typename?: 'CalendarEvent';
  definition: CalendarDefinition;
  availability: CalendarEventAvailability;
};

export type CalendarEventAvailability = {
  __typename?: 'CalendarEventAvailability';
  pages: Array<CalendarEventAvailabilityPage>;
};

export type CalendarEventAvailabilityDay = {
  __typename?: 'CalendarEventAvailabilityDay';
  year: Scalars['Int'];
  month: Scalars['Int'];
  day: Scalars['Int'];
  date: Scalars['String'];
  slots: Array<CalendarEventAvailabilitySlot>;
};

export type CalendarEventAvailabilityPage = {
  __typename?: 'CalendarEventAvailabilityPage';
  year: Scalars['Int'];
  month: Scalars['Int'];
  days: Array<CalendarEventAvailabilityDay>;
};

export type CalendarEventAvailabilitySlot = {
  __typename?: 'CalendarEventAvailabilitySlot';
  from: SlotTime;
  to: SlotTime;
  maxQuantity: Scalars['Int'];
};

export type CalendarHolidayRange = {
  __typename?: 'CalendarHolidayRange';
  from: Scalars['String'];
  to: Scalars['String'];
};

export type CalendarHolidayRangeInput = {
  from: Scalars['String'];
  to: Scalars['String'];
};

export type CalendarPreSelection = {
  __typename?: 'CalendarPreSelection';
  fixed?: Maybe<Scalars['Boolean']>;
  preselectedDate?: Maybe<Scalars['String']>;
};

export type CalendarPreSelectionInput = {
  fixed?: Maybe<Scalars['Boolean']>;
  preselectedDate?: Maybe<Scalars['String']>;
};

export type CalendarReservation = {
  __typename?: 'CalendarReservation';
  id: Scalars['String'];
  calendarId: Scalars['String'];
  guestName: Scalars['String'];
  guestEmail: Scalars['String'];
  guestNotes?: Maybe<Scalars['String']>;
  extraFields?: Maybe<Scalars['JSON']>;
  date: Scalars['String'];
  startHour: Scalars['Int'];
  startMinute: Scalars['Int'];
  endHour: Scalars['Int'];
  endMinute: Scalars['Int'];
  createdOn: Scalars['DateTime'];
  updatedOn: Scalars['DateTime'];
};

export type CalendarReservationCancellation = {
  __typename?: 'CalendarReservationCancellation';
  id: Scalars['String'];
  calendarId: Scalars['String'];
  guestName: Scalars['String'];
  guestEmail: Scalars['String'];
  guestNotes?: Maybe<Scalars['String']>;
  extraFields?: Maybe<Scalars['JSON']>;
  date: Scalars['String'];
  startHour: Scalars['Int'];
  startMinute: Scalars['Int'];
  endHour: Scalars['Int'];
  endMinute: Scalars['Int'];
  createdOn: Scalars['DateTime'];
  updatedOn: Scalars['DateTime'];
};

export type CalendarSchedule = {
  __typename?: 'CalendarSchedule';
  monday: CalendarDaySchedule;
  tuesday: CalendarDaySchedule;
  wednesday: CalendarDaySchedule;
  thursday: CalendarDaySchedule;
  friday: CalendarDaySchedule;
  saturday: CalendarDaySchedule;
  sunday: CalendarDaySchedule;
};

export type CalendarTime = {
  __typename?: 'CalendarTime';
  hour: Scalars['Int'];
  minute: Scalars['Int'];
};

export type CalendarTimeInput = {
  hour: Scalars['Int'];
  minute: Scalars['Int'];
};

export type CalendarsExport = {
  __typename?: 'CalendarsExport';
  url: Scalars['String'];
};

export type CardFetchResult = {
  __typename?: 'CardFetchResult';
  data?: Maybe<Scalars['JSON']>;
};

export type CatalogBrandProductDocument = {
  __typename?: 'CatalogBrandProductDocument';
  product: CatalogBrandProductItem;
  variants: Array<CatalogBrandProductVariantDocument>;
  status: CatalogBrandProductStatus;
};

export type CatalogBrandProductItem = {
  __typename?: 'CatalogBrandProductItem';
  productId: Scalars['String'];
  brandCode: Scalars['String'];
  category?: Maybe<Scalars['String']>;
  missingCategory?: Maybe<Scalars['Boolean']>;
  missingTags?: Maybe<Scalars['Boolean']>;
  secondaryCategories?: Maybe<Array<Scalars['String']>>;
  name?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
  _metadata: CatalogItemMetadata;
};

export type CatalogBrandProductItemContents = {
  __typename?: 'CatalogBrandProductItemContents';
  languageId: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  line?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  longDescription?: Maybe<Scalars['String']>;
  highlights?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  ingredients?: Maybe<Scalars['String']>;
  fragrance?: Maybe<Scalars['String']>;
  fragranceFamily?: Maybe<Scalars['String']>;
  testResults?: Maybe<Scalars['String']>;
  composition?: Maybe<Scalars['String']>;
  technology?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Scalars['String']>>;
};

export type CatalogBrandProductItemContentsInput = {
  languageId: Scalars['String'];
  name: Scalars['String'];
  subtitle?: Maybe<Scalars['String']>;
  line?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  longDescription?: Maybe<Scalars['String']>;
  highlights?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  ingredients?: Maybe<Scalars['String']>;
  fragrance?: Maybe<Scalars['String']>;
  fragranceFamily?: Maybe<Scalars['String']>;
  testResults?: Maybe<Scalars['String']>;
  composition?: Maybe<Scalars['String']>;
  technology?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Scalars['String']>>;
  videos?: Maybe<Array<Scalars['String']>>;
};

export type CatalogBrandProductItemInput = {
  productId: Scalars['String'];
  brandCode: Scalars['String'];
  contents: Array<CatalogBrandProductItemContentsInput>;
  tags?: Maybe<Array<Scalars['String']>>;
  category?: Maybe<Scalars['String']>;
  secondaryCategories?: Maybe<Array<Scalars['String']>>;
  internalNotes?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  options: BrandProductOptionsInput;
};

export type CatalogBrandProductStatus = {
  __typename?: 'CatalogBrandProductStatus';
  published: Scalars['Boolean'];
  publishedOn?: Maybe<Scalars['DateTime']>;
  status: Scalars['String'];
  disabled: Scalars['Boolean'];
  archived?: Maybe<Scalars['Boolean']>;
};

export type CatalogBrandProductVariantDocument = {
  __typename?: 'CatalogBrandProductVariantDocument';
  productId: Scalars['String'];
  productLine?: Maybe<Scalars['String']>;
  productLineId?: Maybe<Scalars['String']>;
  eanCode: Scalars['String'];
  brandName: Scalars['String'];
  brandCode: Scalars['String'];
  contents: Array<CatalogBrandProductItemContents>;
  tags?: Maybe<Array<Scalars['String']>>;
  quantity?: Maybe<QuantityType>;
  color?: Maybe<ColorType>;
  category?: Maybe<Scalars['String']>;
  secondaryCategories?: Maybe<Array<Scalars['String']>>;
  relatedEanCodes?: Maybe<Array<Scalars['String']>>;
  complementaryEanCodes?: Maybe<Array<Scalars['String']>>;
  keywords?: Maybe<Array<Scalars['String']>>;
  internalNotes?: Maybe<Scalars['String']>;
  _metadata: CatalogItemMetadata;
};

export type CatalogBrandProductVariantInput = {
  productId: Scalars['String'];
  line?: Maybe<Scalars['String']>;
  productLine?: Maybe<Scalars['String']>;
  productLineId?: Maybe<Scalars['String']>;
  validFrom?: Maybe<Scalars['String']>;
  validTo?: Maybe<Scalars['String']>;
  eanCode: Scalars['String'];
  brandName: Scalars['String'];
  brandCode: Scalars['String'];
  contents: Array<CatalogBrandProductItemContentsInput>;
  tags?: Maybe<Array<Scalars['String']>>;
  quantity?: Maybe<QuantityInputType>;
  color?: Maybe<ColorInputType>;
  category?: Maybe<Scalars['String']>;
  secondaryCategories?: Maybe<Array<Scalars['String']>>;
  relatedEanCodes?: Maybe<Array<Scalars['String']>>;
  complementaryEanCodes?: Maybe<Array<Scalars['String']>>;
  keywords?: Maybe<Array<Scalars['String']>>;
  internalNotes?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  variantIndex?: Maybe<Scalars['Int']>;
};

export type CatalogColorDefinition = {
  __typename?: 'CatalogColorDefinition';
  hex?: Maybe<Scalars['String']>;
};

export type CatalogInventoryInfo = {
  __typename?: 'CatalogInventoryInfo';
  position: Scalars['Float'];
  price: Scalars['Float'];
  compareAtPrice?: Maybe<Scalars['Float']>;
  discountPercent?: Maybe<Scalars['Float']>;
  maxSellQuantity: Scalars['Float'];
  available?: Maybe<Scalars['Boolean']>;
  priceRuleCode?: Maybe<Scalars['String']>;
  priceRuleCodes?: Maybe<Array<Scalars['String']>>;
  priceRuleIds?: Maybe<Array<Scalars['String']>>;
  sku?: Maybe<Scalars['String']>;
  bestSeller?: Maybe<Scalars['Boolean']>;
};

export type CatalogItemMetadata = {
  __typename?: 'CatalogItemMetadata';
  creationDate: Scalars['String'];
  updateDate: Scalars['String'];
};

export type CatalogMetaItemType = {
  __typename?: 'CatalogMetaItemType';
  key: Scalars['String'];
  categoryLabel?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  parent?: Maybe<CatalogMetaItemType>;
  disabled?: Maybe<Scalars['Boolean']>;
};

export type CatalogProduct = {
  __typename?: 'CatalogProduct';
  info: CatalogProductInfo;
  contents: CatalogProductContents;
  metadata: CatalogProductMetadata;
};

export type CatalogProductBrand = {
  __typename?: 'CatalogProductBrand';
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type CatalogProductCategory = {
  __typename?: 'CatalogProductCategory';
  code: Scalars['String'];
  name: Scalars['String'];
  level: Scalars['Int'];
};

export type CatalogProductCategoryPath = {
  __typename?: 'CatalogProductCategoryPath';
  path: Array<CatalogProductCategory>;
};

export type CatalogProductColor = {
  __typename?: 'CatalogProductColor';
  name: Scalars['String'];
  value?: Maybe<CatalogColorDefinition>;
  texture?: Maybe<CatalogProductTexture>;
};

export type CatalogProductContents = {
  __typename?: 'CatalogProductContents';
  name: Scalars['String'];
  productImages?: Maybe<Array<CatalogProductImage>>;
  campaignImages?: Maybe<Array<CatalogProductImage>>;
};

export type CatalogProductDocument = {
  __typename?: 'CatalogProductDocument';
  documentId: Scalars['String'];
  languageId: Scalars['String'];
  product: CatalogProduct;
  variants: Array<CatalogProductVariant>;
  defaultVariant: CatalogProductVariant;
  extraData?: Maybe<CatalogProductExtraData>;
};

export type CatalogProductExtraData = {
  __typename?: 'CatalogProductExtraData';
  promotions: Array<CatalogProductPromo>;
  noPromo: Scalars['Boolean'];
};

export type CatalogProductImage = {
  __typename?: 'CatalogProductImage';
  url: Scalars['String'];
  asset: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  size?: Maybe<ImageSize>;
};

export type CatalogProductInfo = {
  __typename?: 'CatalogProductInfo';
  productId: Scalars['String'];
  productSlug: Scalars['String'];
  available: Scalars['Boolean'];
  archived: Scalars['Boolean'];
  weight?: Maybe<Scalars['String']>;
};

export type CatalogProductLine = {
  __typename?: 'CatalogProductLine';
  code: Scalars['String'];
  name: Scalars['String'];
};

export type CatalogProductMetadata = {
  __typename?: 'CatalogProductMetadata';
  brand?: Maybe<CatalogProductBrand>;
  productLines?: Maybe<Array<CatalogProductLine>>;
  tags?: Maybe<Array<CatalogProductTag>>;
  categories?: Maybe<Array<CatalogProductCategory>>;
  categoryPaths?: Maybe<Array<CatalogProductCategoryPath>>;
  relatedProducts?: Maybe<Array<Scalars['String']>>;
};

export type CatalogProductPromo = {
  __typename?: 'CatalogProductPromo';
  code: Scalars['String'];
  logoUrl?: Maybe<Scalars['String']>;
};

export type CatalogProductSize = {
  __typename?: 'CatalogProductSize';
  value: Scalars['String'];
};

export type CatalogProductTag = {
  __typename?: 'CatalogProductTag';
  key: Scalars['String'];
  code: Scalars['String'];
  value: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  categoryName?: Maybe<Scalars['String']>;
};

export type CatalogProductTexture = {
  __typename?: 'CatalogProductTexture';
  url: Scalars['String'];
  asset: Scalars['String'];
};

export type CatalogProductVariant = {
  __typename?: 'CatalogProductVariant';
  info: CatalogProductVariantInfo;
  shopify?: Maybe<CatalogProductVariantShopifyData>;
  details: CatalogProductVariantDetails;
  contents: CatalogProductVariantContents;
  metadata: CatalogProductVariantMetadata;
  inventory: CatalogInventoryInfo;
};

export type CatalogProductVariantContents = {
  __typename?: 'CatalogProductVariantContents';
  sanityId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  shortDescription?: Maybe<StructuredText>;
  longDescription?: Maybe<StructuredText>;
  highlights?: Maybe<StructuredText>;
  instructions?: Maybe<StructuredText>;
  ingredients?: Maybe<StructuredText>;
  composition?: Maybe<StructuredText>;
  testResults?: Maybe<StructuredText>;
  fragrance?: Maybe<StructuredText>;
  fragranceFamily?: Maybe<StructuredText>;
  technology?: Maybe<StructuredText>;
  plus?: Maybe<StructuredText>;
  productImages?: Maybe<Array<CatalogProductImage>>;
  campaignImages?: Maybe<Array<CatalogProductImage>>;
  videos?: Maybe<Array<CatalogProductVideo>>;
};

export type CatalogProductVariantDetails = {
  __typename?: 'CatalogProductVariantDetails';
  quantity?: Maybe<CatalogProductSize>;
  color?: Maybe<CatalogProductColor>;
};

export type CatalogProductVariantInfo = {
  __typename?: 'CatalogProductVariantInfo';
  variantId: Scalars['String'];
  default: Scalars['Boolean'];
  available: Scalars['Boolean'];
  archived: Scalars['Boolean'];
  slug?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['String']>;
  brandCode?: Maybe<Scalars['String']>;
  validFrom?: Maybe<Scalars['String']>;
  validTo?: Maybe<Scalars['String']>;
};

export type CatalogProductVariantMetadata = {
  __typename?: 'CatalogProductVariantMetadata';
  brand?: Maybe<CatalogProductBrand>;
  productLines?: Maybe<Array<CatalogProductLine>>;
  tags?: Maybe<Array<CatalogProductTag>>;
  categories?: Maybe<Array<CatalogProductCategory>>;
  allCategories?: Maybe<Array<CatalogProductCategory>>;
  categoryPaths?: Maybe<Array<CatalogProductCategoryPath>>;
  relatedProducts?: Maybe<Array<Scalars['String']>>;
  complementaryProducts?: Maybe<Array<Scalars['String']>>;
};

export type CatalogProductVariantShopifyData = {
  __typename?: 'CatalogProductVariantShopifyData';
  shopifyId?: Maybe<Scalars['String']>;
  shopifyStorefrontId?: Maybe<Scalars['String']>;
};

export type CatalogProductVideo = {
  __typename?: 'CatalogProductVideo';
  url: Scalars['String'];
};

export type CatalogPromo = {
  __typename?: 'CatalogPromo';
  code: Scalars['String'];
  name: Scalars['String'];
  contents: CatalogPromoContents;
};

export type CatalogPromoContents = {
  __typename?: 'CatalogPromoContents';
  conditions?: Maybe<Scalars['JSON']>;
  description?: Maybe<Scalars['JSON']>;
  logoUrl?: Maybe<Scalars['String']>;
};

export type CatalogTreeMetaItemType = {
  __typename?: 'CatalogTreeMetaItemType';
  key: Scalars['String'];
  categoryLabel?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  children?: Maybe<Array<CatalogTreeMetaItemType>>;
};

export type CategoryItem = {
  __typename?: 'CategoryItem';
  code: Scalars['String'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type ChangeBrandProductInputType = {
  oldProductId: Scalars['String'];
  newProductId: Scalars['String'];
  newBrandCode: Scalars['String'];
  newBrandName: Scalars['String'];
};

export enum ChangeEmailError {
  EmailInUse = 'EMAIL_IN_USE',
  Other = 'OTHER'
}

export type ChangeEmailInput = {
  newEmail: Scalars['String'];
  emailVerifyUrl: Scalars['String'];
  redirectUrl?: Maybe<Scalars['String']>;
};

export type ChangeEmailResult = {
  __typename?: 'ChangeEmailResult';
  success: Scalars['Boolean'];
  errorType?: Maybe<ChangeEmailError>;
};

export type ChangeEmailVerifyInput = {
  token: Scalars['String'];
};

export type ChangeEmailVerifyResult = {
  __typename?: 'ChangeEmailVerifyResult';
  success: Scalars['Boolean'];
  accessToken: Scalars['String'];
};

export type ChangePasswordInput = {
  newPassword: Scalars['String'];
};

export type ChangePasswordResult = {
  __typename?: 'ChangePasswordResult';
  success: Scalars['Boolean'];
  accessToken: Scalars['String'];
};

export enum CheckOldUserExistsEnum {
  ExistsCrmOnly = 'EXISTS_CRM_ONLY',
  ExistsBoth = 'EXISTS_BOTH',
  NewUser = 'NEW_USER',
  PhoneInUseByLocalUser = 'PHONE_IN_USE_BY_LOCAL_USER',
  PhoneInUseByCrmUser = 'PHONE_IN_USE_BY_CRM_USER',
  Underage = 'UNDERAGE'
}

export type CheckOldUserExistsInput = {
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  passwordResetBaseUrl: Scalars['String'];
  returnUrl?: Maybe<Scalars['String']>;
};

export type CheckOldUserExistsResult = {
  __typename?: 'CheckOldUserExistsResult';
  success: Scalars['Boolean'];
  enum: CheckOldUserExistsEnum;
};

export type CheckStoreUserData = {
  __typename?: 'CheckStoreUserData';
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
};

export enum CheckStoreUserEnum {
  ExistsCrmOnly = 'EXISTS_CRM_ONLY',
  ExistsBoth = 'EXISTS_BOTH',
  NotFound = 'NOT_FOUND'
}

export type CheckStoreUserInput = {
  contactId: Scalars['String'];
};

export type CheckStoreUserResult = {
  __typename?: 'CheckStoreUserResult';
  status: CheckStoreUserEnum;
  contact?: Maybe<CheckStoreUserData>;
};

export type Checkout = {
  __typename?: 'Checkout';
  id: Scalars['String'];
  alias: Scalars['String'];
  status: CheckoutStatus;
  selectedPayment?: Maybe<CheckoutSelectedPaymentMethod>;
  customer: CheckoutCustomer;
  lineItems: Array<CheckoutLineItem>;
  unavailableLineItems?: Maybe<Array<CheckoutLineItemCatalogData>>;
  giftLineItems?: Maybe<Array<CheckoutLineItem>>;
  price: CheckoutPrice;
  basketDiscounts: Array<CheckoutBasketDiscount>;
  promotions: Array<PromoCode>;
  promotionsExtra?: Maybe<CheckoutExtraPromotions>;
  promoState: CheckoutPromoState;
  shipping?: Maybe<CheckoutShipping>;
  samples?: Maybe<CheckoutSamples>;
  order?: Maybe<CheckoutOrder>;
  settings?: Maybe<CheckoutSettings>;
  customerDiscounts?: Maybe<CustomerAvailableDiscounts>;
  lock?: Maybe<CheckoutLock>;
};

export type CheckoutAppliedGiftcard = {
  __typename?: 'CheckoutAppliedGiftcard';
  code: Scalars['String'];
  totalAmount: Scalars['Float'];
  usedAmount: Scalars['Float'];
};

export type CheckoutAvailableFreeProductPromo = {
  __typename?: 'CheckoutAvailableFreeProductPromo';
  code: Scalars['String'];
  promoId: Scalars['String'];
  products: CheckoutFeeProductItems;
};

export type CheckoutAvailableProductPromoDiscount = {
  __typename?: 'CheckoutAvailableProductPromoDiscount';
  eanCodes: Array<Scalars['String']>;
  maxItems?: Maybe<Scalars['Int']>;
  amount: CheckoutProductPromoDiscountAmount;
};

export type CheckoutBasketDiscount = {
  __typename?: 'CheckoutBasketDiscount';
  totalDiscount?: Maybe<Scalars['Float']>;
  trigger?: Maybe<PromoTriggerType>;
  id: Scalars['String'];
  code: Scalars['String'];
  name: Scalars['String'];
  item?: Maybe<CatalogPromo>;
};

export type CheckoutBasketItemProductPromoApplyPreviewPromoResult = {
  __typename?: 'CheckoutBasketItemProductPromoApplyPreviewPromoResult';
  promoCode: Scalars['String'];
};

export type CheckoutBasketItemProductPromoApplyPreviewResult = {
  __typename?: 'CheckoutBasketItemProductPromoApplyPreviewResult';
  applicable: Scalars['Boolean'];
  promo?: Maybe<CheckoutBasketItemProductPromoApplyPreviewPromoResult>;
  variant: CheckoutBasketItemProductPromoApplyPreviewResultVariant;
  discount?: Maybe<CheckoutBasketItemPromoApplyPreviewDiscount>;
};

export type CheckoutBasketItemProductPromoApplyPreviewResultVariant = {
  __typename?: 'CheckoutBasketItemProductPromoApplyPreviewResultVariant';
  productId: Scalars['String'];
  eanCode: Scalars['String'];
};

export type CheckoutBasketItemPromoApplyPreview = {
  productId: Scalars['String'];
  promoCode: Scalars['String'];
};

export type CheckoutBasketItemPromoApplyPreviewDiscount = {
  __typename?: 'CheckoutBasketItemPromoApplyPreviewDiscount';
  originalCompareAtPrice?: Maybe<Scalars['Float']>;
  originalDiscountPercent?: Maybe<Scalars['Float']>;
  originalDiscountAmount: Scalars['Float'];
  newCompareAtPrice: Scalars['Float'];
  newDiscountPercent: Scalars['Float'];
  newDiscountAmount: Scalars['Float'];
  totalDiscountAmount: Scalars['Float'];
  price: Scalars['Float'];
};

export type CheckoutBasketItemPromoApplyPreviewResult = {
  __typename?: 'CheckoutBasketItemPromoApplyPreviewResult';
  results: Array<CheckoutBasketItemProductPromoApplyPreviewResult>;
};

export type CheckoutBillingInput = {
  billingAddress?: Maybe<CheckoutShippingAddressInput>;
  invoice: CheckoutInvoiceInput;
  samples?: Maybe<CheckoutSamplesInput>;
  consents: CheckoutOrderConsents;
};

export type CheckoutCompleteWithNoPaymentResult = {
  __typename?: 'CheckoutCompleteWithNoPaymentResult';
  success: Scalars['Boolean'];
  data?: Maybe<Checkout>;
};

export type CheckoutContactInput = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  acceptsMarketing: Scalars['Boolean'];
};

export type CheckoutCreateResult = {
  __typename?: 'CheckoutCreateResult';
  success: Scalars['Boolean'];
  data?: Maybe<Checkout>;
};

export type CheckoutCustomer = {
  __typename?: 'CheckoutCustomer';
  email?: Maybe<Scalars['String']>;
  acceptsMarketing?: Maybe<Scalars['Boolean']>;
  fidelityCard?: Maybe<CheckoutCustomerFidelityCard>;
};

export type CheckoutCustomerFidelityCard = {
  __typename?: 'CheckoutCustomerFidelityCard';
  cardNumber: Scalars['String'];
  cardPoints: Scalars['Int'];
};

export type CheckoutExtraPromotions = {
  __typename?: 'CheckoutExtraPromotions';
  freeProductPromoThresholds: Array<CheckoutFreeProductPromoThresholds>;
  availableFreeProducts: Array<CheckoutAvailableFreeProductPromo>;
  availableProductPromoDiscounts: Array<CheckoutAvailableProductPromoDiscount>;
};

export type CheckoutFeeProductItems = {
  __typename?: 'CheckoutFeeProductItems';
  eanCodes: Array<Scalars['String']>;
};

export type CheckoutFreeProductPromoThresholds = {
  __typename?: 'CheckoutFreeProductPromoThresholds';
  promoCode: Scalars['String'];
  promoId: Scalars['String'];
  minOrderSubtotal?: Maybe<Scalars['Float']>;
  amountLeft?: Maybe<Scalars['Float']>;
  products: CheckoutFeeProductItems;
};

export type CheckoutGiftcardAddInput = {
  code: Scalars['String'];
};

export type CheckoutGiftcardApplyResult = {
  __typename?: 'CheckoutGiftcardApplyResult';
  success: Scalars['Boolean'];
  data?: Maybe<Checkout>;
  error?: Maybe<GiftcardApplyError>;
};

export type CheckoutGiftcardRemoveInput = {
  code: Scalars['String'];
};

export type CheckoutInput = {
  lineItems: Array<CheckoutLineItemInput>;
  payload?: Maybe<Scalars['JSON']>;
  email?: Maybe<Scalars['String']>;
};

export type CheckoutInvoiceInput = {
  requestInvoice: Scalars['Boolean'];
  vatNumber?: Maybe<Scalars['String']>;
  taxCode?: Maybe<Scalars['String']>;
  sdiCode?: Maybe<Scalars['String']>;
  pec?: Maybe<Scalars['String']>;
};

export type CheckoutLineItem = {
  __typename?: 'CheckoutLineItem';
  id: Scalars['String'];
  quantity: Scalars['Int'];
  originalUnitPrice: Scalars['Float'];
  originalUnitCompareAtPrice?: Maybe<Scalars['Float']>;
  originalTotal: Scalars['Float'];
  originalTotalCompareAtPrice?: Maybe<Scalars['Float']>;
  discountedUnitPrice?: Maybe<Scalars['Float']>;
  discountedTotal?: Maybe<Scalars['Float']>;
  discountPercent?: Maybe<Scalars['Float']>;
  extraDiscountPercent?: Maybe<Scalars['Float']>;
  assignedGiftCardCode?: Maybe<Scalars['String']>;
  isGift: Scalars['Boolean'];
  promotions: Array<CheckoutPromo>;
  product?: Maybe<CheckoutLineItemCatalogData>;
  source?: Maybe<Scalars['String']>;
  promoCodes?: Maybe<Array<Scalars['String']>>;
};

export type CheckoutLineItemCatalogData = {
  __typename?: 'CheckoutLineItemCatalogData';
  document: CatalogProductDocument;
  variant: CatalogProductVariant;
};

export type CheckoutLineItemInput = {
  productId: Scalars['String'];
  variantId: Scalars['String'];
  quantity: Scalars['Int'];
  isGift: Scalars['Boolean'];
  source?: Maybe<Scalars['String']>;
  promoCodes?: Maybe<Array<Scalars['String']>>;
  priceDiscountCodes?: Maybe<Array<Scalars['String']>>;
};

export type CheckoutLock = {
  __typename?: 'CheckoutLock';
  locked: Scalars['Boolean'];
  lockTime?: Maybe<Scalars['String']>;
  lockExpiration?: Maybe<Scalars['String']>;
};

export type CheckoutOrder = {
  __typename?: 'CheckoutOrder';
  id?: Maybe<Scalars['String']>;
  uid?: Maybe<Scalars['String']>;
  orderDate?: Maybe<Scalars['String']>;
};

export type CheckoutOrderConsents = {
  marketingConsent?: Maybe<Scalars['Boolean']>;
  profilingConsent?: Maybe<Scalars['Boolean']>;
  newsletterConsent?: Maybe<Scalars['Boolean']>;
  createCard?: Maybe<Scalars['Boolean']>;
};

export type CheckoutPaymentMethodSetInput = {
  providerId: Scalars['String'];
};

export type CheckoutPrice = {
  __typename?: 'CheckoutPrice';
  currierPaymentPrice?: Maybe<Scalars['Float']>;
  shippingPrice?: Maybe<Scalars['Float']>;
  shippingDiscount?: Maybe<Scalars['Float']>;
  defaultFreeShippingThreshold: Scalars['Float'];
  defaultShippingPrice: Scalars['Float'];
  hasFreeShippingPromo: Scalars['Boolean'];
  freeShippingPromoCode?: Maybe<Scalars['String']>;
  subtotal: Scalars['Float'];
  discountTotal?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
  totalToPay?: Maybe<Scalars['Float']>;
  totalToPayLater?: Maybe<Scalars['Float']>;
  currency: Scalars['String'];
  appliedGiftcards?: Maybe<Array<CheckoutAppliedGiftcard>>;
};

export type CheckoutProductPromoDiscountAmount = {
  __typename?: 'CheckoutProductPromoDiscountAmount';
  value: Scalars['Float'];
  type: DiscountType;
};

export type CheckoutPromo = {
  __typename?: 'CheckoutPromo';
  code: Scalars['String'];
  name: Scalars['String'];
  item?: Maybe<CatalogPromo>;
};

export enum CheckoutPromoCodeApplyError {
  CodeDoesNotExits = 'CodeDoesNotExits',
  PromoNotApplicable = 'PromoNotApplicable',
  VoucherAlreadyUsed = 'VoucherAlreadyUsed',
  VoucherExpired = 'VoucherExpired',
  VoucherNotApplicable = 'VoucherNotApplicable'
}

export type CheckoutPromoCodeApplyResult = {
  __typename?: 'CheckoutPromoCodeApplyResult';
  success: Scalars['Boolean'];
  data?: Maybe<Checkout>;
  error?: Maybe<CheckoutPromoCodeApplyError>;
};

export type CheckoutPromoState = {
  __typename?: 'CheckoutPromoState';
  hasLoggedPromoInBasket: Scalars['Boolean'];
  acceptedNoLoggedPromo: Scalars['Boolean'];
  authenticatedBasket: Scalars['Boolean'];
};

export type CheckoutRecoverInput = {
  promoCode?: Maybe<Scalars['String']>;
};

export type CheckoutSample = {
  __typename?: 'CheckoutSample';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type CheckoutSampleInput = {
  id: Scalars['String'];
  name: Scalars['String'];
};

export type CheckoutSamples = {
  __typename?: 'CheckoutSamples';
  samples: Array<CheckoutSample>;
  notes: Scalars['String'];
};

export type CheckoutSamplesInput = {
  samples: Array<CheckoutSampleInput>;
  notes: Scalars['String'];
};

export type CheckoutSelectedPaymentMethod = {
  __typename?: 'CheckoutSelectedPaymentMethod';
  providerId: Scalars['String'];
  captureType: PaymentMethodCaptureType;
};

export type CheckoutSettings = {
  __typename?: 'CheckoutSettings';
  clickAndCollectEnabled: Scalars['Boolean'];
  giftcardEnabled: Scalars['Boolean'];
  giftBoxEnabled: Scalars['Boolean'];
  enabledPaymentMethods: Array<Scalars['String']>;
};

export type CheckoutShipping = {
  __typename?: 'CheckoutShipping';
  preference: ShippingPreference;
  shippingAddress?: Maybe<CheckoutShippingAddress>;
  billingAddress?: Maybe<CheckoutShippingAddress>;
  store?: Maybe<StoreDocumentV2>;
};

export type CheckoutShippingAddress = {
  __typename?: 'CheckoutShippingAddress';
  customerAddressId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  phone2?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  vatNumber?: Maybe<Scalars['String']>;
};

export type CheckoutShippingAddressInput = {
  customerAddressId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  phone2?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  vatNumber?: Maybe<Scalars['String']>;
  pec?: Maybe<Scalars['String']>;
};

export type CheckoutShippingInput = {
  preference: ShippingPreference;
  shippingAddress?: Maybe<CheckoutShippingAddressInput>;
  storeId?: Maybe<Scalars['String']>;
};

export enum CheckoutStatus {
  Draft = 'Draft',
  Completed = 'Completed'
}

export type CheckoutUpdateResult = {
  __typename?: 'CheckoutUpdateResult';
  success: Scalars['Boolean'];
  data?: Maybe<Checkout>;
};

export type ClickAndCollectStore = {
  __typename?: 'ClickAndCollectStore';
  storeId: Scalars['String'];
};

export type ColorInputType = {
  hex?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type ColorType = {
  __typename?: 'ColorType';
  hex?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type ConfigParamEntry = {
  __typename?: 'ConfigParamEntry';
  id: Scalars['String'];
  value?: Maybe<Scalars['JSON']>;
  createdDate: Scalars['DateTime'];
  updatedDate: Scalars['DateTime'];
};

export type ContactFetchResult = {
  __typename?: 'ContactFetchResult';
  data?: Maybe<Scalars['JSON']>;
};

export type ConvertOldCustomerInput = {
  marketing: MarketingProfileUpdateInput;
  createNewCard: Scalars['Boolean'];
};

export type ConvertOldCustomerResult = {
  __typename?: 'ConvertOldCustomerResult';
  success: Scalars['Boolean'];
  customer?: Maybe<Customer>;
};

export type Coordinates = {
  __typename?: 'Coordinates';
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type CopyTextsOnAllVariantsInput = {
  eanCode: Scalars['String'];
  productId: Scalars['String'];
};

export type Countries = {
  __typename?: 'Countries';
  default: Country;
  values: Array<Country>;
};

export type Country = {
  __typename?: 'Country';
  name: Scalars['String'];
  code: Scalars['String'];
  hasProvince: Scalars['Boolean'];
};

export type CreateCalendarHolidaysInput = {
  storeId: Scalars['String'];
  range: CalendarHolidayRangeInput;
};

export type CreateCmsProductCategoryInput = {
  languageId: Scalars['String'];
  parentCategoryCode?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  name: Scalars['String'];
};

export type CreateCmsTagCategoryInput = {
  languageId: Scalars['String'];
  code: Scalars['String'];
  name: Scalars['String'];
};

export type CreateCmsTagInput = {
  languageId: Scalars['String'];
  key: Scalars['String'];
  value: Scalars['String'];
  name: Scalars['String'];
};

export type CrmContactEntity = {
  __typename?: 'CrmContactEntity';
  contactId: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  marketingConsent?: Maybe<Scalars['Boolean']>;
  profilingConsent?: Maybe<Scalars['Boolean']>;
  surveyConsent?: Maybe<Scalars['Boolean']>;
  newsletterConsent?: Maybe<Scalars['Boolean']>;
  fidelityCardId?: Maybe<Scalars['String']>;
  fidelityCardNumber?: Maybe<Scalars['String']>;
  fidelityCardPoints?: Maybe<Scalars['Float']>;
  crmCreatedOn?: Maybe<Scalars['String']>;
  crmModifiedOn?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['DateTime']>;
  updatedOn?: Maybe<Scalars['DateTime']>;
};

export type CrmDisabledProfile = {
  __typename?: 'CrmDisabledProfile';
  id: Scalars['String'];
  email: Scalars['String'];
  createdOn: Scalars['DateTime'];
};

export type CrmImportJobsInput = {
  syncContacts: Scalars['Boolean'];
  syncTransactions: Scalars['Boolean'];
  syncCards: Scalars['Boolean'];
  aggregateTransactions: Scalars['Boolean'];
  deltaImport: Scalars['Boolean'];
};

export type CrmLogsSearchFilter = {
  minDate?: Maybe<Scalars['String']>;
  maxDate?: Maybe<Scalars['String']>;
  order: Scalars['String'];
};

export type CrmRequestsEntity = {
  __typename?: 'CrmRequestsEntity';
  id: Scalars['String'];
  cmrId?: Maybe<Scalars['String']>;
  ecommerceId?: Maybe<Scalars['String']>;
  userEmail?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  method?: Maybe<Scalars['String']>;
  requestHeaders?: Maybe<Scalars['String']>;
  request?: Maybe<Scalars['String']>;
  response?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Float']>;
  error?: Maybe<Scalars['String']>;
  createdDate: Scalars['DateTime'];
  updatedDate: Scalars['DateTime'];
};

export type CustomListManageInput = {
  websiteName: Scalars['String'];
  medium: Scalars['String'];
  address: Scalars['String'];
  subscribed: Scalars['Boolean'];
};

export type Customer = {
  __typename?: 'Customer';
  account: CustomerAccount;
  userProfile: UserProfile;
  marketingProfile: MarketingProfile;
  addresses: Array<CustomerAddress>;
};

export type CustomerAccount = {
  __typename?: 'CustomerAccount';
  email: Scalars['String'];
  emailVerified: Scalars['Boolean'];
};

export type CustomerAddress = {
  __typename?: 'CustomerAddress';
  id: Scalars['String'];
  shippingDefault: Scalars['Boolean'];
  invoiceDefault: Scalars['Boolean'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  country: Scalars['String'];
  countryCode: Scalars['String'];
  province: Scalars['String'];
  provinceCode: Scalars['String'];
  city: Scalars['String'];
  address: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  zipCode: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  phone2?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  vatNumber?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type CustomerAddressInput = {
  shippingDefault: Scalars['Boolean'];
  invoiceDefault: Scalars['Boolean'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  country: Scalars['String'];
  countryCode: Scalars['String'];
  city: Scalars['String'];
  address: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  zipCode: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  phone2?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  vatNumber?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type CustomerAvailableDiscounts = {
  __typename?: 'CustomerAvailableDiscounts';
  birthdayDiscount: Scalars['Boolean'];
  passagesDiscount: Scalars['Boolean'];
  pointDiscounts: Array<CustomerPointsDiscount>;
  promoCode: Scalars['Boolean'];
};

export type CustomerDeleteResult = {
  __typename?: 'CustomerDeleteResult';
  success: Scalars['Boolean'];
  errorType?: Maybe<CustomerDeletionError>;
};

export enum CustomerDeletionError {
  UserNotFound = 'USER_NOT_FOUND',
  Other = 'OTHER'
}

export type CustomerEnableCardResult = {
  __typename?: 'CustomerEnableCardResult';
  success: Scalars['Boolean'];
  cardId: Scalars['String'];
};

export type CustomerNavSimulationsInput = {
  cardNumber: Scalars['String'];
  basketPrice: Scalars['Float'];
};

export type CustomerNewPoliciesAcceptResult = {
  __typename?: 'CustomerNewPoliciesAcceptResult';
  success: Scalars['Boolean'];
  customer?: Maybe<Customer>;
};

export type CustomerPointsDiscount = {
  __typename?: 'CustomerPointsDiscount';
  points: Scalars['Int'];
  discount: Scalars['Float'];
};

export enum CustomerType {
  Authenticated = 'Authenticated',
  WithFidelity = 'WithFidelity'
}

export type CustomerUnSubscription = {
  __typename?: 'CustomerUnSubscription';
  id: Scalars['String'];
  websiteName: Scalars['String'];
  medium: Scalars['String'];
  address: Scalars['String'];
  createdOn?: Maybe<Scalars['DateTime']>;
};

export type CustomerUpdateInput = {
  userProfile?: Maybe<UserProfileUpdateInput>;
  marketingProfile?: Maybe<MarketingProfileUpdateInput>;
};

export type CustomerUpdateResult = {
  __typename?: 'CustomerUpdateResult';
  success: Scalars['Boolean'];
  errorType?: Maybe<Scalars['String']>;
  customer?: Maybe<Customer>;
};


export type DeleteBrandProductsInputType = {
  brandCode: Scalars['String'];
};

export type DeleteCalendarHolidaysInput = {
  storeId: Scalars['String'];
  range: CalendarHolidayRangeInput;
};

export type DiscountAmountInput = {
  minOrderPrice?: Maybe<Scalars['Float']>;
  value: Scalars['Float'];
  type: DiscountType;
};

export type DiscountAmountType = {
  __typename?: 'DiscountAmountType';
  minOrderPrice?: Maybe<Scalars['Float']>;
  value: Scalars['Float'];
  type: DiscountType;
};

export enum DiscountType {
  Absolute = 'Absolute',
  Percent = 'Percent'
}

export type DynamicDiscountItemInput = {
  id: Scalars['String'];
  productsFilter?: Maybe<PromoProductsFilterInput>;
  amount: DiscountAmountInput;
};

export type DynamicDiscountItemType = {
  __typename?: 'DynamicDiscountItemType';
  id: Scalars['String'];
  productsFilter?: Maybe<PromoProductsFilterType>;
  amount: DiscountAmountType;
};

export type DynamicDiscountPayloadInput = {
  items: Array<DynamicDiscountItemInput>;
};

export type DynamicDiscountPayloadType = {
  __typename?: 'DynamicDiscountPayloadType';
  items: Array<DynamicDiscountItemType>;
};

export type DynamicPricePayload = {
  __typename?: 'DynamicPricePayload';
  maxDiscountPercent: Scalars['Float'];
  fallbackDiscountPercent: Scalars['Float'];
  unavailableCompetitorStockPercentMarkup: Scalars['Float'];
  roundingStrategy: RoundPriceStrategy;
};

export type DynamicPricePayloadInput = {
  maxDiscountPercent: Scalars['Float'];
  fallbackDiscountPercent: Scalars['Float'];
  unavailableCompetitorStockPercentMarkup: Scalars['Float'];
  roundingStrategy: RoundPriceStrategy;
};

export type DynamicPromoCondition = {
  __typename?: 'DynamicPromoCondition';
  conditions: Array<PromoConditionType>;
};

export type DynamicPromoConditionInput = {
  conditions: Array<PromoConditionInput>;
};

export type EditDatesRange = {
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
};

export type EditOrderDetails = {
  __typename?: 'EditOrderDetails';
  pendingPaymentUrl?: Maybe<Scalars['String']>;
  pendingPaymentAmount?: Maybe<Scalars['Float']>;
};

export type EditOrderInput = {
  editRequestId: Scalars['String'];
  id: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  shipping?: Maybe<EditOrderShippingInfo>;
  lineItems?: Maybe<Array<EditOrderLineItem>>;
  price: EditOrderPrice;
  processRefound: Scalars['Boolean'];
};

export type EditOrderLineItem = {
  id?: Maybe<Scalars['String']>;
  isNew: Scalars['Boolean'];
  isGift: Scalars['Boolean'];
  quantity: Scalars['Int'];
  discountedTotal: Scalars['Float'];
  originalTotal: Scalars['Float'];
  discountedUnitPrice: Scalars['Float'];
  originalUnitPrice: Scalars['Float'];
  eanCode: Scalars['String'];
};

export type EditOrderPaymentSessionInit = {
  __typename?: 'EditOrderPaymentSessionInit';
  providerId: Scalars['String'];
  paymentPayload: Scalars['String'];
  paymentSessionId: Scalars['String'];
  paymentAmount: Scalars['Float'];
  paymentSuccessUrl: Scalars['String'];
  paymentCancelUrl: Scalars['String'];
  transactionId: Scalars['String'];
};

export type EditOrderPaymentSessionInitInput = {
  orderId: Scalars['String'];
  providerId: Scalars['String'];
  cancelUrl: Scalars['String'];
  paymentCompletedBaseUrl: Scalars['String'];
};

export type EditOrderPaymentVerifyInput = {
  orderId: Scalars['String'];
  paymentSessionId: Scalars['String'];
};

export type EditOrderPaymentVerifyResult = {
  __typename?: 'EditOrderPaymentVerifyResult';
  success: Scalars['Boolean'];
};

export type EditOrderPrice = {
  manualDiscount: Scalars['Float'];
  originalTotal: Scalars['Float'];
  newTotal: Scalars['Float'];
  difference: Scalars['Float'];
};

export type EditOrderShippingInfo = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
};

export type EmailValidationResult = {
  __typename?: 'EmailValidationResult';
  isValid: Scalars['Boolean'];
  errorType?: Maybe<ValidationErrorType>;
  errorMessage?: Maybe<Scalars['String']>;
};

export enum EventCancellationErrorType {
  GenericError = 'GenericError',
  EventNotFound = 'EventNotFound'
}

export type EventCancellationInput = {
  eventId: Scalars['String'];
};

export type EventCancellationResult = {
  __typename?: 'EventCancellationResult';
  success: Scalars['Boolean'];
  error?: Maybe<EventCancellationErrorType>;
};

export type EventExtraQuestion = {
  __typename?: 'EventExtraQuestion';
  key: Scalars['String'];
  label: Scalars['String'];
  type: Scalars['String'];
  required: Scalars['Boolean'];
  options?: Maybe<Array<Scalars['String']>>;
};

export type EventRegistration = {
  __typename?: 'EventRegistration';
  calendarId: Scalars['String'];
  date: CalendarDate;
  from: CalendarTime;
  to: CalendarTime;
  data: Scalars['JSON'];
};

export enum EventRegistrationErrorType {
  GenericError = 'GenericError',
  InvalidWorkEmailDomain = 'InvalidWorkEmailDomain',
  BlockedEmailDomain = 'BlockedEmailDomain',
  AlreadyRegistered = 'AlreadyRegistered'
}

export type EventRegistrationInput = {
  calendarId: Scalars['String'];
  date: CalendarDateInput;
  from: CalendarTimeInput;
  to: CalendarTimeInput;
  data: Scalars['JSON'];
};

export type EventRegistrationResult = {
  __typename?: 'EventRegistrationResult';
  success: Scalars['Boolean'];
  error?: Maybe<EventRegistrationErrorType>;
  event?: Maybe<EventRegistration>;
};

export type ExportCrmContactsInput = {
  filters: SearchContactsFilter;
  email?: Maybe<Array<Scalars['String']>>;
};

export type ExportNavProductsInput = {
  type: Scalars['String'];
};

export type ExportToolProductsInput = {
  brandCodes?: Maybe<Array<Scalars['String']>>;
  created?: Maybe<EditDatesRange>;
  modified?: Maybe<EditDatesRange>;
  productsOnly?: Maybe<Scalars['Boolean']>;
};

export type ExportToolProductsResult = {
  __typename?: 'ExportToolProductsResult';
  url: Scalars['String'];
};

export type FeatureSwitchEntity = {
  __typename?: 'FeatureSwitchEntity';
  id: Scalars['String'];
  enabled: Scalars['Boolean'];
  betaUsers: Array<Scalars['String']>;
  createdOn?: Maybe<Scalars['DateTime']>;
  updatedOn?: Maybe<Scalars['DateTime']>;
};

export type FixBasketEmailInput = {
  basketId: Scalars['String'];
  email: Scalars['String'];
};

export type FixBasketEmailResult = {
  __typename?: 'FixBasketEmailResult';
  orderId: Scalars['String'];
};

export type FixedPromoPayloadInput = {
  amount: DiscountAmountInput;
};

export type FixedPromoPayloadType = {
  __typename?: 'FixedPromoPayloadType';
  amount: DiscountAmountType;
};

export type FreeGiftPromoPayloadInput = {
  code: Scalars['String'];
  name: Scalars['String'];
};

export type FreeGiftPromoPayloadType = {
  __typename?: 'FreeGiftPromoPayloadType';
  code: Scalars['String'];
  name: Scalars['String'];
};

export type FreeProductPayloadInput = {
  eanCodes: Array<Scalars['String']>;
};

export type FreeProductPayloadType = {
  __typename?: 'FreeProductPayloadType';
  eanCodes: Array<Scalars['String']>;
};

export type FullTextSearchOptions = {
  languageId: Scalars['String'];
  maxProducts: Scalars['Int'];
  maxCategories: Scalars['Int'];
  maxBrands: Scalars['Int'];
};

export type FullTextSearchResults = {
  __typename?: 'FullTextSearchResults';
  products: Array<CatalogProductDocument>;
  brands: Array<BrandItem>;
  categories: Array<CategoryItem>;
};

export type GetCommerceProductByVariantIdInputType = {
  languageId: Scalars['String'];
  variantId: Scalars['String'];
};

export type GetCommerceProductInputType = {
  languageId: Scalars['String'];
  productId: Scalars['String'];
};

export type GetCommerceProductsInputType = {
  languageId: Scalars['String'];
  productId: Array<Scalars['String']>;
  options: GetCommerceProductsOptionsInputType;
};

export type GetCommerceProductsOptionsInputType = {
  excludeUnavailable: Scalars['Boolean'];
};

export type GetCommerceVariantInputType = {
  variantId: Scalars['String'];
};

export type GetCommerceVariantsInputType = {
  variantIds: Array<Scalars['String']>;
};

export type GetGiftcardBalanceInput = {
  giftcardNumber: Scalars['String'];
};

export type GetGiftcardBalanceResult = {
  __typename?: 'GetGiftcardBalanceResult';
  giftcardNumber: Scalars['String'];
  balance: Scalars['Float'];
};

export type GetLatestProductVersionInputType = {
  productId: Scalars['String'];
};

export type GetLatestVariantVersionInputType = {
  eanCode: Scalars['String'];
};

export type GetNarUserInfoData = {
  __typename?: 'GetNarUserInfoData';
  rawData: Scalars['String'];
};

export type GetNavUserInfoInput = {
  cardNumber: Scalars['String'];
};

export type GetOrdersInput = {
  sorting?: Maybe<OrdersSorting>;
  tot: Scalars['Float'];
  email?: Maybe<Scalars['String']>;
  fullText?: Maybe<Scalars['String']>;
  cursor?: Maybe<Scalars['String']>;
};

export type GetProductInputType = {
  productId: Scalars['String'];
};

export type GetProductVariantInputType = {
  eanCode: Scalars['String'];
};

export type GetProductVariantsInputType = {
  productId: Scalars['String'];
};

export type GetProductVersionInputType = {
  productId: Scalars['String'];
  version: Scalars['Int'];
};

export type GetStoresInput = {
  includeHidden?: Maybe<Scalars['Boolean']>;
};

export type GetVariantVersionInputType = {
  eanCode: Scalars['String'];
  version: Scalars['Int'];
};

export enum GiftcardApplyError {
  GenericError = 'GenericError',
  GiftcardNotFound = 'GiftcardNotFound',
  EmptyGiftcard = 'EmptyGiftcard',
  GiftcardLocked = 'GiftcardLocked'
}

export type GiftcardCheckoutInput = {
  amount: Scalars['Int'];
  email: Scalars['String'];
};

export type ImageSize = {
  __typename?: 'ImageSize';
  width: Scalars['Int'];
  height: Scalars['Int'];
  aspectRatio: Scalars['Float'];
};

export type ImportBrandProductsInputType = {
  feedUrl: Scalars['String'];
  mode: Scalars['String'];
  filter: Scalars['String'];
};

export type ImportProductCostsInputType = {
  feedUrl: Scalars['String'];
};

export type ImportProductItem = {
  __typename?: 'ImportProductItem';
  variants: Array<ImportVariantItem>;
  options: BrandProductOptions;
  productId: Scalars['String'];
};

export type ImportProductItemInput = {
  variants: Array<ImportVariantItemInput>;
  options: BrandProductOptionsInput;
  productId: Scalars['String'];
};

export type ImportProductsInputType = {
  feedUrl: Scalars['String'];
  overwriteCmsData?: Maybe<Scalars['Boolean']>;
  newProductsOnly?: Maybe<Scalars['Boolean']>;
  offset?: Maybe<Scalars['Int']>;
  tot?: Maybe<Scalars['Int']>;
  productIds?: Maybe<Array<Scalars['String']>>;
};

export type ImportStoresInput = {
  feedUrl: Scalars['String'];
};

export type ImportVariantItem = {
  __typename?: 'ImportVariantItem';
  itemIndex: Scalars['Int'];
  eanCode: Scalars['String'];
  imageCode?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  brandName: Scalars['String'];
  brandCode: Scalars['String'];
  lineName?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['String']>;
  colorName?: Maybe<Scalars['String']>;
  colorHex?: Maybe<Scalars['String']>;
  colorUrl?: Maybe<Scalars['String']>;
  gender?: Maybe<VariantGender>;
  shortDescription?: Maybe<Scalars['String']>;
  longDescription?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  inci?: Maybe<Scalars['String']>;
  composition?: Maybe<Scalars['String']>;
  olfactoryNotes?: Maybe<Scalars['String']>;
  highlights?: Maybe<Scalars['String']>;
  testResults?: Maybe<Scalars['String']>;
  technology?: Maybe<Scalars['String']>;
  imageUrls?: Maybe<Array<Scalars['String']>>;
  videoUrls?: Maybe<Array<Scalars['String']>>;
  notes?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
  relatedEan?: Maybe<Array<Scalars['String']>>;
  complementaryEan?: Maybe<Array<Scalars['String']>>;
  categoryCodes?: Maybe<Array<Scalars['String']>>;
  keywords?: Maybe<Array<Scalars['String']>>;
  validFrom?: Maybe<Scalars['String']>;
  validTo?: Maybe<Scalars['String']>;
};

export type ImportVariantItemInput = {
  itemIndex: Scalars['Int'];
  eanCode: Scalars['String'];
  imageCode?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  brandName: Scalars['String'];
  brandCode: Scalars['String'];
  lineName?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['String']>;
  colorName?: Maybe<Scalars['String']>;
  colorHex?: Maybe<Scalars['String']>;
  colorUrl?: Maybe<Scalars['String']>;
  gender?: Maybe<VariantGender>;
  shortDescription?: Maybe<Scalars['String']>;
  longDescription?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  inci?: Maybe<Scalars['String']>;
  composition?: Maybe<Scalars['String']>;
  olfactoryNotes?: Maybe<Scalars['String']>;
  highlights?: Maybe<Scalars['String']>;
  testResults?: Maybe<Scalars['String']>;
  technology?: Maybe<Scalars['String']>;
  imageUrls?: Maybe<Array<Scalars['String']>>;
  videoUrls?: Maybe<Array<Scalars['String']>>;
  notes?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
  relatedEan?: Maybe<Array<Scalars['String']>>;
  complementaryEan?: Maybe<Array<Scalars['String']>>;
  categoryCodes?: Maybe<Array<Scalars['String']>>;
  keywords?: Maybe<Array<Scalars['String']>>;
  validFrom?: Maybe<Scalars['String']>;
  validTo?: Maybe<Scalars['String']>;
};

export type ImportZipCodesInput = {
  feedUrl: Scalars['String'];
};

export type IncrementalDiscountPayloadInput = {
  amounts: Array<DiscountAmountInput>;
};

export type IncrementalDiscountPayloadType = {
  __typename?: 'IncrementalDiscountPayloadType';
  amounts: Array<DiscountAmountType>;
};

export type InitializePaymentSessionInputType = {
  providerId: Scalars['String'];
  checkoutId: Scalars['String'];
  paymentSuccessBaseUrl: Scalars['String'];
  paymentCancelBaseUrl: Scalars['String'];
  paymentVerifyBaseUrl: Scalars['String'];
};

export type InitializePaymentSessionOutputType = {
  __typename?: 'InitializePaymentSessionOutputType';
  providerId: Scalars['String'];
  paymentPayload: Scalars['String'];
  paymentSessionId: Scalars['String'];
  paymentAmount: Scalars['Float'];
  paymentSuccessUrl: Scalars['String'];
  paymentCancelUrl: Scalars['String'];
  transactionId: Scalars['String'];
};


export type JobStatusEntity = {
  __typename?: 'JobStatusEntity';
  id: Scalars['String'];
  jobUid: Scalars['String'];
  runUid: Scalars['String'];
  status: Scalars['String'];
  runType: Scalars['String'];
  startTime?: Maybe<Scalars['DateTime']>;
  endTime?: Maybe<Scalars['DateTime']>;
  result?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  createdDate: Scalars['DateTime'];
  updatedDate: Scalars['DateTime'];
};

export enum LoginError {
  PendingEmailVerification = 'PENDING_EMAIL_VERIFICATION',
  UserNotFound = 'USER_NOT_FOUND',
  WrongPassword = 'WRONG_PASSWORD',
  Other = 'OTHER',
  OldUser = 'OLD_USER'
}

export type LoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  passwordResetBaseUrl?: Maybe<Scalars['String']>;
  returnUrl?: Maybe<Scalars['String']>;
};

export type LoginResult = {
  __typename?: 'LoginResult';
  success: Scalars['Boolean'];
  errorType?: Maybe<LoginError>;
  accessToken?: Maybe<Scalars['String']>;
};

export type MarketingProfile = {
  __typename?: 'MarketingProfile';
  subscribedToNewsletter?: Maybe<Scalars['Boolean']>;
  marketingConsent?: Maybe<Scalars['Boolean']>;
  profilingConsent?: Maybe<Scalars['Boolean']>;
  surveyConsent?: Maybe<Scalars['Boolean']>;
  cardId?: Maybe<Scalars['String']>;
  cardBalance?: Maybe<Scalars['Float']>;
  oldCustomer?: Maybe<Scalars['Boolean']>;
  hasOldCard?: Maybe<Scalars['Boolean']>;
  newTermsAccepted?: Maybe<Scalars['Boolean']>;
};

export type MarketingProfileUpdateInput = {
  profilingConsent?: Maybe<Scalars['Boolean']>;
  marketingConsent?: Maybe<Scalars['Boolean']>;
  surveyConsent?: Maybe<Scalars['Boolean']>;
  newsletterConsent?: Maybe<Scalars['Boolean']>;
};

export type MassiveProductsCreateInput = {
  importHash: Scalars['String'];
  items: Array<ImportProductItemInput>;
};

export type MassiveProductsDeleteInput = {
  batchId: Scalars['String'];
};

export type MasterProductArchiveInput = {
  productId: Scalars['String'];
};

export type MasterProductRestoreInput = {
  productId: Scalars['String'];
};

export type MasterVariantChangeEanCodeInput = {
  productId: Scalars['String'];
  currentEanCode: Scalars['String'];
  newEanCode: Scalars['String'];
};

export type MigrateShopifyOrderJobInput = {
  max?: Maybe<Scalars['Int']>;
  throttleMs?: Maybe<Scalars['Int']>;
};

export type MoveVariantInput = {
  eanCode: Scalars['String'];
  targetProductId: Scalars['String'];
};

export type MoveVariantToNewProductInput = {
  eanCode: Scalars['String'];
  brandCode: Scalars['String'];
};

export type MoveVariantToNewProductResult = {
  __typename?: 'MoveVariantToNewProductResult';
  eanCode: Scalars['String'];
  oldProductId: Scalars['String'];
  newProductId: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  login: LoginResult;
  resetPassword: ResetPasswordResult;
  sendResetPassword: SendResetPasswordResult;
  verify: VerificationResult;
  register: RegistrationResult;
  changeEmailVerify: ChangeEmailVerifyResult;
  checkOldUserExists: CheckOldUserExistsResult;
  checkStoreUserStatus: CheckStoreUserResult;
  completeStoreUserRegistration: RegisterStoreUserResult;
  backofficeUserRoleSet: OperationResult;
  backofficeUserRolesRemove: OperationResult;
  changePassword: ChangePasswordResult;
  changeEmail: ChangeEmailResult;
  checkoutBasketItemPromoApplyPreview: CheckoutBasketItemPromoApplyPreviewResult;
  giftcardCheckoutCreate: CheckoutCreateResult;
  checkoutCreate: CheckoutCreateResult;
  checkoutUpdate: CheckoutUpdateResult;
  checkoutLock: CheckoutUpdateResult;
  checkoutApplyPromoCode: CheckoutPromoCodeApplyResult;
  checkoutRemovePromoCode: CheckoutUpdateResult;
  checkoutApplyGiftcard: CheckoutGiftcardApplyResult;
  checkoutRemoveGiftcard: CheckoutUpdateResult;
  checkoutSelectProductGift: CheckoutUpdateResult;
  checkoutRemoveProductGift: CheckoutUpdateResult;
  checkoutApplyBirthdayDiscount: CheckoutUpdateResult;
  checkoutRemoveBirthdayDiscount: CheckoutUpdateResult;
  checkoutApplyPassagesDiscount: CheckoutUpdateResult;
  checkoutRemovePassagesDiscount: CheckoutUpdateResult;
  checkoutApplyPointsDiscount: CheckoutUpdateResult;
  checkoutRemovePointsDiscount: CheckoutUpdateResult;
  checkoutSetShipping: CheckoutUpdateResult;
  checkoutSetSamples: CheckoutUpdateResult;
  checkoutSetBillingData: CheckoutUpdateResult;
  checkoutSetPaymentMethod: CheckoutUpdateResult;
  checkoutAcceptNoLoggedPromo: CheckoutUpdateResult;
  checkoutCompleteWithoutPayment: CheckoutCompleteWithNoPaymentResult;
  checkoutRecover: CheckoutUpdateResult;
  paymentInitialize: InitializePaymentSessionOutputType;
  paymentVerify: PaymentVerifyResult;
  paymentCheck: PaymentCheckResult;
  fixBasketEmail: FixBasketEmailResult;
  resendOrderInternalNotification: OperationResult;
  runOrdersCheck: OperationResult;
  runBasketPaymentSessionsCheck: OperationResult;
  runCheckoutUnlockJob: OperationResult;
  runExpiredBasketsJob: OperationResult;
  resendPurchaseVoucherCode: OperationResult;
  rawUpdateBasket: RawBasket;
  simulateCustomerNavDiscounts: CustomerAvailableDiscounts;
  runMaintenanceJobs: OperationResult;
  importStores: OperationResult;
  importZipCodes: OperationResult;
  jobUnlock: OperationResult;
  runProductUpdateSimulation: OperationResult;
  importCompetitorPrices: OperationResult;
  productInventoriesRefresh: OperationResult;
  syncAlgoliaProducts: OperationResult;
  rebuildVariantMappings: OperationResult;
  archiveProducts: OperationResult;
  checkShopifyProducts: OperationResult;
  importProducts: OperationResult;
  refreshProductVariants: OperationResult;
  importMeta: OperationResult;
  indexMeta: OperationResult;
  importProductCosts: OperationResult;
  processMinderestFeed: OperationResult;
  processNavCatalogData: OperationResult;
  processNavBarcodesData: OperationResult;
  processNavStockData: OperationResult;
  syncNavCatalogEvents: OperationResult;
  syncNavStockEvents: OperationResult;
  reindexProducts: OperationResult;
  reindexBrandProducts: OperationResult;
  reindexProduct: OperationResult;
  deleteElasticProduct: OperationResult;
  runBusinessCentralFullUpdatesTask: OperationResult;
  runBusinessCentralDeltaUpdatesTask: OperationResult;
  enablePriceRule: PriceRule;
  disablePriceRule: PriceRule;
  createPriceRule: PriceRule;
  updatePriceRule: PriceRule;
  deletePriceRule: OperationResult;
  voucherCreate: VoucherCode;
  voucherUpdate: VoucherCode;
  enablePromo: PromoDefinition;
  disablePromo: PromoDefinition;
  createPromo: PromoDefinition;
  updatePromo: PromoDefinition;
  deletePromo: OperationResult;
  productFeedCreate: ProductsFeedDefinition;
  productFeedUpdate: ProductsFeedDefinition;
  productFeedDelete: OperationResult;
  productFeedEnable: ProductsFeedDefinition;
  productFeedDisable: ProductsFeedDefinition;
  createReturn: OperationResult;
  orderEmailChange: OperationResult;
  shopifyOrderMigrate: OperationResult;
  shopifyOrdersMigrateBatch: OperationResult;
  createOrdersReport: OperationResult;
  refillGiftcard: OperationResult;
  refundOrder: AdminOrderItem;
  editOrder: AdminOrderItem;
  orderTagsModify: OperationResult;
  orderCancelFromNav: OperationResult;
  orderForceDelivered: OperationResult;
  orderForceFulfilled: OperationResult;
  archiveOrder: OperationResult;
  resyncQaplaOrder: OperationResult;
  syncUnfulfilledOrder: OperationResult;
  syncOrderShipping: OperationResult;
  syncUnfulfilledOrders: OperationResult;
  syncNavOrders: OperationResult;
  syncArchivedOrders: OperationResult;
  processReturnFile: OperationResult;
  editOrderPaymentInitialize: EditOrderPaymentSessionInit;
  editOrderPaymentVerify: EditOrderPaymentVerifyResult;
  mappingsRebuild: OperationResult;
  backupCatalogData: OperationResult;
  toggleTagStatus: OperationResult;
  toggleProductCategoryStatus: OperationResult;
  createCmsTag: OperationResult;
  createCmsTagCategory: OperationResult;
  createCmsProductCategory: OperationResult;
  uploadToolAsset: ToolMediaUploadResult;
  publishAllProducts: OperationResult;
  publishProduct: OperationResult;
  loadBrandProducts: OperationResult;
  deleteBrandProducts: OperationResult;
  exportCatalogProducts: ExportToolProductsResult;
  refreshBrandProductItem: OperationResult;
  refreshCatalogBrandProducts: OperationResult;
  refreshAllCatalogProducts: OperationResult;
  upsertProductItem: OperationResult;
  upsertVariantItem: OperationResult;
  updateProductBrandName: OperationResult;
  copyTextsOnAllVariants: OperationResult;
  normalizeVariantNames: OperationResult;
  clearPublishQueue: OperationResult;
  enableProduct: OperationResult;
  disableProduct: OperationResult;
  enableProductVariant: OperationResult;
  disableProductVariant: OperationResult;
  moveVariant: OperationResult;
  moveVariantToNewProduct: MoveVariantToNewProductResult;
  swapVariantsOrder: OperationResult;
  changeVariantsOrder: OperationResult;
  exportProductCategories: ExportToolProductsResult;
  exportTagCategories: ExportToolProductsResult;
  exportTags: ExportToolProductsResult;
  exportProductsForNav: ExportToolProductsResult;
  exportToolFullReport: ExportToolProductsResult;
  importSupplierProducts: OperationResult;
  importNavCategories: OperationResult;
  importNavCategoryMappings: OperationResult;
  runShopifySanitize: OperationResult;
  getAvailableProductId: NewProductIdResult;
  changeProductBrand: OperationResult;
  massiveProductsCreateXlsParse: ProductsCreateXlsParseResult;
  massiveProductsMediaUpload: ProductMediaUploadResult;
  massiveProductsCreate: OperationResult;
  massiveProductsDeleteBatch: OperationResult;
  massiveProductsUpdateXlsPreview: ProductsUpdatePreviewResult;
  massiveProductsUpdateXls: OperationResult;
  massiveProductsDownload: ProductsDownloadResult;
  masterProductArchive: OperationResult;
  masterProductRestore: OperationResult;
  upsertMasterProductCustomInfo: OperationResult;
  deleteMasterProductCustomInfo: OperationResult;
  masterVariantChangeEan: OperationResult;
  parkodBrandMappingsUpload: OperationResult;
  parkodAxisMappingsUpload: OperationResult;
  parkodCategoryMappingsUpload: OperationResult;
  parkodCategoryMappingsDownload: ParkodDataExport;
  parkodBrandMappingsDownload: ParkodDataExport;
  parkodAxisMappingsDownload: ParkodDataExport;
  parkodDataGenerate: ParkodDataExport;
  updateCustomer: CustomerUpdateResult;
  deleteCustomer: CustomerDeleteResult;
  customerAddressCreate: AddressCreateResult;
  customerAddressUpdate: AddressUpdateResult;
  customerAddressDelete: AddressDeleteResult;
  customerEnableCard: CustomerEnableCardResult;
  customerAcceptNewPolicies: CustomerNewPoliciesAcceptResult;
  convertOldCustomer: ConvertOldCustomerResult;
  crmDisabledProfilesAdd: OperationResult;
  crmDisabledProfilesRemove: OperationResult;
  emailAddressValidate: EmailValidationResult;
  customListManage: OperationResult;
  userFeedbackCreate: OperationResult;
  newsletterManage: NewsletterManageResult;
  unsubscribeSms: SmsUnregisterResult;
  userConsentsUnsubscribe: OperationResult;
  wishlistItemAdd: Wishlist;
  wishlistItemRemove: Wishlist;
  subscribeOutOfStockProduct: OperationResult;
  timedVoucherActivate: TimedVoucher;
  importCrmContacts: OperationResult;
  unusedVouchersSync: OperationResult;
  shopifyCrmSync: OperationResult;
  syncKlaviyoProfile: OperationResult;
  syncShopifyProfile: OperationResult;
  exportCrmContacts: OperationResult;
  cleanCrmLogs: OperationResult;
  setFeatureSwitchStatus: OperationResult;
  updateSqlParam: OperationResult;
  calendarsExport: CalendarsExport;
  appointmentsFullSyncRun: OperationResult;
  createCalendarStoreHolidays: Array<CalendarHolidayRange>;
  deleteCalendarStoreHolidays: Array<CalendarHolidayRange>;
  createGlobalStoreHolidays: Array<CalendarHolidayRange>;
  deleteGlobalStoreHolidays: Array<CalendarHolidayRange>;
  updateCalendar: CalendarDefinition;
  createCalendar: CalendarDefinition;
  toggleCalendar: CalendarDefinition;
  submitCalendarEvent: EventRegistrationResult;
  cancelCalendarEvent: EventCancellationResult;
  runProductsReportJob: OperationResult;
  runProductsReportExport: OperationResult;
  runCrmCardsReportJob: OperationResult;
  trackEvent: OperationResult;
  adminPaymentInit: AdminPaymentInitResult;
  adminPaymentGet: AdminPaymentGetResult;
  adminPaymentVerify: AdminPaymentVerifyResult;
  adminPaymentRefund: AdminPaymentRefundResult;
  runSyncAlgoliaOrderTransactions: OperationResult;
  executePerformancesSyncTask: OperationResult;
  serverCacheClear: OperationResult;
};


export type MutationLoginArgs = {
  input: LoginInput;
};


export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};


export type MutationSendResetPasswordArgs = {
  input: SendResetPasswordInput;
};


export type MutationVerifyArgs = {
  input: VerificationInput;
};


export type MutationRegisterArgs = {
  input: RegistrationInput;
};


export type MutationChangeEmailVerifyArgs = {
  input: ChangeEmailVerifyInput;
};


export type MutationCheckOldUserExistsArgs = {
  input: CheckOldUserExistsInput;
};


export type MutationCheckStoreUserStatusArgs = {
  input: CheckStoreUserInput;
};


export type MutationCompleteStoreUserRegistrationArgs = {
  input: RegisterStoreUserInput;
};


export type MutationBackofficeUserRoleSetArgs = {
  input: BackofficeUserRoleSetInput;
};


export type MutationBackofficeUserRolesRemoveArgs = {
  input: BackofficeUserRolesRemoveInput;
};


export type MutationChangePasswordArgs = {
  input: ChangePasswordInput;
};


export type MutationChangeEmailArgs = {
  input: ChangeEmailInput;
};


export type MutationCheckoutBasketItemPromoApplyPreviewArgs = {
  input: CheckoutBasketItemPromoApplyPreview;
};


export type MutationGiftcardCheckoutCreateArgs = {
  input: GiftcardCheckoutInput;
  languageId: Scalars['String'];
};


export type MutationCheckoutCreateArgs = {
  input: CheckoutInput;
  languageId: Scalars['String'];
};


export type MutationCheckoutUpdateArgs = {
  input: CheckoutInput;
  languageId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationCheckoutLockArgs = {
  languageId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationCheckoutApplyPromoCodeArgs = {
  email?: Maybe<Scalars['String']>;
  promoCode: Scalars['String'];
  languageId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationCheckoutRemovePromoCodeArgs = {
  promoCode: Scalars['String'];
  languageId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationCheckoutApplyGiftcardArgs = {
  giftcard: CheckoutGiftcardAddInput;
  languageId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationCheckoutRemoveGiftcardArgs = {
  giftcard: CheckoutGiftcardRemoveInput;
  languageId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationCheckoutSelectProductGiftArgs = {
  eanCode: Scalars['String'];
  languageId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationCheckoutRemoveProductGiftArgs = {
  eanCode: Scalars['String'];
  languageId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationCheckoutApplyBirthdayDiscountArgs = {
  languageId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationCheckoutRemoveBirthdayDiscountArgs = {
  languageId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationCheckoutApplyPassagesDiscountArgs = {
  languageId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationCheckoutRemovePassagesDiscountArgs = {
  languageId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationCheckoutApplyPointsDiscountArgs = {
  points: Scalars['Int'];
  languageId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationCheckoutRemovePointsDiscountArgs = {
  points: Scalars['Int'];
  languageId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationCheckoutSetShippingArgs = {
  contact?: Maybe<CheckoutContactInput>;
  samples?: Maybe<CheckoutSamplesInput>;
  shipping: CheckoutShippingInput;
  languageId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationCheckoutSetSamplesArgs = {
  samples: CheckoutSamplesInput;
  languageId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationCheckoutSetBillingDataArgs = {
  billing: CheckoutBillingInput;
  languageId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationCheckoutSetPaymentMethodArgs = {
  input: CheckoutPaymentMethodSetInput;
  languageId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationCheckoutAcceptNoLoggedPromoArgs = {
  languageId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationCheckoutCompleteWithoutPaymentArgs = {
  languageId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationCheckoutRecoverArgs = {
  input: CheckoutRecoverInput;
  languageId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationPaymentInitializeArgs = {
  input: InitializePaymentSessionInputType;
};


export type MutationPaymentVerifyArgs = {
  input: PaymentVerifyInput;
};


export type MutationPaymentCheckArgs = {
  input: PaymentCheckInput;
};


export type MutationFixBasketEmailArgs = {
  input: FixBasketEmailInput;
};


export type MutationResendOrderInternalNotificationArgs = {
  orderId: Scalars['String'];
};


export type MutationResendPurchaseVoucherCodeArgs = {
  voucherCode: Scalars['String'];
};


export type MutationRawUpdateBasketArgs = {
  input: UpdateBasketInput;
  id: Scalars['String'];
};


export type MutationSimulateCustomerNavDiscountsArgs = {
  input: CustomerNavSimulationsInput;
};


export type MutationImportStoresArgs = {
  input: ImportStoresInput;
};


export type MutationImportZipCodesArgs = {
  input: ImportZipCodesInput;
};


export type MutationJobUnlockArgs = {
  jobUid: Scalars['String'];
};


export type MutationProductInventoriesRefreshArgs = {
  reprocessAll: Scalars['Boolean'];
};


export type MutationSyncAlgoliaProductsArgs = {
  fullImport: Scalars['Boolean'];
};


export type MutationArchiveProductsArgs = {
  input: ArchiveProductInputType;
};


export type MutationImportProductsArgs = {
  input: ImportProductsInputType;
};


export type MutationRefreshProductVariantsArgs = {
  input: RefreshProductsInputType;
};


export type MutationImportProductCostsArgs = {
  input: ImportProductCostsInputType;
};


export type MutationProcessNavCatalogDataArgs = {
  options: ProcessNavCatalogOptions;
  feedUrl: Scalars['String'];
};


export type MutationProcessNavBarcodesDataArgs = {
  feedUrl: Scalars['String'];
};


export type MutationProcessNavStockDataArgs = {
  options: ProcessNavStockOptions;
  feedUrl: Scalars['String'];
};


export type MutationReindexBrandProductsArgs = {
  brandCode: Scalars['String'];
};


export type MutationReindexProductArgs = {
  productId: Scalars['String'];
};


export type MutationDeleteElasticProductArgs = {
  productId: Scalars['String'];
};


export type MutationEnablePriceRuleArgs = {
  id: Scalars['String'];
};


export type MutationDisablePriceRuleArgs = {
  id: Scalars['String'];
};


export type MutationCreatePriceRuleArgs = {
  input: PriceRuleInput;
};


export type MutationUpdatePriceRuleArgs = {
  input: PriceRuleInput;
};


export type MutationDeletePriceRuleArgs = {
  id: Scalars['String'];
};


export type MutationVoucherCreateArgs = {
  input: VoucherCodeInput;
};


export type MutationVoucherUpdateArgs = {
  input: VoucherCodeInput;
};


export type MutationEnablePromoArgs = {
  id: Scalars['String'];
};


export type MutationDisablePromoArgs = {
  id: Scalars['String'];
};


export type MutationCreatePromoArgs = {
  input: PromoDefinitionInput;
};


export type MutationUpdatePromoArgs = {
  input: PromoDefinitionInput;
};


export type MutationDeletePromoArgs = {
  id: Scalars['String'];
};


export type MutationProductFeedCreateArgs = {
  data: ProductsFeedDefinitionInput;
};


export type MutationProductFeedUpdateArgs = {
  data: ProductsFeedDefinitionInput;
  id: Scalars['String'];
};


export type MutationProductFeedDeleteArgs = {
  id: Scalars['String'];
};


export type MutationProductFeedEnableArgs = {
  id: Scalars['String'];
};


export type MutationProductFeedDisableArgs = {
  id: Scalars['String'];
};


export type MutationCreateReturnArgs = {
  input: ReturnInputType;
};


export type MutationOrderEmailChangeArgs = {
  input: OrderEmailChangeInput;
};


export type MutationShopifyOrderMigrateArgs = {
  input: ShopifyOrderMigrationInput;
};


export type MutationShopifyOrdersMigrateBatchArgs = {
  input: MigrateShopifyOrderJobInput;
};


export type MutationCreateOrdersReportArgs = {
  maxDays: Scalars['Int'];
};


export type MutationRefillGiftcardArgs = {
  input: RefillGiftcardInput;
};


export type MutationRefundOrderArgs = {
  languageId: Scalars['String'];
  input: RefundOrderInput;
};


export type MutationEditOrderArgs = {
  languageId: Scalars['String'];
  input: EditOrderInput;
};


export type MutationOrderTagsModifyArgs = {
  input: OrderModifyTagsInput;
};


export type MutationOrderCancelFromNavArgs = {
  orderId: Scalars['String'];
};


export type MutationOrderForceDeliveredArgs = {
  orderId: Scalars['String'];
};


export type MutationOrderForceFulfilledArgs = {
  trackingUrl: Scalars['String'];
  orderId: Scalars['String'];
};


export type MutationArchiveOrderArgs = {
  orderId: Scalars['String'];
};


export type MutationResyncQaplaOrderArgs = {
  orderId: Scalars['String'];
};


export type MutationSyncUnfulfilledOrderArgs = {
  orderId: Scalars['String'];
};


export type MutationSyncOrderShippingArgs = {
  orderId: Scalars['String'];
};


export type MutationProcessReturnFileArgs = {
  file: Scalars['String'];
};


export type MutationEditOrderPaymentInitializeArgs = {
  input: EditOrderPaymentSessionInitInput;
};


export type MutationEditOrderPaymentVerifyArgs = {
  input: EditOrderPaymentVerifyInput;
};


export type MutationToggleTagStatusArgs = {
  input: ToggleTagStatusInput;
};


export type MutationToggleProductCategoryStatusArgs = {
  input: ToggleProductCategoryStatusInput;
};


export type MutationCreateCmsTagArgs = {
  input: CreateCmsTagInput;
};


export type MutationCreateCmsTagCategoryArgs = {
  input: CreateCmsTagCategoryInput;
};


export type MutationCreateCmsProductCategoryArgs = {
  input: CreateCmsProductCategoryInput;
};


export type MutationUploadToolAssetArgs = {
  file: Scalars['Upload'];
};


export type MutationPublishProductArgs = {
  productId: Scalars['String'];
};


export type MutationLoadBrandProductsArgs = {
  input: ImportBrandProductsInputType;
};


export type MutationDeleteBrandProductsArgs = {
  input: DeleteBrandProductsInputType;
};


export type MutationExportCatalogProductsArgs = {
  input: ExportToolProductsInput;
};


export type MutationRefreshBrandProductItemArgs = {
  productId: Scalars['String'];
};


export type MutationRefreshCatalogBrandProductsArgs = {
  brandCode: Scalars['String'];
};


export type MutationUpsertProductItemArgs = {
  mode: Scalars['String'];
  product: CatalogBrandProductItemInput;
};


export type MutationUpsertVariantItemArgs = {
  mode: Scalars['String'];
  variant: CatalogBrandProductVariantInput;
};


export type MutationUpdateProductBrandNameArgs = {
  input: UpdateProductBrandNameInput;
};


export type MutationCopyTextsOnAllVariantsArgs = {
  input: CopyTextsOnAllVariantsInput;
};


export type MutationNormalizeVariantNamesArgs = {
  input: NormalizeVariantNamesInput;
};


export type MutationEnableProductArgs = {
  productId: Scalars['String'];
};


export type MutationDisableProductArgs = {
  productId: Scalars['String'];
};


export type MutationEnableProductVariantArgs = {
  eanCode: Scalars['String'];
};


export type MutationDisableProductVariantArgs = {
  eanCode: Scalars['String'];
};


export type MutationMoveVariantArgs = {
  input: MoveVariantInput;
};


export type MutationMoveVariantToNewProductArgs = {
  input: MoveVariantToNewProductInput;
};


export type MutationSwapVariantsOrderArgs = {
  input: SwapVariantsInputType;
};


export type MutationChangeVariantsOrderArgs = {
  input: VariantOrderInput;
};


export type MutationExportProductCategoriesArgs = {
  format: ReportFormat;
};


export type MutationExportProductsForNavArgs = {
  input: ExportNavProductsInput;
};


export type MutationImportSupplierProductsArgs = {
  feedUrl: Scalars['String'];
};


export type MutationImportNavCategoriesArgs = {
  feedUrl: Scalars['String'];
};


export type MutationImportNavCategoryMappingsArgs = {
  feedUrl: Scalars['String'];
};


export type MutationRunShopifySanitizeArgs = {
  startFromProductId?: Maybe<Scalars['String']>;
};


export type MutationGetAvailableProductIdArgs = {
  brandCode: Scalars['String'];
};


export type MutationChangeProductBrandArgs = {
  input: ChangeBrandProductInputType;
};


export type MutationMassiveProductsCreateXlsParseArgs = {
  file: Scalars['Upload'];
};


export type MutationMassiveProductsMediaUploadArgs = {
  media: Scalars['Upload'];
  ref: ProductMediaRefInput;
};


export type MutationMassiveProductsCreateArgs = {
  input: MassiveProductsCreateInput;
};


export type MutationMassiveProductsDeleteBatchArgs = {
  input: MassiveProductsDeleteInput;
};


export type MutationMassiveProductsUpdateXlsPreviewArgs = {
  file: Scalars['Upload'];
};


export type MutationMassiveProductsUpdateXlsArgs = {
  input: ProductsUpdateInput;
  file: Scalars['Upload'];
};


export type MutationMassiveProductsDownloadArgs = {
  input: ProductsDownloadInput;
};


export type MutationMasterProductArchiveArgs = {
  input: MasterProductArchiveInput;
};


export type MutationMasterProductRestoreArgs = {
  input: MasterProductRestoreInput;
};


export type MutationUpsertMasterProductCustomInfoArgs = {
  input: ProductCustomInfoEntityInput;
};


export type MutationDeleteMasterProductCustomInfoArgs = {
  productId: Scalars['String'];
};


export type MutationMasterVariantChangeEanArgs = {
  input: MasterVariantChangeEanCodeInput;
};


export type MutationParkodBrandMappingsUploadArgs = {
  file: Scalars['Upload'];
};


export type MutationParkodAxisMappingsUploadArgs = {
  file: Scalars['Upload'];
};


export type MutationParkodCategoryMappingsUploadArgs = {
  file: Scalars['Upload'];
};


export type MutationParkodDataGenerateArgs = {
  input: ParkodMapDataInput;
  file: Scalars['Upload'];
};


export type MutationUpdateCustomerArgs = {
  input: CustomerUpdateInput;
};


export type MutationCustomerAddressCreateArgs = {
  input: CustomerAddressInput;
};


export type MutationCustomerAddressUpdateArgs = {
  input: CustomerAddressInput;
  id: Scalars['String'];
};


export type MutationCustomerAddressDeleteArgs = {
  id: Scalars['String'];
};


export type MutationConvertOldCustomerArgs = {
  input: ConvertOldCustomerInput;
};


export type MutationCrmDisabledProfilesAddArgs = {
  email: Scalars['String'];
};


export type MutationCrmDisabledProfilesRemoveArgs = {
  id: Scalars['String'];
};


export type MutationEmailAddressValidateArgs = {
  address: Scalars['String'];
};


export type MutationCustomListManageArgs = {
  input: CustomListManageInput;
};


export type MutationUserFeedbackCreateArgs = {
  input: UserFeedbackInput;
};


export type MutationNewsletterManageArgs = {
  input: NewsletterManageInput;
};


export type MutationUnsubscribeSmsArgs = {
  input: SmsUnsubscribeInput;
};


export type MutationUserConsentsUnsubscribeArgs = {
  input: UserConsentsUnsubscribeInput;
};


export type MutationWishlistItemAddArgs = {
  input: AddWishlistItemInput;
};


export type MutationWishlistItemRemoveArgs = {
  input: RemoveWishlistItemInput;
};


export type MutationSubscribeOutOfStockProductArgs = {
  input: OutOfStockNotificationInput;
};


export type MutationTimedVoucherActivateArgs = {
  input: ActivateTimedVoucherInput;
};


export type MutationImportCrmContactsArgs = {
  input: CrmImportJobsInput;
};


export type MutationSyncKlaviyoProfileArgs = {
  input: SyncKlaviyoProfileInput;
};


export type MutationSyncShopifyProfileArgs = {
  input: SyncShopifyProfileInput;
};


export type MutationExportCrmContactsArgs = {
  input: ExportCrmContactsInput;
};


export type MutationSetFeatureSwitchStatusArgs = {
  input: SetSwitchStatusInput;
};


export type MutationUpdateSqlParamArgs = {
  input: UpdateSqlParamInput;
};


export type MutationCreateCalendarStoreHolidaysArgs = {
  input: CreateCalendarHolidaysInput;
};


export type MutationDeleteCalendarStoreHolidaysArgs = {
  input: DeleteCalendarHolidaysInput;
};


export type MutationCreateGlobalStoreHolidaysArgs = {
  input: CalendarHolidayRangeInput;
};


export type MutationDeleteGlobalStoreHolidaysArgs = {
  input: CalendarHolidayRangeInput;
};


export type MutationUpdateCalendarArgs = {
  input: CalendarDefinitionInput;
};


export type MutationCreateCalendarArgs = {
  input: CalendarDefinitionInput;
};


export type MutationToggleCalendarArgs = {
  enabled: Scalars['Boolean'];
  id: Scalars['String'];
};


export type MutationSubmitCalendarEventArgs = {
  input: EventRegistrationInput;
};


export type MutationCancelCalendarEventArgs = {
  input: EventCancellationInput;
};


export type MutationRunProductsReportExportArgs = {
  input: ReportExtractionInput;
};


export type MutationTrackEventArgs = {
  input: TrackEventInput;
};


export type MutationAdminPaymentInitArgs = {
  input: AdminPaymentInitInput;
};


export type MutationAdminPaymentGetArgs = {
  input: AdminPaymentGetInput;
};


export type MutationAdminPaymentVerifyArgs = {
  input: AdminPaymentVerifyInput;
};


export type MutationAdminPaymentRefundArgs = {
  input: AdminPaymentRefundInput;
};

export type NewProductIdResult = {
  __typename?: 'NewProductIdResult';
  productId: Scalars['String'];
};

export type NewsletterManageInput = {
  email: Scalars['String'];
  gender?: Maybe<Scalars['String']>;
  subscribed: Scalars['Boolean'];
};

export type NewsletterManageResult = {
  __typename?: 'NewsletterManageResult';
  success: Scalars['Boolean'];
  alreadySubscribed?: Maybe<Scalars['Boolean']>;
  alreadyUnsubscribed?: Maybe<Scalars['Boolean']>;
  voucherCreated?: Maybe<Scalars['Boolean']>;
};

export type NormalizeVariantNamesInput = {
  productId: Scalars['String'];
  eanCodes: Array<Scalars['String']>;
  name: Scalars['String'];
  extractColor: Scalars['Boolean'];
};

export type OpenHour = {
  __typename?: 'OpenHour';
  from: Scalars['String'];
  to: Scalars['String'];
};

export type OperationResult = {
  __typename?: 'OperationResult';
  success: Scalars['Boolean'];
};

export type OrderCustomer = {
  __typename?: 'OrderCustomer';
  email?: Maybe<Scalars['String']>;
  acceptsMarketing?: Maybe<Scalars['Boolean']>;
};

export type OrderDetails = {
  __typename?: 'OrderDetails';
  id: Scalars['String'];
  uid: Scalars['String'];
  type: Scalars['String'];
  alias?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
  status: Scalars['String'];
  deliveredDate?: Maybe<Scalars['String']>;
  shippedDate?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  shipping?: Maybe<OrderShipping>;
  customer: OrderCustomer;
  lineItems: Array<OrderLineItem>;
  giftLineItems?: Maybe<Array<OrderLineItem>>;
  price: OrderPrice;
  basketDiscounts: Array<CheckoutBasketDiscount>;
  promotions?: Maybe<Array<PromoCode>>;
  tracking?: Maybe<OrderTracking>;
  editOrder?: Maybe<EditOrderDetails>;
};

export type OrderEmailChangeInput = {
  orderId: Scalars['String'];
  email: Scalars['String'];
  sendNotification: Scalars['Boolean'];
};

export type OrderGiftcard = {
  __typename?: 'OrderGiftcard';
  amountUsed: Scalars['Float'];
  code: Scalars['String'];
};

export type OrderInfo = {
  __typename?: 'OrderInfo';
  id: Scalars['String'];
  uid: Scalars['String'];
  alias?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  status: Scalars['String'];
  email: Scalars['String'];
  createdAt: Scalars['String'];
  totalPrice: Scalars['Float'];
  shippingAddress?: Maybe<OrderShippingAddress>;
  deliveredDate?: Maybe<Scalars['String']>;
  shippedDate?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
  raw?: Maybe<Scalars['JSON']>;
};

export type OrderLineItem = {
  __typename?: 'OrderLineItem';
  id: Scalars['String'];
  quantity: Scalars['Int'];
  originalUnitPrice?: Maybe<Scalars['Float']>;
  originalUnitCompareAtPrice?: Maybe<Scalars['Float']>;
  originalTotal: Scalars['Float'];
  originalTotalCompareAtPrice?: Maybe<Scalars['Float']>;
  discountedUnitPrice?: Maybe<Scalars['Float']>;
  discountedTotal: Scalars['Float'];
  assignedGiftCardCode?: Maybe<Scalars['String']>;
  isGift: Scalars['Boolean'];
  product?: Maybe<OrderLineItemCatalogData>;
};

export type OrderLineItemCatalogData = {
  __typename?: 'OrderLineItemCatalogData';
  document: CatalogProductDocument;
  variant: CatalogProductVariant;
};

export type OrderModifyTagsInput = {
  orderId: Scalars['String'];
  tags: Array<Scalars['String']>;
};

export type OrderPaymentDetails = {
  __typename?: 'OrderPaymentDetails';
  provider: Scalars['String'];
  transactionId: Scalars['String'];
  sessionId: Scalars['String'];
};

export type OrderPrice = {
  __typename?: 'OrderPrice';
  shippingPrice?: Maybe<Scalars['Float']>;
  subtotal: Scalars['Float'];
  discountTotal?: Maybe<Scalars['Float']>;
  total: Scalars['Float'];
  totalPaid: Scalars['Float'];
  currency: Scalars['String'];
  giftcards: Array<OrderGiftcard>;
};

export type OrderResult = {
  __typename?: 'OrderResult';
  orders: Array<OrderInfo>;
  hasNextPage: Scalars['Boolean'];
  cursor?: Maybe<Scalars['String']>;
};

export type OrderShipping = {
  __typename?: 'OrderShipping';
  preference: ShippingPreference;
  shippingAddress?: Maybe<OrderShippingAddress>;
  billingAddress?: Maybe<OrderShippingAddress>;
  store?: Maybe<StoreDocumentV2>;
};

export type OrderShippingAddress = {
  __typename?: 'OrderShippingAddress';
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  vatNumber?: Maybe<Scalars['String']>;
};

export type OrderTracking = {
  __typename?: 'OrderTracking';
  url: Scalars['String'];
};

export enum OrdersSorting {
  Asc = 'Asc',
  Desc = 'Desc'
}

export type OutOfStockNotificationInput = {
  productId: Scalars['String'];
  variantId: Scalars['String'];
  email: Scalars['String'];
  subscribeNewsletter?: Maybe<Scalars['Boolean']>;
};

export type ParkodDataExport = {
  __typename?: 'ParkodDataExport';
  url: Scalars['String'];
};

export enum ParkodFilterType {
  All = 'All',
  NonCoded = 'NonCoded'
}

export type ParkodMapDataFilters = {
  states: Array<Scalars['String']>;
  type: ParkodFilterType;
};

export type ParkodMapDataInput = {
  filters: ParkodMapDataFilters;
};

export type PaymentCheckInput = {
  languageId: Scalars['String'];
  checkoutId: Scalars['String'];
  paymentSessionId: Scalars['String'];
};

export type PaymentCheckResult = {
  __typename?: 'PaymentCheckResult';
  fulfilled: Scalars['Boolean'];
};

export enum PaymentMethodCaptureType {
  PayLater = 'PayLater',
  PayNow = 'PayNow'
}

export type PaymentVerifyInput = {
  languageId: Scalars['String'];
  checkoutId: Scalars['String'];
  paymentSessionId: Scalars['String'];
};

export type PaymentVerifyResult = {
  __typename?: 'PaymentVerifyResult';
  success: Scalars['Boolean'];
  data: Checkout;
};

export type PriceRule = {
  __typename?: 'PriceRule';
  id: Scalars['String'];
  priceCode: Scalars['String'];
  promoCode?: Maybe<Scalars['String']>;
  weight: Scalars['Int'];
  disabled?: Maybe<Scalars['Boolean']>;
  crossedPrice?: Maybe<Scalars['Boolean']>;
  ruleType: PriceRuleType;
  discountType?: Maybe<DiscountType>;
  discountAmount?: Maybe<Scalars['Float']>;
  fixedPrice?: Maybe<Scalars['Float']>;
  fixedCompareAtPrice?: Maybe<Scalars['Float']>;
  validFrom?: Maybe<Scalars['DateTime']>;
  validTo?: Maybe<Scalars['DateTime']>;
  productIds?: Maybe<Array<Scalars['String']>>;
  excludedProductIds?: Maybe<Array<Scalars['String']>>;
  eanCodes?: Maybe<Array<Scalars['String']>>;
  excludedEanCodes?: Maybe<Array<Scalars['String']>>;
  itmCodes?: Maybe<Array<Scalars['String']>>;
  excludedItmCodes?: Maybe<Array<Scalars['String']>>;
  brandCodes?: Maybe<Array<Scalars['String']>>;
  excludedBrandCodes?: Maybe<Array<Scalars['String']>>;
  productCategories?: Maybe<Array<Scalars['String']>>;
  excludedProductCategories?: Maybe<Array<Scalars['String']>>;
  productTags?: Maybe<Array<Scalars['String']>>;
  excludedProductTags?: Maybe<Array<Scalars['String']>>;
  discountRulePayload?: Maybe<Scalars['JSON']>;
  dynamicPricePayload?: Maybe<Scalars['JSON']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
};

export type PriceRuleInput = {
  id: Scalars['String'];
  priceCode: Scalars['String'];
  promoCode?: Maybe<Scalars['String']>;
  weight: Scalars['Int'];
  disabled?: Maybe<Scalars['Boolean']>;
  crossedPrice?: Maybe<Scalars['Boolean']>;
  ruleType: PriceRuleType;
  discountType?: Maybe<DiscountType>;
  discountAmount?: Maybe<Scalars['Float']>;
  fixedPrice?: Maybe<Scalars['Float']>;
  fixedCompareAtPrice?: Maybe<Scalars['Float']>;
  validFrom?: Maybe<Scalars['DateTime']>;
  validTo?: Maybe<Scalars['DateTime']>;
  productIds?: Maybe<Array<Scalars['String']>>;
  excludedProductIds?: Maybe<Array<Scalars['String']>>;
  eanCodes?: Maybe<Array<Scalars['String']>>;
  excludedEanCodes?: Maybe<Array<Scalars['String']>>;
  itmCodes?: Maybe<Array<Scalars['String']>>;
  excludedItmCodes?: Maybe<Array<Scalars['String']>>;
  brandCodes?: Maybe<Array<Scalars['String']>>;
  excludedBrandCodes?: Maybe<Array<Scalars['String']>>;
  productCategories?: Maybe<Array<Scalars['String']>>;
  excludedProductCategories?: Maybe<Array<Scalars['String']>>;
  productTags?: Maybe<Array<Scalars['String']>>;
  excludedProductTags?: Maybe<Array<Scalars['String']>>;
  discountRulePayload?: Maybe<Scalars['JSON']>;
  dynamicPricePayload?: Maybe<Scalars['JSON']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
};

export enum PriceRuleType {
  Discount = 'Discount',
  FixedPrice = 'FixedPrice',
  DynamicPrice = 'DynamicPrice'
}

export type ProcessNavCatalogOptions = {
  refreshProduct?: Maybe<Scalars['Boolean']>;
};

export type ProcessNavStockOptions = {
  refreshProduct?: Maybe<Scalars['Boolean']>;
};

export type ProductCreateFieldNames = {
  __typename?: 'ProductCreateFieldNames';
  columns: Array<Scalars['String']>;
};

export type ProductCustomDataInfo = {
  __typename?: 'ProductCustomDataInfo';
  name: Scalars['String'];
  brand: Scalars['String'];
};

export type ProductCustomInfoEntity = {
  __typename?: 'ProductCustomInfoEntity';
  productId: Scalars['String'];
  weight?: Maybe<Scalars['String']>;
};

export type ProductCustomInfoEntityInput = {
  productId: Scalars['String'];
  weight?: Maybe<Scalars['String']>;
};

export type ProductCustomInfoItems = {
  __typename?: 'ProductCustomInfoItems';
  items: Array<ProductCustomItemMapping>;
};

export type ProductCustomItemMapping = {
  __typename?: 'ProductCustomItemMapping';
  meta?: Maybe<ProductCustomDataInfo>;
  data: ProductCustomInfoEntity;
};

export type ProductDiscountPayloadInput = {
  eanCodes: Array<Scalars['String']>;
  maxItems?: Maybe<Scalars['Int']>;
  amount: DiscountAmountInput;
};

export type ProductDiscountPayloadType = {
  __typename?: 'ProductDiscountPayloadType';
  eanCodes: Array<Scalars['String']>;
  maxItems?: Maybe<Scalars['Int']>;
  amount: DiscountAmountType;
};

export type ProductMediaRef = {
  __typename?: 'ProductMediaRef';
  id: Scalars['String'];
  filename: Scalars['String'];
  uploadHash: Scalars['String'];
  type: Scalars['String'];
};

export type ProductMediaRefInput = {
  id: Scalars['String'];
  filename: Scalars['String'];
  uploadHash: Scalars['String'];
  type: Scalars['String'];
};

export type ProductMediaUploadResult = {
  __typename?: 'ProductMediaUploadResult';
  ref: ProductMediaRef;
  url: Scalars['String'];
  type: VariantMediaType;
};

export type ProductToolSearchFilters = {
  productIds?: Maybe<Array<Scalars['String']>>;
  productVariantIds?: Maybe<Array<Scalars['String']>>;
  brandCodes?: Maybe<Array<Scalars['String']>>;
  categories?: Maybe<Array<Scalars['String']>>;
  name?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type ProductToolSearchOptions = {
  paging?: Maybe<ProductToolSearchPaging>;
  order?: Maybe<ProductToolSearchSorting>;
};

export type ProductToolSearchPaging = {
  tot?: Maybe<Scalars['Int']>;
  from?: Maybe<Scalars['Int']>;
  cursor?: Maybe<Scalars['String']>;
};

export type ProductToolSearchResultBrand = {
  __typename?: 'ProductToolSearchResultBrand';
  name: Scalars['String'];
  code: Scalars['String'];
  occurrences: Scalars['Int'];
};

export type ProductToolSearchResultCategory = {
  __typename?: 'ProductToolSearchResultCategory';
  code: Scalars['String'];
  name: Scalars['String'];
  occurrences: Scalars['Int'];
};

export type ProductToolSearchResultPaging = {
  __typename?: 'ProductToolSearchResultPaging';
  totResults: Scalars['Int'];
  size: Scalars['Int'];
  cursor?: Maybe<Scalars['String']>;
  isLastPage: Scalars['Boolean'];
};

export type ProductToolSearchResults = {
  __typename?: 'ProductToolSearchResults';
  results: Array<CatalogBrandProductDocument>;
  paging?: Maybe<ProductToolSearchResultPaging>;
  aggregations?: Maybe<ProductToolSearchResultsAggregations>;
};

export type ProductToolSearchResultsAggregations = {
  __typename?: 'ProductToolSearchResultsAggregations';
  productCategories: Array<ProductToolSearchResultCategory>;
  brands: Array<ProductToolSearchResultBrand>;
};

export type ProductToolSearchSorting = {
  by: Scalars['String'];
  direction: Scalars['String'];
};

export type ProductUpdateContents = {
  __typename?: 'ProductUpdateContents';
  shortDescription?: Maybe<Scalars['String']>;
  longDescription?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  inci?: Maybe<Scalars['String']>;
  composition?: Maybe<Scalars['String']>;
  highlights?: Maybe<Scalars['String']>;
  olfactoryNotes?: Maybe<Scalars['String']>;
  olfactoryFamily?: Maybe<Scalars['String']>;
  tests?: Maybe<Scalars['String']>;
  technology?: Maybe<Scalars['String']>;
};

export type ProductUpdateContentsInput = {
  shortDescription?: Maybe<Scalars['String']>;
  longDescription?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  inci?: Maybe<Scalars['String']>;
  composition?: Maybe<Scalars['String']>;
  highlights?: Maybe<Scalars['String']>;
  olfactoryNotes?: Maybe<Scalars['String']>;
  olfactoryFamily?: Maybe<Scalars['String']>;
  tests?: Maybe<Scalars['String']>;
  technology?: Maybe<Scalars['String']>;
};

export type ProductUpdateRecord = {
  __typename?: 'ProductUpdateRecord';
  ean: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  line?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['String']>;
  colorName?: Maybe<Scalars['String']>;
  colorHex?: Maybe<Scalars['String']>;
  colorUrl?: Maybe<Scalars['String']>;
  contents: ProductUpdateContents;
  relatedEanCodes?: Maybe<Array<Scalars['String']>>;
  complementaryEanCodes?: Maybe<Array<Scalars['String']>>;
  notes?: Maybe<Scalars['String']>;
  validFrom?: Maybe<Scalars['String']>;
  validTo?: Maybe<Scalars['String']>;
  imageUrls?: Maybe<Array<Scalars['String']>>;
  videoUrls?: Maybe<Array<Scalars['String']>>;
};

export type ProductUpdateRecordInput = {
  ean: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  line?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['String']>;
  colorName?: Maybe<Scalars['String']>;
  colorHex?: Maybe<Scalars['String']>;
  colorUrl?: Maybe<Scalars['String']>;
  contents: ProductUpdateContentsInput;
  relatedEanCodes?: Maybe<Array<Scalars['String']>>;
  complementaryEanCodes?: Maybe<Array<Scalars['String']>>;
  notes?: Maybe<Scalars['String']>;
  validFrom?: Maybe<Scalars['String']>;
  validTo?: Maybe<Scalars['String']>;
  imageUrls?: Maybe<Array<Scalars['String']>>;
  videoUrls?: Maybe<Array<Scalars['String']>>;
};

export type ProductsCreateXlsParseData = {
  __typename?: 'ProductsCreateXlsParseData';
  uploadHash: Scalars['String'];
  items: Array<ImportProductItem>;
  unmappedColumns: Array<Scalars['String']>;
};

export type ProductsCreateXlsParseResult = {
  __typename?: 'ProductsCreateXlsParseResult';
  data?: Maybe<ProductsCreateXlsParseData>;
  success: Scalars['Boolean'];
  errors?: Maybe<Array<ProductsXlsParseError>>;
};

export type ProductsDownloadInput = {
  productIds: Array<Scalars['String']>;
};

export type ProductsDownloadResult = {
  __typename?: 'ProductsDownloadResult';
  downloadUrl: Scalars['String'];
};

export type ProductsFeedDefinition = {
  __typename?: 'ProductsFeedDefinition';
  id: Scalars['String'];
  disabled?: Maybe<Scalars['Boolean']>;
  alias: Scalars['String'];
  path: Scalars['String'];
  user: Scalars['String'];
  password: Scalars['String'];
  filters?: Maybe<Scalars['JSON']>;
  fields?: Maybe<Scalars['JSON']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
};

export type ProductsFeedDefinitionInput = {
  id: Scalars['String'];
  disabled?: Maybe<Scalars['Boolean']>;
  alias: Scalars['String'];
  path: Scalars['String'];
  user: Scalars['String'];
  password: Scalars['String'];
  filters?: Maybe<Scalars['JSON']>;
  fields?: Maybe<Scalars['JSON']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
};

export type ProductsUpdateData = {
  __typename?: 'ProductsUpdateData';
  records: Array<ProductUpdateRecord>;
};

export type ProductsUpdateInput = {
  records: Array<ProductUpdateRecordInput>;
};

export type ProductsUpdatePreviewResult = {
  __typename?: 'ProductsUpdatePreviewResult';
  data: ProductsUpdateData;
  uploadHash: Scalars['String'];
};

export type ProductsXlsParseError = {
  __typename?: 'ProductsXlsParseError';
  type: VariantValidationErrorType;
  fieldName?: Maybe<Scalars['String']>;
  fieldValue?: Maybe<Scalars['String']>;
  itemIndex?: Maybe<Scalars['Int']>;
  itemId?: Maybe<Scalars['String']>;
};

export type PromoCode = {
  __typename?: 'PromoCode';
  id: Scalars['String'];
  code: Scalars['String'];
  trigger: PromoTriggerType;
  amount: Scalars['Float'];
};

export type PromoConditionInput = {
  datesFilter?: Maybe<PromoDatesFilterInput>;
  productsFilter?: Maybe<PromoProductsFilterInput>;
  lineItemsFilter?: Maybe<PromoLineItemFilterInput>;
  customerFilter?: Maybe<PromoCustomerFilterInput>;
  orderFilter?: Maybe<PromoOrderFilterInput>;
};

export type PromoConditionType = {
  __typename?: 'PromoConditionType';
  datesFilter?: Maybe<PromoDatesFilterType>;
  productsFilter?: Maybe<PromoProductsFilterType>;
  lineItemsFilter?: Maybe<PromoLineItemFilterType>;
  customerFilter?: Maybe<PromoCustomerFilterType>;
  orderFilter?: Maybe<PromoOrderFilterType>;
};

export type PromoCustomerFilterInput = {
  type?: Maybe<CustomerType>;
};

export type PromoCustomerFilterType = {
  __typename?: 'PromoCustomerFilterType';
  type?: Maybe<CustomerType>;
};

export type PromoDatesFilterInput = {
  validFrom?: Maybe<Scalars['DateTime']>;
  validTo?: Maybe<Scalars['DateTime']>;
};

export type PromoDatesFilterType = {
  __typename?: 'PromoDatesFilterType';
  validFrom?: Maybe<Scalars['DateTime']>;
  validTo?: Maybe<Scalars['DateTime']>;
};

export type PromoDefinition = {
  __typename?: 'PromoDefinition';
  id: Scalars['String'];
  promoCode: Scalars['String'];
  weight: Scalars['Float'];
  disabled: Scalars['Boolean'];
  options: PromoOptionsType;
  conditionType: PromotionConditionType;
  condition: Scalars['JSON'];
  effect: PromoEffectType;
  triggerType: PromoTriggerType;
  triggerParams?: Maybe<Scalars['JSON']>;
  productPromo?: Maybe<Scalars['Boolean']>;
  promoLogoUrl?: Maybe<Scalars['String']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
};

export type PromoDefinitionInput = {
  id: Scalars['String'];
  promoCode: Scalars['String'];
  weight: Scalars['Float'];
  disabled: Scalars['Boolean'];
  options: PromoOptionsInput;
  conditionType: PromotionConditionType;
  condition: Scalars['JSON'];
  effect: PromoEffectInput;
  triggerType: PromoTriggerType;
  triggerParams?: Maybe<Scalars['JSON']>;
  productPromo?: Maybe<Scalars['Boolean']>;
  promoLogoUrl?: Maybe<Scalars['String']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
};

export type PromoEffectInput = {
  type: PromoType;
  payload: Scalars['JSON'];
};

export type PromoEffectType = {
  __typename?: 'PromoEffectType';
  type: PromoType;
  payload: Scalars['JSON'];
};

export type PromoLineItemFilterInput = {
  linkedPriceCodes?: Maybe<Array<Scalars['String']>>;
};

export type PromoLineItemFilterType = {
  __typename?: 'PromoLineItemFilterType';
  linkedPriceCodes?: Maybe<Array<Scalars['String']>>;
};

export type PromoMinimumPriceTriggerParamsInput = {
  minAmount: Scalars['Float'];
};

export type PromoMinimumPriceTriggerParamsType = {
  __typename?: 'PromoMinimumPriceTriggerParamsType';
  minAmount: Scalars['Float'];
};

export type PromoOptionsInput = {
  cumulative: Scalars['Boolean'];
  type?: Maybe<Scalars['Float']>;
  exclusive?: Maybe<Scalars['Boolean']>;
  mixable?: Maybe<Scalars['Boolean']>;
};

export type PromoOptionsType = {
  __typename?: 'PromoOptionsType';
  cumulative: Scalars['Boolean'];
  type?: Maybe<Scalars['Float']>;
  exclusive?: Maybe<Scalars['Boolean']>;
  mixable?: Maybe<Scalars['Boolean']>;
};

export type PromoOrderFilterInput = {
  minSubtotal?: Maybe<Scalars['Float']>;
  minArticles?: Maybe<Scalars['Int']>;
  firstOrderOnly?: Maybe<Scalars['Boolean']>;
};

export type PromoOrderFilterType = {
  __typename?: 'PromoOrderFilterType';
  minSubtotal?: Maybe<Scalars['Float']>;
  minArticles?: Maybe<Scalars['Int']>;
  firstOrderOnly?: Maybe<Scalars['Boolean']>;
};

export type PromoProductAddToBasketTriggerParamsInput = {
  products: PromoProductsFilterInput;
};

export type PromoProductAddToBasketTriggerParamsType = {
  __typename?: 'PromoProductAddToBasketTriggerParamsType';
  products: PromoProductsFilterType;
};

export type PromoProductsFilterInput = {
  productId?: Maybe<Array<Scalars['String']>>;
  eanCode?: Maybe<Array<Scalars['String']>>;
  itmCode?: Maybe<Array<Scalars['String']>>;
  brandCode?: Maybe<Array<Scalars['String']>>;
  categoryCode?: Maybe<Array<Scalars['String']>>;
  tags?: Maybe<Array<Scalars['String']>>;
  excludedProductId?: Maybe<Array<Scalars['String']>>;
  excludedEanCode?: Maybe<Array<Scalars['String']>>;
  excludedItmCode?: Maybe<Array<Scalars['String']>>;
  excludedBrandCode?: Maybe<Array<Scalars['String']>>;
  excludedCategoryCode?: Maybe<Array<Scalars['String']>>;
  excludedTags?: Maybe<Array<Scalars['String']>>;
};

export type PromoProductsFilterType = {
  __typename?: 'PromoProductsFilterType';
  productId?: Maybe<Array<Scalars['String']>>;
  eanCode?: Maybe<Array<Scalars['String']>>;
  itmCode?: Maybe<Array<Scalars['String']>>;
  brandCode?: Maybe<Array<Scalars['String']>>;
  categoryCode?: Maybe<Array<Scalars['String']>>;
  tags?: Maybe<Array<Scalars['String']>>;
  excludedProductId?: Maybe<Array<Scalars['String']>>;
  excludedEanCode?: Maybe<Array<Scalars['String']>>;
  excludedItmCode?: Maybe<Array<Scalars['String']>>;
  excludedBrandCode?: Maybe<Array<Scalars['String']>>;
  excludedCategoryCode?: Maybe<Array<Scalars['String']>>;
  excludedTags?: Maybe<Array<Scalars['String']>>;
};

export enum PromoTriggerType {
  Automatic = 'Automatic',
  FirstOrder = 'FirstOrder',
  InviteFriend = 'InviteFriend',
  PromoCode = 'PromoCode',
  VoucherCode = 'VoucherCode',
  ExitPopup = 'ExitPopup',
  ProductAddToBasket = 'ProductAddToBasket',
  NewsletterSubscription = 'NewsletterSubscription',
  MinimumPrice = 'MinimumPrice',
  Birthday = 'Birthday',
  Passages = 'Passages',
  Points = 'Points',
  BackOffice = 'BackOffice',
  BasketItemPromoCode = 'BasketItemPromoCode'
}

export enum PromoType {
  Fixed = 'Fixed',
  Dynamic = 'Dynamic',
  Bxgy = 'Bxgy',
  Bundle = 'Bundle',
  IncrementalDiscount = 'IncrementalDiscount',
  FreeShipping = 'FreeShipping',
  FreeProduct = 'FreeProduct',
  FreeGadget = 'FreeGadget',
  ProductDiscount = 'ProductDiscount',
  None = 'None'
}

export type PromoVoucherCodeDurationInput = {
  hours: Scalars['Int'];
  days: Scalars['Int'];
};

export type PromoVoucherCodeDurationType = {
  __typename?: 'PromoVoucherCodeDurationType';
  hours: Scalars['Int'];
  days: Scalars['Int'];
};

export type PromoVoucherCodeTriggerParamsInput = {
  duration: PromoVoucherCodeDurationInput;
  voucherPrefix: Scalars['String'];
};

export type PromoVoucherCodeTriggerParamsType = {
  __typename?: 'PromoVoucherCodeTriggerParamsType';
  duration: PromoVoucherCodeDurationType;
  voucherPrefix: Scalars['String'];
};

export enum PromotionConditionType {
  Default = 'Default',
  Dynamic = 'Dynamic'
}

export type Province = {
  __typename?: 'Province';
  name: Scalars['String'];
  code: Scalars['String'];
};

export type PublishQueueItem = {
  __typename?: 'PublishQueueItem';
  id: Scalars['String'];
  productId: Scalars['String'];
  status: Scalars['String'];
  createdDate: Scalars['DateTime'];
};

export type QuantityInputType = {
  value?: Maybe<Scalars['String']>;
};

export type QuantityType = {
  __typename?: 'QuantityType';
  value: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  backofficeUsersGet: Array<UserType>;
  getUser: UserType;
  checkout?: Maybe<Checkout>;
  customerCheckout?: Maybe<Checkout>;
  checkoutGetAvailableCollectStores: Array<ClickAndCollectStore>;
  searchBasket: Array<RawBasket>;
  countries: Countries;
  provinces: Array<Province>;
  fullTextSearch: FullTextSearchResults;
  jobInstances: Array<JobStatusEntity>;
  storesV2: Array<StoreDocumentV2>;
  getCommerceProduct: CatalogProductDocument;
  getCommerceProductByVariantId: CatalogProductDocument;
  getCommerceProducts: Array<CatalogProductDocument>;
  getCommerceVariant: CatalogProductVariant;
  getCommerceVariants: Array<CatalogProductVariant>;
  priceRules: Array<PriceRule>;
  priceRule: PriceRule;
  dynamicPricePayload: DynamicPricePayload;
  voucherSearch: Array<VoucherCode>;
  voucherGet: VoucherCode;
  promotions: Array<PromoDefinition>;
  promotion: PromoDefinition;
  promoPromoCondition: PromoConditionType;
  promoDynamicPromoCondition: DynamicPromoCondition;
  promoVoucherCodeTriggerParams: PromoVoucherCodeTriggerParamsType;
  basketItemPromoCodeTriggerParams: BasketItemPromoCodeTriggerParamsType;
  promoMinimumPriceTriggerParams: PromoMinimumPriceTriggerParamsType;
  promoProductAddToBasketTriggerParams: PromoProductAddToBasketTriggerParamsType;
  fixedPromoPayload: FixedPromoPayloadType;
  bxgyPromoPayload: BxgyPromoPayloadType;
  freeGiftPromoPayload: FreeGiftPromoPayloadType;
  freeProductPayload: FreeProductPayloadType;
  incrementalDiscountPayload: IncrementalDiscountPayloadType;
  dynamicDiscountPayload: DynamicDiscountPayloadType;
  productDiscountPayload: ProductDiscountPayloadType;
  productsFeed: Array<ProductsFeedDefinition>;
  productFeed: ProductsFeedDefinition;
  getGiftcardBalance: GetGiftcardBalanceResult;
  getOrder?: Maybe<AdminOrderItem>;
  getOrders: OrderResult;
  orders: OrderResult;
  order?: Maybe<OrderDetails>;
  getCmsTags: Array<CatalogMetaItemType>;
  getCmsTagCategories: Array<CatalogMetaItemType>;
  getCmsProductCategories: Array<CatalogMetaItemType>;
  getToolMetaItems: ToolMetaData;
  searchCatalogProducts: ProductToolSearchResults;
  getProductVariants: Array<ToolProductVariantType>;
  getProductVariant?: Maybe<ToolProductVariantType>;
  getLatestVariantVersion: ToolProductVariantVersionType;
  getVariantVersion: ToolProductVariantVersionType;
  getLatestProductVersion: ToolProductVersionType;
  getProductVersion: ToolProductVariantVersionType;
  getProduct: ToolProductType;
  getPublishQueue: Array<PublishQueueItem>;
  massiveProductCreateFieldNames: ProductCreateFieldNames;
  masterProductCustomInfo: ProductCustomInfoItems;
  adminGetCustomer?: Maybe<Customer>;
  getCustomer?: Maybe<Customer>;
  customerUnSubscriptions: Array<CustomerUnSubscription>;
  crmDisabledProfiles: Array<CrmDisabledProfile>;
  getNavUserInfo: GetNarUserInfoData;
  wishlist?: Maybe<Wishlist>;
  wishlistAvailability: WishlistAvailability;
  timedVoucher?: Maybe<TimedVoucher>;
  fetchCrmCard: CardFetchResult;
  fetchCrmContact: ContactFetchResult;
  searchCrmContact: Array<CrmContactEntity>;
  searchCrmLogs: Array<CrmRequestsEntity>;
  getFeatureSwitches: Array<FeatureSwitchEntity>;
  getSqlParams: Array<ConfigParamEntry>;
  getCalendarStoreHolidays: Array<CalendarHolidayRange>;
  getCalendarGlobalHolidays: Array<CalendarHolidayRange>;
  calendarDefinition?: Maybe<CalendarDefinition>;
  calendarDefinitions: Array<CalendarDefinition>;
  calendarReservations: Array<CalendarReservation>;
  calendarCancellations: Array<CalendarReservationCancellation>;
  extraQuestion: EventExtraQuestion;
  calendarSchedule: CalendarSchedule;
  calendarEvent?: Maybe<CalendarEvent>;
};


export type QueryCheckoutArgs = {
  email?: Maybe<Scalars['String']>;
  draftOnly?: Maybe<Scalars['Boolean']>;
  languageId: Scalars['String'];
  id: Scalars['String'];
};


export type QueryCustomerCheckoutArgs = {
  languageId: Scalars['String'];
};


export type QueryCheckoutGetAvailableCollectStoresArgs = {
  checkoutId: Scalars['String'];
};


export type QuerySearchBasketArgs = {
  query: Scalars['String'];
};


export type QueryCountriesArgs = {
  languageId: Scalars['String'];
};


export type QueryProvincesArgs = {
  countryCode: Scalars['String'];
};


export type QueryFullTextSearchArgs = {
  options: FullTextSearchOptions;
  query: Scalars['String'];
};


export type QueryStoresV2Args = {
  input: GetStoresInput;
};


export type QueryGetCommerceProductArgs = {
  input: GetCommerceProductInputType;
};


export type QueryGetCommerceProductByVariantIdArgs = {
  input: GetCommerceProductByVariantIdInputType;
};


export type QueryGetCommerceProductsArgs = {
  input: GetCommerceProductsInputType;
};


export type QueryGetCommerceVariantArgs = {
  input: GetCommerceVariantInputType;
};


export type QueryGetCommerceVariantsArgs = {
  input: GetCommerceVariantsInputType;
};


export type QueryPriceRuleArgs = {
  id: Scalars['String'];
};


export type QueryVoucherSearchArgs = {
  input: SearchVoucherParamsInput;
};


export type QueryVoucherGetArgs = {
  code: Scalars['String'];
};


export type QueryPromotionArgs = {
  id: Scalars['String'];
};


export type QueryProductFeedArgs = {
  id: Scalars['String'];
};


export type QueryGetGiftcardBalanceArgs = {
  input: GetGiftcardBalanceInput;
};


export type QueryGetOrderArgs = {
  languageId: Scalars['String'];
  id: Scalars['String'];
};


export type QueryGetOrdersArgs = {
  input: GetOrdersInput;
};


export type QueryOrdersArgs = {
  cursor?: Maybe<Scalars['String']>;
  tot: Scalars['Int'];
  languageId: Scalars['String'];
};


export type QueryOrderArgs = {
  orderId: Scalars['String'];
  languageId: Scalars['String'];
};


export type QuerySearchCatalogProductsArgs = {
  options: ProductToolSearchOptions;
  filters: ProductToolSearchFilters;
};


export type QueryGetProductVariantsArgs = {
  input: GetProductVariantsInputType;
};


export type QueryGetProductVariantArgs = {
  input: GetProductVariantInputType;
};


export type QueryGetLatestVariantVersionArgs = {
  input: GetLatestVariantVersionInputType;
};


export type QueryGetVariantVersionArgs = {
  input: GetVariantVersionInputType;
};


export type QueryGetLatestProductVersionArgs = {
  input: GetLatestProductVersionInputType;
};


export type QueryGetProductVersionArgs = {
  input: GetProductVersionInputType;
};


export type QueryGetProductArgs = {
  input: GetProductInputType;
};


export type QueryAdminGetCustomerArgs = {
  input: AdminGetUserInput;
};


export type QueryGetNavUserInfoArgs = {
  input: GetNavUserInfoInput;
};


export type QueryWishlistArgs = {
  id: Scalars['String'];
};


export type QueryWishlistAvailabilityArgs = {
  input: WishlistAvailabilityInput;
};


export type QueryTimedVoucherArgs = {
  code: Scalars['String'];
};


export type QueryFetchCrmCardArgs = {
  id: Scalars['String'];
};


export type QueryFetchCrmContactArgs = {
  filter: Scalars['String'];
};


export type QuerySearchCrmContactArgs = {
  term: Scalars['String'];
};


export type QuerySearchCrmLogsArgs = {
  filters: CrmLogsSearchFilter;
};


export type QueryGetCalendarStoreHolidaysArgs = {
  storeId: Scalars['String'];
};


export type QueryCalendarDefinitionArgs = {
  id: Scalars['String'];
};


export type QueryCalendarReservationsArgs = {
  calendarId: Scalars['String'];
};


export type QueryCalendarCancellationsArgs = {
  calendarId: Scalars['String'];
};


export type QueryCalendarEventArgs = {
  alias: Scalars['String'];
};

export type RawBasket = {
  __typename?: 'RawBasket';
  data: Scalars['JSON'];
};

export type RefillGiftcardInput = {
  giftcardNumber: Scalars['String'];
  refillAmount: Scalars['Float'];
  orderId?: Maybe<Scalars['String']>;
};

export type RefreshProductsInputType = {
  eanCodes: Array<Scalars['String']>;
};

export type RefundOrderInput = {
  orderId: Scalars['String'];
  fullAmount: Scalars['Boolean'];
  customAmount?: Maybe<Scalars['Float']>;
};

export type RegisterStoreUserInput = {
  contactId: Scalars['String'];
  password: Scalars['String'];
};

export type RegisterStoreUserResult = {
  __typename?: 'RegisterStoreUserResult';
  success: Scalars['Boolean'];
  accessToken?: Maybe<Scalars['String']>;
};

export enum RegistrationError {
  UserAlreadyExists = 'USER_ALREADY_EXISTS',
  Other = 'OTHER'
}

export type RegistrationInput = {
  password: Scalars['String'];
  email: Scalars['String'];
  name: Scalars['String'];
  surname: Scalars['String'];
  gender?: Maybe<Scalars['String']>;
  createFidelityCard?: Maybe<Scalars['Boolean']>;
  birthDay: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  emailValidationBaseUrl: Scalars['String'];
  marketingAccepted?: Maybe<Scalars['Boolean']>;
  profilingAccepted?: Maybe<Scalars['Boolean']>;
  surveyAccepted?: Maybe<Scalars['Boolean']>;
  redirectUrl?: Maybe<Scalars['String']>;
};

export type RegistrationResult = {
  __typename?: 'RegistrationResult';
  success: Scalars['Boolean'];
  errorType?: Maybe<RegistrationError>;
  accessToken?: Maybe<Scalars['String']>;
};

export type RemoveWishlistItemInput = {
  wishlistId: Scalars['String'];
  variantId: Scalars['String'];
};

export type ReportExportTarget = {
  email: Array<Scalars['String']>;
};

export type ReportExtractionFilters = {
  itemCodes?: Maybe<Array<Scalars['String']>>;
};

export type ReportExtractionInput = {
  filters: ReportExtractionFilters;
  target: ReportExportTarget;
  refreshData: Scalars['Boolean'];
};

export enum ReportFormat {
  Json = 'Json',
  Csv = 'Csv'
}

export enum ResetPasswordError {
  InvalidToken = 'INVALID_TOKEN',
  Other = 'OTHER'
}

export type ResetPasswordInput = {
  newPassword: Scalars['String'];
  tokenJwt: Scalars['String'];
};

export type ResetPasswordResult = {
  __typename?: 'ResetPasswordResult';
  success: Scalars['Boolean'];
  errorType?: Maybe<ResetPasswordError>;
  accessToken?: Maybe<Scalars['String']>;
};

export type ReturnAddressInputType = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  countryCode: Scalars['String'];
  city: Scalars['String'];
  address: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  zipCode: Scalars['String'];
  phone: Scalars['String'];
};

export type ReturnInputType = {
  orderId: Scalars['String'];
  returnAddress: ReturnAddressInputType;
  notes?: Maybe<Scalars['String']>;
};

export enum RoundPriceStrategy {
  NoDecimals = 'NoDecimals',
  FirstDecimal = 'FirstDecimal',
  AllDecimals = 'AllDecimals'
}

export type SearchContactsFilter = {
  birthDateFrom?: Maybe<Scalars['String']>;
  birthDateTo?: Maybe<Scalars['String']>;
  hasEmail?: Maybe<Scalars['Boolean']>;
  hasPhone?: Maybe<Scalars['Boolean']>;
  gender?: Maybe<Scalars['String']>;
  marketingConsent?: Maybe<Scalars['Boolean']>;
  profilingConsent?: Maybe<Scalars['Boolean']>;
  surveyConsent?: Maybe<Scalars['Boolean']>;
  newsletterConsent?: Maybe<Scalars['Boolean']>;
  hasFidelityCard?: Maybe<Scalars['Boolean']>;
};

export type SearchVoucherParamsInput = {
  term?: Maybe<Scalars['String']>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export enum SendResetPasswordError {
  UserNotFound = 'USER_NOT_FOUND',
  Other = 'OTHER'
}

export type SendResetPasswordInput = {
  email: Scalars['String'];
  passwordResetBaseUrl: Scalars['String'];
  redirectUrl?: Maybe<Scalars['String']>;
};

export type SendResetPasswordResult = {
  __typename?: 'SendResetPasswordResult';
  success: Scalars['Boolean'];
  errorType?: Maybe<SendResetPasswordError>;
};

export type SetSwitchStatusInput = {
  id: Scalars['String'];
  enabled: Scalars['Boolean'];
};

export enum ShippingPreference {
  Courier = 'Courier',
  ClickAndCollect = 'ClickAndCollect'
}

export type ShopifyOrderMigrationInput = {
  shopifyOrderId: Scalars['String'];
};

export type SlotTime = {
  __typename?: 'SlotTime';
  hour: Scalars['Int'];
  minute: Scalars['Int'];
};

export enum SmsUnregisterError {
  NotFound = 'NotFound',
  GenericError = 'GenericError'
}

export type SmsUnregisterResult = {
  __typename?: 'SmsUnregisterResult';
  success: Scalars['Boolean'];
  error?: Maybe<SmsUnregisterError>;
};

export type SmsUnsubscribeInput = {
  phone: Scalars['String'];
};

export type StoreAddress = {
  __typename?: 'StoreAddress';
  street: Scalars['String'];
  cap: Scalars['String'];
  city: Scalars['String'];
  province: Scalars['String'];
};

export type StoreBusinessHour = {
  __typename?: 'StoreBusinessHour';
  day: Scalars['String'];
  hours: Scalars['String'];
};

export type StoreCalendar = {
  __typename?: 'StoreCalendar';
  url: Scalars['String'];
};

export type StoreContacts = {
  __typename?: 'StoreContacts';
  phone?: Maybe<Scalars['String']>;
};

export type StoreDocumentV2 = {
  __typename?: 'StoreDocumentV2';
  id: Scalars['String'];
  company: Scalars['String'];
  name: Scalars['String'];
  address: StoreAddress;
  coordinates?: Maybe<Coordinates>;
  businessHours?: Maybe<Array<StoreBusinessHour>>;
  contacts: StoreContacts;
  services?: Maybe<Array<StoreService>>;
  serviceReservationCalendar?: Maybe<StoreCalendar>;
  storeVisitCalendar?: Maybe<StoreCalendar>;
};

export type StoreOpenHours = {
  __typename?: 'StoreOpenHours';
  monday: Array<OpenHour>;
  tuesday: Array<OpenHour>;
  wednesday: Array<OpenHour>;
  thursday: Array<OpenHour>;
  friday: Array<OpenHour>;
  saturday: Array<OpenHour>;
  sunday: Array<OpenHour>;
};

export type StoreService = {
  __typename?: 'StoreService';
  name: Scalars['String'];
};

export type StructuredText = {
  __typename?: 'StructuredText';
  text: Scalars['String'];
  html: Scalars['String'];
};

export type SwapVariantsInputType = {
  eanCode1: Scalars['String'];
  eanCode2: Scalars['String'];
};

export type SyncKlaviyoProfileInput = {
  email: Scalars['String'];
};

export type SyncShopifyProfileInput = {
  email?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['String']>;
};

export type TimedVoucher = {
  __typename?: 'TimedVoucher';
  code: Scalars['String'];
  expirationDate?: Maybe<Scalars['String']>;
  valid: Scalars['Boolean'];
};

export type ToggleProductCategoryStatusInput = {
  code: Scalars['String'];
  disabled: Scalars['Boolean'];
};

export type ToggleTagStatusInput = {
  key: Scalars['String'];
  code: Scalars['String'];
  disabled: Scalars['Boolean'];
};

export type ToolColorType = {
  __typename?: 'ToolColorType';
  hex?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type ToolMediaUploadResult = {
  __typename?: 'ToolMediaUploadResult';
  assetUrl: Scalars['String'];
};

export type ToolMetaData = {
  __typename?: 'ToolMetaData';
  tag: Array<CatalogMetaItemType>;
  tagCategories: Array<CatalogMetaItemType>;
  categories: Array<CatalogMetaItemType>;
  brands: Array<CatalogMetaItemType>;
};

export type ToolProductItemType = {
  __typename?: 'ToolProductItemType';
  productId: Scalars['String'];
  productLine?: Maybe<Scalars['String']>;
  productLineId?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
  category?: Maybe<Scalars['String']>;
  secondaryCategories?: Maybe<Array<Scalars['String']>>;
  relatedEanCodes?: Maybe<Array<Scalars['String']>>;
  complementaryEanCodes?: Maybe<Array<Scalars['String']>>;
  contents: Array<BrandProductItemContentsType>;
  options?: Maybe<BrandProductOptions>;
};

export type ToolProductType = {
  __typename?: 'ToolProductType';
  productId: Scalars['String'];
  brandCode: Scalars['String'];
  versionId: Scalars['String'];
  internalNotes?: Maybe<Scalars['String']>;
  createdDate: Scalars['DateTime'];
  updatedDate: Scalars['DateTime'];
  publishable: Scalars['Boolean'];
  published: Scalars['Boolean'];
  publishedOn?: Maybe<Scalars['DateTime']>;
  status: Scalars['String'];
  disabled: Scalars['Boolean'];
  archived?: Maybe<Scalars['Boolean']>;
};

export type ToolProductVariantType = {
  __typename?: 'ToolProductVariantType';
  eanCode: Scalars['String'];
  productId: Scalars['String'];
  variant: BrandProductVariantType;
  versionId: Scalars['String'];
  variantIndex: Scalars['Int'];
  createdDate: Scalars['DateTime'];
  updatedDate: Scalars['DateTime'];
  disabled: Scalars['Boolean'];
  internalNotes?: Maybe<Scalars['String']>;
};

export type ToolProductVariantVersionType = {
  __typename?: 'ToolProductVariantVersionType';
  id: Scalars['String'];
  eanCode: Scalars['String'];
  version: Scalars['Int'];
  variant: BrandProductVariantType;
  createdDate: Scalars['DateTime'];
  updatedDate: Scalars['DateTime'];
  createdBy: Scalars['String'];
  published: Scalars['Boolean'];
  publishedOn?: Maybe<Scalars['DateTime']>;
  internalNotes?: Maybe<Scalars['String']>;
};

export type ToolProductVersionType = {
  __typename?: 'ToolProductVersionType';
  id: Scalars['String'];
  productId: Scalars['String'];
  brandCode: Scalars['String'];
  version: Scalars['Int'];
  createdDate: Scalars['DateTime'];
  updatedDate: Scalars['DateTime'];
  createdBy: Scalars['String'];
  published: Scalars['Boolean'];
  publishedOn?: Maybe<Scalars['DateTime']>;
  product: ToolProductItemType;
  internalNotes?: Maybe<Scalars['String']>;
};

export type ToolQuantityType = {
  __typename?: 'ToolQuantityType';
  value?: Maybe<Scalars['String']>;
};

export type TrackEventInput = {
  sessionId: Scalars['String'];
  userId: Scalars['String'];
  event: TrackEventType;
  payload: Scalars['JSON'];
};

export enum TrackEventType {
  ClickedProduct = 'ClickedProduct',
  ViewedProduct = 'ViewedProduct',
  PurchasedProduct = 'PurchasedProduct',
  OrderCompleted = 'OrderCompleted',
  OrderSynched = 'OrderSynched',
  ViewedCategory = 'ViewedCategory',
  ViewedBrand = 'ViewedBrand',
  ClickedFilter = 'ClickedFilter',
  UserRegistered = 'UserRegistered',
  NewsletterSubscribed = 'NewsletterSubscribed',
  NewsletterUnsubscribed = 'NewsletterUnsubscribed',
  FidelityCardActivated = 'FidelityCardActivated',
  VoucherCreated = 'VoucherCreated',
  VoucherPending = 'VoucherPending',
  WishlistItemAdded = 'WishlistItemAdded',
  WishlistItemRemoved = 'WishlistItemRemoved'
}

export type UpdateBasketInput = {
  data: Scalars['JSON'];
};

export type UpdateProductBrandNameInput = {
  productId: Scalars['String'];
  brandName: Scalars['String'];
};

export type UpdateSqlParamInput = {
  id: Scalars['String'];
  value?: Maybe<Scalars['JSON']>;
};


export type UserConsentsUnsubscribeInput = {
  email: Scalars['String'];
  revokeNewsletter: Scalars['Boolean'];
  revokeMarketing: Scalars['Boolean'];
};

export type UserFeedbackInput = {
  user?: Maybe<Scalars['String']>;
  category: Scalars['String'];
  reference: Scalars['String'];
  value: Scalars['String'];
};

export type UserProfile = {
  __typename?: 'UserProfile';
  name: Scalars['String'];
  surname: Scalars['String'];
  birthDay: Scalars['String'];
  gender?: Maybe<Scalars['String']>;
  taxCode?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type UserProfileUpdateInput = {
  name: Scalars['String'];
  surname: Scalars['String'];
  birthDay: Scalars['String'];
  gender?: Maybe<Scalars['String']>;
  taxCode?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type UserType = {
  __typename?: 'UserType';
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  roles?: Maybe<Array<Scalars['String']>>;
};

export enum ValidationErrorType {
  InvalidFormat = 'InvalidFormat',
  InvalidDomain = 'InvalidDomain'
}

export enum VariantGender {
  Male = 'Male',
  Female = 'Female',
  Unisex = 'Unisex'
}

export enum VariantMediaType {
  Image = 'Image',
  Video = 'Video',
  File = 'File'
}

export type VariantOrderInput = {
  items: Array<VariantOrderInputItem>;
};

export type VariantOrderInputItem = {
  eanCode: Scalars['String'];
  index: Scalars['Float'];
};

export enum VariantValidationErrorType {
  ProductAlreadyExists = 'ProductAlreadyExists',
  VariantAlreadyExists = 'VariantAlreadyExists',
  MissingField = 'MissingField',
  BrandNotFound = 'BrandNotFound',
  InvalidDateFormat = 'InvalidDateFormat'
}

export enum VerificationError {
  InvalidToken = 'INVALID_TOKEN',
  UserAlreadyVerified = 'USER_ALREADY_VERIFIED',
  Other = 'OTHER'
}

export type VerificationInput = {
  token: Scalars['String'];
};

export type VerificationResult = {
  __typename?: 'VerificationResult';
  success: Scalars['Boolean'];
  accessToken?: Maybe<Scalars['String']>;
  errorType?: Maybe<VerificationError>;
};

export type VoucherCode = {
  __typename?: 'VoucherCode';
  id?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  amount: Scalars['Float'];
  cumulative: Scalars['Boolean'];
  cumulativeType: Scalars['Float'];
  exclusive: Scalars['Boolean'];
  condition: PromoConditionType;
  amountType: DiscountType;
  validFrom?: Maybe<Scalars['String']>;
  validTo?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  basketId?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['String']>;
  usedDate?: Maybe<Scalars['DateTime']>;
  usedOnOrderId?: Maybe<Scalars['String']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
};

export type VoucherCodeInput = {
  id?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  amount: Scalars['Float'];
  cumulative: Scalars['Boolean'];
  cumulativeType: Scalars['Float'];
  exclusive: Scalars['Boolean'];
  condition: PromoConditionInput;
  amountType: DiscountType;
  validFrom?: Maybe<Scalars['String']>;
  validTo?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  basketId?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['String']>;
  usedDate?: Maybe<Scalars['DateTime']>;
  usedOnOrderId?: Maybe<Scalars['String']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
};

export type Wishlist = {
  __typename?: 'Wishlist';
  id: Scalars['String'];
  items: Array<WishlistItem>;
};

export type WishlistAvailability = {
  __typename?: 'WishlistAvailability';
  id: Scalars['String'];
  languageId: Scalars['String'];
  items: Array<WishlistAvailabilityItem>;
};

export type WishlistAvailabilityInput = {
  wishlistId: Scalars['String'];
  languageId: Scalars['String'];
};

export type WishlistAvailabilityItem = {
  __typename?: 'WishlistAvailabilityItem';
  variantId: Scalars['String'];
  item: CatalogProductDocument;
};

export type WishlistItem = {
  __typename?: 'WishlistItem';
  variantId: Scalars['String'];
};

export type CalendarHolidayRangeDataFragment = (
  { __typename?: 'CalendarHolidayRange' }
  & Pick<CalendarHolidayRange, 'from' | 'to'>
);

export type CalendarDefinitionDataFragment = (
  { __typename?: 'CalendarDefinition' }
  & Pick<CalendarDefinition, 'id' | 'alias' | 'name' | 'enabled' | 'validFrom' | 'validTo' | 'schedule' | 'storeId' | 'notificationEmail' | 'locationName' | 'eventName' | 'eventDescription' | 'maxVisibilityDays' | 'defaultEventDurationMin' | 'offsetBeforeEvent' | 'offsetAfterEvent' | 'eventStartTimeIncrements' | 'minReservationDaysOffset' | 'maxReservationsPerDay' | 'extraQuestions' | 'blockPublicEmails' | 'phoneRequired' | 'createdOn' | 'updatedOn' | 'internalNotificationTemplate' | 'internalNotificationSubject' | 'customerNotificationTemplate' | 'customerNotificationSubject' | 'internalNotificationAlias' | 'customerNotificationAlias' | 'internalCancellationNotificationTemplate' | 'customerCancellationNotificationTemplate'>
  & { options?: Maybe<(
    { __typename?: 'CalendarDefinitionOptions' }
    & { preSelection?: Maybe<(
      { __typename?: 'CalendarPreSelection' }
      & Pick<CalendarPreSelection, 'fixed' | 'preselectedDate'>
    )> }
  )> }
);

export type CalendarReservationDataFragment = (
  { __typename?: 'CalendarReservation' }
  & Pick<CalendarReservation, 'id' | 'calendarId' | 'guestName' | 'guestEmail' | 'guestNotes' | 'extraFields' | 'date' | 'startHour' | 'startMinute' | 'endHour' | 'endMinute' | 'createdOn' | 'updatedOn'>
);

export type CalendarReservationCancellationDataFragment = (
  { __typename?: 'CalendarReservationCancellation' }
  & Pick<CalendarReservationCancellation, 'id' | 'calendarId' | 'guestName' | 'guestEmail' | 'guestNotes' | 'extraFields' | 'date' | 'startHour' | 'startMinute' | 'endHour' | 'endMinute' | 'createdOn' | 'updatedOn'>
);

export type CreateCalendarMutationVariables = Exact<{
  input: CalendarDefinitionInput;
}>;


export type CreateCalendarMutation = (
  { __typename?: 'Mutation' }
  & { createCalendar: (
    { __typename?: 'CalendarDefinition' }
    & CalendarDefinitionDataFragment
  ) }
);

export type UpdateCalendarMutationVariables = Exact<{
  input: CalendarDefinitionInput;
}>;


export type UpdateCalendarMutation = (
  { __typename?: 'Mutation' }
  & { updateCalendar: (
    { __typename?: 'CalendarDefinition' }
    & CalendarDefinitionDataFragment
  ) }
);

export type ToggleCalendarMutationVariables = Exact<{
  id: Scalars['String'];
  enabled: Scalars['Boolean'];
}>;


export type ToggleCalendarMutation = (
  { __typename?: 'Mutation' }
  & { toggleCalendar: (
    { __typename?: 'CalendarDefinition' }
    & CalendarDefinitionDataFragment
  ) }
);

export type CreateCalendarStoreHolidaysMutationVariables = Exact<{
  input: CreateCalendarHolidaysInput;
}>;


export type CreateCalendarStoreHolidaysMutation = (
  { __typename?: 'Mutation' }
  & { createCalendarStoreHolidays: Array<(
    { __typename?: 'CalendarHolidayRange' }
    & CalendarHolidayRangeDataFragment
  )> }
);

export type DeleteCalendarStoreHolidaysMutationVariables = Exact<{
  input: DeleteCalendarHolidaysInput;
}>;


export type DeleteCalendarStoreHolidaysMutation = (
  { __typename?: 'Mutation' }
  & { deleteCalendarStoreHolidays: Array<(
    { __typename?: 'CalendarHolidayRange' }
    & CalendarHolidayRangeDataFragment
  )> }
);

export type CreateGlobalStoreHolidaysMutationVariables = Exact<{
  input: CalendarHolidayRangeInput;
}>;


export type CreateGlobalStoreHolidaysMutation = (
  { __typename?: 'Mutation' }
  & { createGlobalStoreHolidays: Array<(
    { __typename?: 'CalendarHolidayRange' }
    & CalendarHolidayRangeDataFragment
  )> }
);

export type DeleteGlobalStoreHolidaysMutationVariables = Exact<{
  input: CalendarHolidayRangeInput;
}>;


export type DeleteGlobalStoreHolidaysMutation = (
  { __typename?: 'Mutation' }
  & { deleteGlobalStoreHolidays: Array<(
    { __typename?: 'CalendarHolidayRange' }
    & CalendarHolidayRangeDataFragment
  )> }
);

export type AppointmentsFullSyncRunMutationVariables = Exact<{ [key: string]: never; }>;


export type AppointmentsFullSyncRunMutation = (
  { __typename?: 'Mutation' }
  & { appointmentsFullSyncRun: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type CalendarsExportMutationVariables = Exact<{ [key: string]: never; }>;


export type CalendarsExportMutation = (
  { __typename?: 'Mutation' }
  & { calendarsExport: (
    { __typename?: 'CalendarsExport' }
    & Pick<CalendarsExport, 'url'>
  ) }
);

export type CalendarDefinitionsQueryVariables = Exact<{ [key: string]: never; }>;


export type CalendarDefinitionsQuery = (
  { __typename?: 'Query' }
  & { calendarDefinitions: Array<(
    { __typename?: 'CalendarDefinition' }
    & CalendarDefinitionDataFragment
  )> }
);

export type CalendarDefinitionQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type CalendarDefinitionQuery = (
  { __typename?: 'Query' }
  & { calendarDefinition?: Maybe<(
    { __typename?: 'CalendarDefinition' }
    & CalendarDefinitionDataFragment
  )> }
);

export type GetCalendarGlobalHolidaysQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCalendarGlobalHolidaysQuery = (
  { __typename?: 'Query' }
  & { getCalendarGlobalHolidays: Array<(
    { __typename?: 'CalendarHolidayRange' }
    & CalendarHolidayRangeDataFragment
  )> }
);

export type GetCalendarStoreHolidaysQueryVariables = Exact<{
  storeId: Scalars['String'];
}>;


export type GetCalendarStoreHolidaysQuery = (
  { __typename?: 'Query' }
  & { getCalendarStoreHolidays: Array<(
    { __typename?: 'CalendarHolidayRange' }
    & CalendarHolidayRangeDataFragment
  )> }
);

export type CalendarReservationsQueryVariables = Exact<{
  calendarId: Scalars['String'];
}>;


export type CalendarReservationsQuery = (
  { __typename?: 'Query' }
  & { calendarReservations: Array<(
    { __typename?: 'CalendarReservation' }
    & CalendarReservationDataFragment
  )> }
);

export type CalendarCancellationsQueryVariables = Exact<{
  calendarId: Scalars['String'];
}>;


export type CalendarCancellationsQuery = (
  { __typename?: 'Query' }
  & { calendarCancellations: Array<(
    { __typename?: 'CalendarReservationCancellation' }
    & CalendarReservationCancellationDataFragment
  )> }
);

export type CrmContactEntityDataFragment = (
  { __typename?: 'CrmContactEntity' }
  & Pick<CrmContactEntity, 'contactId' | 'company' | 'birthDate' | 'email' | 'phoneNumber' | 'firstName' | 'lastName' | 'gender' | 'marketingConsent' | 'profilingConsent' | 'surveyConsent' | 'newsletterConsent' | 'fidelityCardId' | 'fidelityCardNumber' | 'fidelityCardPoints' | 'crmCreatedOn' | 'crmModifiedOn' | 'createdOn' | 'updatedOn'>
);

export type ImportCrmContactsMutationVariables = Exact<{
  input: CrmImportJobsInput;
}>;


export type ImportCrmContactsMutation = (
  { __typename?: 'Mutation' }
  & { importCrmContacts: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type UnusedVouchersSyncMutationVariables = Exact<{ [key: string]: never; }>;


export type UnusedVouchersSyncMutation = (
  { __typename?: 'Mutation' }
  & { unusedVouchersSync: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type ShopifyCrmSyncMutationVariables = Exact<{ [key: string]: never; }>;


export type ShopifyCrmSyncMutation = (
  { __typename?: 'Mutation' }
  & { shopifyCrmSync: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type SyncShopifyProfileMutationVariables = Exact<{
  input: SyncShopifyProfileInput;
}>;


export type SyncShopifyProfileMutation = (
  { __typename?: 'Mutation' }
  & { syncShopifyProfile: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type SyncKlaviyoProfileMutationVariables = Exact<{
  input: SyncKlaviyoProfileInput;
}>;


export type SyncKlaviyoProfileMutation = (
  { __typename?: 'Mutation' }
  & { syncKlaviyoProfile: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type ExportCrmContactsMutationVariables = Exact<{
  input: ExportCrmContactsInput;
}>;


export type ExportCrmContactsMutation = (
  { __typename?: 'Mutation' }
  & { exportCrmContacts: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type RunCrmCardsReportJobMutationVariables = Exact<{ [key: string]: never; }>;


export type RunCrmCardsReportJobMutation = (
  { __typename?: 'Mutation' }
  & { runCrmCardsReportJob: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type CrmDisabledProfilesAddMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type CrmDisabledProfilesAddMutation = (
  { __typename?: 'Mutation' }
  & { crmDisabledProfilesAdd: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type CrmDisabledProfilesRemoveMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type CrmDisabledProfilesRemoveMutation = (
  { __typename?: 'Mutation' }
  & { crmDisabledProfilesRemove: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type SimulateCustomerNavDiscountsMutationVariables = Exact<{
  input: CustomerNavSimulationsInput;
}>;


export type SimulateCustomerNavDiscountsMutation = (
  { __typename?: 'Mutation' }
  & { simulateCustomerNavDiscounts: (
    { __typename?: 'CustomerAvailableDiscounts' }
    & Pick<CustomerAvailableDiscounts, 'birthdayDiscount' | 'passagesDiscount' | 'promoCode'>
    & { pointDiscounts: Array<(
      { __typename?: 'CustomerPointsDiscount' }
      & Pick<CustomerPointsDiscount, 'points' | 'discount'>
    )> }
  ) }
);

export type SearchCrmLogsQueryVariables = Exact<{
  filters: CrmLogsSearchFilter;
}>;


export type SearchCrmLogsQuery = (
  { __typename?: 'Query' }
  & { searchCrmLogs: Array<(
    { __typename?: 'CrmRequestsEntity' }
    & Pick<CrmRequestsEntity, 'id' | 'cmrId' | 'ecommerceId' | 'userEmail' | 'url' | 'method' | 'requestHeaders' | 'request' | 'response' | 'status' | 'error' | 'createdDate'>
  )> }
);

export type SearchCrmContactQueryVariables = Exact<{
  term: Scalars['String'];
}>;


export type SearchCrmContactQuery = (
  { __typename?: 'Query' }
  & { searchCrmContact: Array<(
    { __typename?: 'CrmContactEntity' }
    & CrmContactEntityDataFragment
  )> }
);

export type FetchCrmCardQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type FetchCrmCardQuery = (
  { __typename?: 'Query' }
  & { fetchCrmCard: (
    { __typename?: 'CardFetchResult' }
    & Pick<CardFetchResult, 'data'>
  ) }
);

export type FetchCrmContactQueryVariables = Exact<{
  filter: Scalars['String'];
}>;


export type FetchCrmContactQuery = (
  { __typename?: 'Query' }
  & { fetchCrmContact: (
    { __typename?: 'ContactFetchResult' }
    & Pick<ContactFetchResult, 'data'>
  ) }
);

export type CrmDisabledProfilesQueryVariables = Exact<{ [key: string]: never; }>;


export type CrmDisabledProfilesQuery = (
  { __typename?: 'Query' }
  & { crmDisabledProfiles: Array<(
    { __typename?: 'CrmDisabledProfile' }
    & Pick<CrmDisabledProfile, 'id' | 'email' | 'createdOn'>
  )> }
);

export type AdminGetCustomerQueryVariables = Exact<{
  input: AdminGetUserInput;
}>;


export type AdminGetCustomerQuery = (
  { __typename?: 'Query' }
  & { adminGetCustomer?: Maybe<(
    { __typename?: 'Customer' }
    & { account: (
      { __typename?: 'CustomerAccount' }
      & Pick<CustomerAccount, 'email' | 'emailVerified'>
    ), userProfile: (
      { __typename?: 'UserProfile' }
      & Pick<UserProfile, 'name' | 'surname' | 'birthDay' | 'gender' | 'taxCode' | 'phone' | 'country' | 'state' | 'city' | 'address' | 'zipCode'>
    ), marketingProfile: (
      { __typename?: 'MarketingProfile' }
      & Pick<MarketingProfile, 'subscribedToNewsletter' | 'marketingConsent' | 'profilingConsent' | 'surveyConsent' | 'cardId' | 'cardBalance' | 'oldCustomer' | 'hasOldCard' | 'newTermsAccepted'>
    ) }
  )> }
);

export type GetNavUserInfoQueryVariables = Exact<{
  input: GetNavUserInfoInput;
}>;


export type GetNavUserInfoQuery = (
  { __typename?: 'Query' }
  & { getNavUserInfo: (
    { __typename?: 'GetNarUserInfoData' }
    & Pick<GetNarUserInfoData, 'rawData'>
  ) }
);

export type ImportProductCostsMutationVariables = Exact<{
  input: ImportProductCostsInputType;
}>;


export type ImportProductCostsMutation = (
  { __typename?: 'Mutation' }
  & { importProductCosts: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type ProductsFeedDefinitionDataFragment = (
  { __typename?: 'ProductsFeedDefinition' }
  & Pick<ProductsFeedDefinition, 'id' | 'disabled' | 'alias' | 'path' | 'user' | 'password' | 'filters' | 'fields' | 'createdDate' | 'updatedDate'>
);

export type ProcessMinderestFeedMutationVariables = Exact<{ [key: string]: never; }>;


export type ProcessMinderestFeedMutation = (
  { __typename?: 'Mutation' }
  & { processMinderestFeed: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type ProductFeedCreateMutationVariables = Exact<{
  data: ProductsFeedDefinitionInput;
}>;


export type ProductFeedCreateMutation = (
  { __typename?: 'Mutation' }
  & { productFeedCreate: (
    { __typename?: 'ProductsFeedDefinition' }
    & ProductsFeedDefinitionDataFragment
  ) }
);

export type ProductFeedUpdateMutationVariables = Exact<{
  id: Scalars['String'];
  data: ProductsFeedDefinitionInput;
}>;


export type ProductFeedUpdateMutation = (
  { __typename?: 'Mutation' }
  & { productFeedUpdate: (
    { __typename?: 'ProductsFeedDefinition' }
    & ProductsFeedDefinitionDataFragment
  ) }
);

export type ProductFeedDeleteMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type ProductFeedDeleteMutation = (
  { __typename?: 'Mutation' }
  & { productFeedDelete: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type ProductFeedEnableMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type ProductFeedEnableMutation = (
  { __typename?: 'Mutation' }
  & { productFeedEnable: (
    { __typename?: 'ProductsFeedDefinition' }
    & ProductsFeedDefinitionDataFragment
  ) }
);

export type ProductFeedDisableMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type ProductFeedDisableMutation = (
  { __typename?: 'Mutation' }
  & { productFeedDisable: (
    { __typename?: 'ProductsFeedDefinition' }
    & ProductsFeedDefinitionDataFragment
  ) }
);

export type ProductsFeedQueryVariables = Exact<{ [key: string]: never; }>;


export type ProductsFeedQuery = (
  { __typename?: 'Query' }
  & { productsFeed: Array<(
    { __typename?: 'ProductsFeedDefinition' }
    & ProductsFeedDefinitionDataFragment
  )> }
);

export type ProductFeedQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type ProductFeedQuery = (
  { __typename?: 'Query' }
  & { productFeed: (
    { __typename?: 'ProductsFeedDefinition' }
    & ProductsFeedDefinitionDataFragment
  ) }
);

export type ServerCacheClearMutationVariables = Exact<{ [key: string]: never; }>;


export type ServerCacheClearMutation = (
  { __typename?: 'Mutation' }
  & { serverCacheClear: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type UploadToolAssetMutationVariables = Exact<{
  file: Scalars['Upload'];
}>;


export type UploadToolAssetMutation = (
  { __typename?: 'Mutation' }
  & { uploadToolAsset: (
    { __typename?: 'ToolMediaUploadResult' }
    & Pick<ToolMediaUploadResult, 'assetUrl'>
  ) }
);

export type CreateCmsTagMutationVariables = Exact<{
  input: CreateCmsTagInput;
}>;


export type CreateCmsTagMutation = (
  { __typename?: 'Mutation' }
  & { createCmsTag: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type CreateCmsTagCategoryMutationVariables = Exact<{
  input: CreateCmsTagCategoryInput;
}>;


export type CreateCmsTagCategoryMutation = (
  { __typename?: 'Mutation' }
  & { createCmsTagCategory: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type CreateCmsProductCategoryMutationVariables = Exact<{
  input: CreateCmsProductCategoryInput;
}>;


export type CreateCmsProductCategoryMutation = (
  { __typename?: 'Mutation' }
  & { createCmsProductCategory: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type ExportTagsMutationVariables = Exact<{ [key: string]: never; }>;


export type ExportTagsMutation = (
  { __typename?: 'Mutation' }
  & { exportTags: (
    { __typename?: 'ExportToolProductsResult' }
    & Pick<ExportToolProductsResult, 'url'>
  ) }
);

export type ExportTagCategoriesMutationVariables = Exact<{ [key: string]: never; }>;


export type ExportTagCategoriesMutation = (
  { __typename?: 'Mutation' }
  & { exportTagCategories: (
    { __typename?: 'ExportToolProductsResult' }
    & Pick<ExportToolProductsResult, 'url'>
  ) }
);

export type ExportProductCategoriesMutationVariables = Exact<{
  format: ReportFormat;
}>;


export type ExportProductCategoriesMutation = (
  { __typename?: 'Mutation' }
  & { exportProductCategories: (
    { __typename?: 'ExportToolProductsResult' }
    & Pick<ExportToolProductsResult, 'url'>
  ) }
);

export type ToggleProductCategoryStatusMutationVariables = Exact<{
  input: ToggleProductCategoryStatusInput;
}>;


export type ToggleProductCategoryStatusMutation = (
  { __typename?: 'Mutation' }
  & { toggleProductCategoryStatus: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type ToggleTagStatusMutationVariables = Exact<{
  input: ToggleTagStatusInput;
}>;


export type ToggleTagStatusMutation = (
  { __typename?: 'Mutation' }
  & { toggleTagStatus: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type GetCmsTagsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCmsTagsQuery = (
  { __typename?: 'Query' }
  & { getCmsTags: Array<(
    { __typename?: 'CatalogMetaItemType' }
    & Pick<CatalogMetaItemType, 'key' | 'label' | 'disabled'>
  )> }
);

export type GetCmsTagCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCmsTagCategoriesQuery = (
  { __typename?: 'Query' }
  & { getCmsTagCategories: Array<(
    { __typename?: 'CatalogMetaItemType' }
    & Pick<CatalogMetaItemType, 'key' | 'label'>
  )> }
);

export type GetCmsProductCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCmsProductCategoriesQuery = (
  { __typename?: 'Query' }
  & { getCmsProductCategories: Array<(
    { __typename?: 'CatalogMetaItemType' }
    & Pick<CatalogMetaItemType, 'key' | 'label' | 'disabled'>
    & { parent?: Maybe<(
      { __typename?: 'CatalogMetaItemType' }
      & Pick<CatalogMetaItemType, 'key' | 'label' | 'disabled'>
      & { parent?: Maybe<(
        { __typename?: 'CatalogMetaItemType' }
        & Pick<CatalogMetaItemType, 'key' | 'label' | 'disabled'>
        & { parent?: Maybe<(
          { __typename?: 'CatalogMetaItemType' }
          & Pick<CatalogMetaItemType, 'key' | 'label' | 'disabled'>
        )> }
      )> }
    )> }
  )> }
);

export type OrderPaymentDetailsDataFragment = (
  { __typename?: 'OrderPaymentDetails' }
  & Pick<OrderPaymentDetails, 'provider' | 'transactionId' | 'sessionId'>
);

export type OrderInfoDataFragment = (
  { __typename?: 'OrderInfo' }
  & Pick<OrderInfo, 'raw' | 'id' | 'uid' | 'type' | 'alias' | 'status' | 'createdAt' | 'totalPrice' | 'tags' | 'email'>
  & { shippingAddress?: Maybe<(
    { __typename?: 'OrderShippingAddress' }
    & Pick<OrderShippingAddress, 'firstName' | 'lastName' | 'country' | 'countryCode' | 'provinceCode' | 'city' | 'address' | 'address2' | 'zipCode' | 'phone'>
  )> }
);

export type OrderDetailsDataFragment = (
  { __typename?: 'OrderDetails' }
  & Pick<OrderDetails, 'id' | 'tags' | 'alias' | 'status' | 'createdAt' | 'deliveredDate' | 'shippedDate'>
  & { editOrder?: Maybe<(
    { __typename?: 'EditOrderDetails' }
    & Pick<EditOrderDetails, 'pendingPaymentUrl' | 'pendingPaymentAmount'>
  )>, price: (
    { __typename?: 'OrderPrice' }
    & Pick<OrderPrice, 'shippingPrice' | 'subtotal' | 'discountTotal' | 'total' | 'totalPaid' | 'currency'>
    & { giftcards: Array<(
      { __typename?: 'OrderGiftcard' }
      & Pick<OrderGiftcard, 'code' | 'amountUsed'>
    )> }
  ), basketDiscounts: Array<(
    { __typename?: 'CheckoutBasketDiscount' }
    & Pick<CheckoutBasketDiscount, 'totalDiscount' | 'trigger' | 'code' | 'name'>
    & { item?: Maybe<(
      { __typename?: 'CatalogPromo' }
      & Pick<CatalogPromo, 'code' | 'name'>
      & { contents: (
        { __typename?: 'CatalogPromoContents' }
        & Pick<CatalogPromoContents, 'conditions' | 'description' | 'logoUrl'>
      ) }
    )> }
  )>, tracking?: Maybe<(
    { __typename?: 'OrderTracking' }
    & Pick<OrderTracking, 'url'>
  )>, shipping?: Maybe<(
    { __typename?: 'OrderShipping' }
    & Pick<OrderShipping, 'preference'>
    & { shippingAddress?: Maybe<(
      { __typename?: 'OrderShippingAddress' }
      & Pick<OrderShippingAddress, 'firstName' | 'lastName' | 'country' | 'countryCode' | 'provinceCode' | 'city' | 'address' | 'address2' | 'zipCode' | 'phone'>
    )>, billingAddress?: Maybe<(
      { __typename?: 'OrderShippingAddress' }
      & Pick<OrderShippingAddress, 'firstName' | 'lastName' | 'country' | 'countryCode' | 'provinceCode' | 'city' | 'address' | 'address2' | 'zipCode' | 'phone'>
    )>, store?: Maybe<(
      { __typename?: 'StoreDocumentV2' }
      & Pick<StoreDocumentV2, 'id' | 'name'>
      & { address: (
        { __typename?: 'StoreAddress' }
        & Pick<StoreAddress, 'street' | 'cap' | 'city' | 'province'>
      ), coordinates?: Maybe<(
        { __typename?: 'Coordinates' }
        & Pick<Coordinates, 'latitude' | 'longitude'>
      )>, contacts: (
        { __typename?: 'StoreContacts' }
        & Pick<StoreContacts, 'phone'>
      ) }
    )> }
  )>, customer: (
    { __typename?: 'OrderCustomer' }
    & Pick<OrderCustomer, 'email' | 'acceptsMarketing'>
  ), lineItems: Array<(
    { __typename?: 'OrderLineItem' }
    & Pick<OrderLineItem, 'id' | 'quantity' | 'originalUnitPrice' | 'originalTotal' | 'discountedUnitPrice' | 'discountedTotal' | 'originalTotalCompareAtPrice' | 'originalUnitCompareAtPrice' | 'isGift'>
    & { product?: Maybe<(
      { __typename?: 'OrderLineItemCatalogData' }
      & { document: (
        { __typename?: 'CatalogProductDocument' }
        & { product: (
          { __typename?: 'CatalogProduct' }
          & CatalogProductSearchDataFragment
        ), variants: Array<(
          { __typename?: 'CatalogProductVariant' }
          & CatalogProductVariantSearchDataFragment
        )>, defaultVariant: (
          { __typename?: 'CatalogProductVariant' }
          & DefaultCatalogProductVariantSearchDataFragment
        ) }
      ), variant: (
        { __typename?: 'CatalogProductVariant' }
        & CatalogProductVariantSearchDataFragment
      ) }
    )> }
  )> }
);

export type CheckoutDataFragment = (
  { __typename?: 'Checkout' }
  & Pick<Checkout, 'id' | 'status'>
  & { selectedPayment?: Maybe<(
    { __typename?: 'CheckoutSelectedPaymentMethod' }
    & Pick<CheckoutSelectedPaymentMethod, 'providerId' | 'captureType'>
  )>, price: (
    { __typename?: 'CheckoutPrice' }
    & Pick<CheckoutPrice, 'currierPaymentPrice' | 'shippingPrice' | 'shippingDiscount' | 'defaultFreeShippingThreshold' | 'defaultShippingPrice' | 'hasFreeShippingPromo' | 'subtotal' | 'discountTotal' | 'total' | 'totalToPay' | 'totalToPayLater' | 'currency'>
    & { appliedGiftcards?: Maybe<Array<(
      { __typename?: 'CheckoutAppliedGiftcard' }
      & Pick<CheckoutAppliedGiftcard, 'code' | 'totalAmount' | 'usedAmount'>
    )>> }
  ), promotions: Array<(
    { __typename?: 'PromoCode' }
    & Pick<PromoCode, 'id' | 'code' | 'trigger' | 'amount'>
  )>, basketDiscounts: Array<(
    { __typename?: 'CheckoutBasketDiscount' }
    & Pick<CheckoutBasketDiscount, 'totalDiscount' | 'trigger' | 'id' | 'code' | 'name'>
    & { item?: Maybe<(
      { __typename?: 'CatalogPromo' }
      & Pick<CatalogPromo, 'code' | 'name'>
      & { contents: (
        { __typename?: 'CatalogPromoContents' }
        & Pick<CatalogPromoContents, 'conditions' | 'description' | 'logoUrl'>
      ) }
    )> }
  )>, unavailableLineItems?: Maybe<Array<(
    { __typename?: 'CheckoutLineItemCatalogData' }
    & { variant: (
      { __typename?: 'CatalogProductVariant' }
      & CatalogProductVariantSearchDataFragment
    ) }
  )>>, lineItems: Array<(
    { __typename?: 'CheckoutLineItem' }
    & Pick<CheckoutLineItem, 'id' | 'quantity' | 'isGift' | 'originalUnitPrice' | 'originalTotal' | 'discountedUnitPrice' | 'discountedTotal' | 'originalUnitCompareAtPrice' | 'originalTotalCompareAtPrice' | 'discountPercent' | 'extraDiscountPercent' | 'assignedGiftCardCode' | 'source' | 'promoCodes'>
    & { promotions: Array<(
      { __typename?: 'CheckoutPromo' }
      & Pick<CheckoutPromo, 'code' | 'name'>
      & { item?: Maybe<(
        { __typename?: 'CatalogPromo' }
        & Pick<CatalogPromo, 'code' | 'name'>
        & { contents: (
          { __typename?: 'CatalogPromoContents' }
          & Pick<CatalogPromoContents, 'conditions' | 'description' | 'logoUrl'>
        ) }
      )> }
    )>, product?: Maybe<(
      { __typename?: 'CheckoutLineItemCatalogData' }
      & { document: (
        { __typename?: 'CatalogProductDocument' }
        & { product: (
          { __typename?: 'CatalogProduct' }
          & CatalogProductSearchDataFragment
        ), variants: Array<(
          { __typename?: 'CatalogProductVariant' }
          & CatalogProductVariantSearchDataFragment
        )>, defaultVariant: (
          { __typename?: 'CatalogProductVariant' }
          & DefaultCatalogProductVariantSearchDataFragment
        ), extraData?: Maybe<(
          { __typename?: 'CatalogProductExtraData' }
          & CatalogProductExtraDataInfoFragment
        )> }
      ), variant: (
        { __typename?: 'CatalogProductVariant' }
        & CatalogProductVariantSearchDataFragment
      ) }
    )> }
  )>, promoState: (
    { __typename?: 'CheckoutPromoState' }
    & Pick<CheckoutPromoState, 'hasLoggedPromoInBasket' | 'acceptedNoLoggedPromo' | 'authenticatedBasket'>
  ), samples?: Maybe<(
    { __typename?: 'CheckoutSamples' }
    & Pick<CheckoutSamples, 'notes'>
    & { samples: Array<(
      { __typename?: 'CheckoutSample' }
      & Pick<CheckoutSample, 'id' | 'name'>
    )> }
  )>, shipping?: Maybe<(
    { __typename?: 'CheckoutShipping' }
    & Pick<CheckoutShipping, 'preference'>
    & { store?: Maybe<(
      { __typename?: 'StoreDocumentV2' }
      & Pick<StoreDocumentV2, 'id' | 'name' | 'company'>
      & { address: (
        { __typename?: 'StoreAddress' }
        & Pick<StoreAddress, 'street' | 'cap' | 'city' | 'province'>
      ) }
    )>, shippingAddress?: Maybe<(
      { __typename?: 'CheckoutShippingAddress' }
      & Pick<CheckoutShippingAddress, 'customerAddressId' | 'firstName' | 'lastName' | 'countryCode' | 'provinceCode' | 'city' | 'address' | 'address2' | 'notes' | 'company' | 'vatNumber' | 'zipCode' | 'phone'>
    )>, billingAddress?: Maybe<(
      { __typename?: 'CheckoutShippingAddress' }
      & Pick<CheckoutShippingAddress, 'customerAddressId' | 'firstName' | 'lastName' | 'countryCode' | 'provinceCode' | 'city' | 'address' | 'address2' | 'notes' | 'company' | 'vatNumber' | 'zipCode' | 'phone'>
    )> }
  )>, customer: (
    { __typename?: 'CheckoutCustomer' }
    & Pick<CheckoutCustomer, 'email' | 'acceptsMarketing'>
  ), order?: Maybe<(
    { __typename?: 'CheckoutOrder' }
    & Pick<CheckoutOrder, 'id' | 'uid'>
  )>, settings?: Maybe<(
    { __typename?: 'CheckoutSettings' }
    & Pick<CheckoutSettings, 'clickAndCollectEnabled' | 'giftcardEnabled' | 'giftBoxEnabled' | 'enabledPaymentMethods'>
  )>, customerDiscounts?: Maybe<(
    { __typename?: 'CustomerAvailableDiscounts' }
    & Pick<CustomerAvailableDiscounts, 'birthdayDiscount' | 'passagesDiscount' | 'promoCode'>
    & { pointDiscounts: Array<(
      { __typename?: 'CustomerPointsDiscount' }
      & Pick<CustomerPointsDiscount, 'points' | 'discount'>
    )> }
  )>, lock?: Maybe<(
    { __typename?: 'CheckoutLock' }
    & Pick<CheckoutLock, 'locked' | 'lockTime' | 'lockExpiration'>
  )> }
);

export type CatalogProductExtraDataInfoFragment = (
  { __typename?: 'CatalogProductExtraData' }
  & Pick<CatalogProductExtraData, 'noPromo'>
  & { promotions: Array<(
    { __typename?: 'CatalogProductPromo' }
    & Pick<CatalogProductPromo, 'code' | 'logoUrl'>
  )> }
);

export type OrderForceDeliveredMutationVariables = Exact<{
  orderId: Scalars['String'];
}>;


export type OrderForceDeliveredMutation = (
  { __typename?: 'Mutation' }
  & { orderForceDelivered: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type OrderForceFulfilledMutationVariables = Exact<{
  orderId: Scalars['String'];
  trackingUrl: Scalars['String'];
}>;


export type OrderForceFulfilledMutation = (
  { __typename?: 'Mutation' }
  & { orderForceFulfilled: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type ArchiveOrderMutationVariables = Exact<{
  orderId: Scalars['String'];
}>;


export type ArchiveOrderMutation = (
  { __typename?: 'Mutation' }
  & { archiveOrder: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type SyncUnfulfilledOrderMutationVariables = Exact<{
  orderId: Scalars['String'];
}>;


export type SyncUnfulfilledOrderMutation = (
  { __typename?: 'Mutation' }
  & { syncUnfulfilledOrder: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type ResyncQaplaOrderMutationVariables = Exact<{
  orderId: Scalars['String'];
}>;


export type ResyncQaplaOrderMutation = (
  { __typename?: 'Mutation' }
  & { resyncQaplaOrder: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type SyncOrderShippingMutationVariables = Exact<{
  orderId: Scalars['String'];
}>;


export type SyncOrderShippingMutation = (
  { __typename?: 'Mutation' }
  & { syncOrderShipping: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type SyncUnfulfilledOrdersMutationVariables = Exact<{ [key: string]: never; }>;


export type SyncUnfulfilledOrdersMutation = (
  { __typename?: 'Mutation' }
  & { syncUnfulfilledOrders: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type SyncNavOrdersMutationVariables = Exact<{ [key: string]: never; }>;


export type SyncNavOrdersMutation = (
  { __typename?: 'Mutation' }
  & { syncNavOrders: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type SyncArchivedOrdersMutationVariables = Exact<{ [key: string]: never; }>;


export type SyncArchivedOrdersMutation = (
  { __typename?: 'Mutation' }
  & { syncArchivedOrders: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type EditOrderMutationVariables = Exact<{
  input: EditOrderInput;
  languageId: Scalars['String'];
}>;


export type EditOrderMutation = (
  { __typename?: 'Mutation' }
  & { editOrder: (
    { __typename?: 'AdminOrderItem' }
    & Pick<AdminOrderItem, 'rawBasket' | 'rawOrder'>
    & { details: (
      { __typename?: 'OrderDetails' }
      & OrderDetailsDataFragment
    ) }
  ) }
);

export type OrderTagsModifyMutationVariables = Exact<{
  input: OrderModifyTagsInput;
}>;


export type OrderTagsModifyMutation = (
  { __typename?: 'Mutation' }
  & { orderTagsModify: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type RefundOrderMutationVariables = Exact<{
  input: RefundOrderInput;
  languageId: Scalars['String'];
}>;


export type RefundOrderMutation = (
  { __typename?: 'Mutation' }
  & { refundOrder: (
    { __typename?: 'AdminOrderItem' }
    & Pick<AdminOrderItem, 'rawBasket' | 'rawOrder'>
    & { details: (
      { __typename?: 'OrderDetails' }
      & OrderDetailsDataFragment
    ) }
  ) }
);

export type CreateOrdersReportMutationVariables = Exact<{
  maxDays: Scalars['Int'];
}>;


export type CreateOrdersReportMutation = (
  { __typename?: 'Mutation' }
  & { createOrdersReport: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type RefillGiftcardMutationVariables = Exact<{
  input: RefillGiftcardInput;
}>;


export type RefillGiftcardMutation = (
  { __typename?: 'Mutation' }
  & { refillGiftcard: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type OrderCancelFromNavMutationVariables = Exact<{
  orderId: Scalars['String'];
}>;


export type OrderCancelFromNavMutation = (
  { __typename?: 'Mutation' }
  & { orderCancelFromNav: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type ProcessReturnFileMutationVariables = Exact<{
  file: Scalars['String'];
}>;


export type ProcessReturnFileMutation = (
  { __typename?: 'Mutation' }
  & { processReturnFile: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type ResendOrderInternalNotificationMutationVariables = Exact<{
  orderId: Scalars['String'];
}>;


export type ResendOrderInternalNotificationMutation = (
  { __typename?: 'Mutation' }
  & { resendOrderInternalNotification: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type FixBasketEmailMutationVariables = Exact<{
  input: FixBasketEmailInput;
}>;


export type FixBasketEmailMutation = (
  { __typename?: 'Mutation' }
  & { fixBasketEmail: (
    { __typename?: 'FixBasketEmailResult' }
    & Pick<FixBasketEmailResult, 'orderId'>
  ) }
);

export type RawUpdateBasketMutationVariables = Exact<{
  id: Scalars['String'];
  input: UpdateBasketInput;
}>;


export type RawUpdateBasketMutation = (
  { __typename?: 'Mutation' }
  & { rawUpdateBasket: (
    { __typename?: 'RawBasket' }
    & Pick<RawBasket, 'data'>
  ) }
);

export type PaymentVerifyMutationVariables = Exact<{
  input: PaymentVerifyInput;
}>;


export type PaymentVerifyMutation = (
  { __typename?: 'Mutation' }
  & { paymentVerify: (
    { __typename?: 'PaymentVerifyResult' }
    & Pick<PaymentVerifyResult, 'success'>
    & { data: (
      { __typename?: 'Checkout' }
      & CheckoutDataFragment
    ) }
  ) }
);

export type CheckoutCompleteWithoutPaymentMutationVariables = Exact<{
  id: Scalars['String'];
  languageId: Scalars['String'];
}>;


export type CheckoutCompleteWithoutPaymentMutation = (
  { __typename?: 'Mutation' }
  & { checkoutCompleteWithoutPayment: (
    { __typename?: 'CheckoutCompleteWithNoPaymentResult' }
    & Pick<CheckoutCompleteWithNoPaymentResult, 'success'>
    & { data?: Maybe<(
      { __typename?: 'Checkout' }
      & CheckoutDataFragment
    )> }
  ) }
);

export type ShopifyOrderMigrateMutationVariables = Exact<{
  input: ShopifyOrderMigrationInput;
}>;


export type ShopifyOrderMigrateMutation = (
  { __typename?: 'Mutation' }
  & { shopifyOrderMigrate: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type ShopifyOrdersMigrateBatchMutationVariables = Exact<{
  input: MigrateShopifyOrderJobInput;
}>;


export type ShopifyOrdersMigrateBatchMutation = (
  { __typename?: 'Mutation' }
  & { shopifyOrdersMigrateBatch: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type OrderEmailChangeMutationVariables = Exact<{
  input: OrderEmailChangeInput;
}>;


export type OrderEmailChangeMutation = (
  { __typename?: 'Mutation' }
  & { orderEmailChange: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type GetOrdersQueryVariables = Exact<{
  input: GetOrdersInput;
}>;


export type GetOrdersQuery = (
  { __typename?: 'Query' }
  & { getOrders: (
    { __typename?: 'OrderResult' }
    & Pick<OrderResult, 'hasNextPage' | 'cursor'>
    & { orders: Array<(
      { __typename?: 'OrderInfo' }
      & OrderInfoDataFragment
    )> }
  ) }
);

export type GetOrderQueryVariables = Exact<{
  id: Scalars['String'];
  languageId: Scalars['String'];
}>;


export type GetOrderQuery = (
  { __typename?: 'Query' }
  & { getOrder?: Maybe<(
    { __typename?: 'AdminOrderItem' }
    & Pick<AdminOrderItem, 'rawBasket' | 'rawOrder'>
    & { details: (
      { __typename?: 'OrderDetails' }
      & OrderDetailsDataFragment
    ), payment?: Maybe<(
      { __typename?: 'OrderPaymentDetails' }
      & OrderPaymentDetailsDataFragment
    )> }
  )> }
);

export type GetGiftcardBalanceQueryVariables = Exact<{
  input: GetGiftcardBalanceInput;
}>;


export type GetGiftcardBalanceQuery = (
  { __typename?: 'Query' }
  & { getGiftcardBalance: (
    { __typename?: 'GetGiftcardBalanceResult' }
    & Pick<GetGiftcardBalanceResult, 'balance' | 'giftcardNumber'>
  ) }
);

export type SearchBasketQueryVariables = Exact<{
  query: Scalars['String'];
}>;


export type SearchBasketQuery = (
  { __typename?: 'Query' }
  & { searchBasket: Array<(
    { __typename?: 'RawBasket' }
    & Pick<RawBasket, 'data'>
  )> }
);

export type ParkodBrandMappingsUploadMutationVariables = Exact<{
  file: Scalars['Upload'];
}>;


export type ParkodBrandMappingsUploadMutation = (
  { __typename?: 'Mutation' }
  & { parkodBrandMappingsUpload: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type ParkodAxisMappingsUploadMutationVariables = Exact<{
  file: Scalars['Upload'];
}>;


export type ParkodAxisMappingsUploadMutation = (
  { __typename?: 'Mutation' }
  & { parkodAxisMappingsUpload: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type ParkodCategoryMappingsUploadMutationVariables = Exact<{
  file: Scalars['Upload'];
}>;


export type ParkodCategoryMappingsUploadMutation = (
  { __typename?: 'Mutation' }
  & { parkodCategoryMappingsUpload: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type ParkodCategoryMappingsDownloadMutationVariables = Exact<{ [key: string]: never; }>;


export type ParkodCategoryMappingsDownloadMutation = (
  { __typename?: 'Mutation' }
  & { parkodCategoryMappingsDownload: (
    { __typename?: 'ParkodDataExport' }
    & Pick<ParkodDataExport, 'url'>
  ) }
);

export type ParkodBrandMappingsDownloadMutationVariables = Exact<{ [key: string]: never; }>;


export type ParkodBrandMappingsDownloadMutation = (
  { __typename?: 'Mutation' }
  & { parkodBrandMappingsDownload: (
    { __typename?: 'ParkodDataExport' }
    & Pick<ParkodDataExport, 'url'>
  ) }
);

export type ParkodAxisMappingsDownloadMutationVariables = Exact<{ [key: string]: never; }>;


export type ParkodAxisMappingsDownloadMutation = (
  { __typename?: 'Mutation' }
  & { parkodAxisMappingsDownload: (
    { __typename?: 'ParkodDataExport' }
    & Pick<ParkodDataExport, 'url'>
  ) }
);

export type ParkodDataGenerateMutationVariables = Exact<{
  file: Scalars['Upload'];
  input: ParkodMapDataInput;
}>;


export type ParkodDataGenerateMutation = (
  { __typename?: 'Mutation' }
  & { parkodDataGenerate: (
    { __typename?: 'ParkodDataExport' }
    & Pick<ParkodDataExport, 'url'>
  ) }
);

export type AdminPaymentInitMutationVariables = Exact<{
  input: AdminPaymentInitInput;
}>;


export type AdminPaymentInitMutation = (
  { __typename?: 'Mutation' }
  & { adminPaymentInit: (
    { __typename?: 'AdminPaymentInitResult' }
    & Pick<AdminPaymentInitResult, 'data'>
  ) }
);

export type AdminPaymentVerifyMutationVariables = Exact<{
  input: AdminPaymentVerifyInput;
}>;


export type AdminPaymentVerifyMutation = (
  { __typename?: 'Mutation' }
  & { adminPaymentVerify: (
    { __typename?: 'AdminPaymentVerifyResult' }
    & Pick<AdminPaymentVerifyResult, 'data'>
  ) }
);

export type AdminPaymentRefundMutationVariables = Exact<{
  input: AdminPaymentRefundInput;
}>;


export type AdminPaymentRefundMutation = (
  { __typename?: 'Mutation' }
  & { adminPaymentRefund: (
    { __typename?: 'AdminPaymentRefundResult' }
    & Pick<AdminPaymentRefundResult, 'data'>
  ) }
);

export type AdminPaymentGetMutationVariables = Exact<{
  input: AdminPaymentGetInput;
}>;


export type AdminPaymentGetMutation = (
  { __typename?: 'Mutation' }
  & { adminPaymentGet: (
    { __typename?: 'AdminPaymentGetResult' }
    & Pick<AdminPaymentGetResult, 'data'>
  ) }
);

export type PriceRuleDataFragment = (
  { __typename?: 'PriceRule' }
  & Pick<PriceRule, 'id' | 'priceCode' | 'promoCode' | 'weight' | 'disabled' | 'ruleType' | 'crossedPrice' | 'discountType' | 'discountAmount' | 'fixedPrice' | 'fixedCompareAtPrice' | 'validFrom' | 'validTo' | 'eanCodes' | 'itmCodes' | 'productIds' | 'productCategories' | 'brandCodes' | 'productTags' | 'excludedEanCodes' | 'excludedItmCodes' | 'excludedProductIds' | 'excludedBrandCodes' | 'excludedProductCategories' | 'excludedProductTags' | 'discountRulePayload' | 'dynamicPricePayload' | 'createdDate' | 'updatedDate'>
);

export type EnablePriceRuleMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type EnablePriceRuleMutation = (
  { __typename?: 'Mutation' }
  & { enablePriceRule: (
    { __typename?: 'PriceRule' }
    & PriceRuleDataFragment
  ) }
);

export type DisablePriceRuleMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DisablePriceRuleMutation = (
  { __typename?: 'Mutation' }
  & { disablePriceRule: (
    { __typename?: 'PriceRule' }
    & PriceRuleDataFragment
  ) }
);

export type CreatePriceRuleMutationVariables = Exact<{
  input: PriceRuleInput;
}>;


export type CreatePriceRuleMutation = (
  { __typename?: 'Mutation' }
  & { createPriceRule: (
    { __typename?: 'PriceRule' }
    & PriceRuleDataFragment
  ) }
);

export type UpdatePriceRuleMutationVariables = Exact<{
  input: PriceRuleInput;
}>;


export type UpdatePriceRuleMutation = (
  { __typename?: 'Mutation' }
  & { updatePriceRule: (
    { __typename?: 'PriceRule' }
    & PriceRuleDataFragment
  ) }
);

export type DeletePriceRuleMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeletePriceRuleMutation = (
  { __typename?: 'Mutation' }
  & { deletePriceRule: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type ImportCompetitorPricesMutationVariables = Exact<{ [key: string]: never; }>;


export type ImportCompetitorPricesMutation = (
  { __typename?: 'Mutation' }
  & { importCompetitorPrices: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type PriceRulesQueryVariables = Exact<{ [key: string]: never; }>;


export type PriceRulesQuery = (
  { __typename?: 'Query' }
  & { priceRules: Array<(
    { __typename?: 'PriceRule' }
    & PriceRuleDataFragment
  )> }
);

export type PriceRuleQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type PriceRuleQuery = (
  { __typename?: 'Query' }
  & { priceRule: (
    { __typename?: 'PriceRule' }
    & PriceRuleDataFragment
  ) }
);

export type ProductUpdateRecordDataFragment = (
  { __typename?: 'ProductUpdateRecord' }
  & Pick<ProductUpdateRecord, 'ean' | 'name' | 'line' | 'size' | 'colorName' | 'colorHex' | 'colorUrl' | 'relatedEanCodes' | 'complementaryEanCodes' | 'notes' | 'validFrom' | 'validTo' | 'imageUrls' | 'videoUrls'>
  & { contents: (
    { __typename?: 'ProductUpdateContents' }
    & Pick<ProductUpdateContents, 'shortDescription' | 'longDescription' | 'instructions' | 'inci' | 'composition' | 'highlights' | 'olfactoryNotes' | 'olfactoryFamily' | 'tests' | 'technology'>
  ) }
);

export type ProductsUpdateDataFieldsFragment = (
  { __typename?: 'ProductsUpdateData' }
  & { records: Array<(
    { __typename?: 'ProductUpdateRecord' }
    & ProductUpdateRecordDataFragment
  )> }
);

export type ProductsXlsParseErrorDataFragment = (
  { __typename?: 'ProductsXlsParseError' }
  & Pick<ProductsXlsParseError, 'type' | 'fieldName' | 'fieldValue' | 'itemIndex' | 'itemId'>
);

export type ImportVariantItemDataFragment = (
  { __typename?: 'ImportVariantItem' }
  & Pick<ImportVariantItem, 'itemIndex' | 'eanCode' | 'imageCode' | 'name' | 'brandName' | 'brandCode' | 'lineName' | 'size' | 'colorName' | 'colorHex' | 'gender' | 'shortDescription' | 'longDescription' | 'instructions' | 'inci' | 'composition' | 'olfactoryNotes' | 'highlights' | 'testResults' | 'technology' | 'imageUrls' | 'videoUrls' | 'notes' | 'tags' | 'relatedEan' | 'complementaryEan' | 'categoryCodes' | 'keywords' | 'validFrom' | 'validTo'>
);

export type ImportProductItemDataFragment = (
  { __typename?: 'ImportProductItem' }
  & Pick<ImportProductItem, 'productId'>
  & { variants: Array<(
    { __typename?: 'ImportVariantItem' }
    & ImportVariantItemDataFragment
  )> }
);

export type DeleteBrandProductsMutationVariables = Exact<{
  input: DeleteBrandProductsInputType;
}>;


export type DeleteBrandProductsMutation = (
  { __typename?: 'Mutation' }
  & { deleteBrandProducts: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type LoadBrandProductsMutationVariables = Exact<{
  input: ImportBrandProductsInputType;
}>;


export type LoadBrandProductsMutation = (
  { __typename?: 'Mutation' }
  & { loadBrandProducts: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type UpsertProductItemMutationVariables = Exact<{
  mode: Scalars['String'];
  product: CatalogBrandProductItemInput;
}>;


export type UpsertProductItemMutation = (
  { __typename?: 'Mutation' }
  & { upsertProductItem: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type UpsertVariantItemMutationVariables = Exact<{
  mode: Scalars['String'];
  variant: CatalogBrandProductVariantInput;
}>;


export type UpsertVariantItemMutation = (
  { __typename?: 'Mutation' }
  & { upsertVariantItem: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type ExportCatalogProductsMutationVariables = Exact<{
  input: ExportToolProductsInput;
}>;


export type ExportCatalogProductsMutation = (
  { __typename?: 'Mutation' }
  & { exportCatalogProducts: (
    { __typename?: 'ExportToolProductsResult' }
    & Pick<ExportToolProductsResult, 'url'>
  ) }
);

export type PublishAllProductsMutationVariables = Exact<{ [key: string]: never; }>;


export type PublishAllProductsMutation = (
  { __typename?: 'Mutation' }
  & { publishAllProducts: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type PublishProductMutationVariables = Exact<{
  productId: Scalars['String'];
}>;


export type PublishProductMutation = (
  { __typename?: 'Mutation' }
  & { publishProduct: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type ClearPublishQueueMutationVariables = Exact<{ [key: string]: never; }>;


export type ClearPublishQueueMutation = (
  { __typename?: 'Mutation' }
  & { clearPublishQueue: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type ArchiveProductsMutationVariables = Exact<{
  input: ArchiveProductInputType;
}>;


export type ArchiveProductsMutation = (
  { __typename?: 'Mutation' }
  & { archiveProducts: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type EnableProductMutationVariables = Exact<{
  productId: Scalars['String'];
}>;


export type EnableProductMutation = (
  { __typename?: 'Mutation' }
  & { enableProduct: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type DisableProductMutationVariables = Exact<{
  productId: Scalars['String'];
}>;


export type DisableProductMutation = (
  { __typename?: 'Mutation' }
  & { disableProduct: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type EnableProductVariantMutationVariables = Exact<{
  eanCode: Scalars['String'];
}>;


export type EnableProductVariantMutation = (
  { __typename?: 'Mutation' }
  & { enableProductVariant: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type DisableProductVariantMutationVariables = Exact<{
  eanCode: Scalars['String'];
}>;


export type DisableProductVariantMutation = (
  { __typename?: 'Mutation' }
  & { disableProductVariant: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type SwapVariantsOrderMutationVariables = Exact<{
  input: SwapVariantsInputType;
}>;


export type SwapVariantsOrderMutation = (
  { __typename?: 'Mutation' }
  & { swapVariantsOrder: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type ChangeVariantsOrderMutationVariables = Exact<{
  input: VariantOrderInput;
}>;


export type ChangeVariantsOrderMutation = (
  { __typename?: 'Mutation' }
  & { changeVariantsOrder: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type ExportProductsForNavMutationVariables = Exact<{
  input: ExportNavProductsInput;
}>;


export type ExportProductsForNavMutation = (
  { __typename?: 'Mutation' }
  & { exportProductsForNav: (
    { __typename?: 'ExportToolProductsResult' }
    & Pick<ExportToolProductsResult, 'url'>
  ) }
);

export type ExportToolFullReportMutationVariables = Exact<{ [key: string]: never; }>;


export type ExportToolFullReportMutation = (
  { __typename?: 'Mutation' }
  & { exportToolFullReport: (
    { __typename?: 'ExportToolProductsResult' }
    & Pick<ExportToolProductsResult, 'url'>
  ) }
);

export type RefreshBrandProductItemMutationVariables = Exact<{
  productId: Scalars['String'];
}>;


export type RefreshBrandProductItemMutation = (
  { __typename?: 'Mutation' }
  & { refreshBrandProductItem: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type RefreshCatalogBrandProductsMutationVariables = Exact<{
  brandCode: Scalars['String'];
}>;


export type RefreshCatalogBrandProductsMutation = (
  { __typename?: 'Mutation' }
  & { refreshCatalogBrandProducts: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type RefreshAllCatalogProductsMutationVariables = Exact<{ [key: string]: never; }>;


export type RefreshAllCatalogProductsMutation = (
  { __typename?: 'Mutation' }
  & { refreshAllCatalogProducts: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type ProcessNavBarcodesDataMutationVariables = Exact<{
  feedUrl: Scalars['String'];
}>;


export type ProcessNavBarcodesDataMutation = (
  { __typename?: 'Mutation' }
  & { processNavBarcodesData: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type ImportNavCategoriesMutationVariables = Exact<{
  feedUrl: Scalars['String'];
}>;


export type ImportNavCategoriesMutation = (
  { __typename?: 'Mutation' }
  & { importNavCategories: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type ImportSupplierProductsMutationVariables = Exact<{
  feedUrl: Scalars['String'];
}>;


export type ImportSupplierProductsMutation = (
  { __typename?: 'Mutation' }
  & { importSupplierProducts: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type ImportNavCategoryMappingsMutationVariables = Exact<{
  feedUrl: Scalars['String'];
}>;


export type ImportNavCategoryMappingsMutation = (
  { __typename?: 'Mutation' }
  & { importNavCategoryMappings: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type MoveVariantMutationVariables = Exact<{
  input: MoveVariantInput;
}>;


export type MoveVariantMutation = (
  { __typename?: 'Mutation' }
  & { moveVariant: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type MoveVariantToNewProductMutationVariables = Exact<{
  input: MoveVariantToNewProductInput;
}>;


export type MoveVariantToNewProductMutation = (
  { __typename?: 'Mutation' }
  & { moveVariantToNewProduct: (
    { __typename?: 'MoveVariantToNewProductResult' }
    & Pick<MoveVariantToNewProductResult, 'eanCode' | 'oldProductId' | 'newProductId'>
  ) }
);

export type NormalizeVariantNamesMutationVariables = Exact<{
  input: NormalizeVariantNamesInput;
}>;


export type NormalizeVariantNamesMutation = (
  { __typename?: 'Mutation' }
  & { normalizeVariantNames: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type CopyTextsOnAllVariantsMutationVariables = Exact<{
  input: CopyTextsOnAllVariantsInput;
}>;


export type CopyTextsOnAllVariantsMutation = (
  { __typename?: 'Mutation' }
  & { copyTextsOnAllVariants: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type UpdateProductBrandNameMutationVariables = Exact<{
  input: UpdateProductBrandNameInput;
}>;


export type UpdateProductBrandNameMutation = (
  { __typename?: 'Mutation' }
  & { updateProductBrandName: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type GetAvailableProductIdMutationVariables = Exact<{
  brandCode: Scalars['String'];
}>;


export type GetAvailableProductIdMutation = (
  { __typename?: 'Mutation' }
  & { getAvailableProductId: (
    { __typename?: 'NewProductIdResult' }
    & Pick<NewProductIdResult, 'productId'>
  ) }
);

export type ChangeProductBrandMutationVariables = Exact<{
  input: ChangeBrandProductInputType;
}>;


export type ChangeProductBrandMutation = (
  { __typename?: 'Mutation' }
  & { changeProductBrand: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type MassiveProductsUpdateXlsMutationVariables = Exact<{
  file: Scalars['Upload'];
  input: ProductsUpdateInput;
}>;


export type MassiveProductsUpdateXlsMutation = (
  { __typename?: 'Mutation' }
  & { massiveProductsUpdateXls: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type MassiveProductsUpdateXlsPreviewMutationVariables = Exact<{
  file: Scalars['Upload'];
}>;


export type MassiveProductsUpdateXlsPreviewMutation = (
  { __typename?: 'Mutation' }
  & { massiveProductsUpdateXlsPreview: (
    { __typename?: 'ProductsUpdatePreviewResult' }
    & Pick<ProductsUpdatePreviewResult, 'uploadHash'>
    & { data: (
      { __typename?: 'ProductsUpdateData' }
      & ProductsUpdateDataFieldsFragment
    ) }
  ) }
);

export type MasterProductArchiveMutationVariables = Exact<{
  input: MasterProductArchiveInput;
}>;


export type MasterProductArchiveMutation = (
  { __typename?: 'Mutation' }
  & { masterProductArchive: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type MassiveProductsDownloadMutationVariables = Exact<{
  input: ProductsDownloadInput;
}>;


export type MassiveProductsDownloadMutation = (
  { __typename?: 'Mutation' }
  & { massiveProductsDownload: (
    { __typename?: 'ProductsDownloadResult' }
    & Pick<ProductsDownloadResult, 'downloadUrl'>
  ) }
);

export type MasterProductRestoreMutationVariables = Exact<{
  input: MasterProductRestoreInput;
}>;


export type MasterProductRestoreMutation = (
  { __typename?: 'Mutation' }
  & { masterProductRestore: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type UpsertMasterProductCustomInfoMutationVariables = Exact<{
  input: ProductCustomInfoEntityInput;
}>;


export type UpsertMasterProductCustomInfoMutation = (
  { __typename?: 'Mutation' }
  & { upsertMasterProductCustomInfo: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type DeleteMasterProductCustomInfoMutationVariables = Exact<{
  productId: Scalars['String'];
}>;


export type DeleteMasterProductCustomInfoMutation = (
  { __typename?: 'Mutation' }
  & { deleteMasterProductCustomInfo: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type MassiveProductsCreateXlsParseMutationVariables = Exact<{
  file: Scalars['Upload'];
}>;


export type MassiveProductsCreateXlsParseMutation = (
  { __typename?: 'Mutation' }
  & { massiveProductsCreateXlsParse: (
    { __typename?: 'ProductsCreateXlsParseResult' }
    & Pick<ProductsCreateXlsParseResult, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'ProductsXlsParseError' }
      & ProductsXlsParseErrorDataFragment
    )>>, data?: Maybe<(
      { __typename?: 'ProductsCreateXlsParseData' }
      & Pick<ProductsCreateXlsParseData, 'uploadHash' | 'unmappedColumns'>
      & { items: Array<(
        { __typename?: 'ImportProductItem' }
        & ImportProductItemDataFragment
      )> }
    )> }
  ) }
);

export type MassiveProductsDeleteBatchMutationVariables = Exact<{
  input: MassiveProductsDeleteInput;
}>;


export type MassiveProductsDeleteBatchMutation = (
  { __typename?: 'Mutation' }
  & { massiveProductsDeleteBatch: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type MassiveProductsCreateMutationVariables = Exact<{
  input: MassiveProductsCreateInput;
}>;


export type MassiveProductsCreateMutation = (
  { __typename?: 'Mutation' }
  & { massiveProductsCreate: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type MassiveProductsMediaUploadMutationVariables = Exact<{
  ref: ProductMediaRefInput;
  media: Scalars['Upload'];
}>;


export type MassiveProductsMediaUploadMutation = (
  { __typename?: 'Mutation' }
  & { massiveProductsMediaUpload: (
    { __typename?: 'ProductMediaUploadResult' }
    & Pick<ProductMediaUploadResult, 'url' | 'type'>
    & { ref: (
      { __typename?: 'ProductMediaRef' }
      & Pick<ProductMediaRef, 'id' | 'filename' | 'uploadHash' | 'type'>
    ) }
  ) }
);

export type MasterVariantChangeEanMutationVariables = Exact<{
  input: MasterVariantChangeEanCodeInput;
}>;


export type MasterVariantChangeEanMutation = (
  { __typename?: 'Mutation' }
  & { masterVariantChangeEan: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type RunBusinessCentralFullUpdatesTaskMutationVariables = Exact<{ [key: string]: never; }>;


export type RunBusinessCentralFullUpdatesTaskMutation = (
  { __typename?: 'Mutation' }
  & { runBusinessCentralFullUpdatesTask: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type RunBusinessCentralDeltaUpdatesTaskMutationVariables = Exact<{ [key: string]: never; }>;


export type RunBusinessCentralDeltaUpdatesTaskMutation = (
  { __typename?: 'Mutation' }
  & { runBusinessCentralDeltaUpdatesTask: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type SearchCatalogProductsQueryVariables = Exact<{
  options: ProductToolSearchOptions;
  filters: ProductToolSearchFilters;
}>;


export type SearchCatalogProductsQuery = (
  { __typename?: 'Query' }
  & { searchCatalogProducts: (
    { __typename?: 'ProductToolSearchResults' }
    & { aggregations?: Maybe<(
      { __typename?: 'ProductToolSearchResultsAggregations' }
      & { brands: Array<(
        { __typename?: 'ProductToolSearchResultBrand' }
        & Pick<ProductToolSearchResultBrand, 'name' | 'code' | 'occurrences'>
      )>, productCategories: Array<(
        { __typename?: 'ProductToolSearchResultCategory' }
        & Pick<ProductToolSearchResultCategory, 'name' | 'code' | 'occurrences'>
      )> }
    )>, paging?: Maybe<(
      { __typename?: 'ProductToolSearchResultPaging' }
      & Pick<ProductToolSearchResultPaging, 'totResults' | 'size' | 'cursor' | 'isLastPage'>
    )>, results: Array<(
      { __typename?: 'CatalogBrandProductDocument' }
      & { status: (
        { __typename?: 'CatalogBrandProductStatus' }
        & Pick<CatalogBrandProductStatus, 'published' | 'publishedOn' | 'status' | 'disabled'>
      ), product: (
        { __typename?: 'CatalogBrandProductItem' }
        & Pick<CatalogBrandProductItem, 'productId' | 'brandCode' | 'name' | 'category' | 'secondaryCategories' | 'internalNotes'>
        & { _metadata: (
          { __typename?: 'CatalogItemMetadata' }
          & Pick<CatalogItemMetadata, 'creationDate' | 'updateDate'>
        ) }
      ), variants: Array<(
        { __typename?: 'CatalogBrandProductVariantDocument' }
        & Pick<CatalogBrandProductVariantDocument, 'internalNotes' | 'productId' | 'eanCode' | 'brandName' | 'brandCode'>
        & { _metadata: (
          { __typename?: 'CatalogItemMetadata' }
          & Pick<CatalogItemMetadata, 'creationDate' | 'updateDate'>
        ), contents: Array<(
          { __typename?: 'CatalogBrandProductItemContents' }
          & Pick<CatalogBrandProductItemContents, 'languageId' | 'name' | 'subtitle' | 'images'>
        )> }
      )> }
    )> }
  ) }
);

export type GetLatestProductVersionQueryVariables = Exact<{
  input: GetLatestProductVersionInputType;
}>;


export type GetLatestProductVersionQuery = (
  { __typename?: 'Query' }
  & { getLatestProductVersion: (
    { __typename?: 'ToolProductVersionType' }
    & Pick<ToolProductVersionType, 'id' | 'productId' | 'brandCode' | 'version' | 'createdDate' | 'updatedDate' | 'createdBy' | 'published' | 'publishedOn' | 'internalNotes'>
    & { product: (
      { __typename?: 'ToolProductItemType' }
      & Pick<ToolProductItemType, 'productId' | 'productLine' | 'productLineId' | 'tags' | 'category' | 'secondaryCategories' | 'relatedEanCodes' | 'complementaryEanCodes'>
      & { contents: Array<(
        { __typename?: 'BrandProductItemContentsType' }
        & Pick<BrandProductItemContentsType, 'languageId' | 'name' | 'subtitle' | 'line' | 'shortDescription' | 'longDescription' | 'highlights' | 'instructions' | 'ingredients' | 'fragrance' | 'fragranceFamily' | 'testResults' | 'composition' | 'technology' | 'images'>
      )>, options?: Maybe<(
        { __typename?: 'BrandProductOptions' }
        & Pick<BrandProductOptions, 'hidden' | 'isGift'>
      )> }
    ) }
  ) }
);

export type GetLatestVariantVersionQueryVariables = Exact<{
  input: GetLatestVariantVersionInputType;
}>;


export type GetLatestVariantVersionQuery = (
  { __typename?: 'Query' }
  & { getLatestVariantVersion: (
    { __typename?: 'ToolProductVariantVersionType' }
    & Pick<ToolProductVariantVersionType, 'id' | 'eanCode' | 'version' | 'createdDate' | 'updatedDate' | 'createdBy' | 'published' | 'publishedOn' | 'internalNotes'>
    & { variant: (
      { __typename?: 'BrandProductVariantType' }
      & Pick<BrandProductVariantType, 'productId' | 'validFrom' | 'validTo' | 'productLine' | 'productLineId' | 'eanCode' | 'brandName' | 'brandCode' | 'tags' | 'category' | 'secondaryCategories' | 'relatedEanCodes' | 'complementaryEanCodes' | 'keywords'>
      & { contents: Array<(
        { __typename?: 'BrandProductItemContentsType' }
        & Pick<BrandProductItemContentsType, 'languageId' | 'name' | 'subtitle' | 'line' | 'shortDescription' | 'longDescription' | 'highlights' | 'instructions' | 'ingredients' | 'fragrance' | 'fragranceFamily' | 'testResults' | 'composition' | 'technology' | 'images' | 'videos'>
      )>, quantity?: Maybe<(
        { __typename?: 'ToolQuantityType' }
        & Pick<ToolQuantityType, 'value'>
      )>, color?: Maybe<(
        { __typename?: 'ToolColorType' }
        & Pick<ToolColorType, 'hex' | 'url' | 'name'>
      )> }
    ) }
  ) }
);

export type GetProductVariantsQueryVariables = Exact<{
  input: GetProductVariantsInputType;
}>;


export type GetProductVariantsQuery = (
  { __typename?: 'Query' }
  & { getProductVariants: Array<(
    { __typename?: 'ToolProductVariantType' }
    & Pick<ToolProductVariantType, 'eanCode' | 'productId' | 'variantIndex'>
    & { variant: (
      { __typename?: 'BrandProductVariantType' }
      & Pick<BrandProductVariantType, 'brandName' | 'brandCode'>
      & { color?: Maybe<(
        { __typename?: 'ToolColorType' }
        & Pick<ToolColorType, 'name'>
      )>, quantity?: Maybe<(
        { __typename?: 'ToolQuantityType' }
        & Pick<ToolQuantityType, 'value'>
      )>, contents: Array<(
        { __typename?: 'BrandProductItemContentsType' }
        & Pick<BrandProductItemContentsType, 'languageId' | 'name' | 'subtitle' | 'images'>
      )> }
    ) }
  )> }
);

export type GetToolMetaItemsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetToolMetaItemsQuery = (
  { __typename?: 'Query' }
  & { getToolMetaItems: (
    { __typename?: 'ToolMetaData' }
    & { tag: Array<(
      { __typename?: 'CatalogMetaItemType' }
      & Pick<CatalogMetaItemType, 'key' | 'label' | 'categoryLabel' | 'disabled'>
    )>, tagCategories: Array<(
      { __typename?: 'CatalogMetaItemType' }
      & Pick<CatalogMetaItemType, 'key' | 'label' | 'disabled'>
    )>, categories: Array<(
      { __typename?: 'CatalogMetaItemType' }
      & Pick<CatalogMetaItemType, 'key' | 'label' | 'disabled'>
    )>, brands: Array<(
      { __typename?: 'CatalogMetaItemType' }
      & Pick<CatalogMetaItemType, 'key' | 'label' | 'disabled'>
    )> }
  ) }
);

export type GetPublishQueueQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPublishQueueQuery = (
  { __typename?: 'Query' }
  & { getPublishQueue: Array<(
    { __typename?: 'PublishQueueItem' }
    & Pick<PublishQueueItem, 'id' | 'productId' | 'status' | 'createdDate'>
  )> }
);

export type GetProductQueryVariables = Exact<{
  input: GetProductInputType;
}>;


export type GetProductQuery = (
  { __typename?: 'Query' }
  & { getProduct: (
    { __typename?: 'ToolProductType' }
    & Pick<ToolProductType, 'productId' | 'brandCode' | 'versionId' | 'internalNotes' | 'createdDate' | 'updatedDate' | 'publishable' | 'published' | 'publishedOn' | 'status' | 'disabled' | 'archived'>
  ) }
);

export type GetProductVariantQueryVariables = Exact<{
  input: GetProductVariantInputType;
}>;


export type GetProductVariantQuery = (
  { __typename?: 'Query' }
  & { getProductVariant?: Maybe<(
    { __typename?: 'ToolProductVariantType' }
    & Pick<ToolProductVariantType, 'eanCode' | 'productId' | 'versionId' | 'variantIndex' | 'createdDate' | 'updatedDate' | 'disabled' | 'internalNotes'>
  )> }
);

export type MasterProductCustomInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type MasterProductCustomInfoQuery = (
  { __typename?: 'Query' }
  & { masterProductCustomInfo: (
    { __typename?: 'ProductCustomInfoItems' }
    & { items: Array<(
      { __typename?: 'ProductCustomItemMapping' }
      & { meta?: Maybe<(
        { __typename?: 'ProductCustomDataInfo' }
        & Pick<ProductCustomDataInfo, 'name' | 'brand'>
      )>, data: (
        { __typename?: 'ProductCustomInfoEntity' }
        & Pick<ProductCustomInfoEntity, 'productId' | 'weight'>
      ) }
    )> }
  ) }
);

export type MassiveProductCreateFieldNamesQueryVariables = Exact<{ [key: string]: never; }>;


export type MassiveProductCreateFieldNamesQuery = (
  { __typename?: 'Query' }
  & { massiveProductCreateFieldNames: (
    { __typename?: 'ProductCreateFieldNames' }
    & Pick<ProductCreateFieldNames, 'columns'>
  ) }
);

export type PromoDefinitionDataFragment = (
  { __typename?: 'PromoDefinition' }
  & Pick<PromoDefinition, 'id' | 'promoCode' | 'weight' | 'disabled' | 'condition' | 'conditionType' | 'triggerType' | 'triggerParams' | 'productPromo' | 'promoLogoUrl' | 'createdDate' | 'updatedDate'>
  & { options: (
    { __typename?: 'PromoOptionsType' }
    & Pick<PromoOptionsType, 'cumulative' | 'exclusive' | 'mixable' | 'type'>
  ), effect: (
    { __typename?: 'PromoEffectType' }
    & Pick<PromoEffectType, 'type' | 'payload'>
  ) }
);

export type EnablePromoMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type EnablePromoMutation = (
  { __typename?: 'Mutation' }
  & { enablePromo: (
    { __typename?: 'PromoDefinition' }
    & PromoDefinitionDataFragment
  ) }
);

export type DisablePromoMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DisablePromoMutation = (
  { __typename?: 'Mutation' }
  & { disablePromo: (
    { __typename?: 'PromoDefinition' }
    & PromoDefinitionDataFragment
  ) }
);

export type CreatePromoMutationVariables = Exact<{
  input: PromoDefinitionInput;
}>;


export type CreatePromoMutation = (
  { __typename?: 'Mutation' }
  & { createPromo: (
    { __typename?: 'PromoDefinition' }
    & PromoDefinitionDataFragment
  ) }
);

export type UpdatePromoMutationVariables = Exact<{
  input: PromoDefinitionInput;
}>;


export type UpdatePromoMutation = (
  { __typename?: 'Mutation' }
  & { updatePromo: (
    { __typename?: 'PromoDefinition' }
    & PromoDefinitionDataFragment
  ) }
);

export type DeletePromoMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeletePromoMutation = (
  { __typename?: 'Mutation' }
  & { deletePromo: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type PromotionsQueryVariables = Exact<{ [key: string]: never; }>;


export type PromotionsQuery = (
  { __typename?: 'Query' }
  & { promotions: Array<(
    { __typename?: 'PromoDefinition' }
    & PromoDefinitionDataFragment
  )> }
);

export type PromotionQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type PromotionQuery = (
  { __typename?: 'Query' }
  & { promotion: (
    { __typename?: 'PromoDefinition' }
    & PromoDefinitionDataFragment
  ) }
);

export type DefaultCatalogProductVariantSearchDataFragment = (
  { __typename?: 'CatalogProductVariant' }
  & { info: (
    { __typename?: 'CatalogProductVariantInfo' }
    & Pick<CatalogProductVariantInfo, 'variantId'>
  ) }
);

export type CatalogProductSearchDataFragment = (
  { __typename?: 'CatalogProduct' }
  & { info: (
    { __typename?: 'CatalogProductInfo' }
    & Pick<CatalogProductInfo, 'productId' | 'productSlug'>
  ), contents: (
    { __typename?: 'CatalogProductContents' }
    & Pick<CatalogProductContents, 'name'>
    & { productImages?: Maybe<Array<(
      { __typename?: 'CatalogProductImage' }
      & Pick<CatalogProductImage, 'url' | 'asset' | 'title'>
      & { size?: Maybe<(
        { __typename?: 'ImageSize' }
        & Pick<ImageSize, 'width' | 'height' | 'aspectRatio'>
      )> }
    )>>, campaignImages?: Maybe<Array<(
      { __typename?: 'CatalogProductImage' }
      & Pick<CatalogProductImage, 'url' | 'asset' | 'title'>
      & { size?: Maybe<(
        { __typename?: 'ImageSize' }
        & Pick<ImageSize, 'width' | 'height' | 'aspectRatio'>
      )> }
    )>> }
  ), metadata: (
    { __typename?: 'CatalogProductMetadata' }
    & { tags?: Maybe<Array<(
      { __typename?: 'CatalogProductTag' }
      & Pick<CatalogProductTag, 'key' | 'code' | 'value' | 'name' | 'categoryName'>
    )>>, categoryPaths?: Maybe<Array<(
      { __typename?: 'CatalogProductCategoryPath' }
      & { path: Array<(
        { __typename?: 'CatalogProductCategory' }
        & Pick<CatalogProductCategory, 'code' | 'name' | 'level'>
      )> }
    )>>, brand?: Maybe<(
      { __typename?: 'CatalogProductBrand' }
      & Pick<CatalogProductBrand, 'name' | 'code'>
    )> }
  ) }
);

export type CatalogProductVariantSearchDataFragment = (
  { __typename?: 'CatalogProductVariant' }
  & { info: (
    { __typename?: 'CatalogProductVariantInfo' }
    & Pick<CatalogProductVariantInfo, 'variantId' | 'default' | 'slug'>
  ), shopify?: Maybe<(
    { __typename?: 'CatalogProductVariantShopifyData' }
    & Pick<CatalogProductVariantShopifyData, 'shopifyId' | 'shopifyStorefrontId'>
  )>, details: (
    { __typename?: 'CatalogProductVariantDetails' }
    & { quantity?: Maybe<(
      { __typename?: 'CatalogProductSize' }
      & Pick<CatalogProductSize, 'value'>
    )>, color?: Maybe<(
      { __typename?: 'CatalogProductColor' }
      & Pick<CatalogProductColor, 'name'>
      & { value?: Maybe<(
        { __typename?: 'CatalogColorDefinition' }
        & Pick<CatalogColorDefinition, 'hex'>
      )>, texture?: Maybe<(
        { __typename?: 'CatalogProductTexture' }
        & Pick<CatalogProductTexture, 'asset'>
      )> }
    )> }
  ), contents: (
    { __typename?: 'CatalogProductVariantContents' }
    & Pick<CatalogProductVariantContents, 'name'>
    & { shortDescription?: Maybe<(
      { __typename?: 'StructuredText' }
      & Pick<StructuredText, 'html'>
    )>, longDescription?: Maybe<(
      { __typename?: 'StructuredText' }
      & Pick<StructuredText, 'html'>
    )>, highlights?: Maybe<(
      { __typename?: 'StructuredText' }
      & Pick<StructuredText, 'html'>
    )>, instructions?: Maybe<(
      { __typename?: 'StructuredText' }
      & Pick<StructuredText, 'html'>
    )>, composition?: Maybe<(
      { __typename?: 'StructuredText' }
      & Pick<StructuredText, 'html'>
    )>, fragrance?: Maybe<(
      { __typename?: 'StructuredText' }
      & Pick<StructuredText, 'html'>
    )>, fragranceFamily?: Maybe<(
      { __typename?: 'StructuredText' }
      & Pick<StructuredText, 'html'>
    )>, ingredients?: Maybe<(
      { __typename?: 'StructuredText' }
      & Pick<StructuredText, 'html'>
    )>, productImages?: Maybe<Array<(
      { __typename?: 'CatalogProductImage' }
      & Pick<CatalogProductImage, 'asset' | 'title'>
      & { size?: Maybe<(
        { __typename?: 'ImageSize' }
        & Pick<ImageSize, 'width' | 'height' | 'aspectRatio'>
      )> }
    )>> }
  ), metadata: (
    { __typename?: 'CatalogProductVariantMetadata' }
    & { tags?: Maybe<Array<(
      { __typename?: 'CatalogProductTag' }
      & Pick<CatalogProductTag, 'key' | 'code' | 'value' | 'name' | 'categoryName'>
    )>>, categoryPaths?: Maybe<Array<(
      { __typename?: 'CatalogProductCategoryPath' }
      & { path: Array<(
        { __typename?: 'CatalogProductCategory' }
        & Pick<CatalogProductCategory, 'code' | 'name' | 'level'>
      )> }
    )>>, brand?: Maybe<(
      { __typename?: 'CatalogProductBrand' }
      & Pick<CatalogProductBrand, 'name' | 'code'>
    )> }
  ), inventory: (
    { __typename?: 'CatalogInventoryInfo' }
    & Pick<CatalogInventoryInfo, 'price' | 'compareAtPrice' | 'maxSellQuantity'>
  ) }
);

export type GetProductInventoryDataQueryVariables = Exact<{
  input: GetCommerceProductInputType;
}>;


export type GetProductInventoryDataQuery = (
  { __typename?: 'Query' }
  & { getCommerceProduct: (
    { __typename?: 'CatalogProductDocument' }
    & { product: (
      { __typename?: 'CatalogProduct' }
      & { info: (
        { __typename?: 'CatalogProductInfo' }
        & Pick<CatalogProductInfo, 'productId' | 'productSlug' | 'available'>
      ) }
    ), variants: Array<(
      { __typename?: 'CatalogProductVariant' }
      & { shopify?: Maybe<(
        { __typename?: 'CatalogProductVariantShopifyData' }
        & Pick<CatalogProductVariantShopifyData, 'shopifyId' | 'shopifyStorefrontId'>
      )>, info: (
        { __typename?: 'CatalogProductVariantInfo' }
        & Pick<CatalogProductVariantInfo, 'variantId' | 'default' | 'available' | 'archived'>
      ), inventory: (
        { __typename?: 'CatalogInventoryInfo' }
        & Pick<CatalogInventoryInfo, 'price' | 'compareAtPrice' | 'maxSellQuantity'>
      ) }
    )>, defaultVariant: (
      { __typename?: 'CatalogProductVariant' }
      & DefaultCatalogProductVariantSearchDataFragment
    ) }
  ) }
);

export type SetFeatureSwitchStatusMutationVariables = Exact<{
  input: SetSwitchStatusInput;
}>;


export type SetFeatureSwitchStatusMutation = (
  { __typename?: 'Mutation' }
  & { setFeatureSwitchStatus: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type UpdateSqlParamMutationVariables = Exact<{
  input: UpdateSqlParamInput;
}>;


export type UpdateSqlParamMutation = (
  { __typename?: 'Mutation' }
  & { updateSqlParam: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type GetFeatureSwitchesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFeatureSwitchesQuery = (
  { __typename?: 'Query' }
  & { getFeatureSwitches: Array<(
    { __typename?: 'FeatureSwitchEntity' }
    & Pick<FeatureSwitchEntity, 'id' | 'enabled'>
  )> }
);

export type GetSqlParamsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSqlParamsQuery = (
  { __typename?: 'Query' }
  & { getSqlParams: Array<(
    { __typename?: 'ConfigParamEntry' }
    & Pick<ConfigParamEntry, 'id' | 'value'>
  )> }
);

export type CustomerUnSubscriptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type CustomerUnSubscriptionsQuery = (
  { __typename?: 'Query' }
  & { customerUnSubscriptions: Array<(
    { __typename?: 'CustomerUnSubscription' }
    & Pick<CustomerUnSubscription, 'id' | 'websiteName' | 'medium' | 'address' | 'createdOn'>
  )> }
);

export type MappingsRebuildMutationVariables = Exact<{ [key: string]: never; }>;


export type MappingsRebuildMutation = (
  { __typename?: 'Mutation' }
  & { mappingsRebuild: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type RebuildVariantMappingsMutationVariables = Exact<{ [key: string]: never; }>;


export type RebuildVariantMappingsMutation = (
  { __typename?: 'Mutation' }
  & { rebuildVariantMappings: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type BackupCatalogDataMutationVariables = Exact<{ [key: string]: never; }>;


export type BackupCatalogDataMutation = (
  { __typename?: 'Mutation' }
  & { backupCatalogData: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type CheckShopifyProductsMutationVariables = Exact<{ [key: string]: never; }>;


export type CheckShopifyProductsMutation = (
  { __typename?: 'Mutation' }
  & { checkShopifyProducts: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type RefreshProductVariantsMutationVariables = Exact<{
  input: RefreshProductsInputType;
}>;


export type RefreshProductVariantsMutation = (
  { __typename?: 'Mutation' }
  & { refreshProductVariants: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type ImportMetaMutationVariables = Exact<{ [key: string]: never; }>;


export type ImportMetaMutation = (
  { __typename?: 'Mutation' }
  & { importMeta: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type IndexMetaMutationVariables = Exact<{ [key: string]: never; }>;


export type IndexMetaMutation = (
  { __typename?: 'Mutation' }
  & { indexMeta: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type ProcessNavCatalogDataMutationVariables = Exact<{
  feedUrl: Scalars['String'];
  options: ProcessNavCatalogOptions;
}>;


export type ProcessNavCatalogDataMutation = (
  { __typename?: 'Mutation' }
  & { processNavCatalogData: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type ProcessNavStockDataMutationVariables = Exact<{
  feedUrl: Scalars['String'];
  options: ProcessNavStockOptions;
}>;


export type ProcessNavStockDataMutation = (
  { __typename?: 'Mutation' }
  & { processNavStockData: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type SyncNavCatalogEventsMutationVariables = Exact<{ [key: string]: never; }>;


export type SyncNavCatalogEventsMutation = (
  { __typename?: 'Mutation' }
  & { syncNavCatalogEvents: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type SyncNavStockEventsMutationVariables = Exact<{ [key: string]: never; }>;


export type SyncNavStockEventsMutation = (
  { __typename?: 'Mutation' }
  & { syncNavStockEvents: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type ReindexProductsMutationVariables = Exact<{ [key: string]: never; }>;


export type ReindexProductsMutation = (
  { __typename?: 'Mutation' }
  & { reindexProducts: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type ReindexProductMutationVariables = Exact<{
  productId: Scalars['String'];
}>;


export type ReindexProductMutation = (
  { __typename?: 'Mutation' }
  & { reindexProduct: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type DeleteElasticProductMutationVariables = Exact<{
  productId: Scalars['String'];
}>;


export type DeleteElasticProductMutation = (
  { __typename?: 'Mutation' }
  & { deleteElasticProduct: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type ReindexBrandProductsMutationVariables = Exact<{
  brandCode: Scalars['String'];
}>;


export type ReindexBrandProductsMutation = (
  { __typename?: 'Mutation' }
  & { reindexBrandProducts: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type ImportStoresMutationVariables = Exact<{
  feedUrl: Scalars['String'];
}>;


export type ImportStoresMutation = (
  { __typename?: 'Mutation' }
  & { importStores: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type RunShopifySanitizeMutationVariables = Exact<{
  startFromProductId: Scalars['String'];
}>;


export type RunShopifySanitizeMutation = (
  { __typename?: 'Mutation' }
  & { runShopifySanitize: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type RunCheckoutUnlockJobMutationVariables = Exact<{ [key: string]: never; }>;


export type RunCheckoutUnlockJobMutation = (
  { __typename?: 'Mutation' }
  & { runCheckoutUnlockJob: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type RunExpiredBasketsJobMutationVariables = Exact<{ [key: string]: never; }>;


export type RunExpiredBasketsJobMutation = (
  { __typename?: 'Mutation' }
  & { runExpiredBasketsJob: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type RunProductsReportJobMutationVariables = Exact<{ [key: string]: never; }>;


export type RunProductsReportJobMutation = (
  { __typename?: 'Mutation' }
  & { runProductsReportJob: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type RunProductsReportExportMutationVariables = Exact<{
  input: ReportExtractionInput;
}>;


export type RunProductsReportExportMutation = (
  { __typename?: 'Mutation' }
  & { runProductsReportExport: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type SyncAlgoliaProductsMutationVariables = Exact<{
  fullImport: Scalars['Boolean'];
}>;


export type SyncAlgoliaProductsMutation = (
  { __typename?: 'Mutation' }
  & { syncAlgoliaProducts: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type ProductInventoriesRefreshMutationVariables = Exact<{
  reprocessAll: Scalars['Boolean'];
}>;


export type ProductInventoriesRefreshMutation = (
  { __typename?: 'Mutation' }
  & { productInventoriesRefresh: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type RunBasketPaymentSessionsCheckMutationVariables = Exact<{ [key: string]: never; }>;


export type RunBasketPaymentSessionsCheckMutation = (
  { __typename?: 'Mutation' }
  & { runBasketPaymentSessionsCheck: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type RunOrdersCheckMutationVariables = Exact<{ [key: string]: never; }>;


export type RunOrdersCheckMutation = (
  { __typename?: 'Mutation' }
  & { runOrdersCheck: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type RunProductUpdateSimulationMutationVariables = Exact<{ [key: string]: never; }>;


export type RunProductUpdateSimulationMutation = (
  { __typename?: 'Mutation' }
  & { runProductUpdateSimulation: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type RunSyncAlgoliaOrderTransactionsMutationVariables = Exact<{ [key: string]: never; }>;


export type RunSyncAlgoliaOrderTransactionsMutation = (
  { __typename?: 'Mutation' }
  & { runSyncAlgoliaOrderTransactions: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type RunMaintenanceJobsMutationVariables = Exact<{ [key: string]: never; }>;


export type RunMaintenanceJobsMutation = (
  { __typename?: 'Mutation' }
  & { runMaintenanceJobs: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type ImportZipCodesMutationVariables = Exact<{
  input: ImportZipCodesInput;
}>;


export type ImportZipCodesMutation = (
  { __typename?: 'Mutation' }
  & { importZipCodes: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type ExecutePerformancesSyncTaskMutationVariables = Exact<{ [key: string]: never; }>;


export type ExecutePerformancesSyncTaskMutation = (
  { __typename?: 'Mutation' }
  & { executePerformancesSyncTask: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type JobUnlockMutationVariables = Exact<{
  jobUid: Scalars['String'];
}>;


export type JobUnlockMutation = (
  { __typename?: 'Mutation' }
  & { jobUnlock: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type JobInstancesQueryVariables = Exact<{ [key: string]: never; }>;


export type JobInstancesQuery = (
  { __typename?: 'Query' }
  & { jobInstances: Array<(
    { __typename?: 'JobStatusEntity' }
    & Pick<JobStatusEntity, 'id' | 'jobUid' | 'runUid' | 'status' | 'runType' | 'startTime' | 'endTime' | 'result' | 'error' | 'createdDate' | 'updatedDate'>
  )> }
);

export type LoginMutationVariables = Exact<{
  input: LoginInput;
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename?: 'LoginResult' }
    & Pick<LoginResult, 'success' | 'errorType' | 'accessToken'>
  ) }
);

export type BackofficeUserRoleSetMutationVariables = Exact<{
  input: BackofficeUserRoleSetInput;
}>;


export type BackofficeUserRoleSetMutation = (
  { __typename?: 'Mutation' }
  & { backofficeUserRoleSet: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type BackofficeUserRolesRemoveMutationVariables = Exact<{
  input: BackofficeUserRolesRemoveInput;
}>;


export type BackofficeUserRolesRemoveMutation = (
  { __typename?: 'Mutation' }
  & { backofficeUserRolesRemove: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type GetUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserQuery = (
  { __typename?: 'Query' }
  & { getUser: (
    { __typename?: 'UserType' }
    & Pick<UserType, 'email' | 'firstName' | 'lastName' | 'roles'>
  ) }
);

export type BackofficeUsersGetQueryVariables = Exact<{ [key: string]: never; }>;


export type BackofficeUsersGetQuery = (
  { __typename?: 'Query' }
  & { backofficeUsersGet: Array<(
    { __typename?: 'UserType' }
    & Pick<UserType, 'email' | 'firstName' | 'lastName' | 'roles'>
  )> }
);

export type VoucherCodeDataFragment = (
  { __typename?: 'VoucherCode' }
  & Pick<VoucherCode, 'id' | 'code' | 'amount' | 'cumulative' | 'cumulativeType' | 'exclusive' | 'amountType' | 'validFrom' | 'validTo' | 'email' | 'userId' | 'basketId' | 'orderId' | 'usedDate' | 'usedOnOrderId' | 'createdDate' | 'updatedDate'>
  & { condition: (
    { __typename?: 'PromoConditionType' }
    & { datesFilter?: Maybe<(
      { __typename?: 'PromoDatesFilterType' }
      & Pick<PromoDatesFilterType, 'validFrom' | 'validTo'>
    )>, lineItemsFilter?: Maybe<(
      { __typename?: 'PromoLineItemFilterType' }
      & Pick<PromoLineItemFilterType, 'linkedPriceCodes'>
    )>, productsFilter?: Maybe<(
      { __typename?: 'PromoProductsFilterType' }
      & Pick<PromoProductsFilterType, 'productId' | 'eanCode' | 'itmCode' | 'brandCode' | 'categoryCode' | 'excludedProductId' | 'excludedEanCode' | 'excludedItmCode' | 'excludedBrandCode' | 'excludedCategoryCode'>
    )>, customerFilter?: Maybe<(
      { __typename?: 'PromoCustomerFilterType' }
      & Pick<PromoCustomerFilterType, 'type'>
    )>, orderFilter?: Maybe<(
      { __typename?: 'PromoOrderFilterType' }
      & Pick<PromoOrderFilterType, 'minSubtotal' | 'minArticles'>
    )> }
  ) }
);

export type ResendPurchaseVoucherCodeMutationVariables = Exact<{
  voucherCode: Scalars['String'];
}>;


export type ResendPurchaseVoucherCodeMutation = (
  { __typename?: 'Mutation' }
  & { resendPurchaseVoucherCode: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type VoucherCreateMutationVariables = Exact<{
  input: VoucherCodeInput;
}>;


export type VoucherCreateMutation = (
  { __typename?: 'Mutation' }
  & { voucherCreate: (
    { __typename?: 'VoucherCode' }
    & VoucherCodeDataFragment
  ) }
);

export type VoucherUpdateMutationVariables = Exact<{
  input: VoucherCodeInput;
}>;


export type VoucherUpdateMutation = (
  { __typename?: 'Mutation' }
  & { voucherUpdate: (
    { __typename?: 'VoucherCode' }
    & VoucherCodeDataFragment
  ) }
);

export type VoucherSearchQueryVariables = Exact<{
  input: SearchVoucherParamsInput;
}>;


export type VoucherSearchQuery = (
  { __typename?: 'Query' }
  & { voucherSearch: Array<(
    { __typename?: 'VoucherCode' }
    & VoucherCodeDataFragment
  )> }
);

export type VoucherGetQueryVariables = Exact<{
  code: Scalars['String'];
}>;


export type VoucherGetQuery = (
  { __typename?: 'Query' }
  & { voucherGet: (
    { __typename?: 'VoucherCode' }
    & VoucherCodeDataFragment
  ) }
);

export const CalendarHolidayRangeDataFragmentDoc = gql`
    fragment CalendarHolidayRangeData on CalendarHolidayRange {
  from
  to
}
    `;
export const CalendarDefinitionDataFragmentDoc = gql`
    fragment CalendarDefinitionData on CalendarDefinition {
  id
  alias
  name
  enabled
  validFrom
  validTo
  schedule
  storeId
  notificationEmail
  locationName
  eventName
  eventDescription
  maxVisibilityDays
  defaultEventDurationMin
  offsetBeforeEvent
  offsetAfterEvent
  eventStartTimeIncrements
  minReservationDaysOffset
  maxReservationsPerDay
  extraQuestions
  blockPublicEmails
  phoneRequired
  createdOn
  updatedOn
  internalNotificationTemplate
  internalNotificationSubject
  customerNotificationTemplate
  customerNotificationSubject
  internalNotificationAlias
  customerNotificationAlias
  internalCancellationNotificationTemplate
  customerCancellationNotificationTemplate
  options {
    preSelection {
      fixed
      preselectedDate
    }
  }
}
    `;
export const CalendarReservationDataFragmentDoc = gql`
    fragment CalendarReservationData on CalendarReservation {
  id
  calendarId
  guestName
  guestEmail
  guestNotes
  extraFields
  date
  startHour
  startMinute
  endHour
  endMinute
  createdOn
  updatedOn
}
    `;
export const CalendarReservationCancellationDataFragmentDoc = gql`
    fragment CalendarReservationCancellationData on CalendarReservationCancellation {
  id
  calendarId
  guestName
  guestEmail
  guestNotes
  extraFields
  date
  startHour
  startMinute
  endHour
  endMinute
  createdOn
  updatedOn
}
    `;
export const CrmContactEntityDataFragmentDoc = gql`
    fragment CrmContactEntityData on CrmContactEntity {
  contactId
  company
  birthDate
  email
  phoneNumber
  firstName
  lastName
  gender
  marketingConsent
  profilingConsent
  surveyConsent
  newsletterConsent
  fidelityCardId
  fidelityCardNumber
  fidelityCardPoints
  crmCreatedOn
  crmModifiedOn
  createdOn
  updatedOn
}
    `;
export const ProductsFeedDefinitionDataFragmentDoc = gql`
    fragment ProductsFeedDefinitionData on ProductsFeedDefinition {
  id
  disabled
  alias
  path
  user
  password
  filters
  fields
  createdDate
  updatedDate
}
    `;
export const OrderPaymentDetailsDataFragmentDoc = gql`
    fragment OrderPaymentDetailsData on OrderPaymentDetails {
  provider
  transactionId
  sessionId
}
    `;
export const OrderInfoDataFragmentDoc = gql`
    fragment OrderInfoData on OrderInfo {
  raw
  id
  uid
  type
  alias
  status
  createdAt
  totalPrice
  tags
  email
  shippingAddress {
    firstName
    lastName
    country
    countryCode
    provinceCode
    city
    address
    address2
    zipCode
    phone
  }
}
    `;
export const CatalogProductSearchDataFragmentDoc = gql`
    fragment CatalogProductSearchData on CatalogProduct {
  info {
    productId
    productSlug
  }
  contents {
    name
    productImages {
      url
      asset
      title
      size {
        width
        height
        aspectRatio
      }
    }
    campaignImages {
      url
      asset
      title
      size {
        width
        height
        aspectRatio
      }
    }
  }
  metadata {
    tags {
      key
      code
      value
      name
      categoryName
    }
    categoryPaths {
      path {
        code
        name
        level
      }
    }
    brand {
      name
      code
    }
  }
}
    `;
export const CatalogProductVariantSearchDataFragmentDoc = gql`
    fragment CatalogProductVariantSearchData on CatalogProductVariant {
  info {
    variantId
    default
    slug
  }
  shopify {
    shopifyId
    shopifyStorefrontId
  }
  details {
    quantity {
      value
    }
    color {
      name
      value {
        hex
      }
      texture {
        asset
      }
    }
  }
  contents {
    name
    shortDescription {
      html
    }
    longDescription {
      html
    }
    highlights {
      html
    }
    instructions {
      html
    }
    composition {
      html
    }
    fragrance {
      html
    }
    fragranceFamily {
      html
    }
    ingredients {
      html
    }
    productImages {
      asset
      title
      size {
        width
        height
        aspectRatio
      }
    }
  }
  metadata {
    tags {
      key
      code
      value
      name
      categoryName
    }
    categoryPaths {
      path {
        code
        name
        level
      }
    }
    brand {
      name
      code
    }
  }
  inventory {
    price
    compareAtPrice
    maxSellQuantity
  }
}
    `;
export const DefaultCatalogProductVariantSearchDataFragmentDoc = gql`
    fragment DefaultCatalogProductVariantSearchData on CatalogProductVariant {
  info {
    variantId
  }
}
    `;
export const OrderDetailsDataFragmentDoc = gql`
    fragment OrderDetailsData on OrderDetails {
  id
  tags
  alias
  status
  createdAt
  deliveredDate
  shippedDate
  editOrder {
    pendingPaymentUrl
    pendingPaymentAmount
  }
  price {
    shippingPrice
    subtotal
    discountTotal
    total
    totalPaid
    giftcards {
      code
      amountUsed
    }
    currency
  }
  basketDiscounts {
    totalDiscount
    trigger
    code
    name
    item {
      code
      name
      contents {
        conditions
        description
        logoUrl
      }
    }
  }
  tracking {
    url
  }
  shipping {
    shippingAddress {
      firstName
      lastName
      country
      countryCode
      provinceCode
      city
      address
      address2
      zipCode
      phone
    }
    billingAddress {
      firstName
      lastName
      country
      countryCode
      provinceCode
      city
      address
      address2
      zipCode
      phone
    }
    store {
      id
      name
      address {
        street
        cap
        city
        province
      }
      coordinates {
        latitude
        longitude
      }
      contacts {
        phone
      }
    }
    preference
  }
  customer {
    email
    acceptsMarketing
  }
  lineItems {
    id
    quantity
    originalUnitPrice
    originalTotal
    discountedUnitPrice
    discountedTotal
    originalTotalCompareAtPrice
    originalUnitCompareAtPrice
    isGift
    product {
      document {
        product {
          ...CatalogProductSearchData
        }
        variants {
          ...CatalogProductVariantSearchData
        }
        defaultVariant {
          ...DefaultCatalogProductVariantSearchData
        }
      }
      variant {
        ...CatalogProductVariantSearchData
      }
    }
  }
}
    ${CatalogProductSearchDataFragmentDoc}
${CatalogProductVariantSearchDataFragmentDoc}
${DefaultCatalogProductVariantSearchDataFragmentDoc}`;
export const CatalogProductExtraDataInfoFragmentDoc = gql`
    fragment CatalogProductExtraDataInfo on CatalogProductExtraData {
  promotions {
    code
    logoUrl
  }
  noPromo
}
    `;
export const CheckoutDataFragmentDoc = gql`
    fragment CheckoutData on Checkout {
  id
  status
  selectedPayment {
    providerId
    captureType
  }
  price {
    currierPaymentPrice
    shippingPrice
    shippingDiscount
    defaultFreeShippingThreshold
    defaultShippingPrice
    hasFreeShippingPromo
    subtotal
    discountTotal
    total
    totalToPay
    totalToPayLater
    appliedGiftcards {
      code
      totalAmount
      usedAmount
    }
    currency
  }
  promotions {
    id
    code
    trigger
    amount
  }
  basketDiscounts {
    totalDiscount
    trigger
    id
    code
    name
    item {
      code
      name
      contents {
        conditions
        description
        logoUrl
      }
    }
  }
  unavailableLineItems {
    variant {
      ...CatalogProductVariantSearchData
    }
  }
  lineItems {
    id
    quantity
    isGift
    originalUnitPrice
    originalTotal
    discountedUnitPrice
    discountedTotal
    originalUnitCompareAtPrice
    originalTotalCompareAtPrice
    discountPercent
    extraDiscountPercent
    assignedGiftCardCode
    source
    promoCodes
    promotions {
      code
      name
      item {
        code
        name
        contents {
          conditions
          description
          logoUrl
        }
      }
    }
    product {
      document {
        product {
          ...CatalogProductSearchData
        }
        variants {
          ...CatalogProductVariantSearchData
        }
        defaultVariant {
          ...DefaultCatalogProductVariantSearchData
        }
        extraData {
          ...CatalogProductExtraDataInfo
        }
      }
      variant {
        ...CatalogProductVariantSearchData
      }
    }
  }
  promoState {
    hasLoggedPromoInBasket
    acceptedNoLoggedPromo
    authenticatedBasket
  }
  samples {
    samples {
      id
      name
    }
    notes
  }
  shipping {
    preference
    store {
      id
      name
      company
      address {
        street
        cap
        city
        province
      }
    }
    shippingAddress {
      customerAddressId
      firstName
      lastName
      countryCode
      provinceCode
      city
      address
      address2
      notes
      company
      vatNumber
      zipCode
      phone
    }
    billingAddress {
      customerAddressId
      firstName
      lastName
      countryCode
      provinceCode
      city
      address
      address2
      notes
      company
      vatNumber
      zipCode
      phone
    }
  }
  customer {
    email
    acceptsMarketing
  }
  order {
    id
    uid
  }
  settings {
    clickAndCollectEnabled
    giftcardEnabled
    giftBoxEnabled
    enabledPaymentMethods
  }
  customerDiscounts {
    birthdayDiscount
    passagesDiscount
    pointDiscounts {
      points
      discount
    }
    promoCode
  }
  lock {
    locked
    lockTime
    lockExpiration
  }
}
    ${CatalogProductVariantSearchDataFragmentDoc}
${CatalogProductSearchDataFragmentDoc}
${DefaultCatalogProductVariantSearchDataFragmentDoc}
${CatalogProductExtraDataInfoFragmentDoc}`;
export const PriceRuleDataFragmentDoc = gql`
    fragment PriceRuleData on PriceRule {
  id
  priceCode
  promoCode
  weight
  disabled
  ruleType
  crossedPrice
  discountType
  discountAmount
  fixedPrice
  fixedCompareAtPrice
  validFrom
  validTo
  eanCodes
  itmCodes
  productIds
  productCategories
  brandCodes
  productTags
  excludedEanCodes
  excludedItmCodes
  excludedProductIds
  excludedBrandCodes
  excludedProductCategories
  excludedProductTags
  discountRulePayload
  dynamicPricePayload
  createdDate
  updatedDate
}
    `;
export const ProductUpdateRecordDataFragmentDoc = gql`
    fragment ProductUpdateRecordData on ProductUpdateRecord {
  ean
  name
  line
  size
  colorName
  colorHex
  colorUrl
  contents {
    shortDescription
    longDescription
    instructions
    inci
    composition
    highlights
    olfactoryNotes
    olfactoryFamily
    tests
    technology
  }
  relatedEanCodes
  complementaryEanCodes
  notes
  validFrom
  validTo
  imageUrls
  videoUrls
}
    `;
export const ProductsUpdateDataFieldsFragmentDoc = gql`
    fragment ProductsUpdateDataFields on ProductsUpdateData {
  records {
    ...ProductUpdateRecordData
  }
}
    ${ProductUpdateRecordDataFragmentDoc}`;
export const ProductsXlsParseErrorDataFragmentDoc = gql`
    fragment ProductsXlsParseErrorData on ProductsXlsParseError {
  type
  fieldName
  fieldValue
  itemIndex
  itemId
}
    `;
export const ImportVariantItemDataFragmentDoc = gql`
    fragment ImportVariantItemData on ImportVariantItem {
  itemIndex
  eanCode
  imageCode
  name
  brandName
  brandCode
  lineName
  size
  colorName
  colorHex
  gender
  shortDescription
  longDescription
  instructions
  inci
  composition
  olfactoryNotes
  highlights
  testResults
  technology
  imageUrls
  videoUrls
  notes
  tags
  relatedEan
  complementaryEan
  categoryCodes
  keywords
  validFrom
  validTo
}
    `;
export const ImportProductItemDataFragmentDoc = gql`
    fragment ImportProductItemData on ImportProductItem {
  productId
  variants {
    ...ImportVariantItemData
  }
}
    ${ImportVariantItemDataFragmentDoc}`;
export const PromoDefinitionDataFragmentDoc = gql`
    fragment PromoDefinitionData on PromoDefinition {
  id
  promoCode
  weight
  disabled
  options {
    cumulative
    exclusive
    mixable
    type
  }
  condition
  conditionType
  effect {
    type
    payload
  }
  triggerType
  triggerParams
  productPromo
  promoLogoUrl
  createdDate
  updatedDate
}
    `;
export const VoucherCodeDataFragmentDoc = gql`
    fragment VoucherCodeData on VoucherCode {
  id
  code
  amount
  cumulative
  cumulativeType
  exclusive
  amountType
  validFrom
  validTo
  email
  userId
  basketId
  orderId
  usedDate
  usedOnOrderId
  createdDate
  updatedDate
  condition {
    datesFilter {
      validFrom
      validTo
    }
    lineItemsFilter {
      linkedPriceCodes
    }
    productsFilter {
      productId
      eanCode
      itmCode
      brandCode
      categoryCode
      excludedProductId
      excludedEanCode
      excludedItmCode
      excludedBrandCode
      excludedCategoryCode
    }
    customerFilter {
      type
    }
    orderFilter {
      minSubtotal
      minArticles
    }
  }
}
    `;
export const CreateCalendarDocument = gql`
    mutation createCalendar($input: CalendarDefinitionInput!) {
  createCalendar(input: $input) {
    ...CalendarDefinitionData
  }
}
    ${CalendarDefinitionDataFragmentDoc}`;
export type CreateCalendarMutationFn = Apollo.MutationFunction<CreateCalendarMutation, CreateCalendarMutationVariables>;

/**
 * __useCreateCalendarMutation__
 *
 * To run a mutation, you first call `useCreateCalendarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCalendarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCalendarMutation, { data, loading, error }] = useCreateCalendarMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCalendarMutation(baseOptions?: Apollo.MutationHookOptions<CreateCalendarMutation, CreateCalendarMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCalendarMutation, CreateCalendarMutationVariables>(CreateCalendarDocument, options);
      }
export type CreateCalendarMutationHookResult = ReturnType<typeof useCreateCalendarMutation>;
export type CreateCalendarMutationResult = Apollo.MutationResult<CreateCalendarMutation>;
export type CreateCalendarMutationOptions = Apollo.BaseMutationOptions<CreateCalendarMutation, CreateCalendarMutationVariables>;
export const UpdateCalendarDocument = gql`
    mutation updateCalendar($input: CalendarDefinitionInput!) {
  updateCalendar(input: $input) {
    ...CalendarDefinitionData
  }
}
    ${CalendarDefinitionDataFragmentDoc}`;
export type UpdateCalendarMutationFn = Apollo.MutationFunction<UpdateCalendarMutation, UpdateCalendarMutationVariables>;

/**
 * __useUpdateCalendarMutation__
 *
 * To run a mutation, you first call `useUpdateCalendarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCalendarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCalendarMutation, { data, loading, error }] = useUpdateCalendarMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCalendarMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCalendarMutation, UpdateCalendarMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCalendarMutation, UpdateCalendarMutationVariables>(UpdateCalendarDocument, options);
      }
export type UpdateCalendarMutationHookResult = ReturnType<typeof useUpdateCalendarMutation>;
export type UpdateCalendarMutationResult = Apollo.MutationResult<UpdateCalendarMutation>;
export type UpdateCalendarMutationOptions = Apollo.BaseMutationOptions<UpdateCalendarMutation, UpdateCalendarMutationVariables>;
export const ToggleCalendarDocument = gql`
    mutation toggleCalendar($id: String!, $enabled: Boolean!) {
  toggleCalendar(id: $id, enabled: $enabled) {
    ...CalendarDefinitionData
  }
}
    ${CalendarDefinitionDataFragmentDoc}`;
export type ToggleCalendarMutationFn = Apollo.MutationFunction<ToggleCalendarMutation, ToggleCalendarMutationVariables>;

/**
 * __useToggleCalendarMutation__
 *
 * To run a mutation, you first call `useToggleCalendarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleCalendarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleCalendarMutation, { data, loading, error }] = useToggleCalendarMutation({
 *   variables: {
 *      id: // value for 'id'
 *      enabled: // value for 'enabled'
 *   },
 * });
 */
export function useToggleCalendarMutation(baseOptions?: Apollo.MutationHookOptions<ToggleCalendarMutation, ToggleCalendarMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleCalendarMutation, ToggleCalendarMutationVariables>(ToggleCalendarDocument, options);
      }
export type ToggleCalendarMutationHookResult = ReturnType<typeof useToggleCalendarMutation>;
export type ToggleCalendarMutationResult = Apollo.MutationResult<ToggleCalendarMutation>;
export type ToggleCalendarMutationOptions = Apollo.BaseMutationOptions<ToggleCalendarMutation, ToggleCalendarMutationVariables>;
export const CreateCalendarStoreHolidaysDocument = gql`
    mutation createCalendarStoreHolidays($input: CreateCalendarHolidaysInput!) {
  createCalendarStoreHolidays(input: $input) {
    ...CalendarHolidayRangeData
  }
}
    ${CalendarHolidayRangeDataFragmentDoc}`;
export type CreateCalendarStoreHolidaysMutationFn = Apollo.MutationFunction<CreateCalendarStoreHolidaysMutation, CreateCalendarStoreHolidaysMutationVariables>;

/**
 * __useCreateCalendarStoreHolidaysMutation__
 *
 * To run a mutation, you first call `useCreateCalendarStoreHolidaysMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCalendarStoreHolidaysMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCalendarStoreHolidaysMutation, { data, loading, error }] = useCreateCalendarStoreHolidaysMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCalendarStoreHolidaysMutation(baseOptions?: Apollo.MutationHookOptions<CreateCalendarStoreHolidaysMutation, CreateCalendarStoreHolidaysMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCalendarStoreHolidaysMutation, CreateCalendarStoreHolidaysMutationVariables>(CreateCalendarStoreHolidaysDocument, options);
      }
export type CreateCalendarStoreHolidaysMutationHookResult = ReturnType<typeof useCreateCalendarStoreHolidaysMutation>;
export type CreateCalendarStoreHolidaysMutationResult = Apollo.MutationResult<CreateCalendarStoreHolidaysMutation>;
export type CreateCalendarStoreHolidaysMutationOptions = Apollo.BaseMutationOptions<CreateCalendarStoreHolidaysMutation, CreateCalendarStoreHolidaysMutationVariables>;
export const DeleteCalendarStoreHolidaysDocument = gql`
    mutation deleteCalendarStoreHolidays($input: DeleteCalendarHolidaysInput!) {
  deleteCalendarStoreHolidays(input: $input) {
    ...CalendarHolidayRangeData
  }
}
    ${CalendarHolidayRangeDataFragmentDoc}`;
export type DeleteCalendarStoreHolidaysMutationFn = Apollo.MutationFunction<DeleteCalendarStoreHolidaysMutation, DeleteCalendarStoreHolidaysMutationVariables>;

/**
 * __useDeleteCalendarStoreHolidaysMutation__
 *
 * To run a mutation, you first call `useDeleteCalendarStoreHolidaysMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCalendarStoreHolidaysMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCalendarStoreHolidaysMutation, { data, loading, error }] = useDeleteCalendarStoreHolidaysMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCalendarStoreHolidaysMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCalendarStoreHolidaysMutation, DeleteCalendarStoreHolidaysMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCalendarStoreHolidaysMutation, DeleteCalendarStoreHolidaysMutationVariables>(DeleteCalendarStoreHolidaysDocument, options);
      }
export type DeleteCalendarStoreHolidaysMutationHookResult = ReturnType<typeof useDeleteCalendarStoreHolidaysMutation>;
export type DeleteCalendarStoreHolidaysMutationResult = Apollo.MutationResult<DeleteCalendarStoreHolidaysMutation>;
export type DeleteCalendarStoreHolidaysMutationOptions = Apollo.BaseMutationOptions<DeleteCalendarStoreHolidaysMutation, DeleteCalendarStoreHolidaysMutationVariables>;
export const CreateGlobalStoreHolidaysDocument = gql`
    mutation createGlobalStoreHolidays($input: CalendarHolidayRangeInput!) {
  createGlobalStoreHolidays(input: $input) {
    ...CalendarHolidayRangeData
  }
}
    ${CalendarHolidayRangeDataFragmentDoc}`;
export type CreateGlobalStoreHolidaysMutationFn = Apollo.MutationFunction<CreateGlobalStoreHolidaysMutation, CreateGlobalStoreHolidaysMutationVariables>;

/**
 * __useCreateGlobalStoreHolidaysMutation__
 *
 * To run a mutation, you first call `useCreateGlobalStoreHolidaysMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGlobalStoreHolidaysMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGlobalStoreHolidaysMutation, { data, loading, error }] = useCreateGlobalStoreHolidaysMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateGlobalStoreHolidaysMutation(baseOptions?: Apollo.MutationHookOptions<CreateGlobalStoreHolidaysMutation, CreateGlobalStoreHolidaysMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateGlobalStoreHolidaysMutation, CreateGlobalStoreHolidaysMutationVariables>(CreateGlobalStoreHolidaysDocument, options);
      }
export type CreateGlobalStoreHolidaysMutationHookResult = ReturnType<typeof useCreateGlobalStoreHolidaysMutation>;
export type CreateGlobalStoreHolidaysMutationResult = Apollo.MutationResult<CreateGlobalStoreHolidaysMutation>;
export type CreateGlobalStoreHolidaysMutationOptions = Apollo.BaseMutationOptions<CreateGlobalStoreHolidaysMutation, CreateGlobalStoreHolidaysMutationVariables>;
export const DeleteGlobalStoreHolidaysDocument = gql`
    mutation deleteGlobalStoreHolidays($input: CalendarHolidayRangeInput!) {
  deleteGlobalStoreHolidays(input: $input) {
    ...CalendarHolidayRangeData
  }
}
    ${CalendarHolidayRangeDataFragmentDoc}`;
export type DeleteGlobalStoreHolidaysMutationFn = Apollo.MutationFunction<DeleteGlobalStoreHolidaysMutation, DeleteGlobalStoreHolidaysMutationVariables>;

/**
 * __useDeleteGlobalStoreHolidaysMutation__
 *
 * To run a mutation, you first call `useDeleteGlobalStoreHolidaysMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGlobalStoreHolidaysMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGlobalStoreHolidaysMutation, { data, loading, error }] = useDeleteGlobalStoreHolidaysMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteGlobalStoreHolidaysMutation(baseOptions?: Apollo.MutationHookOptions<DeleteGlobalStoreHolidaysMutation, DeleteGlobalStoreHolidaysMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteGlobalStoreHolidaysMutation, DeleteGlobalStoreHolidaysMutationVariables>(DeleteGlobalStoreHolidaysDocument, options);
      }
export type DeleteGlobalStoreHolidaysMutationHookResult = ReturnType<typeof useDeleteGlobalStoreHolidaysMutation>;
export type DeleteGlobalStoreHolidaysMutationResult = Apollo.MutationResult<DeleteGlobalStoreHolidaysMutation>;
export type DeleteGlobalStoreHolidaysMutationOptions = Apollo.BaseMutationOptions<DeleteGlobalStoreHolidaysMutation, DeleteGlobalStoreHolidaysMutationVariables>;
export const AppointmentsFullSyncRunDocument = gql`
    mutation appointmentsFullSyncRun {
  appointmentsFullSyncRun {
    success
  }
}
    `;
export type AppointmentsFullSyncRunMutationFn = Apollo.MutationFunction<AppointmentsFullSyncRunMutation, AppointmentsFullSyncRunMutationVariables>;

/**
 * __useAppointmentsFullSyncRunMutation__
 *
 * To run a mutation, you first call `useAppointmentsFullSyncRunMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAppointmentsFullSyncRunMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [appointmentsFullSyncRunMutation, { data, loading, error }] = useAppointmentsFullSyncRunMutation({
 *   variables: {
 *   },
 * });
 */
export function useAppointmentsFullSyncRunMutation(baseOptions?: Apollo.MutationHookOptions<AppointmentsFullSyncRunMutation, AppointmentsFullSyncRunMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AppointmentsFullSyncRunMutation, AppointmentsFullSyncRunMutationVariables>(AppointmentsFullSyncRunDocument, options);
      }
export type AppointmentsFullSyncRunMutationHookResult = ReturnType<typeof useAppointmentsFullSyncRunMutation>;
export type AppointmentsFullSyncRunMutationResult = Apollo.MutationResult<AppointmentsFullSyncRunMutation>;
export type AppointmentsFullSyncRunMutationOptions = Apollo.BaseMutationOptions<AppointmentsFullSyncRunMutation, AppointmentsFullSyncRunMutationVariables>;
export const CalendarsExportDocument = gql`
    mutation calendarsExport {
  calendarsExport {
    url
  }
}
    `;
export type CalendarsExportMutationFn = Apollo.MutationFunction<CalendarsExportMutation, CalendarsExportMutationVariables>;

/**
 * __useCalendarsExportMutation__
 *
 * To run a mutation, you first call `useCalendarsExportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCalendarsExportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [calendarsExportMutation, { data, loading, error }] = useCalendarsExportMutation({
 *   variables: {
 *   },
 * });
 */
export function useCalendarsExportMutation(baseOptions?: Apollo.MutationHookOptions<CalendarsExportMutation, CalendarsExportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CalendarsExportMutation, CalendarsExportMutationVariables>(CalendarsExportDocument, options);
      }
export type CalendarsExportMutationHookResult = ReturnType<typeof useCalendarsExportMutation>;
export type CalendarsExportMutationResult = Apollo.MutationResult<CalendarsExportMutation>;
export type CalendarsExportMutationOptions = Apollo.BaseMutationOptions<CalendarsExportMutation, CalendarsExportMutationVariables>;
export const CalendarDefinitionsDocument = gql`
    query calendarDefinitions {
  calendarDefinitions {
    ...CalendarDefinitionData
  }
}
    ${CalendarDefinitionDataFragmentDoc}`;

/**
 * __useCalendarDefinitionsQuery__
 *
 * To run a query within a React component, call `useCalendarDefinitionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarDefinitionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarDefinitionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCalendarDefinitionsQuery(baseOptions?: Apollo.QueryHookOptions<CalendarDefinitionsQuery, CalendarDefinitionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CalendarDefinitionsQuery, CalendarDefinitionsQueryVariables>(CalendarDefinitionsDocument, options);
      }
export function useCalendarDefinitionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CalendarDefinitionsQuery, CalendarDefinitionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CalendarDefinitionsQuery, CalendarDefinitionsQueryVariables>(CalendarDefinitionsDocument, options);
        }
export type CalendarDefinitionsQueryHookResult = ReturnType<typeof useCalendarDefinitionsQuery>;
export type CalendarDefinitionsLazyQueryHookResult = ReturnType<typeof useCalendarDefinitionsLazyQuery>;
export type CalendarDefinitionsQueryResult = Apollo.QueryResult<CalendarDefinitionsQuery, CalendarDefinitionsQueryVariables>;
export const CalendarDefinitionDocument = gql`
    query calendarDefinition($id: String!) {
  calendarDefinition(id: $id) {
    ...CalendarDefinitionData
  }
}
    ${CalendarDefinitionDataFragmentDoc}`;

/**
 * __useCalendarDefinitionQuery__
 *
 * To run a query within a React component, call `useCalendarDefinitionQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarDefinitionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarDefinitionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCalendarDefinitionQuery(baseOptions: Apollo.QueryHookOptions<CalendarDefinitionQuery, CalendarDefinitionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CalendarDefinitionQuery, CalendarDefinitionQueryVariables>(CalendarDefinitionDocument, options);
      }
export function useCalendarDefinitionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CalendarDefinitionQuery, CalendarDefinitionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CalendarDefinitionQuery, CalendarDefinitionQueryVariables>(CalendarDefinitionDocument, options);
        }
export type CalendarDefinitionQueryHookResult = ReturnType<typeof useCalendarDefinitionQuery>;
export type CalendarDefinitionLazyQueryHookResult = ReturnType<typeof useCalendarDefinitionLazyQuery>;
export type CalendarDefinitionQueryResult = Apollo.QueryResult<CalendarDefinitionQuery, CalendarDefinitionQueryVariables>;
export const GetCalendarGlobalHolidaysDocument = gql`
    query getCalendarGlobalHolidays {
  getCalendarGlobalHolidays {
    ...CalendarHolidayRangeData
  }
}
    ${CalendarHolidayRangeDataFragmentDoc}`;

/**
 * __useGetCalendarGlobalHolidaysQuery__
 *
 * To run a query within a React component, call `useGetCalendarGlobalHolidaysQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCalendarGlobalHolidaysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCalendarGlobalHolidaysQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCalendarGlobalHolidaysQuery(baseOptions?: Apollo.QueryHookOptions<GetCalendarGlobalHolidaysQuery, GetCalendarGlobalHolidaysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCalendarGlobalHolidaysQuery, GetCalendarGlobalHolidaysQueryVariables>(GetCalendarGlobalHolidaysDocument, options);
      }
export function useGetCalendarGlobalHolidaysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCalendarGlobalHolidaysQuery, GetCalendarGlobalHolidaysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCalendarGlobalHolidaysQuery, GetCalendarGlobalHolidaysQueryVariables>(GetCalendarGlobalHolidaysDocument, options);
        }
export type GetCalendarGlobalHolidaysQueryHookResult = ReturnType<typeof useGetCalendarGlobalHolidaysQuery>;
export type GetCalendarGlobalHolidaysLazyQueryHookResult = ReturnType<typeof useGetCalendarGlobalHolidaysLazyQuery>;
export type GetCalendarGlobalHolidaysQueryResult = Apollo.QueryResult<GetCalendarGlobalHolidaysQuery, GetCalendarGlobalHolidaysQueryVariables>;
export const GetCalendarStoreHolidaysDocument = gql`
    query getCalendarStoreHolidays($storeId: String!) {
  getCalendarStoreHolidays(storeId: $storeId) {
    ...CalendarHolidayRangeData
  }
}
    ${CalendarHolidayRangeDataFragmentDoc}`;

/**
 * __useGetCalendarStoreHolidaysQuery__
 *
 * To run a query within a React component, call `useGetCalendarStoreHolidaysQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCalendarStoreHolidaysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCalendarStoreHolidaysQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useGetCalendarStoreHolidaysQuery(baseOptions: Apollo.QueryHookOptions<GetCalendarStoreHolidaysQuery, GetCalendarStoreHolidaysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCalendarStoreHolidaysQuery, GetCalendarStoreHolidaysQueryVariables>(GetCalendarStoreHolidaysDocument, options);
      }
export function useGetCalendarStoreHolidaysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCalendarStoreHolidaysQuery, GetCalendarStoreHolidaysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCalendarStoreHolidaysQuery, GetCalendarStoreHolidaysQueryVariables>(GetCalendarStoreHolidaysDocument, options);
        }
export type GetCalendarStoreHolidaysQueryHookResult = ReturnType<typeof useGetCalendarStoreHolidaysQuery>;
export type GetCalendarStoreHolidaysLazyQueryHookResult = ReturnType<typeof useGetCalendarStoreHolidaysLazyQuery>;
export type GetCalendarStoreHolidaysQueryResult = Apollo.QueryResult<GetCalendarStoreHolidaysQuery, GetCalendarStoreHolidaysQueryVariables>;
export const CalendarReservationsDocument = gql`
    query calendarReservations($calendarId: String!) {
  calendarReservations(calendarId: $calendarId) {
    ...CalendarReservationData
  }
}
    ${CalendarReservationDataFragmentDoc}`;

/**
 * __useCalendarReservationsQuery__
 *
 * To run a query within a React component, call `useCalendarReservationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarReservationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarReservationsQuery({
 *   variables: {
 *      calendarId: // value for 'calendarId'
 *   },
 * });
 */
export function useCalendarReservationsQuery(baseOptions: Apollo.QueryHookOptions<CalendarReservationsQuery, CalendarReservationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CalendarReservationsQuery, CalendarReservationsQueryVariables>(CalendarReservationsDocument, options);
      }
export function useCalendarReservationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CalendarReservationsQuery, CalendarReservationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CalendarReservationsQuery, CalendarReservationsQueryVariables>(CalendarReservationsDocument, options);
        }
export type CalendarReservationsQueryHookResult = ReturnType<typeof useCalendarReservationsQuery>;
export type CalendarReservationsLazyQueryHookResult = ReturnType<typeof useCalendarReservationsLazyQuery>;
export type CalendarReservationsQueryResult = Apollo.QueryResult<CalendarReservationsQuery, CalendarReservationsQueryVariables>;
export const CalendarCancellationsDocument = gql`
    query calendarCancellations($calendarId: String!) {
  calendarCancellations(calendarId: $calendarId) {
    ...CalendarReservationCancellationData
  }
}
    ${CalendarReservationCancellationDataFragmentDoc}`;

/**
 * __useCalendarCancellationsQuery__
 *
 * To run a query within a React component, call `useCalendarCancellationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarCancellationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarCancellationsQuery({
 *   variables: {
 *      calendarId: // value for 'calendarId'
 *   },
 * });
 */
export function useCalendarCancellationsQuery(baseOptions: Apollo.QueryHookOptions<CalendarCancellationsQuery, CalendarCancellationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CalendarCancellationsQuery, CalendarCancellationsQueryVariables>(CalendarCancellationsDocument, options);
      }
export function useCalendarCancellationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CalendarCancellationsQuery, CalendarCancellationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CalendarCancellationsQuery, CalendarCancellationsQueryVariables>(CalendarCancellationsDocument, options);
        }
export type CalendarCancellationsQueryHookResult = ReturnType<typeof useCalendarCancellationsQuery>;
export type CalendarCancellationsLazyQueryHookResult = ReturnType<typeof useCalendarCancellationsLazyQuery>;
export type CalendarCancellationsQueryResult = Apollo.QueryResult<CalendarCancellationsQuery, CalendarCancellationsQueryVariables>;
export const ImportCrmContactsDocument = gql`
    mutation importCrmContacts($input: CrmImportJobsInput!) {
  importCrmContacts(input: $input) {
    success
  }
}
    `;
export type ImportCrmContactsMutationFn = Apollo.MutationFunction<ImportCrmContactsMutation, ImportCrmContactsMutationVariables>;

/**
 * __useImportCrmContactsMutation__
 *
 * To run a mutation, you first call `useImportCrmContactsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportCrmContactsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importCrmContactsMutation, { data, loading, error }] = useImportCrmContactsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportCrmContactsMutation(baseOptions?: Apollo.MutationHookOptions<ImportCrmContactsMutation, ImportCrmContactsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportCrmContactsMutation, ImportCrmContactsMutationVariables>(ImportCrmContactsDocument, options);
      }
export type ImportCrmContactsMutationHookResult = ReturnType<typeof useImportCrmContactsMutation>;
export type ImportCrmContactsMutationResult = Apollo.MutationResult<ImportCrmContactsMutation>;
export type ImportCrmContactsMutationOptions = Apollo.BaseMutationOptions<ImportCrmContactsMutation, ImportCrmContactsMutationVariables>;
export const UnusedVouchersSyncDocument = gql`
    mutation unusedVouchersSync {
  unusedVouchersSync {
    success
  }
}
    `;
export type UnusedVouchersSyncMutationFn = Apollo.MutationFunction<UnusedVouchersSyncMutation, UnusedVouchersSyncMutationVariables>;

/**
 * __useUnusedVouchersSyncMutation__
 *
 * To run a mutation, you first call `useUnusedVouchersSyncMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnusedVouchersSyncMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unusedVouchersSyncMutation, { data, loading, error }] = useUnusedVouchersSyncMutation({
 *   variables: {
 *   },
 * });
 */
export function useUnusedVouchersSyncMutation(baseOptions?: Apollo.MutationHookOptions<UnusedVouchersSyncMutation, UnusedVouchersSyncMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnusedVouchersSyncMutation, UnusedVouchersSyncMutationVariables>(UnusedVouchersSyncDocument, options);
      }
export type UnusedVouchersSyncMutationHookResult = ReturnType<typeof useUnusedVouchersSyncMutation>;
export type UnusedVouchersSyncMutationResult = Apollo.MutationResult<UnusedVouchersSyncMutation>;
export type UnusedVouchersSyncMutationOptions = Apollo.BaseMutationOptions<UnusedVouchersSyncMutation, UnusedVouchersSyncMutationVariables>;
export const ShopifyCrmSyncDocument = gql`
    mutation shopifyCrmSync {
  shopifyCrmSync {
    success
  }
}
    `;
export type ShopifyCrmSyncMutationFn = Apollo.MutationFunction<ShopifyCrmSyncMutation, ShopifyCrmSyncMutationVariables>;

/**
 * __useShopifyCrmSyncMutation__
 *
 * To run a mutation, you first call `useShopifyCrmSyncMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShopifyCrmSyncMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shopifyCrmSyncMutation, { data, loading, error }] = useShopifyCrmSyncMutation({
 *   variables: {
 *   },
 * });
 */
export function useShopifyCrmSyncMutation(baseOptions?: Apollo.MutationHookOptions<ShopifyCrmSyncMutation, ShopifyCrmSyncMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ShopifyCrmSyncMutation, ShopifyCrmSyncMutationVariables>(ShopifyCrmSyncDocument, options);
      }
export type ShopifyCrmSyncMutationHookResult = ReturnType<typeof useShopifyCrmSyncMutation>;
export type ShopifyCrmSyncMutationResult = Apollo.MutationResult<ShopifyCrmSyncMutation>;
export type ShopifyCrmSyncMutationOptions = Apollo.BaseMutationOptions<ShopifyCrmSyncMutation, ShopifyCrmSyncMutationVariables>;
export const SyncShopifyProfileDocument = gql`
    mutation syncShopifyProfile($input: SyncShopifyProfileInput!) {
  syncShopifyProfile(input: $input) {
    success
  }
}
    `;
export type SyncShopifyProfileMutationFn = Apollo.MutationFunction<SyncShopifyProfileMutation, SyncShopifyProfileMutationVariables>;

/**
 * __useSyncShopifyProfileMutation__
 *
 * To run a mutation, you first call `useSyncShopifyProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncShopifyProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncShopifyProfileMutation, { data, loading, error }] = useSyncShopifyProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSyncShopifyProfileMutation(baseOptions?: Apollo.MutationHookOptions<SyncShopifyProfileMutation, SyncShopifyProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SyncShopifyProfileMutation, SyncShopifyProfileMutationVariables>(SyncShopifyProfileDocument, options);
      }
export type SyncShopifyProfileMutationHookResult = ReturnType<typeof useSyncShopifyProfileMutation>;
export type SyncShopifyProfileMutationResult = Apollo.MutationResult<SyncShopifyProfileMutation>;
export type SyncShopifyProfileMutationOptions = Apollo.BaseMutationOptions<SyncShopifyProfileMutation, SyncShopifyProfileMutationVariables>;
export const SyncKlaviyoProfileDocument = gql`
    mutation syncKlaviyoProfile($input: SyncKlaviyoProfileInput!) {
  syncKlaviyoProfile(input: $input) {
    success
  }
}
    `;
export type SyncKlaviyoProfileMutationFn = Apollo.MutationFunction<SyncKlaviyoProfileMutation, SyncKlaviyoProfileMutationVariables>;

/**
 * __useSyncKlaviyoProfileMutation__
 *
 * To run a mutation, you first call `useSyncKlaviyoProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncKlaviyoProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncKlaviyoProfileMutation, { data, loading, error }] = useSyncKlaviyoProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSyncKlaviyoProfileMutation(baseOptions?: Apollo.MutationHookOptions<SyncKlaviyoProfileMutation, SyncKlaviyoProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SyncKlaviyoProfileMutation, SyncKlaviyoProfileMutationVariables>(SyncKlaviyoProfileDocument, options);
      }
export type SyncKlaviyoProfileMutationHookResult = ReturnType<typeof useSyncKlaviyoProfileMutation>;
export type SyncKlaviyoProfileMutationResult = Apollo.MutationResult<SyncKlaviyoProfileMutation>;
export type SyncKlaviyoProfileMutationOptions = Apollo.BaseMutationOptions<SyncKlaviyoProfileMutation, SyncKlaviyoProfileMutationVariables>;
export const ExportCrmContactsDocument = gql`
    mutation exportCrmContacts($input: ExportCrmContactsInput!) {
  exportCrmContacts(input: $input) {
    success
  }
}
    `;
export type ExportCrmContactsMutationFn = Apollo.MutationFunction<ExportCrmContactsMutation, ExportCrmContactsMutationVariables>;

/**
 * __useExportCrmContactsMutation__
 *
 * To run a mutation, you first call `useExportCrmContactsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportCrmContactsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportCrmContactsMutation, { data, loading, error }] = useExportCrmContactsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExportCrmContactsMutation(baseOptions?: Apollo.MutationHookOptions<ExportCrmContactsMutation, ExportCrmContactsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportCrmContactsMutation, ExportCrmContactsMutationVariables>(ExportCrmContactsDocument, options);
      }
export type ExportCrmContactsMutationHookResult = ReturnType<typeof useExportCrmContactsMutation>;
export type ExportCrmContactsMutationResult = Apollo.MutationResult<ExportCrmContactsMutation>;
export type ExportCrmContactsMutationOptions = Apollo.BaseMutationOptions<ExportCrmContactsMutation, ExportCrmContactsMutationVariables>;
export const RunCrmCardsReportJobDocument = gql`
    mutation runCrmCardsReportJob {
  runCrmCardsReportJob {
    success
  }
}
    `;
export type RunCrmCardsReportJobMutationFn = Apollo.MutationFunction<RunCrmCardsReportJobMutation, RunCrmCardsReportJobMutationVariables>;

/**
 * __useRunCrmCardsReportJobMutation__
 *
 * To run a mutation, you first call `useRunCrmCardsReportJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRunCrmCardsReportJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [runCrmCardsReportJobMutation, { data, loading, error }] = useRunCrmCardsReportJobMutation({
 *   variables: {
 *   },
 * });
 */
export function useRunCrmCardsReportJobMutation(baseOptions?: Apollo.MutationHookOptions<RunCrmCardsReportJobMutation, RunCrmCardsReportJobMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RunCrmCardsReportJobMutation, RunCrmCardsReportJobMutationVariables>(RunCrmCardsReportJobDocument, options);
      }
export type RunCrmCardsReportJobMutationHookResult = ReturnType<typeof useRunCrmCardsReportJobMutation>;
export type RunCrmCardsReportJobMutationResult = Apollo.MutationResult<RunCrmCardsReportJobMutation>;
export type RunCrmCardsReportJobMutationOptions = Apollo.BaseMutationOptions<RunCrmCardsReportJobMutation, RunCrmCardsReportJobMutationVariables>;
export const CrmDisabledProfilesAddDocument = gql`
    mutation crmDisabledProfilesAdd($email: String!) {
  crmDisabledProfilesAdd(email: $email) {
    success
  }
}
    `;
export type CrmDisabledProfilesAddMutationFn = Apollo.MutationFunction<CrmDisabledProfilesAddMutation, CrmDisabledProfilesAddMutationVariables>;

/**
 * __useCrmDisabledProfilesAddMutation__
 *
 * To run a mutation, you first call `useCrmDisabledProfilesAddMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCrmDisabledProfilesAddMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [crmDisabledProfilesAddMutation, { data, loading, error }] = useCrmDisabledProfilesAddMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCrmDisabledProfilesAddMutation(baseOptions?: Apollo.MutationHookOptions<CrmDisabledProfilesAddMutation, CrmDisabledProfilesAddMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CrmDisabledProfilesAddMutation, CrmDisabledProfilesAddMutationVariables>(CrmDisabledProfilesAddDocument, options);
      }
export type CrmDisabledProfilesAddMutationHookResult = ReturnType<typeof useCrmDisabledProfilesAddMutation>;
export type CrmDisabledProfilesAddMutationResult = Apollo.MutationResult<CrmDisabledProfilesAddMutation>;
export type CrmDisabledProfilesAddMutationOptions = Apollo.BaseMutationOptions<CrmDisabledProfilesAddMutation, CrmDisabledProfilesAddMutationVariables>;
export const CrmDisabledProfilesRemoveDocument = gql`
    mutation crmDisabledProfilesRemove($id: String!) {
  crmDisabledProfilesRemove(id: $id) {
    success
  }
}
    `;
export type CrmDisabledProfilesRemoveMutationFn = Apollo.MutationFunction<CrmDisabledProfilesRemoveMutation, CrmDisabledProfilesRemoveMutationVariables>;

/**
 * __useCrmDisabledProfilesRemoveMutation__
 *
 * To run a mutation, you first call `useCrmDisabledProfilesRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCrmDisabledProfilesRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [crmDisabledProfilesRemoveMutation, { data, loading, error }] = useCrmDisabledProfilesRemoveMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCrmDisabledProfilesRemoveMutation(baseOptions?: Apollo.MutationHookOptions<CrmDisabledProfilesRemoveMutation, CrmDisabledProfilesRemoveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CrmDisabledProfilesRemoveMutation, CrmDisabledProfilesRemoveMutationVariables>(CrmDisabledProfilesRemoveDocument, options);
      }
export type CrmDisabledProfilesRemoveMutationHookResult = ReturnType<typeof useCrmDisabledProfilesRemoveMutation>;
export type CrmDisabledProfilesRemoveMutationResult = Apollo.MutationResult<CrmDisabledProfilesRemoveMutation>;
export type CrmDisabledProfilesRemoveMutationOptions = Apollo.BaseMutationOptions<CrmDisabledProfilesRemoveMutation, CrmDisabledProfilesRemoveMutationVariables>;
export const SimulateCustomerNavDiscountsDocument = gql`
    mutation simulateCustomerNavDiscounts($input: CustomerNavSimulationsInput!) {
  simulateCustomerNavDiscounts(input: $input) {
    birthdayDiscount
    passagesDiscount
    pointDiscounts {
      points
      discount
    }
    promoCode
  }
}
    `;
export type SimulateCustomerNavDiscountsMutationFn = Apollo.MutationFunction<SimulateCustomerNavDiscountsMutation, SimulateCustomerNavDiscountsMutationVariables>;

/**
 * __useSimulateCustomerNavDiscountsMutation__
 *
 * To run a mutation, you first call `useSimulateCustomerNavDiscountsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSimulateCustomerNavDiscountsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [simulateCustomerNavDiscountsMutation, { data, loading, error }] = useSimulateCustomerNavDiscountsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSimulateCustomerNavDiscountsMutation(baseOptions?: Apollo.MutationHookOptions<SimulateCustomerNavDiscountsMutation, SimulateCustomerNavDiscountsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SimulateCustomerNavDiscountsMutation, SimulateCustomerNavDiscountsMutationVariables>(SimulateCustomerNavDiscountsDocument, options);
      }
export type SimulateCustomerNavDiscountsMutationHookResult = ReturnType<typeof useSimulateCustomerNavDiscountsMutation>;
export type SimulateCustomerNavDiscountsMutationResult = Apollo.MutationResult<SimulateCustomerNavDiscountsMutation>;
export type SimulateCustomerNavDiscountsMutationOptions = Apollo.BaseMutationOptions<SimulateCustomerNavDiscountsMutation, SimulateCustomerNavDiscountsMutationVariables>;
export const SearchCrmLogsDocument = gql`
    query searchCrmLogs($filters: CrmLogsSearchFilter!) {
  searchCrmLogs(filters: $filters) {
    id
    cmrId
    ecommerceId
    userEmail
    url
    method
    requestHeaders
    request
    response
    status
    error
    createdDate
  }
}
    `;

/**
 * __useSearchCrmLogsQuery__
 *
 * To run a query within a React component, call `useSearchCrmLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchCrmLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchCrmLogsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useSearchCrmLogsQuery(baseOptions: Apollo.QueryHookOptions<SearchCrmLogsQuery, SearchCrmLogsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchCrmLogsQuery, SearchCrmLogsQueryVariables>(SearchCrmLogsDocument, options);
      }
export function useSearchCrmLogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchCrmLogsQuery, SearchCrmLogsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchCrmLogsQuery, SearchCrmLogsQueryVariables>(SearchCrmLogsDocument, options);
        }
export type SearchCrmLogsQueryHookResult = ReturnType<typeof useSearchCrmLogsQuery>;
export type SearchCrmLogsLazyQueryHookResult = ReturnType<typeof useSearchCrmLogsLazyQuery>;
export type SearchCrmLogsQueryResult = Apollo.QueryResult<SearchCrmLogsQuery, SearchCrmLogsQueryVariables>;
export const SearchCrmContactDocument = gql`
    query searchCrmContact($term: String!) {
  searchCrmContact(term: $term) {
    ...CrmContactEntityData
  }
}
    ${CrmContactEntityDataFragmentDoc}`;

/**
 * __useSearchCrmContactQuery__
 *
 * To run a query within a React component, call `useSearchCrmContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchCrmContactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchCrmContactQuery({
 *   variables: {
 *      term: // value for 'term'
 *   },
 * });
 */
export function useSearchCrmContactQuery(baseOptions: Apollo.QueryHookOptions<SearchCrmContactQuery, SearchCrmContactQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchCrmContactQuery, SearchCrmContactQueryVariables>(SearchCrmContactDocument, options);
      }
export function useSearchCrmContactLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchCrmContactQuery, SearchCrmContactQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchCrmContactQuery, SearchCrmContactQueryVariables>(SearchCrmContactDocument, options);
        }
export type SearchCrmContactQueryHookResult = ReturnType<typeof useSearchCrmContactQuery>;
export type SearchCrmContactLazyQueryHookResult = ReturnType<typeof useSearchCrmContactLazyQuery>;
export type SearchCrmContactQueryResult = Apollo.QueryResult<SearchCrmContactQuery, SearchCrmContactQueryVariables>;
export const FetchCrmCardDocument = gql`
    query fetchCrmCard($id: String!) {
  fetchCrmCard(id: $id) {
    data
  }
}
    `;

/**
 * __useFetchCrmCardQuery__
 *
 * To run a query within a React component, call `useFetchCrmCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchCrmCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchCrmCardQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchCrmCardQuery(baseOptions: Apollo.QueryHookOptions<FetchCrmCardQuery, FetchCrmCardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchCrmCardQuery, FetchCrmCardQueryVariables>(FetchCrmCardDocument, options);
      }
export function useFetchCrmCardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchCrmCardQuery, FetchCrmCardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchCrmCardQuery, FetchCrmCardQueryVariables>(FetchCrmCardDocument, options);
        }
export type FetchCrmCardQueryHookResult = ReturnType<typeof useFetchCrmCardQuery>;
export type FetchCrmCardLazyQueryHookResult = ReturnType<typeof useFetchCrmCardLazyQuery>;
export type FetchCrmCardQueryResult = Apollo.QueryResult<FetchCrmCardQuery, FetchCrmCardQueryVariables>;
export const FetchCrmContactDocument = gql`
    query fetchCrmContact($filter: String!) {
  fetchCrmContact(filter: $filter) {
    data
  }
}
    `;

/**
 * __useFetchCrmContactQuery__
 *
 * To run a query within a React component, call `useFetchCrmContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchCrmContactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchCrmContactQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useFetchCrmContactQuery(baseOptions: Apollo.QueryHookOptions<FetchCrmContactQuery, FetchCrmContactQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchCrmContactQuery, FetchCrmContactQueryVariables>(FetchCrmContactDocument, options);
      }
export function useFetchCrmContactLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchCrmContactQuery, FetchCrmContactQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchCrmContactQuery, FetchCrmContactQueryVariables>(FetchCrmContactDocument, options);
        }
export type FetchCrmContactQueryHookResult = ReturnType<typeof useFetchCrmContactQuery>;
export type FetchCrmContactLazyQueryHookResult = ReturnType<typeof useFetchCrmContactLazyQuery>;
export type FetchCrmContactQueryResult = Apollo.QueryResult<FetchCrmContactQuery, FetchCrmContactQueryVariables>;
export const CrmDisabledProfilesDocument = gql`
    query crmDisabledProfiles {
  crmDisabledProfiles {
    id
    email
    createdOn
  }
}
    `;

/**
 * __useCrmDisabledProfilesQuery__
 *
 * To run a query within a React component, call `useCrmDisabledProfilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCrmDisabledProfilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCrmDisabledProfilesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCrmDisabledProfilesQuery(baseOptions?: Apollo.QueryHookOptions<CrmDisabledProfilesQuery, CrmDisabledProfilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CrmDisabledProfilesQuery, CrmDisabledProfilesQueryVariables>(CrmDisabledProfilesDocument, options);
      }
export function useCrmDisabledProfilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CrmDisabledProfilesQuery, CrmDisabledProfilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CrmDisabledProfilesQuery, CrmDisabledProfilesQueryVariables>(CrmDisabledProfilesDocument, options);
        }
export type CrmDisabledProfilesQueryHookResult = ReturnType<typeof useCrmDisabledProfilesQuery>;
export type CrmDisabledProfilesLazyQueryHookResult = ReturnType<typeof useCrmDisabledProfilesLazyQuery>;
export type CrmDisabledProfilesQueryResult = Apollo.QueryResult<CrmDisabledProfilesQuery, CrmDisabledProfilesQueryVariables>;
export const AdminGetCustomerDocument = gql`
    query adminGetCustomer($input: AdminGetUserInput!) {
  adminGetCustomer(input: $input) {
    account {
      email
      emailVerified
    }
    userProfile {
      name
      surname
      birthDay
      gender
      taxCode
      phone
      country
      state
      city
      address
      zipCode
    }
    marketingProfile {
      subscribedToNewsletter
      marketingConsent
      profilingConsent
      surveyConsent
      cardId
      cardBalance
      oldCustomer
      hasOldCard
      newTermsAccepted
    }
  }
}
    `;

/**
 * __useAdminGetCustomerQuery__
 *
 * To run a query within a React component, call `useAdminGetCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetCustomerQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminGetCustomerQuery(baseOptions: Apollo.QueryHookOptions<AdminGetCustomerQuery, AdminGetCustomerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminGetCustomerQuery, AdminGetCustomerQueryVariables>(AdminGetCustomerDocument, options);
      }
export function useAdminGetCustomerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminGetCustomerQuery, AdminGetCustomerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminGetCustomerQuery, AdminGetCustomerQueryVariables>(AdminGetCustomerDocument, options);
        }
export type AdminGetCustomerQueryHookResult = ReturnType<typeof useAdminGetCustomerQuery>;
export type AdminGetCustomerLazyQueryHookResult = ReturnType<typeof useAdminGetCustomerLazyQuery>;
export type AdminGetCustomerQueryResult = Apollo.QueryResult<AdminGetCustomerQuery, AdminGetCustomerQueryVariables>;
export const GetNavUserInfoDocument = gql`
    query getNavUserInfo($input: GetNavUserInfoInput!) {
  getNavUserInfo(input: $input) {
    rawData
  }
}
    `;

/**
 * __useGetNavUserInfoQuery__
 *
 * To run a query within a React component, call `useGetNavUserInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNavUserInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNavUserInfoQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetNavUserInfoQuery(baseOptions: Apollo.QueryHookOptions<GetNavUserInfoQuery, GetNavUserInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNavUserInfoQuery, GetNavUserInfoQueryVariables>(GetNavUserInfoDocument, options);
      }
export function useGetNavUserInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNavUserInfoQuery, GetNavUserInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNavUserInfoQuery, GetNavUserInfoQueryVariables>(GetNavUserInfoDocument, options);
        }
export type GetNavUserInfoQueryHookResult = ReturnType<typeof useGetNavUserInfoQuery>;
export type GetNavUserInfoLazyQueryHookResult = ReturnType<typeof useGetNavUserInfoLazyQuery>;
export type GetNavUserInfoQueryResult = Apollo.QueryResult<GetNavUserInfoQuery, GetNavUserInfoQueryVariables>;
export const ImportProductCostsDocument = gql`
    mutation importProductCosts($input: ImportProductCostsInputType!) {
  importProductCosts(input: $input) {
    success
  }
}
    `;
export type ImportProductCostsMutationFn = Apollo.MutationFunction<ImportProductCostsMutation, ImportProductCostsMutationVariables>;

/**
 * __useImportProductCostsMutation__
 *
 * To run a mutation, you first call `useImportProductCostsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportProductCostsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importProductCostsMutation, { data, loading, error }] = useImportProductCostsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportProductCostsMutation(baseOptions?: Apollo.MutationHookOptions<ImportProductCostsMutation, ImportProductCostsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportProductCostsMutation, ImportProductCostsMutationVariables>(ImportProductCostsDocument, options);
      }
export type ImportProductCostsMutationHookResult = ReturnType<typeof useImportProductCostsMutation>;
export type ImportProductCostsMutationResult = Apollo.MutationResult<ImportProductCostsMutation>;
export type ImportProductCostsMutationOptions = Apollo.BaseMutationOptions<ImportProductCostsMutation, ImportProductCostsMutationVariables>;
export const ProcessMinderestFeedDocument = gql`
    mutation processMinderestFeed {
  processMinderestFeed {
    success
  }
}
    `;
export type ProcessMinderestFeedMutationFn = Apollo.MutationFunction<ProcessMinderestFeedMutation, ProcessMinderestFeedMutationVariables>;

/**
 * __useProcessMinderestFeedMutation__
 *
 * To run a mutation, you first call `useProcessMinderestFeedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProcessMinderestFeedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [processMinderestFeedMutation, { data, loading, error }] = useProcessMinderestFeedMutation({
 *   variables: {
 *   },
 * });
 */
export function useProcessMinderestFeedMutation(baseOptions?: Apollo.MutationHookOptions<ProcessMinderestFeedMutation, ProcessMinderestFeedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProcessMinderestFeedMutation, ProcessMinderestFeedMutationVariables>(ProcessMinderestFeedDocument, options);
      }
export type ProcessMinderestFeedMutationHookResult = ReturnType<typeof useProcessMinderestFeedMutation>;
export type ProcessMinderestFeedMutationResult = Apollo.MutationResult<ProcessMinderestFeedMutation>;
export type ProcessMinderestFeedMutationOptions = Apollo.BaseMutationOptions<ProcessMinderestFeedMutation, ProcessMinderestFeedMutationVariables>;
export const ProductFeedCreateDocument = gql`
    mutation productFeedCreate($data: ProductsFeedDefinitionInput!) {
  productFeedCreate(data: $data) {
    ...ProductsFeedDefinitionData
  }
}
    ${ProductsFeedDefinitionDataFragmentDoc}`;
export type ProductFeedCreateMutationFn = Apollo.MutationFunction<ProductFeedCreateMutation, ProductFeedCreateMutationVariables>;

/**
 * __useProductFeedCreateMutation__
 *
 * To run a mutation, you first call `useProductFeedCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductFeedCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productFeedCreateMutation, { data, loading, error }] = useProductFeedCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useProductFeedCreateMutation(baseOptions?: Apollo.MutationHookOptions<ProductFeedCreateMutation, ProductFeedCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProductFeedCreateMutation, ProductFeedCreateMutationVariables>(ProductFeedCreateDocument, options);
      }
export type ProductFeedCreateMutationHookResult = ReturnType<typeof useProductFeedCreateMutation>;
export type ProductFeedCreateMutationResult = Apollo.MutationResult<ProductFeedCreateMutation>;
export type ProductFeedCreateMutationOptions = Apollo.BaseMutationOptions<ProductFeedCreateMutation, ProductFeedCreateMutationVariables>;
export const ProductFeedUpdateDocument = gql`
    mutation productFeedUpdate($id: String!, $data: ProductsFeedDefinitionInput!) {
  productFeedUpdate(id: $id, data: $data) {
    ...ProductsFeedDefinitionData
  }
}
    ${ProductsFeedDefinitionDataFragmentDoc}`;
export type ProductFeedUpdateMutationFn = Apollo.MutationFunction<ProductFeedUpdateMutation, ProductFeedUpdateMutationVariables>;

/**
 * __useProductFeedUpdateMutation__
 *
 * To run a mutation, you first call `useProductFeedUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductFeedUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productFeedUpdateMutation, { data, loading, error }] = useProductFeedUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useProductFeedUpdateMutation(baseOptions?: Apollo.MutationHookOptions<ProductFeedUpdateMutation, ProductFeedUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProductFeedUpdateMutation, ProductFeedUpdateMutationVariables>(ProductFeedUpdateDocument, options);
      }
export type ProductFeedUpdateMutationHookResult = ReturnType<typeof useProductFeedUpdateMutation>;
export type ProductFeedUpdateMutationResult = Apollo.MutationResult<ProductFeedUpdateMutation>;
export type ProductFeedUpdateMutationOptions = Apollo.BaseMutationOptions<ProductFeedUpdateMutation, ProductFeedUpdateMutationVariables>;
export const ProductFeedDeleteDocument = gql`
    mutation productFeedDelete($id: String!) {
  productFeedDelete(id: $id) {
    success
  }
}
    `;
export type ProductFeedDeleteMutationFn = Apollo.MutationFunction<ProductFeedDeleteMutation, ProductFeedDeleteMutationVariables>;

/**
 * __useProductFeedDeleteMutation__
 *
 * To run a mutation, you first call `useProductFeedDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductFeedDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productFeedDeleteMutation, { data, loading, error }] = useProductFeedDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProductFeedDeleteMutation(baseOptions?: Apollo.MutationHookOptions<ProductFeedDeleteMutation, ProductFeedDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProductFeedDeleteMutation, ProductFeedDeleteMutationVariables>(ProductFeedDeleteDocument, options);
      }
export type ProductFeedDeleteMutationHookResult = ReturnType<typeof useProductFeedDeleteMutation>;
export type ProductFeedDeleteMutationResult = Apollo.MutationResult<ProductFeedDeleteMutation>;
export type ProductFeedDeleteMutationOptions = Apollo.BaseMutationOptions<ProductFeedDeleteMutation, ProductFeedDeleteMutationVariables>;
export const ProductFeedEnableDocument = gql`
    mutation productFeedEnable($id: String!) {
  productFeedEnable(id: $id) {
    ...ProductsFeedDefinitionData
  }
}
    ${ProductsFeedDefinitionDataFragmentDoc}`;
export type ProductFeedEnableMutationFn = Apollo.MutationFunction<ProductFeedEnableMutation, ProductFeedEnableMutationVariables>;

/**
 * __useProductFeedEnableMutation__
 *
 * To run a mutation, you first call `useProductFeedEnableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductFeedEnableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productFeedEnableMutation, { data, loading, error }] = useProductFeedEnableMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProductFeedEnableMutation(baseOptions?: Apollo.MutationHookOptions<ProductFeedEnableMutation, ProductFeedEnableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProductFeedEnableMutation, ProductFeedEnableMutationVariables>(ProductFeedEnableDocument, options);
      }
export type ProductFeedEnableMutationHookResult = ReturnType<typeof useProductFeedEnableMutation>;
export type ProductFeedEnableMutationResult = Apollo.MutationResult<ProductFeedEnableMutation>;
export type ProductFeedEnableMutationOptions = Apollo.BaseMutationOptions<ProductFeedEnableMutation, ProductFeedEnableMutationVariables>;
export const ProductFeedDisableDocument = gql`
    mutation productFeedDisable($id: String!) {
  productFeedDisable(id: $id) {
    ...ProductsFeedDefinitionData
  }
}
    ${ProductsFeedDefinitionDataFragmentDoc}`;
export type ProductFeedDisableMutationFn = Apollo.MutationFunction<ProductFeedDisableMutation, ProductFeedDisableMutationVariables>;

/**
 * __useProductFeedDisableMutation__
 *
 * To run a mutation, you first call `useProductFeedDisableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductFeedDisableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productFeedDisableMutation, { data, loading, error }] = useProductFeedDisableMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProductFeedDisableMutation(baseOptions?: Apollo.MutationHookOptions<ProductFeedDisableMutation, ProductFeedDisableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProductFeedDisableMutation, ProductFeedDisableMutationVariables>(ProductFeedDisableDocument, options);
      }
export type ProductFeedDisableMutationHookResult = ReturnType<typeof useProductFeedDisableMutation>;
export type ProductFeedDisableMutationResult = Apollo.MutationResult<ProductFeedDisableMutation>;
export type ProductFeedDisableMutationOptions = Apollo.BaseMutationOptions<ProductFeedDisableMutation, ProductFeedDisableMutationVariables>;
export const ProductsFeedDocument = gql`
    query productsFeed {
  productsFeed {
    ...ProductsFeedDefinitionData
  }
}
    ${ProductsFeedDefinitionDataFragmentDoc}`;

/**
 * __useProductsFeedQuery__
 *
 * To run a query within a React component, call `useProductsFeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsFeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsFeedQuery({
 *   variables: {
 *   },
 * });
 */
export function useProductsFeedQuery(baseOptions?: Apollo.QueryHookOptions<ProductsFeedQuery, ProductsFeedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductsFeedQuery, ProductsFeedQueryVariables>(ProductsFeedDocument, options);
      }
export function useProductsFeedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductsFeedQuery, ProductsFeedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductsFeedQuery, ProductsFeedQueryVariables>(ProductsFeedDocument, options);
        }
export type ProductsFeedQueryHookResult = ReturnType<typeof useProductsFeedQuery>;
export type ProductsFeedLazyQueryHookResult = ReturnType<typeof useProductsFeedLazyQuery>;
export type ProductsFeedQueryResult = Apollo.QueryResult<ProductsFeedQuery, ProductsFeedQueryVariables>;
export const ProductFeedDocument = gql`
    query productFeed($id: String!) {
  productFeed(id: $id) {
    ...ProductsFeedDefinitionData
  }
}
    ${ProductsFeedDefinitionDataFragmentDoc}`;

/**
 * __useProductFeedQuery__
 *
 * To run a query within a React component, call `useProductFeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductFeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductFeedQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProductFeedQuery(baseOptions: Apollo.QueryHookOptions<ProductFeedQuery, ProductFeedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductFeedQuery, ProductFeedQueryVariables>(ProductFeedDocument, options);
      }
export function useProductFeedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductFeedQuery, ProductFeedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductFeedQuery, ProductFeedQueryVariables>(ProductFeedDocument, options);
        }
export type ProductFeedQueryHookResult = ReturnType<typeof useProductFeedQuery>;
export type ProductFeedLazyQueryHookResult = ReturnType<typeof useProductFeedLazyQuery>;
export type ProductFeedQueryResult = Apollo.QueryResult<ProductFeedQuery, ProductFeedQueryVariables>;
export const ServerCacheClearDocument = gql`
    mutation serverCacheClear {
  serverCacheClear {
    success
  }
}
    `;
export type ServerCacheClearMutationFn = Apollo.MutationFunction<ServerCacheClearMutation, ServerCacheClearMutationVariables>;

/**
 * __useServerCacheClearMutation__
 *
 * To run a mutation, you first call `useServerCacheClearMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useServerCacheClearMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [serverCacheClearMutation, { data, loading, error }] = useServerCacheClearMutation({
 *   variables: {
 *   },
 * });
 */
export function useServerCacheClearMutation(baseOptions?: Apollo.MutationHookOptions<ServerCacheClearMutation, ServerCacheClearMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ServerCacheClearMutation, ServerCacheClearMutationVariables>(ServerCacheClearDocument, options);
      }
export type ServerCacheClearMutationHookResult = ReturnType<typeof useServerCacheClearMutation>;
export type ServerCacheClearMutationResult = Apollo.MutationResult<ServerCacheClearMutation>;
export type ServerCacheClearMutationOptions = Apollo.BaseMutationOptions<ServerCacheClearMutation, ServerCacheClearMutationVariables>;
export const UploadToolAssetDocument = gql`
    mutation uploadToolAsset($file: Upload!) {
  uploadToolAsset(file: $file) {
    assetUrl
  }
}
    `;
export type UploadToolAssetMutationFn = Apollo.MutationFunction<UploadToolAssetMutation, UploadToolAssetMutationVariables>;

/**
 * __useUploadToolAssetMutation__
 *
 * To run a mutation, you first call `useUploadToolAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadToolAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadToolAssetMutation, { data, loading, error }] = useUploadToolAssetMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadToolAssetMutation(baseOptions?: Apollo.MutationHookOptions<UploadToolAssetMutation, UploadToolAssetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadToolAssetMutation, UploadToolAssetMutationVariables>(UploadToolAssetDocument, options);
      }
export type UploadToolAssetMutationHookResult = ReturnType<typeof useUploadToolAssetMutation>;
export type UploadToolAssetMutationResult = Apollo.MutationResult<UploadToolAssetMutation>;
export type UploadToolAssetMutationOptions = Apollo.BaseMutationOptions<UploadToolAssetMutation, UploadToolAssetMutationVariables>;
export const CreateCmsTagDocument = gql`
    mutation createCmsTag($input: CreateCmsTagInput!) {
  createCmsTag(input: $input) {
    success
  }
}
    `;
export type CreateCmsTagMutationFn = Apollo.MutationFunction<CreateCmsTagMutation, CreateCmsTagMutationVariables>;

/**
 * __useCreateCmsTagMutation__
 *
 * To run a mutation, you first call `useCreateCmsTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCmsTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCmsTagMutation, { data, loading, error }] = useCreateCmsTagMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCmsTagMutation(baseOptions?: Apollo.MutationHookOptions<CreateCmsTagMutation, CreateCmsTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCmsTagMutation, CreateCmsTagMutationVariables>(CreateCmsTagDocument, options);
      }
export type CreateCmsTagMutationHookResult = ReturnType<typeof useCreateCmsTagMutation>;
export type CreateCmsTagMutationResult = Apollo.MutationResult<CreateCmsTagMutation>;
export type CreateCmsTagMutationOptions = Apollo.BaseMutationOptions<CreateCmsTagMutation, CreateCmsTagMutationVariables>;
export const CreateCmsTagCategoryDocument = gql`
    mutation createCmsTagCategory($input: CreateCmsTagCategoryInput!) {
  createCmsTagCategory(input: $input) {
    success
  }
}
    `;
export type CreateCmsTagCategoryMutationFn = Apollo.MutationFunction<CreateCmsTagCategoryMutation, CreateCmsTagCategoryMutationVariables>;

/**
 * __useCreateCmsTagCategoryMutation__
 *
 * To run a mutation, you first call `useCreateCmsTagCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCmsTagCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCmsTagCategoryMutation, { data, loading, error }] = useCreateCmsTagCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCmsTagCategoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateCmsTagCategoryMutation, CreateCmsTagCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCmsTagCategoryMutation, CreateCmsTagCategoryMutationVariables>(CreateCmsTagCategoryDocument, options);
      }
export type CreateCmsTagCategoryMutationHookResult = ReturnType<typeof useCreateCmsTagCategoryMutation>;
export type CreateCmsTagCategoryMutationResult = Apollo.MutationResult<CreateCmsTagCategoryMutation>;
export type CreateCmsTagCategoryMutationOptions = Apollo.BaseMutationOptions<CreateCmsTagCategoryMutation, CreateCmsTagCategoryMutationVariables>;
export const CreateCmsProductCategoryDocument = gql`
    mutation createCmsProductCategory($input: CreateCmsProductCategoryInput!) {
  createCmsProductCategory(input: $input) {
    success
  }
}
    `;
export type CreateCmsProductCategoryMutationFn = Apollo.MutationFunction<CreateCmsProductCategoryMutation, CreateCmsProductCategoryMutationVariables>;

/**
 * __useCreateCmsProductCategoryMutation__
 *
 * To run a mutation, you first call `useCreateCmsProductCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCmsProductCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCmsProductCategoryMutation, { data, loading, error }] = useCreateCmsProductCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCmsProductCategoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateCmsProductCategoryMutation, CreateCmsProductCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCmsProductCategoryMutation, CreateCmsProductCategoryMutationVariables>(CreateCmsProductCategoryDocument, options);
      }
export type CreateCmsProductCategoryMutationHookResult = ReturnType<typeof useCreateCmsProductCategoryMutation>;
export type CreateCmsProductCategoryMutationResult = Apollo.MutationResult<CreateCmsProductCategoryMutation>;
export type CreateCmsProductCategoryMutationOptions = Apollo.BaseMutationOptions<CreateCmsProductCategoryMutation, CreateCmsProductCategoryMutationVariables>;
export const ExportTagsDocument = gql`
    mutation exportTags {
  exportTags {
    url
  }
}
    `;
export type ExportTagsMutationFn = Apollo.MutationFunction<ExportTagsMutation, ExportTagsMutationVariables>;

/**
 * __useExportTagsMutation__
 *
 * To run a mutation, you first call `useExportTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportTagsMutation, { data, loading, error }] = useExportTagsMutation({
 *   variables: {
 *   },
 * });
 */
export function useExportTagsMutation(baseOptions?: Apollo.MutationHookOptions<ExportTagsMutation, ExportTagsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportTagsMutation, ExportTagsMutationVariables>(ExportTagsDocument, options);
      }
export type ExportTagsMutationHookResult = ReturnType<typeof useExportTagsMutation>;
export type ExportTagsMutationResult = Apollo.MutationResult<ExportTagsMutation>;
export type ExportTagsMutationOptions = Apollo.BaseMutationOptions<ExportTagsMutation, ExportTagsMutationVariables>;
export const ExportTagCategoriesDocument = gql`
    mutation exportTagCategories {
  exportTagCategories {
    url
  }
}
    `;
export type ExportTagCategoriesMutationFn = Apollo.MutationFunction<ExportTagCategoriesMutation, ExportTagCategoriesMutationVariables>;

/**
 * __useExportTagCategoriesMutation__
 *
 * To run a mutation, you first call `useExportTagCategoriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportTagCategoriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportTagCategoriesMutation, { data, loading, error }] = useExportTagCategoriesMutation({
 *   variables: {
 *   },
 * });
 */
export function useExportTagCategoriesMutation(baseOptions?: Apollo.MutationHookOptions<ExportTagCategoriesMutation, ExportTagCategoriesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportTagCategoriesMutation, ExportTagCategoriesMutationVariables>(ExportTagCategoriesDocument, options);
      }
export type ExportTagCategoriesMutationHookResult = ReturnType<typeof useExportTagCategoriesMutation>;
export type ExportTagCategoriesMutationResult = Apollo.MutationResult<ExportTagCategoriesMutation>;
export type ExportTagCategoriesMutationOptions = Apollo.BaseMutationOptions<ExportTagCategoriesMutation, ExportTagCategoriesMutationVariables>;
export const ExportProductCategoriesDocument = gql`
    mutation exportProductCategories($format: ReportFormat!) {
  exportProductCategories(format: $format) {
    url
  }
}
    `;
export type ExportProductCategoriesMutationFn = Apollo.MutationFunction<ExportProductCategoriesMutation, ExportProductCategoriesMutationVariables>;

/**
 * __useExportProductCategoriesMutation__
 *
 * To run a mutation, you first call `useExportProductCategoriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportProductCategoriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportProductCategoriesMutation, { data, loading, error }] = useExportProductCategoriesMutation({
 *   variables: {
 *      format: // value for 'format'
 *   },
 * });
 */
export function useExportProductCategoriesMutation(baseOptions?: Apollo.MutationHookOptions<ExportProductCategoriesMutation, ExportProductCategoriesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportProductCategoriesMutation, ExportProductCategoriesMutationVariables>(ExportProductCategoriesDocument, options);
      }
export type ExportProductCategoriesMutationHookResult = ReturnType<typeof useExportProductCategoriesMutation>;
export type ExportProductCategoriesMutationResult = Apollo.MutationResult<ExportProductCategoriesMutation>;
export type ExportProductCategoriesMutationOptions = Apollo.BaseMutationOptions<ExportProductCategoriesMutation, ExportProductCategoriesMutationVariables>;
export const ToggleProductCategoryStatusDocument = gql`
    mutation toggleProductCategoryStatus($input: ToggleProductCategoryStatusInput!) {
  toggleProductCategoryStatus(input: $input) {
    success
  }
}
    `;
export type ToggleProductCategoryStatusMutationFn = Apollo.MutationFunction<ToggleProductCategoryStatusMutation, ToggleProductCategoryStatusMutationVariables>;

/**
 * __useToggleProductCategoryStatusMutation__
 *
 * To run a mutation, you first call `useToggleProductCategoryStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleProductCategoryStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleProductCategoryStatusMutation, { data, loading, error }] = useToggleProductCategoryStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useToggleProductCategoryStatusMutation(baseOptions?: Apollo.MutationHookOptions<ToggleProductCategoryStatusMutation, ToggleProductCategoryStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleProductCategoryStatusMutation, ToggleProductCategoryStatusMutationVariables>(ToggleProductCategoryStatusDocument, options);
      }
export type ToggleProductCategoryStatusMutationHookResult = ReturnType<typeof useToggleProductCategoryStatusMutation>;
export type ToggleProductCategoryStatusMutationResult = Apollo.MutationResult<ToggleProductCategoryStatusMutation>;
export type ToggleProductCategoryStatusMutationOptions = Apollo.BaseMutationOptions<ToggleProductCategoryStatusMutation, ToggleProductCategoryStatusMutationVariables>;
export const ToggleTagStatusDocument = gql`
    mutation toggleTagStatus($input: ToggleTagStatusInput!) {
  toggleTagStatus(input: $input) {
    success
  }
}
    `;
export type ToggleTagStatusMutationFn = Apollo.MutationFunction<ToggleTagStatusMutation, ToggleTagStatusMutationVariables>;

/**
 * __useToggleTagStatusMutation__
 *
 * To run a mutation, you first call `useToggleTagStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleTagStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleTagStatusMutation, { data, loading, error }] = useToggleTagStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useToggleTagStatusMutation(baseOptions?: Apollo.MutationHookOptions<ToggleTagStatusMutation, ToggleTagStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleTagStatusMutation, ToggleTagStatusMutationVariables>(ToggleTagStatusDocument, options);
      }
export type ToggleTagStatusMutationHookResult = ReturnType<typeof useToggleTagStatusMutation>;
export type ToggleTagStatusMutationResult = Apollo.MutationResult<ToggleTagStatusMutation>;
export type ToggleTagStatusMutationOptions = Apollo.BaseMutationOptions<ToggleTagStatusMutation, ToggleTagStatusMutationVariables>;
export const GetCmsTagsDocument = gql`
    query getCmsTags {
  getCmsTags {
    key
    label
    disabled
  }
}
    `;

/**
 * __useGetCmsTagsQuery__
 *
 * To run a query within a React component, call `useGetCmsTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCmsTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCmsTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCmsTagsQuery(baseOptions?: Apollo.QueryHookOptions<GetCmsTagsQuery, GetCmsTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCmsTagsQuery, GetCmsTagsQueryVariables>(GetCmsTagsDocument, options);
      }
export function useGetCmsTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCmsTagsQuery, GetCmsTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCmsTagsQuery, GetCmsTagsQueryVariables>(GetCmsTagsDocument, options);
        }
export type GetCmsTagsQueryHookResult = ReturnType<typeof useGetCmsTagsQuery>;
export type GetCmsTagsLazyQueryHookResult = ReturnType<typeof useGetCmsTagsLazyQuery>;
export type GetCmsTagsQueryResult = Apollo.QueryResult<GetCmsTagsQuery, GetCmsTagsQueryVariables>;
export const GetCmsTagCategoriesDocument = gql`
    query getCmsTagCategories {
  getCmsTagCategories {
    key
    label
  }
}
    `;

/**
 * __useGetCmsTagCategoriesQuery__
 *
 * To run a query within a React component, call `useGetCmsTagCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCmsTagCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCmsTagCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCmsTagCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetCmsTagCategoriesQuery, GetCmsTagCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCmsTagCategoriesQuery, GetCmsTagCategoriesQueryVariables>(GetCmsTagCategoriesDocument, options);
      }
export function useGetCmsTagCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCmsTagCategoriesQuery, GetCmsTagCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCmsTagCategoriesQuery, GetCmsTagCategoriesQueryVariables>(GetCmsTagCategoriesDocument, options);
        }
export type GetCmsTagCategoriesQueryHookResult = ReturnType<typeof useGetCmsTagCategoriesQuery>;
export type GetCmsTagCategoriesLazyQueryHookResult = ReturnType<typeof useGetCmsTagCategoriesLazyQuery>;
export type GetCmsTagCategoriesQueryResult = Apollo.QueryResult<GetCmsTagCategoriesQuery, GetCmsTagCategoriesQueryVariables>;
export const GetCmsProductCategoriesDocument = gql`
    query getCmsProductCategories {
  getCmsProductCategories {
    key
    label
    disabled
    parent {
      key
      label
      disabled
      parent {
        key
        label
        disabled
        parent {
          key
          label
          disabled
        }
      }
    }
  }
}
    `;

/**
 * __useGetCmsProductCategoriesQuery__
 *
 * To run a query within a React component, call `useGetCmsProductCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCmsProductCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCmsProductCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCmsProductCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetCmsProductCategoriesQuery, GetCmsProductCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCmsProductCategoriesQuery, GetCmsProductCategoriesQueryVariables>(GetCmsProductCategoriesDocument, options);
      }
export function useGetCmsProductCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCmsProductCategoriesQuery, GetCmsProductCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCmsProductCategoriesQuery, GetCmsProductCategoriesQueryVariables>(GetCmsProductCategoriesDocument, options);
        }
export type GetCmsProductCategoriesQueryHookResult = ReturnType<typeof useGetCmsProductCategoriesQuery>;
export type GetCmsProductCategoriesLazyQueryHookResult = ReturnType<typeof useGetCmsProductCategoriesLazyQuery>;
export type GetCmsProductCategoriesQueryResult = Apollo.QueryResult<GetCmsProductCategoriesQuery, GetCmsProductCategoriesQueryVariables>;
export const OrderForceDeliveredDocument = gql`
    mutation orderForceDelivered($orderId: String!) {
  orderForceDelivered(orderId: $orderId) {
    success
  }
}
    `;
export type OrderForceDeliveredMutationFn = Apollo.MutationFunction<OrderForceDeliveredMutation, OrderForceDeliveredMutationVariables>;

/**
 * __useOrderForceDeliveredMutation__
 *
 * To run a mutation, you first call `useOrderForceDeliveredMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderForceDeliveredMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderForceDeliveredMutation, { data, loading, error }] = useOrderForceDeliveredMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useOrderForceDeliveredMutation(baseOptions?: Apollo.MutationHookOptions<OrderForceDeliveredMutation, OrderForceDeliveredMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrderForceDeliveredMutation, OrderForceDeliveredMutationVariables>(OrderForceDeliveredDocument, options);
      }
export type OrderForceDeliveredMutationHookResult = ReturnType<typeof useOrderForceDeliveredMutation>;
export type OrderForceDeliveredMutationResult = Apollo.MutationResult<OrderForceDeliveredMutation>;
export type OrderForceDeliveredMutationOptions = Apollo.BaseMutationOptions<OrderForceDeliveredMutation, OrderForceDeliveredMutationVariables>;
export const OrderForceFulfilledDocument = gql`
    mutation orderForceFulfilled($orderId: String!, $trackingUrl: String!) {
  orderForceFulfilled(orderId: $orderId, trackingUrl: $trackingUrl) {
    success
  }
}
    `;
export type OrderForceFulfilledMutationFn = Apollo.MutationFunction<OrderForceFulfilledMutation, OrderForceFulfilledMutationVariables>;

/**
 * __useOrderForceFulfilledMutation__
 *
 * To run a mutation, you first call `useOrderForceFulfilledMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderForceFulfilledMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderForceFulfilledMutation, { data, loading, error }] = useOrderForceFulfilledMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      trackingUrl: // value for 'trackingUrl'
 *   },
 * });
 */
export function useOrderForceFulfilledMutation(baseOptions?: Apollo.MutationHookOptions<OrderForceFulfilledMutation, OrderForceFulfilledMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrderForceFulfilledMutation, OrderForceFulfilledMutationVariables>(OrderForceFulfilledDocument, options);
      }
export type OrderForceFulfilledMutationHookResult = ReturnType<typeof useOrderForceFulfilledMutation>;
export type OrderForceFulfilledMutationResult = Apollo.MutationResult<OrderForceFulfilledMutation>;
export type OrderForceFulfilledMutationOptions = Apollo.BaseMutationOptions<OrderForceFulfilledMutation, OrderForceFulfilledMutationVariables>;
export const ArchiveOrderDocument = gql`
    mutation archiveOrder($orderId: String!) {
  archiveOrder(orderId: $orderId) {
    success
  }
}
    `;
export type ArchiveOrderMutationFn = Apollo.MutationFunction<ArchiveOrderMutation, ArchiveOrderMutationVariables>;

/**
 * __useArchiveOrderMutation__
 *
 * To run a mutation, you first call `useArchiveOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveOrderMutation, { data, loading, error }] = useArchiveOrderMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useArchiveOrderMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveOrderMutation, ArchiveOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArchiveOrderMutation, ArchiveOrderMutationVariables>(ArchiveOrderDocument, options);
      }
export type ArchiveOrderMutationHookResult = ReturnType<typeof useArchiveOrderMutation>;
export type ArchiveOrderMutationResult = Apollo.MutationResult<ArchiveOrderMutation>;
export type ArchiveOrderMutationOptions = Apollo.BaseMutationOptions<ArchiveOrderMutation, ArchiveOrderMutationVariables>;
export const SyncUnfulfilledOrderDocument = gql`
    mutation syncUnfulfilledOrder($orderId: String!) {
  syncUnfulfilledOrder(orderId: $orderId) {
    success
  }
}
    `;
export type SyncUnfulfilledOrderMutationFn = Apollo.MutationFunction<SyncUnfulfilledOrderMutation, SyncUnfulfilledOrderMutationVariables>;

/**
 * __useSyncUnfulfilledOrderMutation__
 *
 * To run a mutation, you first call `useSyncUnfulfilledOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncUnfulfilledOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncUnfulfilledOrderMutation, { data, loading, error }] = useSyncUnfulfilledOrderMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useSyncUnfulfilledOrderMutation(baseOptions?: Apollo.MutationHookOptions<SyncUnfulfilledOrderMutation, SyncUnfulfilledOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SyncUnfulfilledOrderMutation, SyncUnfulfilledOrderMutationVariables>(SyncUnfulfilledOrderDocument, options);
      }
export type SyncUnfulfilledOrderMutationHookResult = ReturnType<typeof useSyncUnfulfilledOrderMutation>;
export type SyncUnfulfilledOrderMutationResult = Apollo.MutationResult<SyncUnfulfilledOrderMutation>;
export type SyncUnfulfilledOrderMutationOptions = Apollo.BaseMutationOptions<SyncUnfulfilledOrderMutation, SyncUnfulfilledOrderMutationVariables>;
export const ResyncQaplaOrderDocument = gql`
    mutation resyncQaplaOrder($orderId: String!) {
  resyncQaplaOrder(orderId: $orderId) {
    success
  }
}
    `;
export type ResyncQaplaOrderMutationFn = Apollo.MutationFunction<ResyncQaplaOrderMutation, ResyncQaplaOrderMutationVariables>;

/**
 * __useResyncQaplaOrderMutation__
 *
 * To run a mutation, you first call `useResyncQaplaOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResyncQaplaOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resyncQaplaOrderMutation, { data, loading, error }] = useResyncQaplaOrderMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useResyncQaplaOrderMutation(baseOptions?: Apollo.MutationHookOptions<ResyncQaplaOrderMutation, ResyncQaplaOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResyncQaplaOrderMutation, ResyncQaplaOrderMutationVariables>(ResyncQaplaOrderDocument, options);
      }
export type ResyncQaplaOrderMutationHookResult = ReturnType<typeof useResyncQaplaOrderMutation>;
export type ResyncQaplaOrderMutationResult = Apollo.MutationResult<ResyncQaplaOrderMutation>;
export type ResyncQaplaOrderMutationOptions = Apollo.BaseMutationOptions<ResyncQaplaOrderMutation, ResyncQaplaOrderMutationVariables>;
export const SyncOrderShippingDocument = gql`
    mutation syncOrderShipping($orderId: String!) {
  syncOrderShipping(orderId: $orderId) {
    success
  }
}
    `;
export type SyncOrderShippingMutationFn = Apollo.MutationFunction<SyncOrderShippingMutation, SyncOrderShippingMutationVariables>;

/**
 * __useSyncOrderShippingMutation__
 *
 * To run a mutation, you first call `useSyncOrderShippingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncOrderShippingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncOrderShippingMutation, { data, loading, error }] = useSyncOrderShippingMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useSyncOrderShippingMutation(baseOptions?: Apollo.MutationHookOptions<SyncOrderShippingMutation, SyncOrderShippingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SyncOrderShippingMutation, SyncOrderShippingMutationVariables>(SyncOrderShippingDocument, options);
      }
export type SyncOrderShippingMutationHookResult = ReturnType<typeof useSyncOrderShippingMutation>;
export type SyncOrderShippingMutationResult = Apollo.MutationResult<SyncOrderShippingMutation>;
export type SyncOrderShippingMutationOptions = Apollo.BaseMutationOptions<SyncOrderShippingMutation, SyncOrderShippingMutationVariables>;
export const SyncUnfulfilledOrdersDocument = gql`
    mutation syncUnfulfilledOrders {
  syncUnfulfilledOrders {
    success
  }
}
    `;
export type SyncUnfulfilledOrdersMutationFn = Apollo.MutationFunction<SyncUnfulfilledOrdersMutation, SyncUnfulfilledOrdersMutationVariables>;

/**
 * __useSyncUnfulfilledOrdersMutation__
 *
 * To run a mutation, you first call `useSyncUnfulfilledOrdersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncUnfulfilledOrdersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncUnfulfilledOrdersMutation, { data, loading, error }] = useSyncUnfulfilledOrdersMutation({
 *   variables: {
 *   },
 * });
 */
export function useSyncUnfulfilledOrdersMutation(baseOptions?: Apollo.MutationHookOptions<SyncUnfulfilledOrdersMutation, SyncUnfulfilledOrdersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SyncUnfulfilledOrdersMutation, SyncUnfulfilledOrdersMutationVariables>(SyncUnfulfilledOrdersDocument, options);
      }
export type SyncUnfulfilledOrdersMutationHookResult = ReturnType<typeof useSyncUnfulfilledOrdersMutation>;
export type SyncUnfulfilledOrdersMutationResult = Apollo.MutationResult<SyncUnfulfilledOrdersMutation>;
export type SyncUnfulfilledOrdersMutationOptions = Apollo.BaseMutationOptions<SyncUnfulfilledOrdersMutation, SyncUnfulfilledOrdersMutationVariables>;
export const SyncNavOrdersDocument = gql`
    mutation syncNavOrders {
  syncNavOrders {
    success
  }
}
    `;
export type SyncNavOrdersMutationFn = Apollo.MutationFunction<SyncNavOrdersMutation, SyncNavOrdersMutationVariables>;

/**
 * __useSyncNavOrdersMutation__
 *
 * To run a mutation, you first call `useSyncNavOrdersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncNavOrdersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncNavOrdersMutation, { data, loading, error }] = useSyncNavOrdersMutation({
 *   variables: {
 *   },
 * });
 */
export function useSyncNavOrdersMutation(baseOptions?: Apollo.MutationHookOptions<SyncNavOrdersMutation, SyncNavOrdersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SyncNavOrdersMutation, SyncNavOrdersMutationVariables>(SyncNavOrdersDocument, options);
      }
export type SyncNavOrdersMutationHookResult = ReturnType<typeof useSyncNavOrdersMutation>;
export type SyncNavOrdersMutationResult = Apollo.MutationResult<SyncNavOrdersMutation>;
export type SyncNavOrdersMutationOptions = Apollo.BaseMutationOptions<SyncNavOrdersMutation, SyncNavOrdersMutationVariables>;
export const SyncArchivedOrdersDocument = gql`
    mutation syncArchivedOrders {
  syncArchivedOrders {
    success
  }
}
    `;
export type SyncArchivedOrdersMutationFn = Apollo.MutationFunction<SyncArchivedOrdersMutation, SyncArchivedOrdersMutationVariables>;

/**
 * __useSyncArchivedOrdersMutation__
 *
 * To run a mutation, you first call `useSyncArchivedOrdersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncArchivedOrdersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncArchivedOrdersMutation, { data, loading, error }] = useSyncArchivedOrdersMutation({
 *   variables: {
 *   },
 * });
 */
export function useSyncArchivedOrdersMutation(baseOptions?: Apollo.MutationHookOptions<SyncArchivedOrdersMutation, SyncArchivedOrdersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SyncArchivedOrdersMutation, SyncArchivedOrdersMutationVariables>(SyncArchivedOrdersDocument, options);
      }
export type SyncArchivedOrdersMutationHookResult = ReturnType<typeof useSyncArchivedOrdersMutation>;
export type SyncArchivedOrdersMutationResult = Apollo.MutationResult<SyncArchivedOrdersMutation>;
export type SyncArchivedOrdersMutationOptions = Apollo.BaseMutationOptions<SyncArchivedOrdersMutation, SyncArchivedOrdersMutationVariables>;
export const EditOrderDocument = gql`
    mutation editOrder($input: EditOrderInput!, $languageId: String!) {
  editOrder(input: $input, languageId: $languageId) {
    details {
      ...OrderDetailsData
    }
    rawBasket
    rawOrder
  }
}
    ${OrderDetailsDataFragmentDoc}`;
export type EditOrderMutationFn = Apollo.MutationFunction<EditOrderMutation, EditOrderMutationVariables>;

/**
 * __useEditOrderMutation__
 *
 * To run a mutation, you first call `useEditOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editOrderMutation, { data, loading, error }] = useEditOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *      languageId: // value for 'languageId'
 *   },
 * });
 */
export function useEditOrderMutation(baseOptions?: Apollo.MutationHookOptions<EditOrderMutation, EditOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditOrderMutation, EditOrderMutationVariables>(EditOrderDocument, options);
      }
export type EditOrderMutationHookResult = ReturnType<typeof useEditOrderMutation>;
export type EditOrderMutationResult = Apollo.MutationResult<EditOrderMutation>;
export type EditOrderMutationOptions = Apollo.BaseMutationOptions<EditOrderMutation, EditOrderMutationVariables>;
export const OrderTagsModifyDocument = gql`
    mutation orderTagsModify($input: OrderModifyTagsInput!) {
  orderTagsModify(input: $input) {
    success
  }
}
    `;
export type OrderTagsModifyMutationFn = Apollo.MutationFunction<OrderTagsModifyMutation, OrderTagsModifyMutationVariables>;

/**
 * __useOrderTagsModifyMutation__
 *
 * To run a mutation, you first call `useOrderTagsModifyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderTagsModifyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderTagsModifyMutation, { data, loading, error }] = useOrderTagsModifyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrderTagsModifyMutation(baseOptions?: Apollo.MutationHookOptions<OrderTagsModifyMutation, OrderTagsModifyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrderTagsModifyMutation, OrderTagsModifyMutationVariables>(OrderTagsModifyDocument, options);
      }
export type OrderTagsModifyMutationHookResult = ReturnType<typeof useOrderTagsModifyMutation>;
export type OrderTagsModifyMutationResult = Apollo.MutationResult<OrderTagsModifyMutation>;
export type OrderTagsModifyMutationOptions = Apollo.BaseMutationOptions<OrderTagsModifyMutation, OrderTagsModifyMutationVariables>;
export const RefundOrderDocument = gql`
    mutation refundOrder($input: RefundOrderInput!, $languageId: String!) {
  refundOrder(input: $input, languageId: $languageId) {
    details {
      ...OrderDetailsData
    }
    rawBasket
    rawOrder
  }
}
    ${OrderDetailsDataFragmentDoc}`;
export type RefundOrderMutationFn = Apollo.MutationFunction<RefundOrderMutation, RefundOrderMutationVariables>;

/**
 * __useRefundOrderMutation__
 *
 * To run a mutation, you first call `useRefundOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefundOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refundOrderMutation, { data, loading, error }] = useRefundOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *      languageId: // value for 'languageId'
 *   },
 * });
 */
export function useRefundOrderMutation(baseOptions?: Apollo.MutationHookOptions<RefundOrderMutation, RefundOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefundOrderMutation, RefundOrderMutationVariables>(RefundOrderDocument, options);
      }
export type RefundOrderMutationHookResult = ReturnType<typeof useRefundOrderMutation>;
export type RefundOrderMutationResult = Apollo.MutationResult<RefundOrderMutation>;
export type RefundOrderMutationOptions = Apollo.BaseMutationOptions<RefundOrderMutation, RefundOrderMutationVariables>;
export const CreateOrdersReportDocument = gql`
    mutation createOrdersReport($maxDays: Int!) {
  createOrdersReport(maxDays: $maxDays) {
    success
  }
}
    `;
export type CreateOrdersReportMutationFn = Apollo.MutationFunction<CreateOrdersReportMutation, CreateOrdersReportMutationVariables>;

/**
 * __useCreateOrdersReportMutation__
 *
 * To run a mutation, you first call `useCreateOrdersReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrdersReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrdersReportMutation, { data, loading, error }] = useCreateOrdersReportMutation({
 *   variables: {
 *      maxDays: // value for 'maxDays'
 *   },
 * });
 */
export function useCreateOrdersReportMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrdersReportMutation, CreateOrdersReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrdersReportMutation, CreateOrdersReportMutationVariables>(CreateOrdersReportDocument, options);
      }
export type CreateOrdersReportMutationHookResult = ReturnType<typeof useCreateOrdersReportMutation>;
export type CreateOrdersReportMutationResult = Apollo.MutationResult<CreateOrdersReportMutation>;
export type CreateOrdersReportMutationOptions = Apollo.BaseMutationOptions<CreateOrdersReportMutation, CreateOrdersReportMutationVariables>;
export const RefillGiftcardDocument = gql`
    mutation refillGiftcard($input: RefillGiftcardInput!) {
  refillGiftcard(input: $input) {
    success
  }
}
    `;
export type RefillGiftcardMutationFn = Apollo.MutationFunction<RefillGiftcardMutation, RefillGiftcardMutationVariables>;

/**
 * __useRefillGiftcardMutation__
 *
 * To run a mutation, you first call `useRefillGiftcardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefillGiftcardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refillGiftcardMutation, { data, loading, error }] = useRefillGiftcardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRefillGiftcardMutation(baseOptions?: Apollo.MutationHookOptions<RefillGiftcardMutation, RefillGiftcardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefillGiftcardMutation, RefillGiftcardMutationVariables>(RefillGiftcardDocument, options);
      }
export type RefillGiftcardMutationHookResult = ReturnType<typeof useRefillGiftcardMutation>;
export type RefillGiftcardMutationResult = Apollo.MutationResult<RefillGiftcardMutation>;
export type RefillGiftcardMutationOptions = Apollo.BaseMutationOptions<RefillGiftcardMutation, RefillGiftcardMutationVariables>;
export const OrderCancelFromNavDocument = gql`
    mutation orderCancelFromNav($orderId: String!) {
  orderCancelFromNav(orderId: $orderId) {
    success
  }
}
    `;
export type OrderCancelFromNavMutationFn = Apollo.MutationFunction<OrderCancelFromNavMutation, OrderCancelFromNavMutationVariables>;

/**
 * __useOrderCancelFromNavMutation__
 *
 * To run a mutation, you first call `useOrderCancelFromNavMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderCancelFromNavMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderCancelFromNavMutation, { data, loading, error }] = useOrderCancelFromNavMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useOrderCancelFromNavMutation(baseOptions?: Apollo.MutationHookOptions<OrderCancelFromNavMutation, OrderCancelFromNavMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrderCancelFromNavMutation, OrderCancelFromNavMutationVariables>(OrderCancelFromNavDocument, options);
      }
export type OrderCancelFromNavMutationHookResult = ReturnType<typeof useOrderCancelFromNavMutation>;
export type OrderCancelFromNavMutationResult = Apollo.MutationResult<OrderCancelFromNavMutation>;
export type OrderCancelFromNavMutationOptions = Apollo.BaseMutationOptions<OrderCancelFromNavMutation, OrderCancelFromNavMutationVariables>;
export const ProcessReturnFileDocument = gql`
    mutation processReturnFile($file: String!) {
  processReturnFile(file: $file) {
    success
  }
}
    `;
export type ProcessReturnFileMutationFn = Apollo.MutationFunction<ProcessReturnFileMutation, ProcessReturnFileMutationVariables>;

/**
 * __useProcessReturnFileMutation__
 *
 * To run a mutation, you first call `useProcessReturnFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProcessReturnFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [processReturnFileMutation, { data, loading, error }] = useProcessReturnFileMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useProcessReturnFileMutation(baseOptions?: Apollo.MutationHookOptions<ProcessReturnFileMutation, ProcessReturnFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProcessReturnFileMutation, ProcessReturnFileMutationVariables>(ProcessReturnFileDocument, options);
      }
export type ProcessReturnFileMutationHookResult = ReturnType<typeof useProcessReturnFileMutation>;
export type ProcessReturnFileMutationResult = Apollo.MutationResult<ProcessReturnFileMutation>;
export type ProcessReturnFileMutationOptions = Apollo.BaseMutationOptions<ProcessReturnFileMutation, ProcessReturnFileMutationVariables>;
export const ResendOrderInternalNotificationDocument = gql`
    mutation resendOrderInternalNotification($orderId: String!) {
  resendOrderInternalNotification(orderId: $orderId) {
    success
  }
}
    `;
export type ResendOrderInternalNotificationMutationFn = Apollo.MutationFunction<ResendOrderInternalNotificationMutation, ResendOrderInternalNotificationMutationVariables>;

/**
 * __useResendOrderInternalNotificationMutation__
 *
 * To run a mutation, you first call `useResendOrderInternalNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendOrderInternalNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendOrderInternalNotificationMutation, { data, loading, error }] = useResendOrderInternalNotificationMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useResendOrderInternalNotificationMutation(baseOptions?: Apollo.MutationHookOptions<ResendOrderInternalNotificationMutation, ResendOrderInternalNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendOrderInternalNotificationMutation, ResendOrderInternalNotificationMutationVariables>(ResendOrderInternalNotificationDocument, options);
      }
export type ResendOrderInternalNotificationMutationHookResult = ReturnType<typeof useResendOrderInternalNotificationMutation>;
export type ResendOrderInternalNotificationMutationResult = Apollo.MutationResult<ResendOrderInternalNotificationMutation>;
export type ResendOrderInternalNotificationMutationOptions = Apollo.BaseMutationOptions<ResendOrderInternalNotificationMutation, ResendOrderInternalNotificationMutationVariables>;
export const FixBasketEmailDocument = gql`
    mutation fixBasketEmail($input: FixBasketEmailInput!) {
  fixBasketEmail(input: $input) {
    orderId
  }
}
    `;
export type FixBasketEmailMutationFn = Apollo.MutationFunction<FixBasketEmailMutation, FixBasketEmailMutationVariables>;

/**
 * __useFixBasketEmailMutation__
 *
 * To run a mutation, you first call `useFixBasketEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFixBasketEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fixBasketEmailMutation, { data, loading, error }] = useFixBasketEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFixBasketEmailMutation(baseOptions?: Apollo.MutationHookOptions<FixBasketEmailMutation, FixBasketEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FixBasketEmailMutation, FixBasketEmailMutationVariables>(FixBasketEmailDocument, options);
      }
export type FixBasketEmailMutationHookResult = ReturnType<typeof useFixBasketEmailMutation>;
export type FixBasketEmailMutationResult = Apollo.MutationResult<FixBasketEmailMutation>;
export type FixBasketEmailMutationOptions = Apollo.BaseMutationOptions<FixBasketEmailMutation, FixBasketEmailMutationVariables>;
export const RawUpdateBasketDocument = gql`
    mutation rawUpdateBasket($id: String!, $input: UpdateBasketInput!) {
  rawUpdateBasket(id: $id, input: $input) {
    data
  }
}
    `;
export type RawUpdateBasketMutationFn = Apollo.MutationFunction<RawUpdateBasketMutation, RawUpdateBasketMutationVariables>;

/**
 * __useRawUpdateBasketMutation__
 *
 * To run a mutation, you first call `useRawUpdateBasketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRawUpdateBasketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rawUpdateBasketMutation, { data, loading, error }] = useRawUpdateBasketMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRawUpdateBasketMutation(baseOptions?: Apollo.MutationHookOptions<RawUpdateBasketMutation, RawUpdateBasketMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RawUpdateBasketMutation, RawUpdateBasketMutationVariables>(RawUpdateBasketDocument, options);
      }
export type RawUpdateBasketMutationHookResult = ReturnType<typeof useRawUpdateBasketMutation>;
export type RawUpdateBasketMutationResult = Apollo.MutationResult<RawUpdateBasketMutation>;
export type RawUpdateBasketMutationOptions = Apollo.BaseMutationOptions<RawUpdateBasketMutation, RawUpdateBasketMutationVariables>;
export const PaymentVerifyDocument = gql`
    mutation paymentVerify($input: PaymentVerifyInput!) {
  paymentVerify(input: $input) {
    success
    data {
      ...CheckoutData
    }
  }
}
    ${CheckoutDataFragmentDoc}`;
export type PaymentVerifyMutationFn = Apollo.MutationFunction<PaymentVerifyMutation, PaymentVerifyMutationVariables>;

/**
 * __usePaymentVerifyMutation__
 *
 * To run a mutation, you first call `usePaymentVerifyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePaymentVerifyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [paymentVerifyMutation, { data, loading, error }] = usePaymentVerifyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePaymentVerifyMutation(baseOptions?: Apollo.MutationHookOptions<PaymentVerifyMutation, PaymentVerifyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PaymentVerifyMutation, PaymentVerifyMutationVariables>(PaymentVerifyDocument, options);
      }
export type PaymentVerifyMutationHookResult = ReturnType<typeof usePaymentVerifyMutation>;
export type PaymentVerifyMutationResult = Apollo.MutationResult<PaymentVerifyMutation>;
export type PaymentVerifyMutationOptions = Apollo.BaseMutationOptions<PaymentVerifyMutation, PaymentVerifyMutationVariables>;
export const CheckoutCompleteWithoutPaymentDocument = gql`
    mutation checkoutCompleteWithoutPayment($id: String!, $languageId: String!) {
  checkoutCompleteWithoutPayment(languageId: $languageId, id: $id) {
    success
    data {
      ...CheckoutData
    }
  }
}
    ${CheckoutDataFragmentDoc}`;
export type CheckoutCompleteWithoutPaymentMutationFn = Apollo.MutationFunction<CheckoutCompleteWithoutPaymentMutation, CheckoutCompleteWithoutPaymentMutationVariables>;

/**
 * __useCheckoutCompleteWithoutPaymentMutation__
 *
 * To run a mutation, you first call `useCheckoutCompleteWithoutPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckoutCompleteWithoutPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkoutCompleteWithoutPaymentMutation, { data, loading, error }] = useCheckoutCompleteWithoutPaymentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      languageId: // value for 'languageId'
 *   },
 * });
 */
export function useCheckoutCompleteWithoutPaymentMutation(baseOptions?: Apollo.MutationHookOptions<CheckoutCompleteWithoutPaymentMutation, CheckoutCompleteWithoutPaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CheckoutCompleteWithoutPaymentMutation, CheckoutCompleteWithoutPaymentMutationVariables>(CheckoutCompleteWithoutPaymentDocument, options);
      }
export type CheckoutCompleteWithoutPaymentMutationHookResult = ReturnType<typeof useCheckoutCompleteWithoutPaymentMutation>;
export type CheckoutCompleteWithoutPaymentMutationResult = Apollo.MutationResult<CheckoutCompleteWithoutPaymentMutation>;
export type CheckoutCompleteWithoutPaymentMutationOptions = Apollo.BaseMutationOptions<CheckoutCompleteWithoutPaymentMutation, CheckoutCompleteWithoutPaymentMutationVariables>;
export const ShopifyOrderMigrateDocument = gql`
    mutation shopifyOrderMigrate($input: ShopifyOrderMigrationInput!) {
  shopifyOrderMigrate(input: $input) {
    success
  }
}
    `;
export type ShopifyOrderMigrateMutationFn = Apollo.MutationFunction<ShopifyOrderMigrateMutation, ShopifyOrderMigrateMutationVariables>;

/**
 * __useShopifyOrderMigrateMutation__
 *
 * To run a mutation, you first call `useShopifyOrderMigrateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShopifyOrderMigrateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shopifyOrderMigrateMutation, { data, loading, error }] = useShopifyOrderMigrateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useShopifyOrderMigrateMutation(baseOptions?: Apollo.MutationHookOptions<ShopifyOrderMigrateMutation, ShopifyOrderMigrateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ShopifyOrderMigrateMutation, ShopifyOrderMigrateMutationVariables>(ShopifyOrderMigrateDocument, options);
      }
export type ShopifyOrderMigrateMutationHookResult = ReturnType<typeof useShopifyOrderMigrateMutation>;
export type ShopifyOrderMigrateMutationResult = Apollo.MutationResult<ShopifyOrderMigrateMutation>;
export type ShopifyOrderMigrateMutationOptions = Apollo.BaseMutationOptions<ShopifyOrderMigrateMutation, ShopifyOrderMigrateMutationVariables>;
export const ShopifyOrdersMigrateBatchDocument = gql`
    mutation shopifyOrdersMigrateBatch($input: MigrateShopifyOrderJobInput!) {
  shopifyOrdersMigrateBatch(input: $input) {
    success
  }
}
    `;
export type ShopifyOrdersMigrateBatchMutationFn = Apollo.MutationFunction<ShopifyOrdersMigrateBatchMutation, ShopifyOrdersMigrateBatchMutationVariables>;

/**
 * __useShopifyOrdersMigrateBatchMutation__
 *
 * To run a mutation, you first call `useShopifyOrdersMigrateBatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShopifyOrdersMigrateBatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shopifyOrdersMigrateBatchMutation, { data, loading, error }] = useShopifyOrdersMigrateBatchMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useShopifyOrdersMigrateBatchMutation(baseOptions?: Apollo.MutationHookOptions<ShopifyOrdersMigrateBatchMutation, ShopifyOrdersMigrateBatchMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ShopifyOrdersMigrateBatchMutation, ShopifyOrdersMigrateBatchMutationVariables>(ShopifyOrdersMigrateBatchDocument, options);
      }
export type ShopifyOrdersMigrateBatchMutationHookResult = ReturnType<typeof useShopifyOrdersMigrateBatchMutation>;
export type ShopifyOrdersMigrateBatchMutationResult = Apollo.MutationResult<ShopifyOrdersMigrateBatchMutation>;
export type ShopifyOrdersMigrateBatchMutationOptions = Apollo.BaseMutationOptions<ShopifyOrdersMigrateBatchMutation, ShopifyOrdersMigrateBatchMutationVariables>;
export const OrderEmailChangeDocument = gql`
    mutation orderEmailChange($input: OrderEmailChangeInput!) {
  orderEmailChange(input: $input) {
    success
  }
}
    `;
export type OrderEmailChangeMutationFn = Apollo.MutationFunction<OrderEmailChangeMutation, OrderEmailChangeMutationVariables>;

/**
 * __useOrderEmailChangeMutation__
 *
 * To run a mutation, you first call `useOrderEmailChangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderEmailChangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderEmailChangeMutation, { data, loading, error }] = useOrderEmailChangeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrderEmailChangeMutation(baseOptions?: Apollo.MutationHookOptions<OrderEmailChangeMutation, OrderEmailChangeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrderEmailChangeMutation, OrderEmailChangeMutationVariables>(OrderEmailChangeDocument, options);
      }
export type OrderEmailChangeMutationHookResult = ReturnType<typeof useOrderEmailChangeMutation>;
export type OrderEmailChangeMutationResult = Apollo.MutationResult<OrderEmailChangeMutation>;
export type OrderEmailChangeMutationOptions = Apollo.BaseMutationOptions<OrderEmailChangeMutation, OrderEmailChangeMutationVariables>;
export const GetOrdersDocument = gql`
    query getOrders($input: GetOrdersInput!) {
  getOrders(input: $input) {
    orders {
      ...OrderInfoData
    }
    hasNextPage
    cursor
  }
}
    ${OrderInfoDataFragmentDoc}`;

/**
 * __useGetOrdersQuery__
 *
 * To run a query within a React component, call `useGetOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrdersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetOrdersQuery(baseOptions: Apollo.QueryHookOptions<GetOrdersQuery, GetOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrdersQuery, GetOrdersQueryVariables>(GetOrdersDocument, options);
      }
export function useGetOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrdersQuery, GetOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrdersQuery, GetOrdersQueryVariables>(GetOrdersDocument, options);
        }
export type GetOrdersQueryHookResult = ReturnType<typeof useGetOrdersQuery>;
export type GetOrdersLazyQueryHookResult = ReturnType<typeof useGetOrdersLazyQuery>;
export type GetOrdersQueryResult = Apollo.QueryResult<GetOrdersQuery, GetOrdersQueryVariables>;
export const GetOrderDocument = gql`
    query getOrder($id: String!, $languageId: String!) {
  getOrder(id: $id, languageId: $languageId) {
    details {
      ...OrderDetailsData
    }
    payment {
      ...OrderPaymentDetailsData
    }
    rawBasket
    rawOrder
  }
}
    ${OrderDetailsDataFragmentDoc}
${OrderPaymentDetailsDataFragmentDoc}`;

/**
 * __useGetOrderQuery__
 *
 * To run a query within a React component, call `useGetOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderQuery({
 *   variables: {
 *      id: // value for 'id'
 *      languageId: // value for 'languageId'
 *   },
 * });
 */
export function useGetOrderQuery(baseOptions: Apollo.QueryHookOptions<GetOrderQuery, GetOrderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrderQuery, GetOrderQueryVariables>(GetOrderDocument, options);
      }
export function useGetOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrderQuery, GetOrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrderQuery, GetOrderQueryVariables>(GetOrderDocument, options);
        }
export type GetOrderQueryHookResult = ReturnType<typeof useGetOrderQuery>;
export type GetOrderLazyQueryHookResult = ReturnType<typeof useGetOrderLazyQuery>;
export type GetOrderQueryResult = Apollo.QueryResult<GetOrderQuery, GetOrderQueryVariables>;
export const GetGiftcardBalanceDocument = gql`
    query getGiftcardBalance($input: GetGiftcardBalanceInput!) {
  getGiftcardBalance(input: $input) {
    balance
    giftcardNumber
  }
}
    `;

/**
 * __useGetGiftcardBalanceQuery__
 *
 * To run a query within a React component, call `useGetGiftcardBalanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGiftcardBalanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGiftcardBalanceQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetGiftcardBalanceQuery(baseOptions: Apollo.QueryHookOptions<GetGiftcardBalanceQuery, GetGiftcardBalanceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGiftcardBalanceQuery, GetGiftcardBalanceQueryVariables>(GetGiftcardBalanceDocument, options);
      }
export function useGetGiftcardBalanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGiftcardBalanceQuery, GetGiftcardBalanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGiftcardBalanceQuery, GetGiftcardBalanceQueryVariables>(GetGiftcardBalanceDocument, options);
        }
export type GetGiftcardBalanceQueryHookResult = ReturnType<typeof useGetGiftcardBalanceQuery>;
export type GetGiftcardBalanceLazyQueryHookResult = ReturnType<typeof useGetGiftcardBalanceLazyQuery>;
export type GetGiftcardBalanceQueryResult = Apollo.QueryResult<GetGiftcardBalanceQuery, GetGiftcardBalanceQueryVariables>;
export const SearchBasketDocument = gql`
    query searchBasket($query: String!) {
  searchBasket(query: $query) {
    data
  }
}
    `;

/**
 * __useSearchBasketQuery__
 *
 * To run a query within a React component, call `useSearchBasketQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchBasketQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchBasketQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSearchBasketQuery(baseOptions: Apollo.QueryHookOptions<SearchBasketQuery, SearchBasketQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchBasketQuery, SearchBasketQueryVariables>(SearchBasketDocument, options);
      }
export function useSearchBasketLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchBasketQuery, SearchBasketQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchBasketQuery, SearchBasketQueryVariables>(SearchBasketDocument, options);
        }
export type SearchBasketQueryHookResult = ReturnType<typeof useSearchBasketQuery>;
export type SearchBasketLazyQueryHookResult = ReturnType<typeof useSearchBasketLazyQuery>;
export type SearchBasketQueryResult = Apollo.QueryResult<SearchBasketQuery, SearchBasketQueryVariables>;
export const ParkodBrandMappingsUploadDocument = gql`
    mutation parkodBrandMappingsUpload($file: Upload!) {
  parkodBrandMappingsUpload(file: $file) {
    success
  }
}
    `;
export type ParkodBrandMappingsUploadMutationFn = Apollo.MutationFunction<ParkodBrandMappingsUploadMutation, ParkodBrandMappingsUploadMutationVariables>;

/**
 * __useParkodBrandMappingsUploadMutation__
 *
 * To run a mutation, you first call `useParkodBrandMappingsUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useParkodBrandMappingsUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [parkodBrandMappingsUploadMutation, { data, loading, error }] = useParkodBrandMappingsUploadMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useParkodBrandMappingsUploadMutation(baseOptions?: Apollo.MutationHookOptions<ParkodBrandMappingsUploadMutation, ParkodBrandMappingsUploadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ParkodBrandMappingsUploadMutation, ParkodBrandMappingsUploadMutationVariables>(ParkodBrandMappingsUploadDocument, options);
      }
export type ParkodBrandMappingsUploadMutationHookResult = ReturnType<typeof useParkodBrandMappingsUploadMutation>;
export type ParkodBrandMappingsUploadMutationResult = Apollo.MutationResult<ParkodBrandMappingsUploadMutation>;
export type ParkodBrandMappingsUploadMutationOptions = Apollo.BaseMutationOptions<ParkodBrandMappingsUploadMutation, ParkodBrandMappingsUploadMutationVariables>;
export const ParkodAxisMappingsUploadDocument = gql`
    mutation parkodAxisMappingsUpload($file: Upload!) {
  parkodAxisMappingsUpload(file: $file) {
    success
  }
}
    `;
export type ParkodAxisMappingsUploadMutationFn = Apollo.MutationFunction<ParkodAxisMappingsUploadMutation, ParkodAxisMappingsUploadMutationVariables>;

/**
 * __useParkodAxisMappingsUploadMutation__
 *
 * To run a mutation, you first call `useParkodAxisMappingsUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useParkodAxisMappingsUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [parkodAxisMappingsUploadMutation, { data, loading, error }] = useParkodAxisMappingsUploadMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useParkodAxisMappingsUploadMutation(baseOptions?: Apollo.MutationHookOptions<ParkodAxisMappingsUploadMutation, ParkodAxisMappingsUploadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ParkodAxisMappingsUploadMutation, ParkodAxisMappingsUploadMutationVariables>(ParkodAxisMappingsUploadDocument, options);
      }
export type ParkodAxisMappingsUploadMutationHookResult = ReturnType<typeof useParkodAxisMappingsUploadMutation>;
export type ParkodAxisMappingsUploadMutationResult = Apollo.MutationResult<ParkodAxisMappingsUploadMutation>;
export type ParkodAxisMappingsUploadMutationOptions = Apollo.BaseMutationOptions<ParkodAxisMappingsUploadMutation, ParkodAxisMappingsUploadMutationVariables>;
export const ParkodCategoryMappingsUploadDocument = gql`
    mutation parkodCategoryMappingsUpload($file: Upload!) {
  parkodCategoryMappingsUpload(file: $file) {
    success
  }
}
    `;
export type ParkodCategoryMappingsUploadMutationFn = Apollo.MutationFunction<ParkodCategoryMappingsUploadMutation, ParkodCategoryMappingsUploadMutationVariables>;

/**
 * __useParkodCategoryMappingsUploadMutation__
 *
 * To run a mutation, you first call `useParkodCategoryMappingsUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useParkodCategoryMappingsUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [parkodCategoryMappingsUploadMutation, { data, loading, error }] = useParkodCategoryMappingsUploadMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useParkodCategoryMappingsUploadMutation(baseOptions?: Apollo.MutationHookOptions<ParkodCategoryMappingsUploadMutation, ParkodCategoryMappingsUploadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ParkodCategoryMappingsUploadMutation, ParkodCategoryMappingsUploadMutationVariables>(ParkodCategoryMappingsUploadDocument, options);
      }
export type ParkodCategoryMappingsUploadMutationHookResult = ReturnType<typeof useParkodCategoryMappingsUploadMutation>;
export type ParkodCategoryMappingsUploadMutationResult = Apollo.MutationResult<ParkodCategoryMappingsUploadMutation>;
export type ParkodCategoryMappingsUploadMutationOptions = Apollo.BaseMutationOptions<ParkodCategoryMappingsUploadMutation, ParkodCategoryMappingsUploadMutationVariables>;
export const ParkodCategoryMappingsDownloadDocument = gql`
    mutation parkodCategoryMappingsDownload {
  parkodCategoryMappingsDownload {
    url
  }
}
    `;
export type ParkodCategoryMappingsDownloadMutationFn = Apollo.MutationFunction<ParkodCategoryMappingsDownloadMutation, ParkodCategoryMappingsDownloadMutationVariables>;

/**
 * __useParkodCategoryMappingsDownloadMutation__
 *
 * To run a mutation, you first call `useParkodCategoryMappingsDownloadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useParkodCategoryMappingsDownloadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [parkodCategoryMappingsDownloadMutation, { data, loading, error }] = useParkodCategoryMappingsDownloadMutation({
 *   variables: {
 *   },
 * });
 */
export function useParkodCategoryMappingsDownloadMutation(baseOptions?: Apollo.MutationHookOptions<ParkodCategoryMappingsDownloadMutation, ParkodCategoryMappingsDownloadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ParkodCategoryMappingsDownloadMutation, ParkodCategoryMappingsDownloadMutationVariables>(ParkodCategoryMappingsDownloadDocument, options);
      }
export type ParkodCategoryMappingsDownloadMutationHookResult = ReturnType<typeof useParkodCategoryMappingsDownloadMutation>;
export type ParkodCategoryMappingsDownloadMutationResult = Apollo.MutationResult<ParkodCategoryMappingsDownloadMutation>;
export type ParkodCategoryMappingsDownloadMutationOptions = Apollo.BaseMutationOptions<ParkodCategoryMappingsDownloadMutation, ParkodCategoryMappingsDownloadMutationVariables>;
export const ParkodBrandMappingsDownloadDocument = gql`
    mutation parkodBrandMappingsDownload {
  parkodBrandMappingsDownload {
    url
  }
}
    `;
export type ParkodBrandMappingsDownloadMutationFn = Apollo.MutationFunction<ParkodBrandMappingsDownloadMutation, ParkodBrandMappingsDownloadMutationVariables>;

/**
 * __useParkodBrandMappingsDownloadMutation__
 *
 * To run a mutation, you first call `useParkodBrandMappingsDownloadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useParkodBrandMappingsDownloadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [parkodBrandMappingsDownloadMutation, { data, loading, error }] = useParkodBrandMappingsDownloadMutation({
 *   variables: {
 *   },
 * });
 */
export function useParkodBrandMappingsDownloadMutation(baseOptions?: Apollo.MutationHookOptions<ParkodBrandMappingsDownloadMutation, ParkodBrandMappingsDownloadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ParkodBrandMappingsDownloadMutation, ParkodBrandMappingsDownloadMutationVariables>(ParkodBrandMappingsDownloadDocument, options);
      }
export type ParkodBrandMappingsDownloadMutationHookResult = ReturnType<typeof useParkodBrandMappingsDownloadMutation>;
export type ParkodBrandMappingsDownloadMutationResult = Apollo.MutationResult<ParkodBrandMappingsDownloadMutation>;
export type ParkodBrandMappingsDownloadMutationOptions = Apollo.BaseMutationOptions<ParkodBrandMappingsDownloadMutation, ParkodBrandMappingsDownloadMutationVariables>;
export const ParkodAxisMappingsDownloadDocument = gql`
    mutation parkodAxisMappingsDownload {
  parkodAxisMappingsDownload {
    url
  }
}
    `;
export type ParkodAxisMappingsDownloadMutationFn = Apollo.MutationFunction<ParkodAxisMappingsDownloadMutation, ParkodAxisMappingsDownloadMutationVariables>;

/**
 * __useParkodAxisMappingsDownloadMutation__
 *
 * To run a mutation, you first call `useParkodAxisMappingsDownloadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useParkodAxisMappingsDownloadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [parkodAxisMappingsDownloadMutation, { data, loading, error }] = useParkodAxisMappingsDownloadMutation({
 *   variables: {
 *   },
 * });
 */
export function useParkodAxisMappingsDownloadMutation(baseOptions?: Apollo.MutationHookOptions<ParkodAxisMappingsDownloadMutation, ParkodAxisMappingsDownloadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ParkodAxisMappingsDownloadMutation, ParkodAxisMappingsDownloadMutationVariables>(ParkodAxisMappingsDownloadDocument, options);
      }
export type ParkodAxisMappingsDownloadMutationHookResult = ReturnType<typeof useParkodAxisMappingsDownloadMutation>;
export type ParkodAxisMappingsDownloadMutationResult = Apollo.MutationResult<ParkodAxisMappingsDownloadMutation>;
export type ParkodAxisMappingsDownloadMutationOptions = Apollo.BaseMutationOptions<ParkodAxisMappingsDownloadMutation, ParkodAxisMappingsDownloadMutationVariables>;
export const ParkodDataGenerateDocument = gql`
    mutation parkodDataGenerate($file: Upload!, $input: ParkodMapDataInput!) {
  parkodDataGenerate(file: $file, input: $input) {
    url
  }
}
    `;
export type ParkodDataGenerateMutationFn = Apollo.MutationFunction<ParkodDataGenerateMutation, ParkodDataGenerateMutationVariables>;

/**
 * __useParkodDataGenerateMutation__
 *
 * To run a mutation, you first call `useParkodDataGenerateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useParkodDataGenerateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [parkodDataGenerateMutation, { data, loading, error }] = useParkodDataGenerateMutation({
 *   variables: {
 *      file: // value for 'file'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useParkodDataGenerateMutation(baseOptions?: Apollo.MutationHookOptions<ParkodDataGenerateMutation, ParkodDataGenerateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ParkodDataGenerateMutation, ParkodDataGenerateMutationVariables>(ParkodDataGenerateDocument, options);
      }
export type ParkodDataGenerateMutationHookResult = ReturnType<typeof useParkodDataGenerateMutation>;
export type ParkodDataGenerateMutationResult = Apollo.MutationResult<ParkodDataGenerateMutation>;
export type ParkodDataGenerateMutationOptions = Apollo.BaseMutationOptions<ParkodDataGenerateMutation, ParkodDataGenerateMutationVariables>;
export const AdminPaymentInitDocument = gql`
    mutation adminPaymentInit($input: AdminPaymentInitInput!) {
  adminPaymentInit(input: $input) {
    data
  }
}
    `;
export type AdminPaymentInitMutationFn = Apollo.MutationFunction<AdminPaymentInitMutation, AdminPaymentInitMutationVariables>;

/**
 * __useAdminPaymentInitMutation__
 *
 * To run a mutation, you first call `useAdminPaymentInitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminPaymentInitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminPaymentInitMutation, { data, loading, error }] = useAdminPaymentInitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminPaymentInitMutation(baseOptions?: Apollo.MutationHookOptions<AdminPaymentInitMutation, AdminPaymentInitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminPaymentInitMutation, AdminPaymentInitMutationVariables>(AdminPaymentInitDocument, options);
      }
export type AdminPaymentInitMutationHookResult = ReturnType<typeof useAdminPaymentInitMutation>;
export type AdminPaymentInitMutationResult = Apollo.MutationResult<AdminPaymentInitMutation>;
export type AdminPaymentInitMutationOptions = Apollo.BaseMutationOptions<AdminPaymentInitMutation, AdminPaymentInitMutationVariables>;
export const AdminPaymentVerifyDocument = gql`
    mutation adminPaymentVerify($input: AdminPaymentVerifyInput!) {
  adminPaymentVerify(input: $input) {
    data
  }
}
    `;
export type AdminPaymentVerifyMutationFn = Apollo.MutationFunction<AdminPaymentVerifyMutation, AdminPaymentVerifyMutationVariables>;

/**
 * __useAdminPaymentVerifyMutation__
 *
 * To run a mutation, you first call `useAdminPaymentVerifyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminPaymentVerifyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminPaymentVerifyMutation, { data, loading, error }] = useAdminPaymentVerifyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminPaymentVerifyMutation(baseOptions?: Apollo.MutationHookOptions<AdminPaymentVerifyMutation, AdminPaymentVerifyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminPaymentVerifyMutation, AdminPaymentVerifyMutationVariables>(AdminPaymentVerifyDocument, options);
      }
export type AdminPaymentVerifyMutationHookResult = ReturnType<typeof useAdminPaymentVerifyMutation>;
export type AdminPaymentVerifyMutationResult = Apollo.MutationResult<AdminPaymentVerifyMutation>;
export type AdminPaymentVerifyMutationOptions = Apollo.BaseMutationOptions<AdminPaymentVerifyMutation, AdminPaymentVerifyMutationVariables>;
export const AdminPaymentRefundDocument = gql`
    mutation adminPaymentRefund($input: AdminPaymentRefundInput!) {
  adminPaymentRefund(input: $input) {
    data
  }
}
    `;
export type AdminPaymentRefundMutationFn = Apollo.MutationFunction<AdminPaymentRefundMutation, AdminPaymentRefundMutationVariables>;

/**
 * __useAdminPaymentRefundMutation__
 *
 * To run a mutation, you first call `useAdminPaymentRefundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminPaymentRefundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminPaymentRefundMutation, { data, loading, error }] = useAdminPaymentRefundMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminPaymentRefundMutation(baseOptions?: Apollo.MutationHookOptions<AdminPaymentRefundMutation, AdminPaymentRefundMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminPaymentRefundMutation, AdminPaymentRefundMutationVariables>(AdminPaymentRefundDocument, options);
      }
export type AdminPaymentRefundMutationHookResult = ReturnType<typeof useAdminPaymentRefundMutation>;
export type AdminPaymentRefundMutationResult = Apollo.MutationResult<AdminPaymentRefundMutation>;
export type AdminPaymentRefundMutationOptions = Apollo.BaseMutationOptions<AdminPaymentRefundMutation, AdminPaymentRefundMutationVariables>;
export const AdminPaymentGetDocument = gql`
    mutation adminPaymentGet($input: AdminPaymentGetInput!) {
  adminPaymentGet(input: $input) {
    data
  }
}
    `;
export type AdminPaymentGetMutationFn = Apollo.MutationFunction<AdminPaymentGetMutation, AdminPaymentGetMutationVariables>;

/**
 * __useAdminPaymentGetMutation__
 *
 * To run a mutation, you first call `useAdminPaymentGetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminPaymentGetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminPaymentGetMutation, { data, loading, error }] = useAdminPaymentGetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminPaymentGetMutation(baseOptions?: Apollo.MutationHookOptions<AdminPaymentGetMutation, AdminPaymentGetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminPaymentGetMutation, AdminPaymentGetMutationVariables>(AdminPaymentGetDocument, options);
      }
export type AdminPaymentGetMutationHookResult = ReturnType<typeof useAdminPaymentGetMutation>;
export type AdminPaymentGetMutationResult = Apollo.MutationResult<AdminPaymentGetMutation>;
export type AdminPaymentGetMutationOptions = Apollo.BaseMutationOptions<AdminPaymentGetMutation, AdminPaymentGetMutationVariables>;
export const EnablePriceRuleDocument = gql`
    mutation enablePriceRule($id: String!) {
  enablePriceRule(id: $id) {
    ...PriceRuleData
  }
}
    ${PriceRuleDataFragmentDoc}`;
export type EnablePriceRuleMutationFn = Apollo.MutationFunction<EnablePriceRuleMutation, EnablePriceRuleMutationVariables>;

/**
 * __useEnablePriceRuleMutation__
 *
 * To run a mutation, you first call `useEnablePriceRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnablePriceRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enablePriceRuleMutation, { data, loading, error }] = useEnablePriceRuleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEnablePriceRuleMutation(baseOptions?: Apollo.MutationHookOptions<EnablePriceRuleMutation, EnablePriceRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EnablePriceRuleMutation, EnablePriceRuleMutationVariables>(EnablePriceRuleDocument, options);
      }
export type EnablePriceRuleMutationHookResult = ReturnType<typeof useEnablePriceRuleMutation>;
export type EnablePriceRuleMutationResult = Apollo.MutationResult<EnablePriceRuleMutation>;
export type EnablePriceRuleMutationOptions = Apollo.BaseMutationOptions<EnablePriceRuleMutation, EnablePriceRuleMutationVariables>;
export const DisablePriceRuleDocument = gql`
    mutation disablePriceRule($id: String!) {
  disablePriceRule(id: $id) {
    ...PriceRuleData
  }
}
    ${PriceRuleDataFragmentDoc}`;
export type DisablePriceRuleMutationFn = Apollo.MutationFunction<DisablePriceRuleMutation, DisablePriceRuleMutationVariables>;

/**
 * __useDisablePriceRuleMutation__
 *
 * To run a mutation, you first call `useDisablePriceRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisablePriceRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disablePriceRuleMutation, { data, loading, error }] = useDisablePriceRuleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDisablePriceRuleMutation(baseOptions?: Apollo.MutationHookOptions<DisablePriceRuleMutation, DisablePriceRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DisablePriceRuleMutation, DisablePriceRuleMutationVariables>(DisablePriceRuleDocument, options);
      }
export type DisablePriceRuleMutationHookResult = ReturnType<typeof useDisablePriceRuleMutation>;
export type DisablePriceRuleMutationResult = Apollo.MutationResult<DisablePriceRuleMutation>;
export type DisablePriceRuleMutationOptions = Apollo.BaseMutationOptions<DisablePriceRuleMutation, DisablePriceRuleMutationVariables>;
export const CreatePriceRuleDocument = gql`
    mutation createPriceRule($input: PriceRuleInput!) {
  createPriceRule(input: $input) {
    ...PriceRuleData
  }
}
    ${PriceRuleDataFragmentDoc}`;
export type CreatePriceRuleMutationFn = Apollo.MutationFunction<CreatePriceRuleMutation, CreatePriceRuleMutationVariables>;

/**
 * __useCreatePriceRuleMutation__
 *
 * To run a mutation, you first call `useCreatePriceRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePriceRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPriceRuleMutation, { data, loading, error }] = useCreatePriceRuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePriceRuleMutation(baseOptions?: Apollo.MutationHookOptions<CreatePriceRuleMutation, CreatePriceRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePriceRuleMutation, CreatePriceRuleMutationVariables>(CreatePriceRuleDocument, options);
      }
export type CreatePriceRuleMutationHookResult = ReturnType<typeof useCreatePriceRuleMutation>;
export type CreatePriceRuleMutationResult = Apollo.MutationResult<CreatePriceRuleMutation>;
export type CreatePriceRuleMutationOptions = Apollo.BaseMutationOptions<CreatePriceRuleMutation, CreatePriceRuleMutationVariables>;
export const UpdatePriceRuleDocument = gql`
    mutation updatePriceRule($input: PriceRuleInput!) {
  updatePriceRule(input: $input) {
    ...PriceRuleData
  }
}
    ${PriceRuleDataFragmentDoc}`;
export type UpdatePriceRuleMutationFn = Apollo.MutationFunction<UpdatePriceRuleMutation, UpdatePriceRuleMutationVariables>;

/**
 * __useUpdatePriceRuleMutation__
 *
 * To run a mutation, you first call `useUpdatePriceRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePriceRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePriceRuleMutation, { data, loading, error }] = useUpdatePriceRuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePriceRuleMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePriceRuleMutation, UpdatePriceRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePriceRuleMutation, UpdatePriceRuleMutationVariables>(UpdatePriceRuleDocument, options);
      }
export type UpdatePriceRuleMutationHookResult = ReturnType<typeof useUpdatePriceRuleMutation>;
export type UpdatePriceRuleMutationResult = Apollo.MutationResult<UpdatePriceRuleMutation>;
export type UpdatePriceRuleMutationOptions = Apollo.BaseMutationOptions<UpdatePriceRuleMutation, UpdatePriceRuleMutationVariables>;
export const DeletePriceRuleDocument = gql`
    mutation deletePriceRule($id: String!) {
  deletePriceRule(id: $id) {
    success
  }
}
    `;
export type DeletePriceRuleMutationFn = Apollo.MutationFunction<DeletePriceRuleMutation, DeletePriceRuleMutationVariables>;

/**
 * __useDeletePriceRuleMutation__
 *
 * To run a mutation, you first call `useDeletePriceRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePriceRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePriceRuleMutation, { data, loading, error }] = useDeletePriceRuleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePriceRuleMutation(baseOptions?: Apollo.MutationHookOptions<DeletePriceRuleMutation, DeletePriceRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePriceRuleMutation, DeletePriceRuleMutationVariables>(DeletePriceRuleDocument, options);
      }
export type DeletePriceRuleMutationHookResult = ReturnType<typeof useDeletePriceRuleMutation>;
export type DeletePriceRuleMutationResult = Apollo.MutationResult<DeletePriceRuleMutation>;
export type DeletePriceRuleMutationOptions = Apollo.BaseMutationOptions<DeletePriceRuleMutation, DeletePriceRuleMutationVariables>;
export const ImportCompetitorPricesDocument = gql`
    mutation importCompetitorPrices {
  importCompetitorPrices {
    success
  }
}
    `;
export type ImportCompetitorPricesMutationFn = Apollo.MutationFunction<ImportCompetitorPricesMutation, ImportCompetitorPricesMutationVariables>;

/**
 * __useImportCompetitorPricesMutation__
 *
 * To run a mutation, you first call `useImportCompetitorPricesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportCompetitorPricesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importCompetitorPricesMutation, { data, loading, error }] = useImportCompetitorPricesMutation({
 *   variables: {
 *   },
 * });
 */
export function useImportCompetitorPricesMutation(baseOptions?: Apollo.MutationHookOptions<ImportCompetitorPricesMutation, ImportCompetitorPricesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportCompetitorPricesMutation, ImportCompetitorPricesMutationVariables>(ImportCompetitorPricesDocument, options);
      }
export type ImportCompetitorPricesMutationHookResult = ReturnType<typeof useImportCompetitorPricesMutation>;
export type ImportCompetitorPricesMutationResult = Apollo.MutationResult<ImportCompetitorPricesMutation>;
export type ImportCompetitorPricesMutationOptions = Apollo.BaseMutationOptions<ImportCompetitorPricesMutation, ImportCompetitorPricesMutationVariables>;
export const PriceRulesDocument = gql`
    query priceRules {
  priceRules {
    ...PriceRuleData
  }
}
    ${PriceRuleDataFragmentDoc}`;

/**
 * __usePriceRulesQuery__
 *
 * To run a query within a React component, call `usePriceRulesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePriceRulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePriceRulesQuery({
 *   variables: {
 *   },
 * });
 */
export function usePriceRulesQuery(baseOptions?: Apollo.QueryHookOptions<PriceRulesQuery, PriceRulesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PriceRulesQuery, PriceRulesQueryVariables>(PriceRulesDocument, options);
      }
export function usePriceRulesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PriceRulesQuery, PriceRulesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PriceRulesQuery, PriceRulesQueryVariables>(PriceRulesDocument, options);
        }
export type PriceRulesQueryHookResult = ReturnType<typeof usePriceRulesQuery>;
export type PriceRulesLazyQueryHookResult = ReturnType<typeof usePriceRulesLazyQuery>;
export type PriceRulesQueryResult = Apollo.QueryResult<PriceRulesQuery, PriceRulesQueryVariables>;
export const PriceRuleDocument = gql`
    query priceRule($id: String!) {
  priceRule(id: $id) {
    ...PriceRuleData
  }
}
    ${PriceRuleDataFragmentDoc}`;

/**
 * __usePriceRuleQuery__
 *
 * To run a query within a React component, call `usePriceRuleQuery` and pass it any options that fit your needs.
 * When your component renders, `usePriceRuleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePriceRuleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePriceRuleQuery(baseOptions: Apollo.QueryHookOptions<PriceRuleQuery, PriceRuleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PriceRuleQuery, PriceRuleQueryVariables>(PriceRuleDocument, options);
      }
export function usePriceRuleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PriceRuleQuery, PriceRuleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PriceRuleQuery, PriceRuleQueryVariables>(PriceRuleDocument, options);
        }
export type PriceRuleQueryHookResult = ReturnType<typeof usePriceRuleQuery>;
export type PriceRuleLazyQueryHookResult = ReturnType<typeof usePriceRuleLazyQuery>;
export type PriceRuleQueryResult = Apollo.QueryResult<PriceRuleQuery, PriceRuleQueryVariables>;
export const DeleteBrandProductsDocument = gql`
    mutation deleteBrandProducts($input: DeleteBrandProductsInputType!) {
  deleteBrandProducts(input: $input) {
    success
  }
}
    `;
export type DeleteBrandProductsMutationFn = Apollo.MutationFunction<DeleteBrandProductsMutation, DeleteBrandProductsMutationVariables>;

/**
 * __useDeleteBrandProductsMutation__
 *
 * To run a mutation, you first call `useDeleteBrandProductsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBrandProductsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBrandProductsMutation, { data, loading, error }] = useDeleteBrandProductsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteBrandProductsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBrandProductsMutation, DeleteBrandProductsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBrandProductsMutation, DeleteBrandProductsMutationVariables>(DeleteBrandProductsDocument, options);
      }
export type DeleteBrandProductsMutationHookResult = ReturnType<typeof useDeleteBrandProductsMutation>;
export type DeleteBrandProductsMutationResult = Apollo.MutationResult<DeleteBrandProductsMutation>;
export type DeleteBrandProductsMutationOptions = Apollo.BaseMutationOptions<DeleteBrandProductsMutation, DeleteBrandProductsMutationVariables>;
export const LoadBrandProductsDocument = gql`
    mutation loadBrandProducts($input: ImportBrandProductsInputType!) {
  loadBrandProducts(input: $input) {
    success
  }
}
    `;
export type LoadBrandProductsMutationFn = Apollo.MutationFunction<LoadBrandProductsMutation, LoadBrandProductsMutationVariables>;

/**
 * __useLoadBrandProductsMutation__
 *
 * To run a mutation, you first call `useLoadBrandProductsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoadBrandProductsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loadBrandProductsMutation, { data, loading, error }] = useLoadBrandProductsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoadBrandProductsMutation(baseOptions?: Apollo.MutationHookOptions<LoadBrandProductsMutation, LoadBrandProductsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoadBrandProductsMutation, LoadBrandProductsMutationVariables>(LoadBrandProductsDocument, options);
      }
export type LoadBrandProductsMutationHookResult = ReturnType<typeof useLoadBrandProductsMutation>;
export type LoadBrandProductsMutationResult = Apollo.MutationResult<LoadBrandProductsMutation>;
export type LoadBrandProductsMutationOptions = Apollo.BaseMutationOptions<LoadBrandProductsMutation, LoadBrandProductsMutationVariables>;
export const UpsertProductItemDocument = gql`
    mutation upsertProductItem($mode: String!, $product: CatalogBrandProductItemInput!) {
  upsertProductItem(mode: $mode, product: $product) {
    success
  }
}
    `;
export type UpsertProductItemMutationFn = Apollo.MutationFunction<UpsertProductItemMutation, UpsertProductItemMutationVariables>;

/**
 * __useUpsertProductItemMutation__
 *
 * To run a mutation, you first call `useUpsertProductItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertProductItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertProductItemMutation, { data, loading, error }] = useUpsertProductItemMutation({
 *   variables: {
 *      mode: // value for 'mode'
 *      product: // value for 'product'
 *   },
 * });
 */
export function useUpsertProductItemMutation(baseOptions?: Apollo.MutationHookOptions<UpsertProductItemMutation, UpsertProductItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertProductItemMutation, UpsertProductItemMutationVariables>(UpsertProductItemDocument, options);
      }
export type UpsertProductItemMutationHookResult = ReturnType<typeof useUpsertProductItemMutation>;
export type UpsertProductItemMutationResult = Apollo.MutationResult<UpsertProductItemMutation>;
export type UpsertProductItemMutationOptions = Apollo.BaseMutationOptions<UpsertProductItemMutation, UpsertProductItemMutationVariables>;
export const UpsertVariantItemDocument = gql`
    mutation upsertVariantItem($mode: String!, $variant: CatalogBrandProductVariantInput!) {
  upsertVariantItem(mode: $mode, variant: $variant) {
    success
  }
}
    `;
export type UpsertVariantItemMutationFn = Apollo.MutationFunction<UpsertVariantItemMutation, UpsertVariantItemMutationVariables>;

/**
 * __useUpsertVariantItemMutation__
 *
 * To run a mutation, you first call `useUpsertVariantItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertVariantItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertVariantItemMutation, { data, loading, error }] = useUpsertVariantItemMutation({
 *   variables: {
 *      mode: // value for 'mode'
 *      variant: // value for 'variant'
 *   },
 * });
 */
export function useUpsertVariantItemMutation(baseOptions?: Apollo.MutationHookOptions<UpsertVariantItemMutation, UpsertVariantItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertVariantItemMutation, UpsertVariantItemMutationVariables>(UpsertVariantItemDocument, options);
      }
export type UpsertVariantItemMutationHookResult = ReturnType<typeof useUpsertVariantItemMutation>;
export type UpsertVariantItemMutationResult = Apollo.MutationResult<UpsertVariantItemMutation>;
export type UpsertVariantItemMutationOptions = Apollo.BaseMutationOptions<UpsertVariantItemMutation, UpsertVariantItemMutationVariables>;
export const ExportCatalogProductsDocument = gql`
    mutation exportCatalogProducts($input: ExportToolProductsInput!) {
  exportCatalogProducts(input: $input) {
    url
  }
}
    `;
export type ExportCatalogProductsMutationFn = Apollo.MutationFunction<ExportCatalogProductsMutation, ExportCatalogProductsMutationVariables>;

/**
 * __useExportCatalogProductsMutation__
 *
 * To run a mutation, you first call `useExportCatalogProductsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportCatalogProductsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportCatalogProductsMutation, { data, loading, error }] = useExportCatalogProductsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExportCatalogProductsMutation(baseOptions?: Apollo.MutationHookOptions<ExportCatalogProductsMutation, ExportCatalogProductsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportCatalogProductsMutation, ExportCatalogProductsMutationVariables>(ExportCatalogProductsDocument, options);
      }
export type ExportCatalogProductsMutationHookResult = ReturnType<typeof useExportCatalogProductsMutation>;
export type ExportCatalogProductsMutationResult = Apollo.MutationResult<ExportCatalogProductsMutation>;
export type ExportCatalogProductsMutationOptions = Apollo.BaseMutationOptions<ExportCatalogProductsMutation, ExportCatalogProductsMutationVariables>;
export const PublishAllProductsDocument = gql`
    mutation publishAllProducts {
  publishAllProducts {
    success
  }
}
    `;
export type PublishAllProductsMutationFn = Apollo.MutationFunction<PublishAllProductsMutation, PublishAllProductsMutationVariables>;

/**
 * __usePublishAllProductsMutation__
 *
 * To run a mutation, you first call `usePublishAllProductsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishAllProductsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishAllProductsMutation, { data, loading, error }] = usePublishAllProductsMutation({
 *   variables: {
 *   },
 * });
 */
export function usePublishAllProductsMutation(baseOptions?: Apollo.MutationHookOptions<PublishAllProductsMutation, PublishAllProductsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PublishAllProductsMutation, PublishAllProductsMutationVariables>(PublishAllProductsDocument, options);
      }
export type PublishAllProductsMutationHookResult = ReturnType<typeof usePublishAllProductsMutation>;
export type PublishAllProductsMutationResult = Apollo.MutationResult<PublishAllProductsMutation>;
export type PublishAllProductsMutationOptions = Apollo.BaseMutationOptions<PublishAllProductsMutation, PublishAllProductsMutationVariables>;
export const PublishProductDocument = gql`
    mutation publishProduct($productId: String!) {
  publishProduct(productId: $productId) {
    success
  }
}
    `;
export type PublishProductMutationFn = Apollo.MutationFunction<PublishProductMutation, PublishProductMutationVariables>;

/**
 * __usePublishProductMutation__
 *
 * To run a mutation, you first call `usePublishProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishProductMutation, { data, loading, error }] = usePublishProductMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function usePublishProductMutation(baseOptions?: Apollo.MutationHookOptions<PublishProductMutation, PublishProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PublishProductMutation, PublishProductMutationVariables>(PublishProductDocument, options);
      }
export type PublishProductMutationHookResult = ReturnType<typeof usePublishProductMutation>;
export type PublishProductMutationResult = Apollo.MutationResult<PublishProductMutation>;
export type PublishProductMutationOptions = Apollo.BaseMutationOptions<PublishProductMutation, PublishProductMutationVariables>;
export const ClearPublishQueueDocument = gql`
    mutation clearPublishQueue {
  clearPublishQueue {
    success
  }
}
    `;
export type ClearPublishQueueMutationFn = Apollo.MutationFunction<ClearPublishQueueMutation, ClearPublishQueueMutationVariables>;

/**
 * __useClearPublishQueueMutation__
 *
 * To run a mutation, you first call `useClearPublishQueueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearPublishQueueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearPublishQueueMutation, { data, loading, error }] = useClearPublishQueueMutation({
 *   variables: {
 *   },
 * });
 */
export function useClearPublishQueueMutation(baseOptions?: Apollo.MutationHookOptions<ClearPublishQueueMutation, ClearPublishQueueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClearPublishQueueMutation, ClearPublishQueueMutationVariables>(ClearPublishQueueDocument, options);
      }
export type ClearPublishQueueMutationHookResult = ReturnType<typeof useClearPublishQueueMutation>;
export type ClearPublishQueueMutationResult = Apollo.MutationResult<ClearPublishQueueMutation>;
export type ClearPublishQueueMutationOptions = Apollo.BaseMutationOptions<ClearPublishQueueMutation, ClearPublishQueueMutationVariables>;
export const ArchiveProductsDocument = gql`
    mutation archiveProducts($input: ArchiveProductInputType!) {
  archiveProducts(input: $input) {
    success
  }
}
    `;
export type ArchiveProductsMutationFn = Apollo.MutationFunction<ArchiveProductsMutation, ArchiveProductsMutationVariables>;

/**
 * __useArchiveProductsMutation__
 *
 * To run a mutation, you first call `useArchiveProductsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveProductsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveProductsMutation, { data, loading, error }] = useArchiveProductsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArchiveProductsMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveProductsMutation, ArchiveProductsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArchiveProductsMutation, ArchiveProductsMutationVariables>(ArchiveProductsDocument, options);
      }
export type ArchiveProductsMutationHookResult = ReturnType<typeof useArchiveProductsMutation>;
export type ArchiveProductsMutationResult = Apollo.MutationResult<ArchiveProductsMutation>;
export type ArchiveProductsMutationOptions = Apollo.BaseMutationOptions<ArchiveProductsMutation, ArchiveProductsMutationVariables>;
export const EnableProductDocument = gql`
    mutation enableProduct($productId: String!) {
  enableProduct(productId: $productId) {
    success
  }
}
    `;
export type EnableProductMutationFn = Apollo.MutationFunction<EnableProductMutation, EnableProductMutationVariables>;

/**
 * __useEnableProductMutation__
 *
 * To run a mutation, you first call `useEnableProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableProductMutation, { data, loading, error }] = useEnableProductMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useEnableProductMutation(baseOptions?: Apollo.MutationHookOptions<EnableProductMutation, EnableProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EnableProductMutation, EnableProductMutationVariables>(EnableProductDocument, options);
      }
export type EnableProductMutationHookResult = ReturnType<typeof useEnableProductMutation>;
export type EnableProductMutationResult = Apollo.MutationResult<EnableProductMutation>;
export type EnableProductMutationOptions = Apollo.BaseMutationOptions<EnableProductMutation, EnableProductMutationVariables>;
export const DisableProductDocument = gql`
    mutation disableProduct($productId: String!) {
  disableProduct(productId: $productId) {
    success
  }
}
    `;
export type DisableProductMutationFn = Apollo.MutationFunction<DisableProductMutation, DisableProductMutationVariables>;

/**
 * __useDisableProductMutation__
 *
 * To run a mutation, you first call `useDisableProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableProductMutation, { data, loading, error }] = useDisableProductMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useDisableProductMutation(baseOptions?: Apollo.MutationHookOptions<DisableProductMutation, DisableProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DisableProductMutation, DisableProductMutationVariables>(DisableProductDocument, options);
      }
export type DisableProductMutationHookResult = ReturnType<typeof useDisableProductMutation>;
export type DisableProductMutationResult = Apollo.MutationResult<DisableProductMutation>;
export type DisableProductMutationOptions = Apollo.BaseMutationOptions<DisableProductMutation, DisableProductMutationVariables>;
export const EnableProductVariantDocument = gql`
    mutation enableProductVariant($eanCode: String!) {
  enableProductVariant(eanCode: $eanCode) {
    success
  }
}
    `;
export type EnableProductVariantMutationFn = Apollo.MutationFunction<EnableProductVariantMutation, EnableProductVariantMutationVariables>;

/**
 * __useEnableProductVariantMutation__
 *
 * To run a mutation, you first call `useEnableProductVariantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableProductVariantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableProductVariantMutation, { data, loading, error }] = useEnableProductVariantMutation({
 *   variables: {
 *      eanCode: // value for 'eanCode'
 *   },
 * });
 */
export function useEnableProductVariantMutation(baseOptions?: Apollo.MutationHookOptions<EnableProductVariantMutation, EnableProductVariantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EnableProductVariantMutation, EnableProductVariantMutationVariables>(EnableProductVariantDocument, options);
      }
export type EnableProductVariantMutationHookResult = ReturnType<typeof useEnableProductVariantMutation>;
export type EnableProductVariantMutationResult = Apollo.MutationResult<EnableProductVariantMutation>;
export type EnableProductVariantMutationOptions = Apollo.BaseMutationOptions<EnableProductVariantMutation, EnableProductVariantMutationVariables>;
export const DisableProductVariantDocument = gql`
    mutation disableProductVariant($eanCode: String!) {
  disableProductVariant(eanCode: $eanCode) {
    success
  }
}
    `;
export type DisableProductVariantMutationFn = Apollo.MutationFunction<DisableProductVariantMutation, DisableProductVariantMutationVariables>;

/**
 * __useDisableProductVariantMutation__
 *
 * To run a mutation, you first call `useDisableProductVariantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableProductVariantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableProductVariantMutation, { data, loading, error }] = useDisableProductVariantMutation({
 *   variables: {
 *      eanCode: // value for 'eanCode'
 *   },
 * });
 */
export function useDisableProductVariantMutation(baseOptions?: Apollo.MutationHookOptions<DisableProductVariantMutation, DisableProductVariantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DisableProductVariantMutation, DisableProductVariantMutationVariables>(DisableProductVariantDocument, options);
      }
export type DisableProductVariantMutationHookResult = ReturnType<typeof useDisableProductVariantMutation>;
export type DisableProductVariantMutationResult = Apollo.MutationResult<DisableProductVariantMutation>;
export type DisableProductVariantMutationOptions = Apollo.BaseMutationOptions<DisableProductVariantMutation, DisableProductVariantMutationVariables>;
export const SwapVariantsOrderDocument = gql`
    mutation swapVariantsOrder($input: SwapVariantsInputType!) {
  swapVariantsOrder(input: $input) {
    success
  }
}
    `;
export type SwapVariantsOrderMutationFn = Apollo.MutationFunction<SwapVariantsOrderMutation, SwapVariantsOrderMutationVariables>;

/**
 * __useSwapVariantsOrderMutation__
 *
 * To run a mutation, you first call `useSwapVariantsOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSwapVariantsOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [swapVariantsOrderMutation, { data, loading, error }] = useSwapVariantsOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSwapVariantsOrderMutation(baseOptions?: Apollo.MutationHookOptions<SwapVariantsOrderMutation, SwapVariantsOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SwapVariantsOrderMutation, SwapVariantsOrderMutationVariables>(SwapVariantsOrderDocument, options);
      }
export type SwapVariantsOrderMutationHookResult = ReturnType<typeof useSwapVariantsOrderMutation>;
export type SwapVariantsOrderMutationResult = Apollo.MutationResult<SwapVariantsOrderMutation>;
export type SwapVariantsOrderMutationOptions = Apollo.BaseMutationOptions<SwapVariantsOrderMutation, SwapVariantsOrderMutationVariables>;
export const ChangeVariantsOrderDocument = gql`
    mutation changeVariantsOrder($input: VariantOrderInput!) {
  changeVariantsOrder(input: $input) {
    success
  }
}
    `;
export type ChangeVariantsOrderMutationFn = Apollo.MutationFunction<ChangeVariantsOrderMutation, ChangeVariantsOrderMutationVariables>;

/**
 * __useChangeVariantsOrderMutation__
 *
 * To run a mutation, you first call `useChangeVariantsOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeVariantsOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeVariantsOrderMutation, { data, loading, error }] = useChangeVariantsOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeVariantsOrderMutation(baseOptions?: Apollo.MutationHookOptions<ChangeVariantsOrderMutation, ChangeVariantsOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeVariantsOrderMutation, ChangeVariantsOrderMutationVariables>(ChangeVariantsOrderDocument, options);
      }
export type ChangeVariantsOrderMutationHookResult = ReturnType<typeof useChangeVariantsOrderMutation>;
export type ChangeVariantsOrderMutationResult = Apollo.MutationResult<ChangeVariantsOrderMutation>;
export type ChangeVariantsOrderMutationOptions = Apollo.BaseMutationOptions<ChangeVariantsOrderMutation, ChangeVariantsOrderMutationVariables>;
export const ExportProductsForNavDocument = gql`
    mutation exportProductsForNav($input: ExportNavProductsInput!) {
  exportProductsForNav(input: $input) {
    url
  }
}
    `;
export type ExportProductsForNavMutationFn = Apollo.MutationFunction<ExportProductsForNavMutation, ExportProductsForNavMutationVariables>;

/**
 * __useExportProductsForNavMutation__
 *
 * To run a mutation, you first call `useExportProductsForNavMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportProductsForNavMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportProductsForNavMutation, { data, loading, error }] = useExportProductsForNavMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExportProductsForNavMutation(baseOptions?: Apollo.MutationHookOptions<ExportProductsForNavMutation, ExportProductsForNavMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportProductsForNavMutation, ExportProductsForNavMutationVariables>(ExportProductsForNavDocument, options);
      }
export type ExportProductsForNavMutationHookResult = ReturnType<typeof useExportProductsForNavMutation>;
export type ExportProductsForNavMutationResult = Apollo.MutationResult<ExportProductsForNavMutation>;
export type ExportProductsForNavMutationOptions = Apollo.BaseMutationOptions<ExportProductsForNavMutation, ExportProductsForNavMutationVariables>;
export const ExportToolFullReportDocument = gql`
    mutation exportToolFullReport {
  exportToolFullReport {
    url
  }
}
    `;
export type ExportToolFullReportMutationFn = Apollo.MutationFunction<ExportToolFullReportMutation, ExportToolFullReportMutationVariables>;

/**
 * __useExportToolFullReportMutation__
 *
 * To run a mutation, you first call `useExportToolFullReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportToolFullReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportToolFullReportMutation, { data, loading, error }] = useExportToolFullReportMutation({
 *   variables: {
 *   },
 * });
 */
export function useExportToolFullReportMutation(baseOptions?: Apollo.MutationHookOptions<ExportToolFullReportMutation, ExportToolFullReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportToolFullReportMutation, ExportToolFullReportMutationVariables>(ExportToolFullReportDocument, options);
      }
export type ExportToolFullReportMutationHookResult = ReturnType<typeof useExportToolFullReportMutation>;
export type ExportToolFullReportMutationResult = Apollo.MutationResult<ExportToolFullReportMutation>;
export type ExportToolFullReportMutationOptions = Apollo.BaseMutationOptions<ExportToolFullReportMutation, ExportToolFullReportMutationVariables>;
export const RefreshBrandProductItemDocument = gql`
    mutation refreshBrandProductItem($productId: String!) {
  refreshBrandProductItem(productId: $productId) {
    success
  }
}
    `;
export type RefreshBrandProductItemMutationFn = Apollo.MutationFunction<RefreshBrandProductItemMutation, RefreshBrandProductItemMutationVariables>;

/**
 * __useRefreshBrandProductItemMutation__
 *
 * To run a mutation, you first call `useRefreshBrandProductItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshBrandProductItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshBrandProductItemMutation, { data, loading, error }] = useRefreshBrandProductItemMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useRefreshBrandProductItemMutation(baseOptions?: Apollo.MutationHookOptions<RefreshBrandProductItemMutation, RefreshBrandProductItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefreshBrandProductItemMutation, RefreshBrandProductItemMutationVariables>(RefreshBrandProductItemDocument, options);
      }
export type RefreshBrandProductItemMutationHookResult = ReturnType<typeof useRefreshBrandProductItemMutation>;
export type RefreshBrandProductItemMutationResult = Apollo.MutationResult<RefreshBrandProductItemMutation>;
export type RefreshBrandProductItemMutationOptions = Apollo.BaseMutationOptions<RefreshBrandProductItemMutation, RefreshBrandProductItemMutationVariables>;
export const RefreshCatalogBrandProductsDocument = gql`
    mutation refreshCatalogBrandProducts($brandCode: String!) {
  refreshCatalogBrandProducts(brandCode: $brandCode) {
    success
  }
}
    `;
export type RefreshCatalogBrandProductsMutationFn = Apollo.MutationFunction<RefreshCatalogBrandProductsMutation, RefreshCatalogBrandProductsMutationVariables>;

/**
 * __useRefreshCatalogBrandProductsMutation__
 *
 * To run a mutation, you first call `useRefreshCatalogBrandProductsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshCatalogBrandProductsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshCatalogBrandProductsMutation, { data, loading, error }] = useRefreshCatalogBrandProductsMutation({
 *   variables: {
 *      brandCode: // value for 'brandCode'
 *   },
 * });
 */
export function useRefreshCatalogBrandProductsMutation(baseOptions?: Apollo.MutationHookOptions<RefreshCatalogBrandProductsMutation, RefreshCatalogBrandProductsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefreshCatalogBrandProductsMutation, RefreshCatalogBrandProductsMutationVariables>(RefreshCatalogBrandProductsDocument, options);
      }
export type RefreshCatalogBrandProductsMutationHookResult = ReturnType<typeof useRefreshCatalogBrandProductsMutation>;
export type RefreshCatalogBrandProductsMutationResult = Apollo.MutationResult<RefreshCatalogBrandProductsMutation>;
export type RefreshCatalogBrandProductsMutationOptions = Apollo.BaseMutationOptions<RefreshCatalogBrandProductsMutation, RefreshCatalogBrandProductsMutationVariables>;
export const RefreshAllCatalogProductsDocument = gql`
    mutation refreshAllCatalogProducts {
  refreshAllCatalogProducts {
    success
  }
}
    `;
export type RefreshAllCatalogProductsMutationFn = Apollo.MutationFunction<RefreshAllCatalogProductsMutation, RefreshAllCatalogProductsMutationVariables>;

/**
 * __useRefreshAllCatalogProductsMutation__
 *
 * To run a mutation, you first call `useRefreshAllCatalogProductsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshAllCatalogProductsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshAllCatalogProductsMutation, { data, loading, error }] = useRefreshAllCatalogProductsMutation({
 *   variables: {
 *   },
 * });
 */
export function useRefreshAllCatalogProductsMutation(baseOptions?: Apollo.MutationHookOptions<RefreshAllCatalogProductsMutation, RefreshAllCatalogProductsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefreshAllCatalogProductsMutation, RefreshAllCatalogProductsMutationVariables>(RefreshAllCatalogProductsDocument, options);
      }
export type RefreshAllCatalogProductsMutationHookResult = ReturnType<typeof useRefreshAllCatalogProductsMutation>;
export type RefreshAllCatalogProductsMutationResult = Apollo.MutationResult<RefreshAllCatalogProductsMutation>;
export type RefreshAllCatalogProductsMutationOptions = Apollo.BaseMutationOptions<RefreshAllCatalogProductsMutation, RefreshAllCatalogProductsMutationVariables>;
export const ProcessNavBarcodesDataDocument = gql`
    mutation processNavBarcodesData($feedUrl: String!) {
  processNavBarcodesData(feedUrl: $feedUrl) {
    success
  }
}
    `;
export type ProcessNavBarcodesDataMutationFn = Apollo.MutationFunction<ProcessNavBarcodesDataMutation, ProcessNavBarcodesDataMutationVariables>;

/**
 * __useProcessNavBarcodesDataMutation__
 *
 * To run a mutation, you first call `useProcessNavBarcodesDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProcessNavBarcodesDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [processNavBarcodesDataMutation, { data, loading, error }] = useProcessNavBarcodesDataMutation({
 *   variables: {
 *      feedUrl: // value for 'feedUrl'
 *   },
 * });
 */
export function useProcessNavBarcodesDataMutation(baseOptions?: Apollo.MutationHookOptions<ProcessNavBarcodesDataMutation, ProcessNavBarcodesDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProcessNavBarcodesDataMutation, ProcessNavBarcodesDataMutationVariables>(ProcessNavBarcodesDataDocument, options);
      }
export type ProcessNavBarcodesDataMutationHookResult = ReturnType<typeof useProcessNavBarcodesDataMutation>;
export type ProcessNavBarcodesDataMutationResult = Apollo.MutationResult<ProcessNavBarcodesDataMutation>;
export type ProcessNavBarcodesDataMutationOptions = Apollo.BaseMutationOptions<ProcessNavBarcodesDataMutation, ProcessNavBarcodesDataMutationVariables>;
export const ImportNavCategoriesDocument = gql`
    mutation importNavCategories($feedUrl: String!) {
  importNavCategories(feedUrl: $feedUrl) {
    success
  }
}
    `;
export type ImportNavCategoriesMutationFn = Apollo.MutationFunction<ImportNavCategoriesMutation, ImportNavCategoriesMutationVariables>;

/**
 * __useImportNavCategoriesMutation__
 *
 * To run a mutation, you first call `useImportNavCategoriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportNavCategoriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importNavCategoriesMutation, { data, loading, error }] = useImportNavCategoriesMutation({
 *   variables: {
 *      feedUrl: // value for 'feedUrl'
 *   },
 * });
 */
export function useImportNavCategoriesMutation(baseOptions?: Apollo.MutationHookOptions<ImportNavCategoriesMutation, ImportNavCategoriesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportNavCategoriesMutation, ImportNavCategoriesMutationVariables>(ImportNavCategoriesDocument, options);
      }
export type ImportNavCategoriesMutationHookResult = ReturnType<typeof useImportNavCategoriesMutation>;
export type ImportNavCategoriesMutationResult = Apollo.MutationResult<ImportNavCategoriesMutation>;
export type ImportNavCategoriesMutationOptions = Apollo.BaseMutationOptions<ImportNavCategoriesMutation, ImportNavCategoriesMutationVariables>;
export const ImportSupplierProductsDocument = gql`
    mutation importSupplierProducts($feedUrl: String!) {
  importSupplierProducts(feedUrl: $feedUrl) {
    success
  }
}
    `;
export type ImportSupplierProductsMutationFn = Apollo.MutationFunction<ImportSupplierProductsMutation, ImportSupplierProductsMutationVariables>;

/**
 * __useImportSupplierProductsMutation__
 *
 * To run a mutation, you first call `useImportSupplierProductsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportSupplierProductsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importSupplierProductsMutation, { data, loading, error }] = useImportSupplierProductsMutation({
 *   variables: {
 *      feedUrl: // value for 'feedUrl'
 *   },
 * });
 */
export function useImportSupplierProductsMutation(baseOptions?: Apollo.MutationHookOptions<ImportSupplierProductsMutation, ImportSupplierProductsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportSupplierProductsMutation, ImportSupplierProductsMutationVariables>(ImportSupplierProductsDocument, options);
      }
export type ImportSupplierProductsMutationHookResult = ReturnType<typeof useImportSupplierProductsMutation>;
export type ImportSupplierProductsMutationResult = Apollo.MutationResult<ImportSupplierProductsMutation>;
export type ImportSupplierProductsMutationOptions = Apollo.BaseMutationOptions<ImportSupplierProductsMutation, ImportSupplierProductsMutationVariables>;
export const ImportNavCategoryMappingsDocument = gql`
    mutation importNavCategoryMappings($feedUrl: String!) {
  importNavCategoryMappings(feedUrl: $feedUrl) {
    success
  }
}
    `;
export type ImportNavCategoryMappingsMutationFn = Apollo.MutationFunction<ImportNavCategoryMappingsMutation, ImportNavCategoryMappingsMutationVariables>;

/**
 * __useImportNavCategoryMappingsMutation__
 *
 * To run a mutation, you first call `useImportNavCategoryMappingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportNavCategoryMappingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importNavCategoryMappingsMutation, { data, loading, error }] = useImportNavCategoryMappingsMutation({
 *   variables: {
 *      feedUrl: // value for 'feedUrl'
 *   },
 * });
 */
export function useImportNavCategoryMappingsMutation(baseOptions?: Apollo.MutationHookOptions<ImportNavCategoryMappingsMutation, ImportNavCategoryMappingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportNavCategoryMappingsMutation, ImportNavCategoryMappingsMutationVariables>(ImportNavCategoryMappingsDocument, options);
      }
export type ImportNavCategoryMappingsMutationHookResult = ReturnType<typeof useImportNavCategoryMappingsMutation>;
export type ImportNavCategoryMappingsMutationResult = Apollo.MutationResult<ImportNavCategoryMappingsMutation>;
export type ImportNavCategoryMappingsMutationOptions = Apollo.BaseMutationOptions<ImportNavCategoryMappingsMutation, ImportNavCategoryMappingsMutationVariables>;
export const MoveVariantDocument = gql`
    mutation moveVariant($input: MoveVariantInput!) {
  moveVariant(input: $input) {
    success
  }
}
    `;
export type MoveVariantMutationFn = Apollo.MutationFunction<MoveVariantMutation, MoveVariantMutationVariables>;

/**
 * __useMoveVariantMutation__
 *
 * To run a mutation, you first call `useMoveVariantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveVariantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveVariantMutation, { data, loading, error }] = useMoveVariantMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMoveVariantMutation(baseOptions?: Apollo.MutationHookOptions<MoveVariantMutation, MoveVariantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MoveVariantMutation, MoveVariantMutationVariables>(MoveVariantDocument, options);
      }
export type MoveVariantMutationHookResult = ReturnType<typeof useMoveVariantMutation>;
export type MoveVariantMutationResult = Apollo.MutationResult<MoveVariantMutation>;
export type MoveVariantMutationOptions = Apollo.BaseMutationOptions<MoveVariantMutation, MoveVariantMutationVariables>;
export const MoveVariantToNewProductDocument = gql`
    mutation moveVariantToNewProduct($input: MoveVariantToNewProductInput!) {
  moveVariantToNewProduct(input: $input) {
    eanCode
    oldProductId
    newProductId
  }
}
    `;
export type MoveVariantToNewProductMutationFn = Apollo.MutationFunction<MoveVariantToNewProductMutation, MoveVariantToNewProductMutationVariables>;

/**
 * __useMoveVariantToNewProductMutation__
 *
 * To run a mutation, you first call `useMoveVariantToNewProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveVariantToNewProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveVariantToNewProductMutation, { data, loading, error }] = useMoveVariantToNewProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMoveVariantToNewProductMutation(baseOptions?: Apollo.MutationHookOptions<MoveVariantToNewProductMutation, MoveVariantToNewProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MoveVariantToNewProductMutation, MoveVariantToNewProductMutationVariables>(MoveVariantToNewProductDocument, options);
      }
export type MoveVariantToNewProductMutationHookResult = ReturnType<typeof useMoveVariantToNewProductMutation>;
export type MoveVariantToNewProductMutationResult = Apollo.MutationResult<MoveVariantToNewProductMutation>;
export type MoveVariantToNewProductMutationOptions = Apollo.BaseMutationOptions<MoveVariantToNewProductMutation, MoveVariantToNewProductMutationVariables>;
export const NormalizeVariantNamesDocument = gql`
    mutation normalizeVariantNames($input: NormalizeVariantNamesInput!) {
  normalizeVariantNames(input: $input) {
    success
  }
}
    `;
export type NormalizeVariantNamesMutationFn = Apollo.MutationFunction<NormalizeVariantNamesMutation, NormalizeVariantNamesMutationVariables>;

/**
 * __useNormalizeVariantNamesMutation__
 *
 * To run a mutation, you first call `useNormalizeVariantNamesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNormalizeVariantNamesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [normalizeVariantNamesMutation, { data, loading, error }] = useNormalizeVariantNamesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNormalizeVariantNamesMutation(baseOptions?: Apollo.MutationHookOptions<NormalizeVariantNamesMutation, NormalizeVariantNamesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NormalizeVariantNamesMutation, NormalizeVariantNamesMutationVariables>(NormalizeVariantNamesDocument, options);
      }
export type NormalizeVariantNamesMutationHookResult = ReturnType<typeof useNormalizeVariantNamesMutation>;
export type NormalizeVariantNamesMutationResult = Apollo.MutationResult<NormalizeVariantNamesMutation>;
export type NormalizeVariantNamesMutationOptions = Apollo.BaseMutationOptions<NormalizeVariantNamesMutation, NormalizeVariantNamesMutationVariables>;
export const CopyTextsOnAllVariantsDocument = gql`
    mutation copyTextsOnAllVariants($input: CopyTextsOnAllVariantsInput!) {
  copyTextsOnAllVariants(input: $input) {
    success
  }
}
    `;
export type CopyTextsOnAllVariantsMutationFn = Apollo.MutationFunction<CopyTextsOnAllVariantsMutation, CopyTextsOnAllVariantsMutationVariables>;

/**
 * __useCopyTextsOnAllVariantsMutation__
 *
 * To run a mutation, you first call `useCopyTextsOnAllVariantsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyTextsOnAllVariantsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyTextsOnAllVariantsMutation, { data, loading, error }] = useCopyTextsOnAllVariantsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCopyTextsOnAllVariantsMutation(baseOptions?: Apollo.MutationHookOptions<CopyTextsOnAllVariantsMutation, CopyTextsOnAllVariantsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CopyTextsOnAllVariantsMutation, CopyTextsOnAllVariantsMutationVariables>(CopyTextsOnAllVariantsDocument, options);
      }
export type CopyTextsOnAllVariantsMutationHookResult = ReturnType<typeof useCopyTextsOnAllVariantsMutation>;
export type CopyTextsOnAllVariantsMutationResult = Apollo.MutationResult<CopyTextsOnAllVariantsMutation>;
export type CopyTextsOnAllVariantsMutationOptions = Apollo.BaseMutationOptions<CopyTextsOnAllVariantsMutation, CopyTextsOnAllVariantsMutationVariables>;
export const UpdateProductBrandNameDocument = gql`
    mutation updateProductBrandName($input: UpdateProductBrandNameInput!) {
  updateProductBrandName(input: $input) {
    success
  }
}
    `;
export type UpdateProductBrandNameMutationFn = Apollo.MutationFunction<UpdateProductBrandNameMutation, UpdateProductBrandNameMutationVariables>;

/**
 * __useUpdateProductBrandNameMutation__
 *
 * To run a mutation, you first call `useUpdateProductBrandNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductBrandNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductBrandNameMutation, { data, loading, error }] = useUpdateProductBrandNameMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProductBrandNameMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductBrandNameMutation, UpdateProductBrandNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProductBrandNameMutation, UpdateProductBrandNameMutationVariables>(UpdateProductBrandNameDocument, options);
      }
export type UpdateProductBrandNameMutationHookResult = ReturnType<typeof useUpdateProductBrandNameMutation>;
export type UpdateProductBrandNameMutationResult = Apollo.MutationResult<UpdateProductBrandNameMutation>;
export type UpdateProductBrandNameMutationOptions = Apollo.BaseMutationOptions<UpdateProductBrandNameMutation, UpdateProductBrandNameMutationVariables>;
export const GetAvailableProductIdDocument = gql`
    mutation getAvailableProductId($brandCode: String!) {
  getAvailableProductId(brandCode: $brandCode) {
    productId
  }
}
    `;
export type GetAvailableProductIdMutationFn = Apollo.MutationFunction<GetAvailableProductIdMutation, GetAvailableProductIdMutationVariables>;

/**
 * __useGetAvailableProductIdMutation__
 *
 * To run a mutation, you first call `useGetAvailableProductIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetAvailableProductIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getAvailableProductIdMutation, { data, loading, error }] = useGetAvailableProductIdMutation({
 *   variables: {
 *      brandCode: // value for 'brandCode'
 *   },
 * });
 */
export function useGetAvailableProductIdMutation(baseOptions?: Apollo.MutationHookOptions<GetAvailableProductIdMutation, GetAvailableProductIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetAvailableProductIdMutation, GetAvailableProductIdMutationVariables>(GetAvailableProductIdDocument, options);
      }
export type GetAvailableProductIdMutationHookResult = ReturnType<typeof useGetAvailableProductIdMutation>;
export type GetAvailableProductIdMutationResult = Apollo.MutationResult<GetAvailableProductIdMutation>;
export type GetAvailableProductIdMutationOptions = Apollo.BaseMutationOptions<GetAvailableProductIdMutation, GetAvailableProductIdMutationVariables>;
export const ChangeProductBrandDocument = gql`
    mutation changeProductBrand($input: ChangeBrandProductInputType!) {
  changeProductBrand(input: $input) {
    success
  }
}
    `;
export type ChangeProductBrandMutationFn = Apollo.MutationFunction<ChangeProductBrandMutation, ChangeProductBrandMutationVariables>;

/**
 * __useChangeProductBrandMutation__
 *
 * To run a mutation, you first call `useChangeProductBrandMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeProductBrandMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeProductBrandMutation, { data, loading, error }] = useChangeProductBrandMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeProductBrandMutation(baseOptions?: Apollo.MutationHookOptions<ChangeProductBrandMutation, ChangeProductBrandMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeProductBrandMutation, ChangeProductBrandMutationVariables>(ChangeProductBrandDocument, options);
      }
export type ChangeProductBrandMutationHookResult = ReturnType<typeof useChangeProductBrandMutation>;
export type ChangeProductBrandMutationResult = Apollo.MutationResult<ChangeProductBrandMutation>;
export type ChangeProductBrandMutationOptions = Apollo.BaseMutationOptions<ChangeProductBrandMutation, ChangeProductBrandMutationVariables>;
export const MassiveProductsUpdateXlsDocument = gql`
    mutation massiveProductsUpdateXls($file: Upload!, $input: ProductsUpdateInput!) {
  massiveProductsUpdateXls(file: $file, input: $input) {
    success
  }
}
    `;
export type MassiveProductsUpdateXlsMutationFn = Apollo.MutationFunction<MassiveProductsUpdateXlsMutation, MassiveProductsUpdateXlsMutationVariables>;

/**
 * __useMassiveProductsUpdateXlsMutation__
 *
 * To run a mutation, you first call `useMassiveProductsUpdateXlsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMassiveProductsUpdateXlsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [massiveProductsUpdateXlsMutation, { data, loading, error }] = useMassiveProductsUpdateXlsMutation({
 *   variables: {
 *      file: // value for 'file'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMassiveProductsUpdateXlsMutation(baseOptions?: Apollo.MutationHookOptions<MassiveProductsUpdateXlsMutation, MassiveProductsUpdateXlsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MassiveProductsUpdateXlsMutation, MassiveProductsUpdateXlsMutationVariables>(MassiveProductsUpdateXlsDocument, options);
      }
export type MassiveProductsUpdateXlsMutationHookResult = ReturnType<typeof useMassiveProductsUpdateXlsMutation>;
export type MassiveProductsUpdateXlsMutationResult = Apollo.MutationResult<MassiveProductsUpdateXlsMutation>;
export type MassiveProductsUpdateXlsMutationOptions = Apollo.BaseMutationOptions<MassiveProductsUpdateXlsMutation, MassiveProductsUpdateXlsMutationVariables>;
export const MassiveProductsUpdateXlsPreviewDocument = gql`
    mutation massiveProductsUpdateXlsPreview($file: Upload!) {
  massiveProductsUpdateXlsPreview(file: $file) {
    data {
      ...ProductsUpdateDataFields
    }
    uploadHash
  }
}
    ${ProductsUpdateDataFieldsFragmentDoc}`;
export type MassiveProductsUpdateXlsPreviewMutationFn = Apollo.MutationFunction<MassiveProductsUpdateXlsPreviewMutation, MassiveProductsUpdateXlsPreviewMutationVariables>;

/**
 * __useMassiveProductsUpdateXlsPreviewMutation__
 *
 * To run a mutation, you first call `useMassiveProductsUpdateXlsPreviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMassiveProductsUpdateXlsPreviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [massiveProductsUpdateXlsPreviewMutation, { data, loading, error }] = useMassiveProductsUpdateXlsPreviewMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useMassiveProductsUpdateXlsPreviewMutation(baseOptions?: Apollo.MutationHookOptions<MassiveProductsUpdateXlsPreviewMutation, MassiveProductsUpdateXlsPreviewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MassiveProductsUpdateXlsPreviewMutation, MassiveProductsUpdateXlsPreviewMutationVariables>(MassiveProductsUpdateXlsPreviewDocument, options);
      }
export type MassiveProductsUpdateXlsPreviewMutationHookResult = ReturnType<typeof useMassiveProductsUpdateXlsPreviewMutation>;
export type MassiveProductsUpdateXlsPreviewMutationResult = Apollo.MutationResult<MassiveProductsUpdateXlsPreviewMutation>;
export type MassiveProductsUpdateXlsPreviewMutationOptions = Apollo.BaseMutationOptions<MassiveProductsUpdateXlsPreviewMutation, MassiveProductsUpdateXlsPreviewMutationVariables>;
export const MasterProductArchiveDocument = gql`
    mutation masterProductArchive($input: MasterProductArchiveInput!) {
  masterProductArchive(input: $input) {
    success
  }
}
    `;
export type MasterProductArchiveMutationFn = Apollo.MutationFunction<MasterProductArchiveMutation, MasterProductArchiveMutationVariables>;

/**
 * __useMasterProductArchiveMutation__
 *
 * To run a mutation, you first call `useMasterProductArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMasterProductArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [masterProductArchiveMutation, { data, loading, error }] = useMasterProductArchiveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMasterProductArchiveMutation(baseOptions?: Apollo.MutationHookOptions<MasterProductArchiveMutation, MasterProductArchiveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MasterProductArchiveMutation, MasterProductArchiveMutationVariables>(MasterProductArchiveDocument, options);
      }
export type MasterProductArchiveMutationHookResult = ReturnType<typeof useMasterProductArchiveMutation>;
export type MasterProductArchiveMutationResult = Apollo.MutationResult<MasterProductArchiveMutation>;
export type MasterProductArchiveMutationOptions = Apollo.BaseMutationOptions<MasterProductArchiveMutation, MasterProductArchiveMutationVariables>;
export const MassiveProductsDownloadDocument = gql`
    mutation massiveProductsDownload($input: ProductsDownloadInput!) {
  massiveProductsDownload(input: $input) {
    downloadUrl
  }
}
    `;
export type MassiveProductsDownloadMutationFn = Apollo.MutationFunction<MassiveProductsDownloadMutation, MassiveProductsDownloadMutationVariables>;

/**
 * __useMassiveProductsDownloadMutation__
 *
 * To run a mutation, you first call `useMassiveProductsDownloadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMassiveProductsDownloadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [massiveProductsDownloadMutation, { data, loading, error }] = useMassiveProductsDownloadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMassiveProductsDownloadMutation(baseOptions?: Apollo.MutationHookOptions<MassiveProductsDownloadMutation, MassiveProductsDownloadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MassiveProductsDownloadMutation, MassiveProductsDownloadMutationVariables>(MassiveProductsDownloadDocument, options);
      }
export type MassiveProductsDownloadMutationHookResult = ReturnType<typeof useMassiveProductsDownloadMutation>;
export type MassiveProductsDownloadMutationResult = Apollo.MutationResult<MassiveProductsDownloadMutation>;
export type MassiveProductsDownloadMutationOptions = Apollo.BaseMutationOptions<MassiveProductsDownloadMutation, MassiveProductsDownloadMutationVariables>;
export const MasterProductRestoreDocument = gql`
    mutation masterProductRestore($input: MasterProductRestoreInput!) {
  masterProductRestore(input: $input) {
    success
  }
}
    `;
export type MasterProductRestoreMutationFn = Apollo.MutationFunction<MasterProductRestoreMutation, MasterProductRestoreMutationVariables>;

/**
 * __useMasterProductRestoreMutation__
 *
 * To run a mutation, you first call `useMasterProductRestoreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMasterProductRestoreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [masterProductRestoreMutation, { data, loading, error }] = useMasterProductRestoreMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMasterProductRestoreMutation(baseOptions?: Apollo.MutationHookOptions<MasterProductRestoreMutation, MasterProductRestoreMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MasterProductRestoreMutation, MasterProductRestoreMutationVariables>(MasterProductRestoreDocument, options);
      }
export type MasterProductRestoreMutationHookResult = ReturnType<typeof useMasterProductRestoreMutation>;
export type MasterProductRestoreMutationResult = Apollo.MutationResult<MasterProductRestoreMutation>;
export type MasterProductRestoreMutationOptions = Apollo.BaseMutationOptions<MasterProductRestoreMutation, MasterProductRestoreMutationVariables>;
export const UpsertMasterProductCustomInfoDocument = gql`
    mutation upsertMasterProductCustomInfo($input: ProductCustomInfoEntityInput!) {
  upsertMasterProductCustomInfo(input: $input) {
    success
  }
}
    `;
export type UpsertMasterProductCustomInfoMutationFn = Apollo.MutationFunction<UpsertMasterProductCustomInfoMutation, UpsertMasterProductCustomInfoMutationVariables>;

/**
 * __useUpsertMasterProductCustomInfoMutation__
 *
 * To run a mutation, you first call `useUpsertMasterProductCustomInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertMasterProductCustomInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertMasterProductCustomInfoMutation, { data, loading, error }] = useUpsertMasterProductCustomInfoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertMasterProductCustomInfoMutation(baseOptions?: Apollo.MutationHookOptions<UpsertMasterProductCustomInfoMutation, UpsertMasterProductCustomInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertMasterProductCustomInfoMutation, UpsertMasterProductCustomInfoMutationVariables>(UpsertMasterProductCustomInfoDocument, options);
      }
export type UpsertMasterProductCustomInfoMutationHookResult = ReturnType<typeof useUpsertMasterProductCustomInfoMutation>;
export type UpsertMasterProductCustomInfoMutationResult = Apollo.MutationResult<UpsertMasterProductCustomInfoMutation>;
export type UpsertMasterProductCustomInfoMutationOptions = Apollo.BaseMutationOptions<UpsertMasterProductCustomInfoMutation, UpsertMasterProductCustomInfoMutationVariables>;
export const DeleteMasterProductCustomInfoDocument = gql`
    mutation deleteMasterProductCustomInfo($productId: String!) {
  deleteMasterProductCustomInfo(productId: $productId) {
    success
  }
}
    `;
export type DeleteMasterProductCustomInfoMutationFn = Apollo.MutationFunction<DeleteMasterProductCustomInfoMutation, DeleteMasterProductCustomInfoMutationVariables>;

/**
 * __useDeleteMasterProductCustomInfoMutation__
 *
 * To run a mutation, you first call `useDeleteMasterProductCustomInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMasterProductCustomInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMasterProductCustomInfoMutation, { data, loading, error }] = useDeleteMasterProductCustomInfoMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useDeleteMasterProductCustomInfoMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMasterProductCustomInfoMutation, DeleteMasterProductCustomInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMasterProductCustomInfoMutation, DeleteMasterProductCustomInfoMutationVariables>(DeleteMasterProductCustomInfoDocument, options);
      }
export type DeleteMasterProductCustomInfoMutationHookResult = ReturnType<typeof useDeleteMasterProductCustomInfoMutation>;
export type DeleteMasterProductCustomInfoMutationResult = Apollo.MutationResult<DeleteMasterProductCustomInfoMutation>;
export type DeleteMasterProductCustomInfoMutationOptions = Apollo.BaseMutationOptions<DeleteMasterProductCustomInfoMutation, DeleteMasterProductCustomInfoMutationVariables>;
export const MassiveProductsCreateXlsParseDocument = gql`
    mutation massiveProductsCreateXlsParse($file: Upload!) {
  massiveProductsCreateXlsParse(file: $file) {
    success
    errors {
      ...ProductsXlsParseErrorData
    }
    data {
      uploadHash
      unmappedColumns
      items {
        ...ImportProductItemData
      }
    }
  }
}
    ${ProductsXlsParseErrorDataFragmentDoc}
${ImportProductItemDataFragmentDoc}`;
export type MassiveProductsCreateXlsParseMutationFn = Apollo.MutationFunction<MassiveProductsCreateXlsParseMutation, MassiveProductsCreateXlsParseMutationVariables>;

/**
 * __useMassiveProductsCreateXlsParseMutation__
 *
 * To run a mutation, you first call `useMassiveProductsCreateXlsParseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMassiveProductsCreateXlsParseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [massiveProductsCreateXlsParseMutation, { data, loading, error }] = useMassiveProductsCreateXlsParseMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useMassiveProductsCreateXlsParseMutation(baseOptions?: Apollo.MutationHookOptions<MassiveProductsCreateXlsParseMutation, MassiveProductsCreateXlsParseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MassiveProductsCreateXlsParseMutation, MassiveProductsCreateXlsParseMutationVariables>(MassiveProductsCreateXlsParseDocument, options);
      }
export type MassiveProductsCreateXlsParseMutationHookResult = ReturnType<typeof useMassiveProductsCreateXlsParseMutation>;
export type MassiveProductsCreateXlsParseMutationResult = Apollo.MutationResult<MassiveProductsCreateXlsParseMutation>;
export type MassiveProductsCreateXlsParseMutationOptions = Apollo.BaseMutationOptions<MassiveProductsCreateXlsParseMutation, MassiveProductsCreateXlsParseMutationVariables>;
export const MassiveProductsDeleteBatchDocument = gql`
    mutation massiveProductsDeleteBatch($input: MassiveProductsDeleteInput!) {
  massiveProductsDeleteBatch(input: $input) {
    success
  }
}
    `;
export type MassiveProductsDeleteBatchMutationFn = Apollo.MutationFunction<MassiveProductsDeleteBatchMutation, MassiveProductsDeleteBatchMutationVariables>;

/**
 * __useMassiveProductsDeleteBatchMutation__
 *
 * To run a mutation, you first call `useMassiveProductsDeleteBatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMassiveProductsDeleteBatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [massiveProductsDeleteBatchMutation, { data, loading, error }] = useMassiveProductsDeleteBatchMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMassiveProductsDeleteBatchMutation(baseOptions?: Apollo.MutationHookOptions<MassiveProductsDeleteBatchMutation, MassiveProductsDeleteBatchMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MassiveProductsDeleteBatchMutation, MassiveProductsDeleteBatchMutationVariables>(MassiveProductsDeleteBatchDocument, options);
      }
export type MassiveProductsDeleteBatchMutationHookResult = ReturnType<typeof useMassiveProductsDeleteBatchMutation>;
export type MassiveProductsDeleteBatchMutationResult = Apollo.MutationResult<MassiveProductsDeleteBatchMutation>;
export type MassiveProductsDeleteBatchMutationOptions = Apollo.BaseMutationOptions<MassiveProductsDeleteBatchMutation, MassiveProductsDeleteBatchMutationVariables>;
export const MassiveProductsCreateDocument = gql`
    mutation massiveProductsCreate($input: MassiveProductsCreateInput!) {
  massiveProductsCreate(input: $input) {
    success
  }
}
    `;
export type MassiveProductsCreateMutationFn = Apollo.MutationFunction<MassiveProductsCreateMutation, MassiveProductsCreateMutationVariables>;

/**
 * __useMassiveProductsCreateMutation__
 *
 * To run a mutation, you first call `useMassiveProductsCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMassiveProductsCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [massiveProductsCreateMutation, { data, loading, error }] = useMassiveProductsCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMassiveProductsCreateMutation(baseOptions?: Apollo.MutationHookOptions<MassiveProductsCreateMutation, MassiveProductsCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MassiveProductsCreateMutation, MassiveProductsCreateMutationVariables>(MassiveProductsCreateDocument, options);
      }
export type MassiveProductsCreateMutationHookResult = ReturnType<typeof useMassiveProductsCreateMutation>;
export type MassiveProductsCreateMutationResult = Apollo.MutationResult<MassiveProductsCreateMutation>;
export type MassiveProductsCreateMutationOptions = Apollo.BaseMutationOptions<MassiveProductsCreateMutation, MassiveProductsCreateMutationVariables>;
export const MassiveProductsMediaUploadDocument = gql`
    mutation massiveProductsMediaUpload($ref: ProductMediaRefInput!, $media: Upload!) {
  massiveProductsMediaUpload(ref: $ref, media: $media) {
    ref {
      id
      filename
      uploadHash
      type
    }
    url
    type
  }
}
    `;
export type MassiveProductsMediaUploadMutationFn = Apollo.MutationFunction<MassiveProductsMediaUploadMutation, MassiveProductsMediaUploadMutationVariables>;

/**
 * __useMassiveProductsMediaUploadMutation__
 *
 * To run a mutation, you first call `useMassiveProductsMediaUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMassiveProductsMediaUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [massiveProductsMediaUploadMutation, { data, loading, error }] = useMassiveProductsMediaUploadMutation({
 *   variables: {
 *      ref: // value for 'ref'
 *      media: // value for 'media'
 *   },
 * });
 */
export function useMassiveProductsMediaUploadMutation(baseOptions?: Apollo.MutationHookOptions<MassiveProductsMediaUploadMutation, MassiveProductsMediaUploadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MassiveProductsMediaUploadMutation, MassiveProductsMediaUploadMutationVariables>(MassiveProductsMediaUploadDocument, options);
      }
export type MassiveProductsMediaUploadMutationHookResult = ReturnType<typeof useMassiveProductsMediaUploadMutation>;
export type MassiveProductsMediaUploadMutationResult = Apollo.MutationResult<MassiveProductsMediaUploadMutation>;
export type MassiveProductsMediaUploadMutationOptions = Apollo.BaseMutationOptions<MassiveProductsMediaUploadMutation, MassiveProductsMediaUploadMutationVariables>;
export const MasterVariantChangeEanDocument = gql`
    mutation masterVariantChangeEan($input: MasterVariantChangeEanCodeInput!) {
  masterVariantChangeEan(input: $input) {
    success
  }
}
    `;
export type MasterVariantChangeEanMutationFn = Apollo.MutationFunction<MasterVariantChangeEanMutation, MasterVariantChangeEanMutationVariables>;

/**
 * __useMasterVariantChangeEanMutation__
 *
 * To run a mutation, you first call `useMasterVariantChangeEanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMasterVariantChangeEanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [masterVariantChangeEanMutation, { data, loading, error }] = useMasterVariantChangeEanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMasterVariantChangeEanMutation(baseOptions?: Apollo.MutationHookOptions<MasterVariantChangeEanMutation, MasterVariantChangeEanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MasterVariantChangeEanMutation, MasterVariantChangeEanMutationVariables>(MasterVariantChangeEanDocument, options);
      }
export type MasterVariantChangeEanMutationHookResult = ReturnType<typeof useMasterVariantChangeEanMutation>;
export type MasterVariantChangeEanMutationResult = Apollo.MutationResult<MasterVariantChangeEanMutation>;
export type MasterVariantChangeEanMutationOptions = Apollo.BaseMutationOptions<MasterVariantChangeEanMutation, MasterVariantChangeEanMutationVariables>;
export const RunBusinessCentralFullUpdatesTaskDocument = gql`
    mutation runBusinessCentralFullUpdatesTask {
  runBusinessCentralFullUpdatesTask {
    success
  }
}
    `;
export type RunBusinessCentralFullUpdatesTaskMutationFn = Apollo.MutationFunction<RunBusinessCentralFullUpdatesTaskMutation, RunBusinessCentralFullUpdatesTaskMutationVariables>;

/**
 * __useRunBusinessCentralFullUpdatesTaskMutation__
 *
 * To run a mutation, you first call `useRunBusinessCentralFullUpdatesTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRunBusinessCentralFullUpdatesTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [runBusinessCentralFullUpdatesTaskMutation, { data, loading, error }] = useRunBusinessCentralFullUpdatesTaskMutation({
 *   variables: {
 *   },
 * });
 */
export function useRunBusinessCentralFullUpdatesTaskMutation(baseOptions?: Apollo.MutationHookOptions<RunBusinessCentralFullUpdatesTaskMutation, RunBusinessCentralFullUpdatesTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RunBusinessCentralFullUpdatesTaskMutation, RunBusinessCentralFullUpdatesTaskMutationVariables>(RunBusinessCentralFullUpdatesTaskDocument, options);
      }
export type RunBusinessCentralFullUpdatesTaskMutationHookResult = ReturnType<typeof useRunBusinessCentralFullUpdatesTaskMutation>;
export type RunBusinessCentralFullUpdatesTaskMutationResult = Apollo.MutationResult<RunBusinessCentralFullUpdatesTaskMutation>;
export type RunBusinessCentralFullUpdatesTaskMutationOptions = Apollo.BaseMutationOptions<RunBusinessCentralFullUpdatesTaskMutation, RunBusinessCentralFullUpdatesTaskMutationVariables>;
export const RunBusinessCentralDeltaUpdatesTaskDocument = gql`
    mutation runBusinessCentralDeltaUpdatesTask {
  runBusinessCentralDeltaUpdatesTask {
    success
  }
}
    `;
export type RunBusinessCentralDeltaUpdatesTaskMutationFn = Apollo.MutationFunction<RunBusinessCentralDeltaUpdatesTaskMutation, RunBusinessCentralDeltaUpdatesTaskMutationVariables>;

/**
 * __useRunBusinessCentralDeltaUpdatesTaskMutation__
 *
 * To run a mutation, you first call `useRunBusinessCentralDeltaUpdatesTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRunBusinessCentralDeltaUpdatesTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [runBusinessCentralDeltaUpdatesTaskMutation, { data, loading, error }] = useRunBusinessCentralDeltaUpdatesTaskMutation({
 *   variables: {
 *   },
 * });
 */
export function useRunBusinessCentralDeltaUpdatesTaskMutation(baseOptions?: Apollo.MutationHookOptions<RunBusinessCentralDeltaUpdatesTaskMutation, RunBusinessCentralDeltaUpdatesTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RunBusinessCentralDeltaUpdatesTaskMutation, RunBusinessCentralDeltaUpdatesTaskMutationVariables>(RunBusinessCentralDeltaUpdatesTaskDocument, options);
      }
export type RunBusinessCentralDeltaUpdatesTaskMutationHookResult = ReturnType<typeof useRunBusinessCentralDeltaUpdatesTaskMutation>;
export type RunBusinessCentralDeltaUpdatesTaskMutationResult = Apollo.MutationResult<RunBusinessCentralDeltaUpdatesTaskMutation>;
export type RunBusinessCentralDeltaUpdatesTaskMutationOptions = Apollo.BaseMutationOptions<RunBusinessCentralDeltaUpdatesTaskMutation, RunBusinessCentralDeltaUpdatesTaskMutationVariables>;
export const SearchCatalogProductsDocument = gql`
    query searchCatalogProducts($options: ProductToolSearchOptions!, $filters: ProductToolSearchFilters!) {
  searchCatalogProducts(filters: $filters, options: $options) {
    aggregations {
      brands {
        name
        code
        occurrences
      }
      productCategories {
        name
        code
        occurrences
      }
    }
    paging {
      totResults
      size
      cursor
      isLastPage
    }
    results {
      status {
        published
        publishedOn
        status
        disabled
      }
      product {
        productId
        brandCode
        name
        category
        secondaryCategories
        internalNotes
        _metadata {
          creationDate
          updateDate
        }
      }
      variants {
        _metadata {
          creationDate
          updateDate
        }
        internalNotes
        productId
        eanCode
        brandName
        brandCode
        contents {
          languageId
          name
          subtitle
          images
        }
      }
    }
  }
}
    `;

/**
 * __useSearchCatalogProductsQuery__
 *
 * To run a query within a React component, call `useSearchCatalogProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchCatalogProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchCatalogProductsQuery({
 *   variables: {
 *      options: // value for 'options'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useSearchCatalogProductsQuery(baseOptions: Apollo.QueryHookOptions<SearchCatalogProductsQuery, SearchCatalogProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchCatalogProductsQuery, SearchCatalogProductsQueryVariables>(SearchCatalogProductsDocument, options);
      }
export function useSearchCatalogProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchCatalogProductsQuery, SearchCatalogProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchCatalogProductsQuery, SearchCatalogProductsQueryVariables>(SearchCatalogProductsDocument, options);
        }
export type SearchCatalogProductsQueryHookResult = ReturnType<typeof useSearchCatalogProductsQuery>;
export type SearchCatalogProductsLazyQueryHookResult = ReturnType<typeof useSearchCatalogProductsLazyQuery>;
export type SearchCatalogProductsQueryResult = Apollo.QueryResult<SearchCatalogProductsQuery, SearchCatalogProductsQueryVariables>;
export const GetLatestProductVersionDocument = gql`
    query getLatestProductVersion($input: GetLatestProductVersionInputType!) {
  getLatestProductVersion(input: $input) {
    id
    productId
    brandCode
    version
    createdDate
    updatedDate
    createdBy
    published
    publishedOn
    internalNotes
    product {
      productId
      productLine
      productLineId
      tags
      category
      secondaryCategories
      relatedEanCodes
      complementaryEanCodes
      contents {
        languageId
        name
        subtitle
        line
        shortDescription
        longDescription
        highlights
        instructions
        ingredients
        fragrance
        fragranceFamily
        testResults
        composition
        technology
        images
      }
      options {
        hidden
        isGift
      }
    }
  }
}
    `;

/**
 * __useGetLatestProductVersionQuery__
 *
 * To run a query within a React component, call `useGetLatestProductVersionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestProductVersionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestProductVersionQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetLatestProductVersionQuery(baseOptions: Apollo.QueryHookOptions<GetLatestProductVersionQuery, GetLatestProductVersionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLatestProductVersionQuery, GetLatestProductVersionQueryVariables>(GetLatestProductVersionDocument, options);
      }
export function useGetLatestProductVersionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLatestProductVersionQuery, GetLatestProductVersionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLatestProductVersionQuery, GetLatestProductVersionQueryVariables>(GetLatestProductVersionDocument, options);
        }
export type GetLatestProductVersionQueryHookResult = ReturnType<typeof useGetLatestProductVersionQuery>;
export type GetLatestProductVersionLazyQueryHookResult = ReturnType<typeof useGetLatestProductVersionLazyQuery>;
export type GetLatestProductVersionQueryResult = Apollo.QueryResult<GetLatestProductVersionQuery, GetLatestProductVersionQueryVariables>;
export const GetLatestVariantVersionDocument = gql`
    query getLatestVariantVersion($input: GetLatestVariantVersionInputType!) {
  getLatestVariantVersion(input: $input) {
    id
    eanCode
    version
    createdDate
    updatedDate
    createdBy
    published
    publishedOn
    internalNotes
    variant {
      productId
      validFrom
      validTo
      productLine
      productLineId
      eanCode
      brandName
      brandCode
      contents {
        languageId
        name
        subtitle
        line
        shortDescription
        longDescription
        highlights
        instructions
        ingredients
        fragrance
        fragranceFamily
        testResults
        composition
        technology
        images
        videos
      }
      tags
      quantity {
        value
      }
      color {
        hex
        url
        name
      }
      category
      secondaryCategories
      relatedEanCodes
      complementaryEanCodes
      keywords
    }
  }
}
    `;

/**
 * __useGetLatestVariantVersionQuery__
 *
 * To run a query within a React component, call `useGetLatestVariantVersionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestVariantVersionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestVariantVersionQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetLatestVariantVersionQuery(baseOptions: Apollo.QueryHookOptions<GetLatestVariantVersionQuery, GetLatestVariantVersionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLatestVariantVersionQuery, GetLatestVariantVersionQueryVariables>(GetLatestVariantVersionDocument, options);
      }
export function useGetLatestVariantVersionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLatestVariantVersionQuery, GetLatestVariantVersionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLatestVariantVersionQuery, GetLatestVariantVersionQueryVariables>(GetLatestVariantVersionDocument, options);
        }
export type GetLatestVariantVersionQueryHookResult = ReturnType<typeof useGetLatestVariantVersionQuery>;
export type GetLatestVariantVersionLazyQueryHookResult = ReturnType<typeof useGetLatestVariantVersionLazyQuery>;
export type GetLatestVariantVersionQueryResult = Apollo.QueryResult<GetLatestVariantVersionQuery, GetLatestVariantVersionQueryVariables>;
export const GetProductVariantsDocument = gql`
    query getProductVariants($input: GetProductVariantsInputType!) {
  getProductVariants(input: $input) {
    eanCode
    productId
    variant {
      brandName
      brandCode
      color {
        name
      }
      quantity {
        value
      }
      contents {
        languageId
        name
        subtitle
        images
      }
    }
    variantIndex
  }
}
    `;

/**
 * __useGetProductVariantsQuery__
 *
 * To run a query within a React component, call `useGetProductVariantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductVariantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductVariantsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetProductVariantsQuery(baseOptions: Apollo.QueryHookOptions<GetProductVariantsQuery, GetProductVariantsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductVariantsQuery, GetProductVariantsQueryVariables>(GetProductVariantsDocument, options);
      }
export function useGetProductVariantsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductVariantsQuery, GetProductVariantsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductVariantsQuery, GetProductVariantsQueryVariables>(GetProductVariantsDocument, options);
        }
export type GetProductVariantsQueryHookResult = ReturnType<typeof useGetProductVariantsQuery>;
export type GetProductVariantsLazyQueryHookResult = ReturnType<typeof useGetProductVariantsLazyQuery>;
export type GetProductVariantsQueryResult = Apollo.QueryResult<GetProductVariantsQuery, GetProductVariantsQueryVariables>;
export const GetToolMetaItemsDocument = gql`
    query getToolMetaItems {
  getToolMetaItems {
    tag {
      key
      label
      categoryLabel
      disabled
    }
    tagCategories {
      key
      label
      disabled
    }
    categories {
      key
      label
      disabled
    }
    brands {
      key
      label
      disabled
    }
  }
}
    `;

/**
 * __useGetToolMetaItemsQuery__
 *
 * To run a query within a React component, call `useGetToolMetaItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetToolMetaItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetToolMetaItemsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetToolMetaItemsQuery(baseOptions?: Apollo.QueryHookOptions<GetToolMetaItemsQuery, GetToolMetaItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetToolMetaItemsQuery, GetToolMetaItemsQueryVariables>(GetToolMetaItemsDocument, options);
      }
export function useGetToolMetaItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetToolMetaItemsQuery, GetToolMetaItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetToolMetaItemsQuery, GetToolMetaItemsQueryVariables>(GetToolMetaItemsDocument, options);
        }
export type GetToolMetaItemsQueryHookResult = ReturnType<typeof useGetToolMetaItemsQuery>;
export type GetToolMetaItemsLazyQueryHookResult = ReturnType<typeof useGetToolMetaItemsLazyQuery>;
export type GetToolMetaItemsQueryResult = Apollo.QueryResult<GetToolMetaItemsQuery, GetToolMetaItemsQueryVariables>;
export const GetPublishQueueDocument = gql`
    query getPublishQueue {
  getPublishQueue {
    id
    productId
    status
    createdDate
  }
}
    `;

/**
 * __useGetPublishQueueQuery__
 *
 * To run a query within a React component, call `useGetPublishQueueQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublishQueueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublishQueueQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPublishQueueQuery(baseOptions?: Apollo.QueryHookOptions<GetPublishQueueQuery, GetPublishQueueQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPublishQueueQuery, GetPublishQueueQueryVariables>(GetPublishQueueDocument, options);
      }
export function useGetPublishQueueLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPublishQueueQuery, GetPublishQueueQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPublishQueueQuery, GetPublishQueueQueryVariables>(GetPublishQueueDocument, options);
        }
export type GetPublishQueueQueryHookResult = ReturnType<typeof useGetPublishQueueQuery>;
export type GetPublishQueueLazyQueryHookResult = ReturnType<typeof useGetPublishQueueLazyQuery>;
export type GetPublishQueueQueryResult = Apollo.QueryResult<GetPublishQueueQuery, GetPublishQueueQueryVariables>;
export const GetProductDocument = gql`
    query getProduct($input: GetProductInputType!) {
  getProduct(input: $input) {
    productId
    brandCode
    versionId
    internalNotes
    createdDate
    updatedDate
    publishable
    published
    publishedOn
    status
    disabled
    archived
  }
}
    `;

/**
 * __useGetProductQuery__
 *
 * To run a query within a React component, call `useGetProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetProductQuery(baseOptions: Apollo.QueryHookOptions<GetProductQuery, GetProductQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductQuery, GetProductQueryVariables>(GetProductDocument, options);
      }
export function useGetProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductQuery, GetProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductQuery, GetProductQueryVariables>(GetProductDocument, options);
        }
export type GetProductQueryHookResult = ReturnType<typeof useGetProductQuery>;
export type GetProductLazyQueryHookResult = ReturnType<typeof useGetProductLazyQuery>;
export type GetProductQueryResult = Apollo.QueryResult<GetProductQuery, GetProductQueryVariables>;
export const GetProductVariantDocument = gql`
    query getProductVariant($input: GetProductVariantInputType!) {
  getProductVariant(input: $input) {
    eanCode
    productId
    versionId
    variantIndex
    createdDate
    updatedDate
    disabled
    internalNotes
  }
}
    `;

/**
 * __useGetProductVariantQuery__
 *
 * To run a query within a React component, call `useGetProductVariantQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductVariantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductVariantQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetProductVariantQuery(baseOptions: Apollo.QueryHookOptions<GetProductVariantQuery, GetProductVariantQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductVariantQuery, GetProductVariantQueryVariables>(GetProductVariantDocument, options);
      }
export function useGetProductVariantLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductVariantQuery, GetProductVariantQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductVariantQuery, GetProductVariantQueryVariables>(GetProductVariantDocument, options);
        }
export type GetProductVariantQueryHookResult = ReturnType<typeof useGetProductVariantQuery>;
export type GetProductVariantLazyQueryHookResult = ReturnType<typeof useGetProductVariantLazyQuery>;
export type GetProductVariantQueryResult = Apollo.QueryResult<GetProductVariantQuery, GetProductVariantQueryVariables>;
export const MasterProductCustomInfoDocument = gql`
    query masterProductCustomInfo {
  masterProductCustomInfo {
    items {
      meta {
        name
        brand
      }
      data {
        productId
        weight
      }
    }
  }
}
    `;

/**
 * __useMasterProductCustomInfoQuery__
 *
 * To run a query within a React component, call `useMasterProductCustomInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useMasterProductCustomInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMasterProductCustomInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useMasterProductCustomInfoQuery(baseOptions?: Apollo.QueryHookOptions<MasterProductCustomInfoQuery, MasterProductCustomInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MasterProductCustomInfoQuery, MasterProductCustomInfoQueryVariables>(MasterProductCustomInfoDocument, options);
      }
export function useMasterProductCustomInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MasterProductCustomInfoQuery, MasterProductCustomInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MasterProductCustomInfoQuery, MasterProductCustomInfoQueryVariables>(MasterProductCustomInfoDocument, options);
        }
export type MasterProductCustomInfoQueryHookResult = ReturnType<typeof useMasterProductCustomInfoQuery>;
export type MasterProductCustomInfoLazyQueryHookResult = ReturnType<typeof useMasterProductCustomInfoLazyQuery>;
export type MasterProductCustomInfoQueryResult = Apollo.QueryResult<MasterProductCustomInfoQuery, MasterProductCustomInfoQueryVariables>;
export const MassiveProductCreateFieldNamesDocument = gql`
    query massiveProductCreateFieldNames {
  massiveProductCreateFieldNames {
    columns
  }
}
    `;

/**
 * __useMassiveProductCreateFieldNamesQuery__
 *
 * To run a query within a React component, call `useMassiveProductCreateFieldNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMassiveProductCreateFieldNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMassiveProductCreateFieldNamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useMassiveProductCreateFieldNamesQuery(baseOptions?: Apollo.QueryHookOptions<MassiveProductCreateFieldNamesQuery, MassiveProductCreateFieldNamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MassiveProductCreateFieldNamesQuery, MassiveProductCreateFieldNamesQueryVariables>(MassiveProductCreateFieldNamesDocument, options);
      }
export function useMassiveProductCreateFieldNamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MassiveProductCreateFieldNamesQuery, MassiveProductCreateFieldNamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MassiveProductCreateFieldNamesQuery, MassiveProductCreateFieldNamesQueryVariables>(MassiveProductCreateFieldNamesDocument, options);
        }
export type MassiveProductCreateFieldNamesQueryHookResult = ReturnType<typeof useMassiveProductCreateFieldNamesQuery>;
export type MassiveProductCreateFieldNamesLazyQueryHookResult = ReturnType<typeof useMassiveProductCreateFieldNamesLazyQuery>;
export type MassiveProductCreateFieldNamesQueryResult = Apollo.QueryResult<MassiveProductCreateFieldNamesQuery, MassiveProductCreateFieldNamesQueryVariables>;
export const EnablePromoDocument = gql`
    mutation enablePromo($id: String!) {
  enablePromo(id: $id) {
    ...PromoDefinitionData
  }
}
    ${PromoDefinitionDataFragmentDoc}`;
export type EnablePromoMutationFn = Apollo.MutationFunction<EnablePromoMutation, EnablePromoMutationVariables>;

/**
 * __useEnablePromoMutation__
 *
 * To run a mutation, you first call `useEnablePromoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnablePromoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enablePromoMutation, { data, loading, error }] = useEnablePromoMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEnablePromoMutation(baseOptions?: Apollo.MutationHookOptions<EnablePromoMutation, EnablePromoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EnablePromoMutation, EnablePromoMutationVariables>(EnablePromoDocument, options);
      }
export type EnablePromoMutationHookResult = ReturnType<typeof useEnablePromoMutation>;
export type EnablePromoMutationResult = Apollo.MutationResult<EnablePromoMutation>;
export type EnablePromoMutationOptions = Apollo.BaseMutationOptions<EnablePromoMutation, EnablePromoMutationVariables>;
export const DisablePromoDocument = gql`
    mutation disablePromo($id: String!) {
  disablePromo(id: $id) {
    ...PromoDefinitionData
  }
}
    ${PromoDefinitionDataFragmentDoc}`;
export type DisablePromoMutationFn = Apollo.MutationFunction<DisablePromoMutation, DisablePromoMutationVariables>;

/**
 * __useDisablePromoMutation__
 *
 * To run a mutation, you first call `useDisablePromoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisablePromoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disablePromoMutation, { data, loading, error }] = useDisablePromoMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDisablePromoMutation(baseOptions?: Apollo.MutationHookOptions<DisablePromoMutation, DisablePromoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DisablePromoMutation, DisablePromoMutationVariables>(DisablePromoDocument, options);
      }
export type DisablePromoMutationHookResult = ReturnType<typeof useDisablePromoMutation>;
export type DisablePromoMutationResult = Apollo.MutationResult<DisablePromoMutation>;
export type DisablePromoMutationOptions = Apollo.BaseMutationOptions<DisablePromoMutation, DisablePromoMutationVariables>;
export const CreatePromoDocument = gql`
    mutation createPromo($input: PromoDefinitionInput!) {
  createPromo(input: $input) {
    ...PromoDefinitionData
  }
}
    ${PromoDefinitionDataFragmentDoc}`;
export type CreatePromoMutationFn = Apollo.MutationFunction<CreatePromoMutation, CreatePromoMutationVariables>;

/**
 * __useCreatePromoMutation__
 *
 * To run a mutation, you first call `useCreatePromoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePromoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPromoMutation, { data, loading, error }] = useCreatePromoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePromoMutation(baseOptions?: Apollo.MutationHookOptions<CreatePromoMutation, CreatePromoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePromoMutation, CreatePromoMutationVariables>(CreatePromoDocument, options);
      }
export type CreatePromoMutationHookResult = ReturnType<typeof useCreatePromoMutation>;
export type CreatePromoMutationResult = Apollo.MutationResult<CreatePromoMutation>;
export type CreatePromoMutationOptions = Apollo.BaseMutationOptions<CreatePromoMutation, CreatePromoMutationVariables>;
export const UpdatePromoDocument = gql`
    mutation updatePromo($input: PromoDefinitionInput!) {
  updatePromo(input: $input) {
    ...PromoDefinitionData
  }
}
    ${PromoDefinitionDataFragmentDoc}`;
export type UpdatePromoMutationFn = Apollo.MutationFunction<UpdatePromoMutation, UpdatePromoMutationVariables>;

/**
 * __useUpdatePromoMutation__
 *
 * To run a mutation, you first call `useUpdatePromoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePromoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePromoMutation, { data, loading, error }] = useUpdatePromoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePromoMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePromoMutation, UpdatePromoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePromoMutation, UpdatePromoMutationVariables>(UpdatePromoDocument, options);
      }
export type UpdatePromoMutationHookResult = ReturnType<typeof useUpdatePromoMutation>;
export type UpdatePromoMutationResult = Apollo.MutationResult<UpdatePromoMutation>;
export type UpdatePromoMutationOptions = Apollo.BaseMutationOptions<UpdatePromoMutation, UpdatePromoMutationVariables>;
export const DeletePromoDocument = gql`
    mutation deletePromo($id: String!) {
  deletePromo(id: $id) {
    success
  }
}
    `;
export type DeletePromoMutationFn = Apollo.MutationFunction<DeletePromoMutation, DeletePromoMutationVariables>;

/**
 * __useDeletePromoMutation__
 *
 * To run a mutation, you first call `useDeletePromoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePromoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePromoMutation, { data, loading, error }] = useDeletePromoMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePromoMutation(baseOptions?: Apollo.MutationHookOptions<DeletePromoMutation, DeletePromoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePromoMutation, DeletePromoMutationVariables>(DeletePromoDocument, options);
      }
export type DeletePromoMutationHookResult = ReturnType<typeof useDeletePromoMutation>;
export type DeletePromoMutationResult = Apollo.MutationResult<DeletePromoMutation>;
export type DeletePromoMutationOptions = Apollo.BaseMutationOptions<DeletePromoMutation, DeletePromoMutationVariables>;
export const PromotionsDocument = gql`
    query promotions {
  promotions {
    ...PromoDefinitionData
  }
}
    ${PromoDefinitionDataFragmentDoc}`;

/**
 * __usePromotionsQuery__
 *
 * To run a query within a React component, call `usePromotionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePromotionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePromotionsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePromotionsQuery(baseOptions?: Apollo.QueryHookOptions<PromotionsQuery, PromotionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PromotionsQuery, PromotionsQueryVariables>(PromotionsDocument, options);
      }
export function usePromotionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PromotionsQuery, PromotionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PromotionsQuery, PromotionsQueryVariables>(PromotionsDocument, options);
        }
export type PromotionsQueryHookResult = ReturnType<typeof usePromotionsQuery>;
export type PromotionsLazyQueryHookResult = ReturnType<typeof usePromotionsLazyQuery>;
export type PromotionsQueryResult = Apollo.QueryResult<PromotionsQuery, PromotionsQueryVariables>;
export const PromotionDocument = gql`
    query promotion($id: String!) {
  promotion(id: $id) {
    ...PromoDefinitionData
  }
}
    ${PromoDefinitionDataFragmentDoc}`;

/**
 * __usePromotionQuery__
 *
 * To run a query within a React component, call `usePromotionQuery` and pass it any options that fit your needs.
 * When your component renders, `usePromotionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePromotionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePromotionQuery(baseOptions: Apollo.QueryHookOptions<PromotionQuery, PromotionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PromotionQuery, PromotionQueryVariables>(PromotionDocument, options);
      }
export function usePromotionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PromotionQuery, PromotionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PromotionQuery, PromotionQueryVariables>(PromotionDocument, options);
        }
export type PromotionQueryHookResult = ReturnType<typeof usePromotionQuery>;
export type PromotionLazyQueryHookResult = ReturnType<typeof usePromotionLazyQuery>;
export type PromotionQueryResult = Apollo.QueryResult<PromotionQuery, PromotionQueryVariables>;
export const GetProductInventoryDataDocument = gql`
    query getProductInventoryData($input: GetCommerceProductInputType!) {
  getCommerceProduct(input: $input) {
    product {
      info {
        productId
        productSlug
        available
      }
    }
    variants {
      shopify {
        shopifyId
        shopifyStorefrontId
      }
      info {
        variantId
        default
        available
        archived
      }
      inventory {
        price
        compareAtPrice
        maxSellQuantity
      }
    }
    defaultVariant {
      ...DefaultCatalogProductVariantSearchData
    }
  }
}
    ${DefaultCatalogProductVariantSearchDataFragmentDoc}`;

/**
 * __useGetProductInventoryDataQuery__
 *
 * To run a query within a React component, call `useGetProductInventoryDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductInventoryDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductInventoryDataQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetProductInventoryDataQuery(baseOptions: Apollo.QueryHookOptions<GetProductInventoryDataQuery, GetProductInventoryDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductInventoryDataQuery, GetProductInventoryDataQueryVariables>(GetProductInventoryDataDocument, options);
      }
export function useGetProductInventoryDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductInventoryDataQuery, GetProductInventoryDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductInventoryDataQuery, GetProductInventoryDataQueryVariables>(GetProductInventoryDataDocument, options);
        }
export type GetProductInventoryDataQueryHookResult = ReturnType<typeof useGetProductInventoryDataQuery>;
export type GetProductInventoryDataLazyQueryHookResult = ReturnType<typeof useGetProductInventoryDataLazyQuery>;
export type GetProductInventoryDataQueryResult = Apollo.QueryResult<GetProductInventoryDataQuery, GetProductInventoryDataQueryVariables>;
export const SetFeatureSwitchStatusDocument = gql`
    mutation setFeatureSwitchStatus($input: SetSwitchStatusInput!) {
  setFeatureSwitchStatus(input: $input) {
    success
  }
}
    `;
export type SetFeatureSwitchStatusMutationFn = Apollo.MutationFunction<SetFeatureSwitchStatusMutation, SetFeatureSwitchStatusMutationVariables>;

/**
 * __useSetFeatureSwitchStatusMutation__
 *
 * To run a mutation, you first call `useSetFeatureSwitchStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetFeatureSwitchStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setFeatureSwitchStatusMutation, { data, loading, error }] = useSetFeatureSwitchStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetFeatureSwitchStatusMutation(baseOptions?: Apollo.MutationHookOptions<SetFeatureSwitchStatusMutation, SetFeatureSwitchStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetFeatureSwitchStatusMutation, SetFeatureSwitchStatusMutationVariables>(SetFeatureSwitchStatusDocument, options);
      }
export type SetFeatureSwitchStatusMutationHookResult = ReturnType<typeof useSetFeatureSwitchStatusMutation>;
export type SetFeatureSwitchStatusMutationResult = Apollo.MutationResult<SetFeatureSwitchStatusMutation>;
export type SetFeatureSwitchStatusMutationOptions = Apollo.BaseMutationOptions<SetFeatureSwitchStatusMutation, SetFeatureSwitchStatusMutationVariables>;
export const UpdateSqlParamDocument = gql`
    mutation updateSqlParam($input: UpdateSqlParamInput!) {
  updateSqlParam(input: $input) {
    success
  }
}
    `;
export type UpdateSqlParamMutationFn = Apollo.MutationFunction<UpdateSqlParamMutation, UpdateSqlParamMutationVariables>;

/**
 * __useUpdateSqlParamMutation__
 *
 * To run a mutation, you first call `useUpdateSqlParamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSqlParamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSqlParamMutation, { data, loading, error }] = useUpdateSqlParamMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSqlParamMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSqlParamMutation, UpdateSqlParamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSqlParamMutation, UpdateSqlParamMutationVariables>(UpdateSqlParamDocument, options);
      }
export type UpdateSqlParamMutationHookResult = ReturnType<typeof useUpdateSqlParamMutation>;
export type UpdateSqlParamMutationResult = Apollo.MutationResult<UpdateSqlParamMutation>;
export type UpdateSqlParamMutationOptions = Apollo.BaseMutationOptions<UpdateSqlParamMutation, UpdateSqlParamMutationVariables>;
export const GetFeatureSwitchesDocument = gql`
    query getFeatureSwitches {
  getFeatureSwitches {
    id
    enabled
  }
}
    `;

/**
 * __useGetFeatureSwitchesQuery__
 *
 * To run a query within a React component, call `useGetFeatureSwitchesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeatureSwitchesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeatureSwitchesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFeatureSwitchesQuery(baseOptions?: Apollo.QueryHookOptions<GetFeatureSwitchesQuery, GetFeatureSwitchesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFeatureSwitchesQuery, GetFeatureSwitchesQueryVariables>(GetFeatureSwitchesDocument, options);
      }
export function useGetFeatureSwitchesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFeatureSwitchesQuery, GetFeatureSwitchesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFeatureSwitchesQuery, GetFeatureSwitchesQueryVariables>(GetFeatureSwitchesDocument, options);
        }
export type GetFeatureSwitchesQueryHookResult = ReturnType<typeof useGetFeatureSwitchesQuery>;
export type GetFeatureSwitchesLazyQueryHookResult = ReturnType<typeof useGetFeatureSwitchesLazyQuery>;
export type GetFeatureSwitchesQueryResult = Apollo.QueryResult<GetFeatureSwitchesQuery, GetFeatureSwitchesQueryVariables>;
export const GetSqlParamsDocument = gql`
    query getSqlParams {
  getSqlParams {
    id
    value
  }
}
    `;

/**
 * __useGetSqlParamsQuery__
 *
 * To run a query within a React component, call `useGetSqlParamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSqlParamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSqlParamsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSqlParamsQuery(baseOptions?: Apollo.QueryHookOptions<GetSqlParamsQuery, GetSqlParamsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSqlParamsQuery, GetSqlParamsQueryVariables>(GetSqlParamsDocument, options);
      }
export function useGetSqlParamsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSqlParamsQuery, GetSqlParamsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSqlParamsQuery, GetSqlParamsQueryVariables>(GetSqlParamsDocument, options);
        }
export type GetSqlParamsQueryHookResult = ReturnType<typeof useGetSqlParamsQuery>;
export type GetSqlParamsLazyQueryHookResult = ReturnType<typeof useGetSqlParamsLazyQuery>;
export type GetSqlParamsQueryResult = Apollo.QueryResult<GetSqlParamsQuery, GetSqlParamsQueryVariables>;
export const CustomerUnSubscriptionsDocument = gql`
    query customerUnSubscriptions {
  customerUnSubscriptions {
    id
    websiteName
    medium
    address
    createdOn
  }
}
    `;

/**
 * __useCustomerUnSubscriptionsQuery__
 *
 * To run a query within a React component, call `useCustomerUnSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerUnSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerUnSubscriptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCustomerUnSubscriptionsQuery(baseOptions?: Apollo.QueryHookOptions<CustomerUnSubscriptionsQuery, CustomerUnSubscriptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomerUnSubscriptionsQuery, CustomerUnSubscriptionsQueryVariables>(CustomerUnSubscriptionsDocument, options);
      }
export function useCustomerUnSubscriptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerUnSubscriptionsQuery, CustomerUnSubscriptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomerUnSubscriptionsQuery, CustomerUnSubscriptionsQueryVariables>(CustomerUnSubscriptionsDocument, options);
        }
export type CustomerUnSubscriptionsQueryHookResult = ReturnType<typeof useCustomerUnSubscriptionsQuery>;
export type CustomerUnSubscriptionsLazyQueryHookResult = ReturnType<typeof useCustomerUnSubscriptionsLazyQuery>;
export type CustomerUnSubscriptionsQueryResult = Apollo.QueryResult<CustomerUnSubscriptionsQuery, CustomerUnSubscriptionsQueryVariables>;
export const MappingsRebuildDocument = gql`
    mutation mappingsRebuild {
  mappingsRebuild {
    success
  }
}
    `;
export type MappingsRebuildMutationFn = Apollo.MutationFunction<MappingsRebuildMutation, MappingsRebuildMutationVariables>;

/**
 * __useMappingsRebuildMutation__
 *
 * To run a mutation, you first call `useMappingsRebuildMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMappingsRebuildMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mappingsRebuildMutation, { data, loading, error }] = useMappingsRebuildMutation({
 *   variables: {
 *   },
 * });
 */
export function useMappingsRebuildMutation(baseOptions?: Apollo.MutationHookOptions<MappingsRebuildMutation, MappingsRebuildMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MappingsRebuildMutation, MappingsRebuildMutationVariables>(MappingsRebuildDocument, options);
      }
export type MappingsRebuildMutationHookResult = ReturnType<typeof useMappingsRebuildMutation>;
export type MappingsRebuildMutationResult = Apollo.MutationResult<MappingsRebuildMutation>;
export type MappingsRebuildMutationOptions = Apollo.BaseMutationOptions<MappingsRebuildMutation, MappingsRebuildMutationVariables>;
export const RebuildVariantMappingsDocument = gql`
    mutation rebuildVariantMappings {
  rebuildVariantMappings {
    success
  }
}
    `;
export type RebuildVariantMappingsMutationFn = Apollo.MutationFunction<RebuildVariantMappingsMutation, RebuildVariantMappingsMutationVariables>;

/**
 * __useRebuildVariantMappingsMutation__
 *
 * To run a mutation, you first call `useRebuildVariantMappingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRebuildVariantMappingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rebuildVariantMappingsMutation, { data, loading, error }] = useRebuildVariantMappingsMutation({
 *   variables: {
 *   },
 * });
 */
export function useRebuildVariantMappingsMutation(baseOptions?: Apollo.MutationHookOptions<RebuildVariantMappingsMutation, RebuildVariantMappingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RebuildVariantMappingsMutation, RebuildVariantMappingsMutationVariables>(RebuildVariantMappingsDocument, options);
      }
export type RebuildVariantMappingsMutationHookResult = ReturnType<typeof useRebuildVariantMappingsMutation>;
export type RebuildVariantMappingsMutationResult = Apollo.MutationResult<RebuildVariantMappingsMutation>;
export type RebuildVariantMappingsMutationOptions = Apollo.BaseMutationOptions<RebuildVariantMappingsMutation, RebuildVariantMappingsMutationVariables>;
export const BackupCatalogDataDocument = gql`
    mutation backupCatalogData {
  backupCatalogData {
    success
  }
}
    `;
export type BackupCatalogDataMutationFn = Apollo.MutationFunction<BackupCatalogDataMutation, BackupCatalogDataMutationVariables>;

/**
 * __useBackupCatalogDataMutation__
 *
 * To run a mutation, you first call `useBackupCatalogDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBackupCatalogDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [backupCatalogDataMutation, { data, loading, error }] = useBackupCatalogDataMutation({
 *   variables: {
 *   },
 * });
 */
export function useBackupCatalogDataMutation(baseOptions?: Apollo.MutationHookOptions<BackupCatalogDataMutation, BackupCatalogDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BackupCatalogDataMutation, BackupCatalogDataMutationVariables>(BackupCatalogDataDocument, options);
      }
export type BackupCatalogDataMutationHookResult = ReturnType<typeof useBackupCatalogDataMutation>;
export type BackupCatalogDataMutationResult = Apollo.MutationResult<BackupCatalogDataMutation>;
export type BackupCatalogDataMutationOptions = Apollo.BaseMutationOptions<BackupCatalogDataMutation, BackupCatalogDataMutationVariables>;
export const CheckShopifyProductsDocument = gql`
    mutation checkShopifyProducts {
  checkShopifyProducts {
    success
  }
}
    `;
export type CheckShopifyProductsMutationFn = Apollo.MutationFunction<CheckShopifyProductsMutation, CheckShopifyProductsMutationVariables>;

/**
 * __useCheckShopifyProductsMutation__
 *
 * To run a mutation, you first call `useCheckShopifyProductsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckShopifyProductsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkShopifyProductsMutation, { data, loading, error }] = useCheckShopifyProductsMutation({
 *   variables: {
 *   },
 * });
 */
export function useCheckShopifyProductsMutation(baseOptions?: Apollo.MutationHookOptions<CheckShopifyProductsMutation, CheckShopifyProductsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CheckShopifyProductsMutation, CheckShopifyProductsMutationVariables>(CheckShopifyProductsDocument, options);
      }
export type CheckShopifyProductsMutationHookResult = ReturnType<typeof useCheckShopifyProductsMutation>;
export type CheckShopifyProductsMutationResult = Apollo.MutationResult<CheckShopifyProductsMutation>;
export type CheckShopifyProductsMutationOptions = Apollo.BaseMutationOptions<CheckShopifyProductsMutation, CheckShopifyProductsMutationVariables>;
export const RefreshProductVariantsDocument = gql`
    mutation refreshProductVariants($input: RefreshProductsInputType!) {
  refreshProductVariants(input: $input) {
    success
  }
}
    `;
export type RefreshProductVariantsMutationFn = Apollo.MutationFunction<RefreshProductVariantsMutation, RefreshProductVariantsMutationVariables>;

/**
 * __useRefreshProductVariantsMutation__
 *
 * To run a mutation, you first call `useRefreshProductVariantsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshProductVariantsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshProductVariantsMutation, { data, loading, error }] = useRefreshProductVariantsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRefreshProductVariantsMutation(baseOptions?: Apollo.MutationHookOptions<RefreshProductVariantsMutation, RefreshProductVariantsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefreshProductVariantsMutation, RefreshProductVariantsMutationVariables>(RefreshProductVariantsDocument, options);
      }
export type RefreshProductVariantsMutationHookResult = ReturnType<typeof useRefreshProductVariantsMutation>;
export type RefreshProductVariantsMutationResult = Apollo.MutationResult<RefreshProductVariantsMutation>;
export type RefreshProductVariantsMutationOptions = Apollo.BaseMutationOptions<RefreshProductVariantsMutation, RefreshProductVariantsMutationVariables>;
export const ImportMetaDocument = gql`
    mutation importMeta {
  importMeta {
    success
  }
}
    `;
export type ImportMetaMutationFn = Apollo.MutationFunction<ImportMetaMutation, ImportMetaMutationVariables>;

/**
 * __useImportMetaMutation__
 *
 * To run a mutation, you first call `useImportMetaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportMetaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importMetaMutation, { data, loading, error }] = useImportMetaMutation({
 *   variables: {
 *   },
 * });
 */
export function useImportMetaMutation(baseOptions?: Apollo.MutationHookOptions<ImportMetaMutation, ImportMetaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportMetaMutation, ImportMetaMutationVariables>(ImportMetaDocument, options);
      }
export type ImportMetaMutationHookResult = ReturnType<typeof useImportMetaMutation>;
export type ImportMetaMutationResult = Apollo.MutationResult<ImportMetaMutation>;
export type ImportMetaMutationOptions = Apollo.BaseMutationOptions<ImportMetaMutation, ImportMetaMutationVariables>;
export const IndexMetaDocument = gql`
    mutation indexMeta {
  indexMeta {
    success
  }
}
    `;
export type IndexMetaMutationFn = Apollo.MutationFunction<IndexMetaMutation, IndexMetaMutationVariables>;

/**
 * __useIndexMetaMutation__
 *
 * To run a mutation, you first call `useIndexMetaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIndexMetaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [indexMetaMutation, { data, loading, error }] = useIndexMetaMutation({
 *   variables: {
 *   },
 * });
 */
export function useIndexMetaMutation(baseOptions?: Apollo.MutationHookOptions<IndexMetaMutation, IndexMetaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IndexMetaMutation, IndexMetaMutationVariables>(IndexMetaDocument, options);
      }
export type IndexMetaMutationHookResult = ReturnType<typeof useIndexMetaMutation>;
export type IndexMetaMutationResult = Apollo.MutationResult<IndexMetaMutation>;
export type IndexMetaMutationOptions = Apollo.BaseMutationOptions<IndexMetaMutation, IndexMetaMutationVariables>;
export const ProcessNavCatalogDataDocument = gql`
    mutation processNavCatalogData($feedUrl: String!, $options: ProcessNavCatalogOptions!) {
  processNavCatalogData(feedUrl: $feedUrl, options: $options) {
    success
  }
}
    `;
export type ProcessNavCatalogDataMutationFn = Apollo.MutationFunction<ProcessNavCatalogDataMutation, ProcessNavCatalogDataMutationVariables>;

/**
 * __useProcessNavCatalogDataMutation__
 *
 * To run a mutation, you first call `useProcessNavCatalogDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProcessNavCatalogDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [processNavCatalogDataMutation, { data, loading, error }] = useProcessNavCatalogDataMutation({
 *   variables: {
 *      feedUrl: // value for 'feedUrl'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useProcessNavCatalogDataMutation(baseOptions?: Apollo.MutationHookOptions<ProcessNavCatalogDataMutation, ProcessNavCatalogDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProcessNavCatalogDataMutation, ProcessNavCatalogDataMutationVariables>(ProcessNavCatalogDataDocument, options);
      }
export type ProcessNavCatalogDataMutationHookResult = ReturnType<typeof useProcessNavCatalogDataMutation>;
export type ProcessNavCatalogDataMutationResult = Apollo.MutationResult<ProcessNavCatalogDataMutation>;
export type ProcessNavCatalogDataMutationOptions = Apollo.BaseMutationOptions<ProcessNavCatalogDataMutation, ProcessNavCatalogDataMutationVariables>;
export const ProcessNavStockDataDocument = gql`
    mutation processNavStockData($feedUrl: String!, $options: ProcessNavStockOptions!) {
  processNavStockData(feedUrl: $feedUrl, options: $options) {
    success
  }
}
    `;
export type ProcessNavStockDataMutationFn = Apollo.MutationFunction<ProcessNavStockDataMutation, ProcessNavStockDataMutationVariables>;

/**
 * __useProcessNavStockDataMutation__
 *
 * To run a mutation, you first call `useProcessNavStockDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProcessNavStockDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [processNavStockDataMutation, { data, loading, error }] = useProcessNavStockDataMutation({
 *   variables: {
 *      feedUrl: // value for 'feedUrl'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useProcessNavStockDataMutation(baseOptions?: Apollo.MutationHookOptions<ProcessNavStockDataMutation, ProcessNavStockDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProcessNavStockDataMutation, ProcessNavStockDataMutationVariables>(ProcessNavStockDataDocument, options);
      }
export type ProcessNavStockDataMutationHookResult = ReturnType<typeof useProcessNavStockDataMutation>;
export type ProcessNavStockDataMutationResult = Apollo.MutationResult<ProcessNavStockDataMutation>;
export type ProcessNavStockDataMutationOptions = Apollo.BaseMutationOptions<ProcessNavStockDataMutation, ProcessNavStockDataMutationVariables>;
export const SyncNavCatalogEventsDocument = gql`
    mutation syncNavCatalogEvents {
  syncNavCatalogEvents {
    success
  }
}
    `;
export type SyncNavCatalogEventsMutationFn = Apollo.MutationFunction<SyncNavCatalogEventsMutation, SyncNavCatalogEventsMutationVariables>;

/**
 * __useSyncNavCatalogEventsMutation__
 *
 * To run a mutation, you first call `useSyncNavCatalogEventsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncNavCatalogEventsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncNavCatalogEventsMutation, { data, loading, error }] = useSyncNavCatalogEventsMutation({
 *   variables: {
 *   },
 * });
 */
export function useSyncNavCatalogEventsMutation(baseOptions?: Apollo.MutationHookOptions<SyncNavCatalogEventsMutation, SyncNavCatalogEventsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SyncNavCatalogEventsMutation, SyncNavCatalogEventsMutationVariables>(SyncNavCatalogEventsDocument, options);
      }
export type SyncNavCatalogEventsMutationHookResult = ReturnType<typeof useSyncNavCatalogEventsMutation>;
export type SyncNavCatalogEventsMutationResult = Apollo.MutationResult<SyncNavCatalogEventsMutation>;
export type SyncNavCatalogEventsMutationOptions = Apollo.BaseMutationOptions<SyncNavCatalogEventsMutation, SyncNavCatalogEventsMutationVariables>;
export const SyncNavStockEventsDocument = gql`
    mutation syncNavStockEvents {
  syncNavStockEvents {
    success
  }
}
    `;
export type SyncNavStockEventsMutationFn = Apollo.MutationFunction<SyncNavStockEventsMutation, SyncNavStockEventsMutationVariables>;

/**
 * __useSyncNavStockEventsMutation__
 *
 * To run a mutation, you first call `useSyncNavStockEventsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncNavStockEventsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncNavStockEventsMutation, { data, loading, error }] = useSyncNavStockEventsMutation({
 *   variables: {
 *   },
 * });
 */
export function useSyncNavStockEventsMutation(baseOptions?: Apollo.MutationHookOptions<SyncNavStockEventsMutation, SyncNavStockEventsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SyncNavStockEventsMutation, SyncNavStockEventsMutationVariables>(SyncNavStockEventsDocument, options);
      }
export type SyncNavStockEventsMutationHookResult = ReturnType<typeof useSyncNavStockEventsMutation>;
export type SyncNavStockEventsMutationResult = Apollo.MutationResult<SyncNavStockEventsMutation>;
export type SyncNavStockEventsMutationOptions = Apollo.BaseMutationOptions<SyncNavStockEventsMutation, SyncNavStockEventsMutationVariables>;
export const ReindexProductsDocument = gql`
    mutation reindexProducts {
  reindexProducts {
    success
  }
}
    `;
export type ReindexProductsMutationFn = Apollo.MutationFunction<ReindexProductsMutation, ReindexProductsMutationVariables>;

/**
 * __useReindexProductsMutation__
 *
 * To run a mutation, you first call `useReindexProductsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReindexProductsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reindexProductsMutation, { data, loading, error }] = useReindexProductsMutation({
 *   variables: {
 *   },
 * });
 */
export function useReindexProductsMutation(baseOptions?: Apollo.MutationHookOptions<ReindexProductsMutation, ReindexProductsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReindexProductsMutation, ReindexProductsMutationVariables>(ReindexProductsDocument, options);
      }
export type ReindexProductsMutationHookResult = ReturnType<typeof useReindexProductsMutation>;
export type ReindexProductsMutationResult = Apollo.MutationResult<ReindexProductsMutation>;
export type ReindexProductsMutationOptions = Apollo.BaseMutationOptions<ReindexProductsMutation, ReindexProductsMutationVariables>;
export const ReindexProductDocument = gql`
    mutation reindexProduct($productId: String!) {
  reindexProduct(productId: $productId) {
    success
  }
}
    `;
export type ReindexProductMutationFn = Apollo.MutationFunction<ReindexProductMutation, ReindexProductMutationVariables>;

/**
 * __useReindexProductMutation__
 *
 * To run a mutation, you first call `useReindexProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReindexProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reindexProductMutation, { data, loading, error }] = useReindexProductMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useReindexProductMutation(baseOptions?: Apollo.MutationHookOptions<ReindexProductMutation, ReindexProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReindexProductMutation, ReindexProductMutationVariables>(ReindexProductDocument, options);
      }
export type ReindexProductMutationHookResult = ReturnType<typeof useReindexProductMutation>;
export type ReindexProductMutationResult = Apollo.MutationResult<ReindexProductMutation>;
export type ReindexProductMutationOptions = Apollo.BaseMutationOptions<ReindexProductMutation, ReindexProductMutationVariables>;
export const DeleteElasticProductDocument = gql`
    mutation deleteElasticProduct($productId: String!) {
  deleteElasticProduct(productId: $productId) {
    success
  }
}
    `;
export type DeleteElasticProductMutationFn = Apollo.MutationFunction<DeleteElasticProductMutation, DeleteElasticProductMutationVariables>;

/**
 * __useDeleteElasticProductMutation__
 *
 * To run a mutation, you first call `useDeleteElasticProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteElasticProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteElasticProductMutation, { data, loading, error }] = useDeleteElasticProductMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useDeleteElasticProductMutation(baseOptions?: Apollo.MutationHookOptions<DeleteElasticProductMutation, DeleteElasticProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteElasticProductMutation, DeleteElasticProductMutationVariables>(DeleteElasticProductDocument, options);
      }
export type DeleteElasticProductMutationHookResult = ReturnType<typeof useDeleteElasticProductMutation>;
export type DeleteElasticProductMutationResult = Apollo.MutationResult<DeleteElasticProductMutation>;
export type DeleteElasticProductMutationOptions = Apollo.BaseMutationOptions<DeleteElasticProductMutation, DeleteElasticProductMutationVariables>;
export const ReindexBrandProductsDocument = gql`
    mutation reindexBrandProducts($brandCode: String!) {
  reindexBrandProducts(brandCode: $brandCode) {
    success
  }
}
    `;
export type ReindexBrandProductsMutationFn = Apollo.MutationFunction<ReindexBrandProductsMutation, ReindexBrandProductsMutationVariables>;

/**
 * __useReindexBrandProductsMutation__
 *
 * To run a mutation, you first call `useReindexBrandProductsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReindexBrandProductsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reindexBrandProductsMutation, { data, loading, error }] = useReindexBrandProductsMutation({
 *   variables: {
 *      brandCode: // value for 'brandCode'
 *   },
 * });
 */
export function useReindexBrandProductsMutation(baseOptions?: Apollo.MutationHookOptions<ReindexBrandProductsMutation, ReindexBrandProductsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReindexBrandProductsMutation, ReindexBrandProductsMutationVariables>(ReindexBrandProductsDocument, options);
      }
export type ReindexBrandProductsMutationHookResult = ReturnType<typeof useReindexBrandProductsMutation>;
export type ReindexBrandProductsMutationResult = Apollo.MutationResult<ReindexBrandProductsMutation>;
export type ReindexBrandProductsMutationOptions = Apollo.BaseMutationOptions<ReindexBrandProductsMutation, ReindexBrandProductsMutationVariables>;
export const ImportStoresDocument = gql`
    mutation importStores($feedUrl: String!) {
  importStores(input: {feedUrl: $feedUrl}) {
    success
  }
}
    `;
export type ImportStoresMutationFn = Apollo.MutationFunction<ImportStoresMutation, ImportStoresMutationVariables>;

/**
 * __useImportStoresMutation__
 *
 * To run a mutation, you first call `useImportStoresMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportStoresMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importStoresMutation, { data, loading, error }] = useImportStoresMutation({
 *   variables: {
 *      feedUrl: // value for 'feedUrl'
 *   },
 * });
 */
export function useImportStoresMutation(baseOptions?: Apollo.MutationHookOptions<ImportStoresMutation, ImportStoresMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportStoresMutation, ImportStoresMutationVariables>(ImportStoresDocument, options);
      }
export type ImportStoresMutationHookResult = ReturnType<typeof useImportStoresMutation>;
export type ImportStoresMutationResult = Apollo.MutationResult<ImportStoresMutation>;
export type ImportStoresMutationOptions = Apollo.BaseMutationOptions<ImportStoresMutation, ImportStoresMutationVariables>;
export const RunShopifySanitizeDocument = gql`
    mutation runShopifySanitize($startFromProductId: String!) {
  runShopifySanitize(startFromProductId: $startFromProductId) {
    success
  }
}
    `;
export type RunShopifySanitizeMutationFn = Apollo.MutationFunction<RunShopifySanitizeMutation, RunShopifySanitizeMutationVariables>;

/**
 * __useRunShopifySanitizeMutation__
 *
 * To run a mutation, you first call `useRunShopifySanitizeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRunShopifySanitizeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [runShopifySanitizeMutation, { data, loading, error }] = useRunShopifySanitizeMutation({
 *   variables: {
 *      startFromProductId: // value for 'startFromProductId'
 *   },
 * });
 */
export function useRunShopifySanitizeMutation(baseOptions?: Apollo.MutationHookOptions<RunShopifySanitizeMutation, RunShopifySanitizeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RunShopifySanitizeMutation, RunShopifySanitizeMutationVariables>(RunShopifySanitizeDocument, options);
      }
export type RunShopifySanitizeMutationHookResult = ReturnType<typeof useRunShopifySanitizeMutation>;
export type RunShopifySanitizeMutationResult = Apollo.MutationResult<RunShopifySanitizeMutation>;
export type RunShopifySanitizeMutationOptions = Apollo.BaseMutationOptions<RunShopifySanitizeMutation, RunShopifySanitizeMutationVariables>;
export const RunCheckoutUnlockJobDocument = gql`
    mutation runCheckoutUnlockJob {
  runCheckoutUnlockJob {
    success
  }
}
    `;
export type RunCheckoutUnlockJobMutationFn = Apollo.MutationFunction<RunCheckoutUnlockJobMutation, RunCheckoutUnlockJobMutationVariables>;

/**
 * __useRunCheckoutUnlockJobMutation__
 *
 * To run a mutation, you first call `useRunCheckoutUnlockJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRunCheckoutUnlockJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [runCheckoutUnlockJobMutation, { data, loading, error }] = useRunCheckoutUnlockJobMutation({
 *   variables: {
 *   },
 * });
 */
export function useRunCheckoutUnlockJobMutation(baseOptions?: Apollo.MutationHookOptions<RunCheckoutUnlockJobMutation, RunCheckoutUnlockJobMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RunCheckoutUnlockJobMutation, RunCheckoutUnlockJobMutationVariables>(RunCheckoutUnlockJobDocument, options);
      }
export type RunCheckoutUnlockJobMutationHookResult = ReturnType<typeof useRunCheckoutUnlockJobMutation>;
export type RunCheckoutUnlockJobMutationResult = Apollo.MutationResult<RunCheckoutUnlockJobMutation>;
export type RunCheckoutUnlockJobMutationOptions = Apollo.BaseMutationOptions<RunCheckoutUnlockJobMutation, RunCheckoutUnlockJobMutationVariables>;
export const RunExpiredBasketsJobDocument = gql`
    mutation runExpiredBasketsJob {
  runExpiredBasketsJob {
    success
  }
}
    `;
export type RunExpiredBasketsJobMutationFn = Apollo.MutationFunction<RunExpiredBasketsJobMutation, RunExpiredBasketsJobMutationVariables>;

/**
 * __useRunExpiredBasketsJobMutation__
 *
 * To run a mutation, you first call `useRunExpiredBasketsJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRunExpiredBasketsJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [runExpiredBasketsJobMutation, { data, loading, error }] = useRunExpiredBasketsJobMutation({
 *   variables: {
 *   },
 * });
 */
export function useRunExpiredBasketsJobMutation(baseOptions?: Apollo.MutationHookOptions<RunExpiredBasketsJobMutation, RunExpiredBasketsJobMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RunExpiredBasketsJobMutation, RunExpiredBasketsJobMutationVariables>(RunExpiredBasketsJobDocument, options);
      }
export type RunExpiredBasketsJobMutationHookResult = ReturnType<typeof useRunExpiredBasketsJobMutation>;
export type RunExpiredBasketsJobMutationResult = Apollo.MutationResult<RunExpiredBasketsJobMutation>;
export type RunExpiredBasketsJobMutationOptions = Apollo.BaseMutationOptions<RunExpiredBasketsJobMutation, RunExpiredBasketsJobMutationVariables>;
export const RunProductsReportJobDocument = gql`
    mutation runProductsReportJob {
  runProductsReportJob {
    success
  }
}
    `;
export type RunProductsReportJobMutationFn = Apollo.MutationFunction<RunProductsReportJobMutation, RunProductsReportJobMutationVariables>;

/**
 * __useRunProductsReportJobMutation__
 *
 * To run a mutation, you first call `useRunProductsReportJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRunProductsReportJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [runProductsReportJobMutation, { data, loading, error }] = useRunProductsReportJobMutation({
 *   variables: {
 *   },
 * });
 */
export function useRunProductsReportJobMutation(baseOptions?: Apollo.MutationHookOptions<RunProductsReportJobMutation, RunProductsReportJobMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RunProductsReportJobMutation, RunProductsReportJobMutationVariables>(RunProductsReportJobDocument, options);
      }
export type RunProductsReportJobMutationHookResult = ReturnType<typeof useRunProductsReportJobMutation>;
export type RunProductsReportJobMutationResult = Apollo.MutationResult<RunProductsReportJobMutation>;
export type RunProductsReportJobMutationOptions = Apollo.BaseMutationOptions<RunProductsReportJobMutation, RunProductsReportJobMutationVariables>;
export const RunProductsReportExportDocument = gql`
    mutation runProductsReportExport($input: ReportExtractionInput!) {
  runProductsReportExport(input: $input) {
    success
  }
}
    `;
export type RunProductsReportExportMutationFn = Apollo.MutationFunction<RunProductsReportExportMutation, RunProductsReportExportMutationVariables>;

/**
 * __useRunProductsReportExportMutation__
 *
 * To run a mutation, you first call `useRunProductsReportExportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRunProductsReportExportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [runProductsReportExportMutation, { data, loading, error }] = useRunProductsReportExportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRunProductsReportExportMutation(baseOptions?: Apollo.MutationHookOptions<RunProductsReportExportMutation, RunProductsReportExportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RunProductsReportExportMutation, RunProductsReportExportMutationVariables>(RunProductsReportExportDocument, options);
      }
export type RunProductsReportExportMutationHookResult = ReturnType<typeof useRunProductsReportExportMutation>;
export type RunProductsReportExportMutationResult = Apollo.MutationResult<RunProductsReportExportMutation>;
export type RunProductsReportExportMutationOptions = Apollo.BaseMutationOptions<RunProductsReportExportMutation, RunProductsReportExportMutationVariables>;
export const SyncAlgoliaProductsDocument = gql`
    mutation syncAlgoliaProducts($fullImport: Boolean!) {
  syncAlgoliaProducts(fullImport: $fullImport) {
    success
  }
}
    `;
export type SyncAlgoliaProductsMutationFn = Apollo.MutationFunction<SyncAlgoliaProductsMutation, SyncAlgoliaProductsMutationVariables>;

/**
 * __useSyncAlgoliaProductsMutation__
 *
 * To run a mutation, you first call `useSyncAlgoliaProductsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncAlgoliaProductsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncAlgoliaProductsMutation, { data, loading, error }] = useSyncAlgoliaProductsMutation({
 *   variables: {
 *      fullImport: // value for 'fullImport'
 *   },
 * });
 */
export function useSyncAlgoliaProductsMutation(baseOptions?: Apollo.MutationHookOptions<SyncAlgoliaProductsMutation, SyncAlgoliaProductsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SyncAlgoliaProductsMutation, SyncAlgoliaProductsMutationVariables>(SyncAlgoliaProductsDocument, options);
      }
export type SyncAlgoliaProductsMutationHookResult = ReturnType<typeof useSyncAlgoliaProductsMutation>;
export type SyncAlgoliaProductsMutationResult = Apollo.MutationResult<SyncAlgoliaProductsMutation>;
export type SyncAlgoliaProductsMutationOptions = Apollo.BaseMutationOptions<SyncAlgoliaProductsMutation, SyncAlgoliaProductsMutationVariables>;
export const ProductInventoriesRefreshDocument = gql`
    mutation productInventoriesRefresh($reprocessAll: Boolean!) {
  productInventoriesRefresh(reprocessAll: $reprocessAll) {
    success
  }
}
    `;
export type ProductInventoriesRefreshMutationFn = Apollo.MutationFunction<ProductInventoriesRefreshMutation, ProductInventoriesRefreshMutationVariables>;

/**
 * __useProductInventoriesRefreshMutation__
 *
 * To run a mutation, you first call `useProductInventoriesRefreshMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductInventoriesRefreshMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productInventoriesRefreshMutation, { data, loading, error }] = useProductInventoriesRefreshMutation({
 *   variables: {
 *      reprocessAll: // value for 'reprocessAll'
 *   },
 * });
 */
export function useProductInventoriesRefreshMutation(baseOptions?: Apollo.MutationHookOptions<ProductInventoriesRefreshMutation, ProductInventoriesRefreshMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProductInventoriesRefreshMutation, ProductInventoriesRefreshMutationVariables>(ProductInventoriesRefreshDocument, options);
      }
export type ProductInventoriesRefreshMutationHookResult = ReturnType<typeof useProductInventoriesRefreshMutation>;
export type ProductInventoriesRefreshMutationResult = Apollo.MutationResult<ProductInventoriesRefreshMutation>;
export type ProductInventoriesRefreshMutationOptions = Apollo.BaseMutationOptions<ProductInventoriesRefreshMutation, ProductInventoriesRefreshMutationVariables>;
export const RunBasketPaymentSessionsCheckDocument = gql`
    mutation runBasketPaymentSessionsCheck {
  runBasketPaymentSessionsCheck {
    success
  }
}
    `;
export type RunBasketPaymentSessionsCheckMutationFn = Apollo.MutationFunction<RunBasketPaymentSessionsCheckMutation, RunBasketPaymentSessionsCheckMutationVariables>;

/**
 * __useRunBasketPaymentSessionsCheckMutation__
 *
 * To run a mutation, you first call `useRunBasketPaymentSessionsCheckMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRunBasketPaymentSessionsCheckMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [runBasketPaymentSessionsCheckMutation, { data, loading, error }] = useRunBasketPaymentSessionsCheckMutation({
 *   variables: {
 *   },
 * });
 */
export function useRunBasketPaymentSessionsCheckMutation(baseOptions?: Apollo.MutationHookOptions<RunBasketPaymentSessionsCheckMutation, RunBasketPaymentSessionsCheckMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RunBasketPaymentSessionsCheckMutation, RunBasketPaymentSessionsCheckMutationVariables>(RunBasketPaymentSessionsCheckDocument, options);
      }
export type RunBasketPaymentSessionsCheckMutationHookResult = ReturnType<typeof useRunBasketPaymentSessionsCheckMutation>;
export type RunBasketPaymentSessionsCheckMutationResult = Apollo.MutationResult<RunBasketPaymentSessionsCheckMutation>;
export type RunBasketPaymentSessionsCheckMutationOptions = Apollo.BaseMutationOptions<RunBasketPaymentSessionsCheckMutation, RunBasketPaymentSessionsCheckMutationVariables>;
export const RunOrdersCheckDocument = gql`
    mutation runOrdersCheck {
  runOrdersCheck {
    success
  }
}
    `;
export type RunOrdersCheckMutationFn = Apollo.MutationFunction<RunOrdersCheckMutation, RunOrdersCheckMutationVariables>;

/**
 * __useRunOrdersCheckMutation__
 *
 * To run a mutation, you first call `useRunOrdersCheckMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRunOrdersCheckMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [runOrdersCheckMutation, { data, loading, error }] = useRunOrdersCheckMutation({
 *   variables: {
 *   },
 * });
 */
export function useRunOrdersCheckMutation(baseOptions?: Apollo.MutationHookOptions<RunOrdersCheckMutation, RunOrdersCheckMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RunOrdersCheckMutation, RunOrdersCheckMutationVariables>(RunOrdersCheckDocument, options);
      }
export type RunOrdersCheckMutationHookResult = ReturnType<typeof useRunOrdersCheckMutation>;
export type RunOrdersCheckMutationResult = Apollo.MutationResult<RunOrdersCheckMutation>;
export type RunOrdersCheckMutationOptions = Apollo.BaseMutationOptions<RunOrdersCheckMutation, RunOrdersCheckMutationVariables>;
export const RunProductUpdateSimulationDocument = gql`
    mutation runProductUpdateSimulation {
  runProductUpdateSimulation {
    success
  }
}
    `;
export type RunProductUpdateSimulationMutationFn = Apollo.MutationFunction<RunProductUpdateSimulationMutation, RunProductUpdateSimulationMutationVariables>;

/**
 * __useRunProductUpdateSimulationMutation__
 *
 * To run a mutation, you first call `useRunProductUpdateSimulationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRunProductUpdateSimulationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [runProductUpdateSimulationMutation, { data, loading, error }] = useRunProductUpdateSimulationMutation({
 *   variables: {
 *   },
 * });
 */
export function useRunProductUpdateSimulationMutation(baseOptions?: Apollo.MutationHookOptions<RunProductUpdateSimulationMutation, RunProductUpdateSimulationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RunProductUpdateSimulationMutation, RunProductUpdateSimulationMutationVariables>(RunProductUpdateSimulationDocument, options);
      }
export type RunProductUpdateSimulationMutationHookResult = ReturnType<typeof useRunProductUpdateSimulationMutation>;
export type RunProductUpdateSimulationMutationResult = Apollo.MutationResult<RunProductUpdateSimulationMutation>;
export type RunProductUpdateSimulationMutationOptions = Apollo.BaseMutationOptions<RunProductUpdateSimulationMutation, RunProductUpdateSimulationMutationVariables>;
export const RunSyncAlgoliaOrderTransactionsDocument = gql`
    mutation runSyncAlgoliaOrderTransactions {
  runSyncAlgoliaOrderTransactions {
    success
  }
}
    `;
export type RunSyncAlgoliaOrderTransactionsMutationFn = Apollo.MutationFunction<RunSyncAlgoliaOrderTransactionsMutation, RunSyncAlgoliaOrderTransactionsMutationVariables>;

/**
 * __useRunSyncAlgoliaOrderTransactionsMutation__
 *
 * To run a mutation, you first call `useRunSyncAlgoliaOrderTransactionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRunSyncAlgoliaOrderTransactionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [runSyncAlgoliaOrderTransactionsMutation, { data, loading, error }] = useRunSyncAlgoliaOrderTransactionsMutation({
 *   variables: {
 *   },
 * });
 */
export function useRunSyncAlgoliaOrderTransactionsMutation(baseOptions?: Apollo.MutationHookOptions<RunSyncAlgoliaOrderTransactionsMutation, RunSyncAlgoliaOrderTransactionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RunSyncAlgoliaOrderTransactionsMutation, RunSyncAlgoliaOrderTransactionsMutationVariables>(RunSyncAlgoliaOrderTransactionsDocument, options);
      }
export type RunSyncAlgoliaOrderTransactionsMutationHookResult = ReturnType<typeof useRunSyncAlgoliaOrderTransactionsMutation>;
export type RunSyncAlgoliaOrderTransactionsMutationResult = Apollo.MutationResult<RunSyncAlgoliaOrderTransactionsMutation>;
export type RunSyncAlgoliaOrderTransactionsMutationOptions = Apollo.BaseMutationOptions<RunSyncAlgoliaOrderTransactionsMutation, RunSyncAlgoliaOrderTransactionsMutationVariables>;
export const RunMaintenanceJobsDocument = gql`
    mutation runMaintenanceJobs {
  runMaintenanceJobs {
    success
  }
}
    `;
export type RunMaintenanceJobsMutationFn = Apollo.MutationFunction<RunMaintenanceJobsMutation, RunMaintenanceJobsMutationVariables>;

/**
 * __useRunMaintenanceJobsMutation__
 *
 * To run a mutation, you first call `useRunMaintenanceJobsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRunMaintenanceJobsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [runMaintenanceJobsMutation, { data, loading, error }] = useRunMaintenanceJobsMutation({
 *   variables: {
 *   },
 * });
 */
export function useRunMaintenanceJobsMutation(baseOptions?: Apollo.MutationHookOptions<RunMaintenanceJobsMutation, RunMaintenanceJobsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RunMaintenanceJobsMutation, RunMaintenanceJobsMutationVariables>(RunMaintenanceJobsDocument, options);
      }
export type RunMaintenanceJobsMutationHookResult = ReturnType<typeof useRunMaintenanceJobsMutation>;
export type RunMaintenanceJobsMutationResult = Apollo.MutationResult<RunMaintenanceJobsMutation>;
export type RunMaintenanceJobsMutationOptions = Apollo.BaseMutationOptions<RunMaintenanceJobsMutation, RunMaintenanceJobsMutationVariables>;
export const ImportZipCodesDocument = gql`
    mutation importZipCodes($input: ImportZipCodesInput!) {
  importZipCodes(input: $input) {
    success
  }
}
    `;
export type ImportZipCodesMutationFn = Apollo.MutationFunction<ImportZipCodesMutation, ImportZipCodesMutationVariables>;

/**
 * __useImportZipCodesMutation__
 *
 * To run a mutation, you first call `useImportZipCodesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportZipCodesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importZipCodesMutation, { data, loading, error }] = useImportZipCodesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportZipCodesMutation(baseOptions?: Apollo.MutationHookOptions<ImportZipCodesMutation, ImportZipCodesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportZipCodesMutation, ImportZipCodesMutationVariables>(ImportZipCodesDocument, options);
      }
export type ImportZipCodesMutationHookResult = ReturnType<typeof useImportZipCodesMutation>;
export type ImportZipCodesMutationResult = Apollo.MutationResult<ImportZipCodesMutation>;
export type ImportZipCodesMutationOptions = Apollo.BaseMutationOptions<ImportZipCodesMutation, ImportZipCodesMutationVariables>;
export const ExecutePerformancesSyncTaskDocument = gql`
    mutation executePerformancesSyncTask {
  executePerformancesSyncTask {
    success
  }
}
    `;
export type ExecutePerformancesSyncTaskMutationFn = Apollo.MutationFunction<ExecutePerformancesSyncTaskMutation, ExecutePerformancesSyncTaskMutationVariables>;

/**
 * __useExecutePerformancesSyncTaskMutation__
 *
 * To run a mutation, you first call `useExecutePerformancesSyncTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExecutePerformancesSyncTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [executePerformancesSyncTaskMutation, { data, loading, error }] = useExecutePerformancesSyncTaskMutation({
 *   variables: {
 *   },
 * });
 */
export function useExecutePerformancesSyncTaskMutation(baseOptions?: Apollo.MutationHookOptions<ExecutePerformancesSyncTaskMutation, ExecutePerformancesSyncTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExecutePerformancesSyncTaskMutation, ExecutePerformancesSyncTaskMutationVariables>(ExecutePerformancesSyncTaskDocument, options);
      }
export type ExecutePerformancesSyncTaskMutationHookResult = ReturnType<typeof useExecutePerformancesSyncTaskMutation>;
export type ExecutePerformancesSyncTaskMutationResult = Apollo.MutationResult<ExecutePerformancesSyncTaskMutation>;
export type ExecutePerformancesSyncTaskMutationOptions = Apollo.BaseMutationOptions<ExecutePerformancesSyncTaskMutation, ExecutePerformancesSyncTaskMutationVariables>;
export const JobUnlockDocument = gql`
    mutation jobUnlock($jobUid: String!) {
  jobUnlock(jobUid: $jobUid) {
    success
  }
}
    `;
export type JobUnlockMutationFn = Apollo.MutationFunction<JobUnlockMutation, JobUnlockMutationVariables>;

/**
 * __useJobUnlockMutation__
 *
 * To run a mutation, you first call `useJobUnlockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobUnlockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobUnlockMutation, { data, loading, error }] = useJobUnlockMutation({
 *   variables: {
 *      jobUid: // value for 'jobUid'
 *   },
 * });
 */
export function useJobUnlockMutation(baseOptions?: Apollo.MutationHookOptions<JobUnlockMutation, JobUnlockMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<JobUnlockMutation, JobUnlockMutationVariables>(JobUnlockDocument, options);
      }
export type JobUnlockMutationHookResult = ReturnType<typeof useJobUnlockMutation>;
export type JobUnlockMutationResult = Apollo.MutationResult<JobUnlockMutation>;
export type JobUnlockMutationOptions = Apollo.BaseMutationOptions<JobUnlockMutation, JobUnlockMutationVariables>;
export const JobInstancesDocument = gql`
    query jobInstances {
  jobInstances {
    id
    jobUid
    runUid
    status
    runType
    startTime
    endTime
    result
    error
    createdDate
    updatedDate
  }
}
    `;

/**
 * __useJobInstancesQuery__
 *
 * To run a query within a React component, call `useJobInstancesQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobInstancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobInstancesQuery({
 *   variables: {
 *   },
 * });
 */
export function useJobInstancesQuery(baseOptions?: Apollo.QueryHookOptions<JobInstancesQuery, JobInstancesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JobInstancesQuery, JobInstancesQueryVariables>(JobInstancesDocument, options);
      }
export function useJobInstancesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JobInstancesQuery, JobInstancesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JobInstancesQuery, JobInstancesQueryVariables>(JobInstancesDocument, options);
        }
export type JobInstancesQueryHookResult = ReturnType<typeof useJobInstancesQuery>;
export type JobInstancesLazyQueryHookResult = ReturnType<typeof useJobInstancesLazyQuery>;
export type JobInstancesQueryResult = Apollo.QueryResult<JobInstancesQuery, JobInstancesQueryVariables>;
export const LoginDocument = gql`
    mutation login($input: LoginInput!) {
  login(input: $input) {
    success
    errorType
    accessToken
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const BackofficeUserRoleSetDocument = gql`
    mutation backofficeUserRoleSet($input: BackofficeUserRoleSetInput!) {
  backofficeUserRoleSet(input: $input) {
    success
  }
}
    `;
export type BackofficeUserRoleSetMutationFn = Apollo.MutationFunction<BackofficeUserRoleSetMutation, BackofficeUserRoleSetMutationVariables>;

/**
 * __useBackofficeUserRoleSetMutation__
 *
 * To run a mutation, you first call `useBackofficeUserRoleSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBackofficeUserRoleSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [backofficeUserRoleSetMutation, { data, loading, error }] = useBackofficeUserRoleSetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBackofficeUserRoleSetMutation(baseOptions?: Apollo.MutationHookOptions<BackofficeUserRoleSetMutation, BackofficeUserRoleSetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BackofficeUserRoleSetMutation, BackofficeUserRoleSetMutationVariables>(BackofficeUserRoleSetDocument, options);
      }
export type BackofficeUserRoleSetMutationHookResult = ReturnType<typeof useBackofficeUserRoleSetMutation>;
export type BackofficeUserRoleSetMutationResult = Apollo.MutationResult<BackofficeUserRoleSetMutation>;
export type BackofficeUserRoleSetMutationOptions = Apollo.BaseMutationOptions<BackofficeUserRoleSetMutation, BackofficeUserRoleSetMutationVariables>;
export const BackofficeUserRolesRemoveDocument = gql`
    mutation backofficeUserRolesRemove($input: BackofficeUserRolesRemoveInput!) {
  backofficeUserRolesRemove(input: $input) {
    success
  }
}
    `;
export type BackofficeUserRolesRemoveMutationFn = Apollo.MutationFunction<BackofficeUserRolesRemoveMutation, BackofficeUserRolesRemoveMutationVariables>;

/**
 * __useBackofficeUserRolesRemoveMutation__
 *
 * To run a mutation, you first call `useBackofficeUserRolesRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBackofficeUserRolesRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [backofficeUserRolesRemoveMutation, { data, loading, error }] = useBackofficeUserRolesRemoveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBackofficeUserRolesRemoveMutation(baseOptions?: Apollo.MutationHookOptions<BackofficeUserRolesRemoveMutation, BackofficeUserRolesRemoveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BackofficeUserRolesRemoveMutation, BackofficeUserRolesRemoveMutationVariables>(BackofficeUserRolesRemoveDocument, options);
      }
export type BackofficeUserRolesRemoveMutationHookResult = ReturnType<typeof useBackofficeUserRolesRemoveMutation>;
export type BackofficeUserRolesRemoveMutationResult = Apollo.MutationResult<BackofficeUserRolesRemoveMutation>;
export type BackofficeUserRolesRemoveMutationOptions = Apollo.BaseMutationOptions<BackofficeUserRolesRemoveMutation, BackofficeUserRolesRemoveMutationVariables>;
export const GetUserDocument = gql`
    query getUser {
  getUser {
    email
    firstName
    lastName
    roles
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserQuery(baseOptions?: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const BackofficeUsersGetDocument = gql`
    query backofficeUsersGet {
  backofficeUsersGet {
    email
    firstName
    lastName
    roles
  }
}
    `;

/**
 * __useBackofficeUsersGetQuery__
 *
 * To run a query within a React component, call `useBackofficeUsersGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useBackofficeUsersGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBackofficeUsersGetQuery({
 *   variables: {
 *   },
 * });
 */
export function useBackofficeUsersGetQuery(baseOptions?: Apollo.QueryHookOptions<BackofficeUsersGetQuery, BackofficeUsersGetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BackofficeUsersGetQuery, BackofficeUsersGetQueryVariables>(BackofficeUsersGetDocument, options);
      }
export function useBackofficeUsersGetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BackofficeUsersGetQuery, BackofficeUsersGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BackofficeUsersGetQuery, BackofficeUsersGetQueryVariables>(BackofficeUsersGetDocument, options);
        }
export type BackofficeUsersGetQueryHookResult = ReturnType<typeof useBackofficeUsersGetQuery>;
export type BackofficeUsersGetLazyQueryHookResult = ReturnType<typeof useBackofficeUsersGetLazyQuery>;
export type BackofficeUsersGetQueryResult = Apollo.QueryResult<BackofficeUsersGetQuery, BackofficeUsersGetQueryVariables>;
export const ResendPurchaseVoucherCodeDocument = gql`
    mutation resendPurchaseVoucherCode($voucherCode: String!) {
  resendPurchaseVoucherCode(voucherCode: $voucherCode) {
    success
  }
}
    `;
export type ResendPurchaseVoucherCodeMutationFn = Apollo.MutationFunction<ResendPurchaseVoucherCodeMutation, ResendPurchaseVoucherCodeMutationVariables>;

/**
 * __useResendPurchaseVoucherCodeMutation__
 *
 * To run a mutation, you first call `useResendPurchaseVoucherCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendPurchaseVoucherCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendPurchaseVoucherCodeMutation, { data, loading, error }] = useResendPurchaseVoucherCodeMutation({
 *   variables: {
 *      voucherCode: // value for 'voucherCode'
 *   },
 * });
 */
export function useResendPurchaseVoucherCodeMutation(baseOptions?: Apollo.MutationHookOptions<ResendPurchaseVoucherCodeMutation, ResendPurchaseVoucherCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendPurchaseVoucherCodeMutation, ResendPurchaseVoucherCodeMutationVariables>(ResendPurchaseVoucherCodeDocument, options);
      }
export type ResendPurchaseVoucherCodeMutationHookResult = ReturnType<typeof useResendPurchaseVoucherCodeMutation>;
export type ResendPurchaseVoucherCodeMutationResult = Apollo.MutationResult<ResendPurchaseVoucherCodeMutation>;
export type ResendPurchaseVoucherCodeMutationOptions = Apollo.BaseMutationOptions<ResendPurchaseVoucherCodeMutation, ResendPurchaseVoucherCodeMutationVariables>;
export const VoucherCreateDocument = gql`
    mutation voucherCreate($input: VoucherCodeInput!) {
  voucherCreate(input: $input) {
    ...VoucherCodeData
  }
}
    ${VoucherCodeDataFragmentDoc}`;
export type VoucherCreateMutationFn = Apollo.MutationFunction<VoucherCreateMutation, VoucherCreateMutationVariables>;

/**
 * __useVoucherCreateMutation__
 *
 * To run a mutation, you first call `useVoucherCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVoucherCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [voucherCreateMutation, { data, loading, error }] = useVoucherCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVoucherCreateMutation(baseOptions?: Apollo.MutationHookOptions<VoucherCreateMutation, VoucherCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VoucherCreateMutation, VoucherCreateMutationVariables>(VoucherCreateDocument, options);
      }
export type VoucherCreateMutationHookResult = ReturnType<typeof useVoucherCreateMutation>;
export type VoucherCreateMutationResult = Apollo.MutationResult<VoucherCreateMutation>;
export type VoucherCreateMutationOptions = Apollo.BaseMutationOptions<VoucherCreateMutation, VoucherCreateMutationVariables>;
export const VoucherUpdateDocument = gql`
    mutation voucherUpdate($input: VoucherCodeInput!) {
  voucherUpdate(input: $input) {
    ...VoucherCodeData
  }
}
    ${VoucherCodeDataFragmentDoc}`;
export type VoucherUpdateMutationFn = Apollo.MutationFunction<VoucherUpdateMutation, VoucherUpdateMutationVariables>;

/**
 * __useVoucherUpdateMutation__
 *
 * To run a mutation, you first call `useVoucherUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVoucherUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [voucherUpdateMutation, { data, loading, error }] = useVoucherUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVoucherUpdateMutation(baseOptions?: Apollo.MutationHookOptions<VoucherUpdateMutation, VoucherUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VoucherUpdateMutation, VoucherUpdateMutationVariables>(VoucherUpdateDocument, options);
      }
export type VoucherUpdateMutationHookResult = ReturnType<typeof useVoucherUpdateMutation>;
export type VoucherUpdateMutationResult = Apollo.MutationResult<VoucherUpdateMutation>;
export type VoucherUpdateMutationOptions = Apollo.BaseMutationOptions<VoucherUpdateMutation, VoucherUpdateMutationVariables>;
export const VoucherSearchDocument = gql`
    query voucherSearch($input: SearchVoucherParamsInput!) {
  voucherSearch(input: $input) {
    ...VoucherCodeData
  }
}
    ${VoucherCodeDataFragmentDoc}`;

/**
 * __useVoucherSearchQuery__
 *
 * To run a query within a React component, call `useVoucherSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useVoucherSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVoucherSearchQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVoucherSearchQuery(baseOptions: Apollo.QueryHookOptions<VoucherSearchQuery, VoucherSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VoucherSearchQuery, VoucherSearchQueryVariables>(VoucherSearchDocument, options);
      }
export function useVoucherSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VoucherSearchQuery, VoucherSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VoucherSearchQuery, VoucherSearchQueryVariables>(VoucherSearchDocument, options);
        }
export type VoucherSearchQueryHookResult = ReturnType<typeof useVoucherSearchQuery>;
export type VoucherSearchLazyQueryHookResult = ReturnType<typeof useVoucherSearchLazyQuery>;
export type VoucherSearchQueryResult = Apollo.QueryResult<VoucherSearchQuery, VoucherSearchQueryVariables>;
export const VoucherGetDocument = gql`
    query voucherGet($code: String!) {
  voucherGet(code: $code) {
    ...VoucherCodeData
  }
}
    ${VoucherCodeDataFragmentDoc}`;

/**
 * __useVoucherGetQuery__
 *
 * To run a query within a React component, call `useVoucherGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useVoucherGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVoucherGetQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useVoucherGetQuery(baseOptions: Apollo.QueryHookOptions<VoucherGetQuery, VoucherGetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VoucherGetQuery, VoucherGetQueryVariables>(VoucherGetDocument, options);
      }
export function useVoucherGetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VoucherGetQuery, VoucherGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VoucherGetQuery, VoucherGetQueryVariables>(VoucherGetDocument, options);
        }
export type VoucherGetQueryHookResult = ReturnType<typeof useVoucherGetQuery>;
export type VoucherGetLazyQueryHookResult = ReturnType<typeof useVoucherGetLazyQuery>;
export type VoucherGetQueryResult = Apollo.QueryResult<VoucherGetQuery, VoucherGetQueryVariables>;