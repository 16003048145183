import { ReactNode } from "react"
import { toast, ToastContainer, ToastOptions } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

export type NotificationType = "success" | "error" | "info" | "warning"

const defaultOptions: ToastOptions = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
}

export const withNotification = <T,>(
  operation: () => Promise<T>,
  messages: {
    pending: ReactNode
    success: ReactNode
    error: ReactNode
  }
) =>
  toast.promise(operation, {
    pending: messages.pending as string,
    success: messages.success as string,
    error: messages.error as string,
  })

export const showSuccessAlert = (content: React.ReactNode) =>
  toast.success(content, defaultOptions)

export const showErrorAlert = (content: React.ReactNode) =>
  toast.error(content, defaultOptions)

export const showInfoAlert = (content: React.ReactNode) =>
  toast.info(content, defaultOptions)

export const showWarningAlert = (content: React.ReactNode) =>
  toast.warning(content, defaultOptions)

interface AlertInput {
  type: NotificationType
  content: React.ReactNode
}

export const showAlert = ({ content, type }: AlertInput) => {
  switch (type) {
    case "success":
      showSuccessAlert(content)
      break
    case "error":
      showErrorAlert(content)
      break
    case "info":
      showInfoAlert(content)
      break
    case "warning":
      showWarningAlert(content)
      break
    default:
      throw new Error(`Unknown notification type: ${type}`)
  }
}

export const ToastRoot = () => <ToastContainer position="top-right" />
