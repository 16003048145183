import React from "react"
import { ApolloProvider } from "@apollo/react-hooks"
import { Provider } from "react-redux"
import AuthWrapper from "../features/user/components/AuthWrapper"
import store from "../state/store"
import client from "../api/backend-api-client"
import "react-image-crop/dist/ReactCrop.css"
import "../../styles/globals.css"
import "tailwindcss/tailwind.css"
import { GtmBody, GtmHead } from "../root/analytics/gtm"
import Head from "next/head"
import LogRocket from "logrocket"
import setupLogRocketReact from "logrocket-react"
import { gtmId, logRocketAppId } from "../root/envs"
import { ToastRoot } from "../ui/notifications/toasts"

function App({ Component, pageProps }: any) {
  React.useEffect(() => {
    const logRocketId = logRocketAppId()
    if (logRocketId) {
      LogRocket.init(logRocketId)
      setupLogRocketReact(LogRocket)
    }
  })

  const gtm = gtmId()

  return (
    <>
      <Head>{gtm && <GtmHead tagManagerId={gtm} />}</Head>
      <Provider store={store}>
        <ApolloProvider client={client}>
          <AuthWrapper loginUrl="/login" loader={<div>loading</div>}>
            <Component {...pageProps} />
            {gtm && <GtmBody tagManagerId={gtm} />}
          </AuthWrapper>
        </ApolloProvider>
      </Provider>
      <ToastRoot />
    </>
  )
}

export default App
